import { httpCommon } from "./interceptor";

class HomeService {
    getAnnouncementsListAPI() {
    return httpCommon.get("system_messages_api/system_messages/");
  }
}

const homeService = new HomeService();
export default homeService;
