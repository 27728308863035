import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import footerLogo from '../../assets/images/jcaoc-logo.png';
import styles from "./layout.module.css";

const GlobalFooter: React.FC = () => {
  return (
    <footer className={styles.globalFooter}>
      <div className={styles.max_width_container}>
        <AppBar
          position="static"
          sx={{
            background: "#404040",
            height: "auto",
            minHeight: "50px"
          }}
        >
          <Toolbar
            sx={{ height: "100%", minHeight: "50px!important", margin: { md: '0rem 7rem', sm: '0 4rem', xs: '0rem' } }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{
                width: "100%",
                height: "100%",
                px: { md: 3, xs: 0 },
                marginY: { xs: 3, sm: 0 },
              }}
            >
              <Grid
                item
                xs={12}
                sm="auto"
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', sm: 'flex-start' },
                  alignItems: 'center',
                  mb: { xs: 2, sm: 0 },
                  paddingBottom: { md: 0, sm: 0, xs: 3 },
                  borderBottom: { md: 'none', sm: 'none', xs: '1px solid #dfdfdf' }
                }}
              >
                <img src={footerLogo} alt="Logo" style={{ height: '30px', marginRight: '10px' }} />
              </Grid>

              <Grid
                item
                xs={12}
                sm="auto"
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', sm: 'flex-end' },
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body2"
                  fontSize={9}
                  sx={{ color: '#F2F2F2', textAlign: { xs: 'center', sm: 'left' } }}
                >
                  © Georgia Commission on Child Support {new Date().getFullYear()}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </footer>
  );
};

export default GlobalFooter;
