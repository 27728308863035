export function getBrowserName() {
  const userAgent = navigator.userAgent;

  if (/firefox/i.test(userAgent)) {
    return "Firefox";
  } else if (/safari/i.test(userAgent) && !/chrome/i.test(userAgent)) {
    return "Safari";
  } else if (/chrome/i.test(userAgent)) {
    return "Chrome";
  } else if (/edg/i.test(userAgent)) {
    return "Edge";
  } else if (/trident/i.test(userAgent)) {
    return "Internet Explorer";
  } else {
    return "Unknown Browser";
  }
}


