import { FormErrors } from "../../types/global";

interface AddShareWorksheet {
  shareEmail: string;
  shareType: string;
  shareTerm: string;
}

export const AddShareWorksheetValidation = (
  formData: AddShareWorksheet
): { [key: string]: string } => {
  const formErrors: FormErrors = {};
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (!formData.shareEmail.trim()) {
    formErrors.shareEmail = "Email Address cannot be blank";
  } else if (!emailRegex.test(formData.shareEmail.trim())) {
    formErrors.shareEmail = "Email Address is invalid";
  }

  if (!formData.shareType.trim()) {
    formErrors.shareType = "Please select share type";
  }

  if (!formData.shareTerm.trim()) {
    formErrors.shareTerm = "Please select share term";
  }

  return formErrors;
};
