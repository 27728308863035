import React from "react";
import Header from "./header";
import styles from "./layout.module.css";
import { Box } from "@mui/system";
import Footer from "./footer";
import GlobalFooter from "./globalFooter";
import { useLocation } from "react-router-dom";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <React.Fragment>
      <div className={styles.layout_container}>
        <div>
          <Header />
        </div>
        <Box
          sx={{ marginTop: { md: "60px", xs: "55px" }, flex: 1 }}
          className={`${styles.content} ${styles.max_width_container}`}
        >
          {children}
        </Box>
        <div >
          {isHomePage ? <Footer /> : <GlobalFooter />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
