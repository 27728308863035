export const BasicChildSupportObligationTableData = [
  {
    adjusted: "800.00",
    one_child: "170.00",
    two_children: "259.00",
    three_children: "313.00",
    four_children: "349.00",
    five_children: "384.00",
    six_children: "417.00"
  },
  {
    adjusted: "850.00",
    one_child: "180.00",
    two_children: "275.00",
    three_children: "332.00",
    four_children: "371.00",
    five_children: "408.00",
    six_children: "443.00"
  },
  {
    adjusted: "900.00",
    one_child: "191.00",
    two_children: "291.00",
    three_children: "351.00",
    four_children: "392.00",
    five_children: "431.00",
    six_children: "468.00"
  },
  {
    adjusted: "950.00",
    one_child: "201.00",
    two_children: "306.00",
    three_children: "370.00",
    four_children: "413.00",
    five_children: "454.00",
    six_children: "494.00"
  },
  {
    adjusted: "1,000.00",
    one_child: "211.00",
    two_children: "322.00",
    three_children: "389.00",
    four_children: "434.00",
    five_children: "477.00",
    six_children: "519.00"
  },
  {
    adjusted: "1,050.00",
    one_child: "222.00",
    two_children: "337.00",
    three_children: "408.00",
    four_children: "455.00",
    five_children: "500.00",
    six_children: "544.00"
  },
  {
    adjusted: "1,100.00",
    one_child: "231.00",
    two_children: "352.00",
    three_children: "426.00",
    four_children: "475.00",
    five_children: "522.00",
    six_children: "568.00"
  },
  {
    adjusted: "1,150.00",
    one_child: "240.00",
    two_children: "366.00",
    three_children: "442.00",
    four_children: "493.00",
    five_children: "543.00",
    six_children: "590.00"
  },
  {
    adjusted: "1,200.00",
    one_child: "249.00",
    two_children: "379.00",
    three_children: "459.00",
    four_children: "512.00",
    five_children: "563.00",
    six_children: "612.00"
  },
  {
    adjusted: "1,250.00",
    one_child: "258.00",
    two_children: "393.00",
    three_children: "475.00",
    four_children: "530.00",
    five_children: "583.00",
    six_children: "634.00"
  },
  {
    adjusted: "1,300.00",
    one_child: "267.00",
    two_children: "407.00",
    three_children: "492.00",
    four_children: "548.00",
    five_children: "603.00",
    six_children: "655.00"
  },
  {
    adjusted: "1,350.00",
    one_child: "276.00",
    two_children: "420.00",
    three_children: "508.00",
    four_children: "566.00",
    five_children: "623.00",
    six_children: "677.00"
  },
  {
    adjusted: "1,400.00",
    one_child: "285.00",
    two_children: "433.00",
    three_children: "524.00",
    four_children: "584.00",
    five_children: "643.00",
    six_children: "699.00"
  },
  {
    adjusted: "1,450.00",
    one_child: "293.00",
    two_children: "447.00",
    three_children: "540.00",
    four_children: "603.00",
    five_children: "663.00",
    six_children: "720.00"
  },
  {
    adjusted: "1,500.00",
    one_child: "302.00",
    two_children: "460.00",
    three_children: "557.00",
    four_children: "621.00",
    five_children: "683.00",
    six_children: "742.00"
  },
  {
    adjusted: "1,550.00",
    one_child: "311.00",
    two_children: "474.00",
    three_children: "573.00",
    four_children: "639.00",
    five_children: "703.00",
    six_children: "764.00"
  },
  {
    adjusted: "1,600.00",
    one_child: "320.00",
    two_children: "487.00",
    three_children: "589.00",
    four_children: "657.00",
    five_children: "723.00",
    six_children: "786.00"
  },
  {
    adjusted: "1,650.00",
    one_child: "329.00",
    two_children: "501.00",
    three_children: "606.00",
    four_children: "675.00",
    five_children: "743.00",
    six_children: "807.00"
  },
  {
    adjusted: "1,700.00",
    one_child: "338.00",
    two_children: "514.00",
    three_children: "622.00",
    four_children: "693.00",
    five_children: "763.00",
    six_children: "829.00"
  },
  {
    adjusted: "1,750.00",
    one_child: "346.00",
    two_children: "528.00",
    three_children: "638.00",
    four_children: "711.00",
    five_children: "783.00",
    six_children: "851.00"
  },
  {
    adjusted: "1,800.00",
    one_child: "355.00",
    two_children: "541.00",
    three_children: "654.00",
    four_children: "730.00",
    five_children: "802.00",
    six_children: "872.00"
  },
  {
    adjusted: "1,850.00",
    one_child: "364.00",
    two_children: "555.00",
    three_children: "671.00",
    four_children: "748.00",
    five_children: "822.00",
    six_children: "894.00"
  },
  {
    adjusted: "1,900.00",
    one_child: "373.00",
    two_children: "568.00",
    three_children: "687.00",
    four_children: "766.00",
    five_children: "842.00",
    six_children: "916.00"
  },
  {
    adjusted: "1,950.00",
    one_child: "382.00",
    two_children: "581.00",
    three_children: "703.00",
    four_children: "784.00",
    five_children: "862.00",
    six_children: "937.00"
  },
  {
    adjusted: "2,000.00",
    one_child: "390.00",
    two_children: "594.00",
    three_children: "719.00",
    four_children: "802.00",
    five_children: "882.00",
    six_children: "958.00"
  },
  {
    adjusted: "2,050.00",
    one_child: "399.00",
    two_children: "608.00",
    three_children: "735.00",
    four_children: "819.00",
    five_children: "901.00",
    six_children: "979.00"
  },
  {
    adjusted: "2,100.00",
    one_child: "407.00",
    two_children: "621.00",
    three_children: "751.00",
    four_children: "837.00",
    five_children: "921.00",
    six_children: "1,001.00"
  },
  {
    adjusted: "2,150.00",
    one_child: "416.00",
    two_children: "634.00",
    three_children: "766.00",
    four_children: "855.00",
    five_children: "940.00",
    six_children: "1,022.00"
  },
  {
    adjusted: "2,200.00",
    one_child: "425.00",
    two_children: "647.00",
    three_children: "782.00",
    four_children: "872.00",
    five_children: "959.00",
    six_children: "1,043.00"
  },
  {
    adjusted: "2,250.00",
    one_child: "433.00",
    two_children: "660.00",
    three_children: "798.00",
    four_children: "890.00",
    five_children: "979.00",
    six_children: "1,064.00"
  },
  {
    adjusted: "2,300.00",
    one_child: "442.00",
    two_children: "673.00",
    three_children: "814.00",
    four_children: "908.00",
    five_children: "998.00",
    six_children: "1,085.00"
  },
  {
    adjusted: "2,350.00",
    one_child: "450.00",
    two_children: "686.00",
    three_children: "830.00",
    four_children: "925.00",
    five_children: "1,018.00",
    six_children: "1,106.00"
  },
  {
    adjusted: "2,400.00",
    one_child: "459.00",
    two_children: "699.00",
    three_children: "846.00",
    four_children: "943.00",
    five_children: "1,037.00",
    six_children: "1,127.00"
  },
  {
    adjusted: "2,450.00",
    one_child: "468.00",
    two_children: "712.00",
    three_children: "862.00",
    four_children: "961.00",
    five_children: "1,057.00",
    six_children: "1,149.00"
  },
  {
    adjusted: "2,500.00",
    one_child: "476.00",
    two_children: "726.00",
    three_children: "877.00",
    four_children: "978.00",
    five_children: "1,076.00",
    six_children: "1,170.00"
  },
  {
    adjusted: "2,550.00",
    one_child: "485.00",
    two_children: "739.00",
    three_children: "893.00",
    four_children: "996.00",
    five_children: "1,095.00",
    six_children: "1,191.00"
  },
  {
    adjusted: "2,600.00",
    one_child: "494.00",
    two_children: "752.00",
    three_children: "909.00",
    four_children: "1,014.00",
    five_children: "1,115.00",
    six_children: "1,212.00"
  },
  {
    adjusted: "2,650.00",
    one_child: "502.00",
    two_children: "765.00",
    three_children: "925.00",
    four_children: "1,031.00",
    five_children: "1,134.00",
    six_children: "1,233.00"
  },
  {
    adjusted: "2,700.00",
    one_child: "511.00",
    two_children: "778.00",
    three_children: "941.00",
    four_children: "1,049.00",
    five_children: "1,154.00",
    six_children: "1,254.00"
  },
  {
    adjusted: "2,750.00",
    one_child: "519.00",
    two_children: "791.00",
    three_children: "957.00",
    four_children: "1,067.00",
    five_children: "1,173.00",
    six_children: "1,275.00"
  },
  {
    adjusted: "2,800.00",
    one_child: "528.00",
    two_children: "804.00",
    three_children: "973.00",
    four_children: "1,084.00",
    five_children: "1,193.00",
    six_children: "1,296.00"
  },
  {
    adjusted: "2,850.00",
    one_child: "537.00",
    two_children: "817.00",
    three_children: "988.00",
    four_children: "1,102.00",
    five_children: "1,212.00",
    six_children: "1,318.00"
  },
  {
    adjusted: "2,900.00",
    one_child: "545.00",
    two_children: "830.00",
    three_children: "1,004.00",
    four_children: "1,120.00",
    five_children: "1,232.00",
    six_children: "1,339.00"
  },
  {
    adjusted: "2,950.00",
    one_child: "554.00",
    two_children: "844.00",
    three_children: "1,020.00",
    four_children: "1,137.00",
    five_children: "1,251.00",
    six_children: "1,360.00"
  },
  {
    adjusted: "3,000.00",
    one_child: "562.00",
    two_children: "857.00",
    three_children: "1,036.00",
    four_children: "1,155.00",
    five_children: "1,270.00",
    six_children: "1,381.00"
  },
  {
    adjusted: "3,050.00",
    one_child: "571.00",
    two_children: "870.00",
    three_children: "1,052.00",
    four_children: "1,173.00",
    five_children: "1,290.00",
    six_children: "1,402.00"
  },
  {
    adjusted: "3,100.00",
    one_child: "580.00",
    two_children: "883.00",
    three_children: "1,068.00",
    four_children: "1,190.00",
    five_children: "1,309.00",
    six_children: "1,423.00"
  },
  {
    adjusted: "3,150.00",
    one_child: "588.00",
    two_children: "896.00",
    three_children: "1,084.00",
    four_children: "1,208.00",
    five_children: "1,329.00",
    six_children: "1,444.00"
  },
  {
    adjusted: "3,200.00",
    one_child: "597.00",
    two_children: "909.00",
    three_children: "1,099.00",
    four_children: "1,226.00",
    five_children: "1,348.00",
    six_children: "1,465.00"
  },
  {
    adjusted: "3,250.00",
    one_child: "605.00",
    two_children: "922.00",
    three_children: "1,115.00",
    four_children: "1,243.00",
    five_children: "1,368.00",
    six_children: "1,487.00"
  },
  {
    adjusted: "3,300.00",
    one_child: "614.00",
    two_children: "935.00",
    three_children: "1,131.00",
    four_children: "1,261.00",
    five_children: "1,387.00",
    six_children: "1,508.00"
  },
  {
    adjusted: "3,350.00",
    one_child: "623.00",
    two_children: "948.00",
    three_children: "1,147.00",
    four_children: "1,279.00",
    five_children: "1,406.00",
    six_children: "1,529.00"
  },
  {
    adjusted: "3,400.00",
    one_child: "631.00",
    two_children: "962.00",
    three_children: "1,163.00",
    four_children: "1,296.00",
    five_children: "1,426.00",
    six_children: "1,550.00"
  },
  {
    adjusted: "3,450.00",
    one_child: "640.00",
    two_children: "975.00",
    three_children: "1,179.00",
    four_children: "1,314.00",
    five_children: "1,445.00",
    six_children: "1,571.00"
  },
  {
    adjusted: "3,500.00",
    one_child: "648.00",
    two_children: "988.00",
    three_children: "1,194.00",
    four_children: "1,332.00",
    five_children: "1,465.00",
    six_children: "1,592.00"
  },
  {
    adjusted: "3,550.00",
    one_child: "657.00",
    two_children: "1,001.00",
    three_children: "1,210.00",
    four_children: "1,349.00",
    five_children: "1,484.00",
    six_children: "1,613.00"
  },
  {
    adjusted: "3,600.00",
    one_child: "666.00",
    two_children: "1,014.00",
    three_children: "1,226.00",
    four_children: "1,367.00",
    five_children: "1,504.00",
    six_children: "1,635.00"
  },
  {
    adjusted: "3,650.00",
    one_child: "674.00",
    two_children: "1,027.00",
    three_children: "1,242.00",
    four_children: "1,385.00",
    five_children: "1,523.00",
    six_children: "1,656.00"
  },
  {
    adjusted: "3,700.00",
    one_child: "683.00",
    two_children: "1,040.00",
    three_children: "1,258.00",
    four_children: "1,402.00",
    five_children: "1,543.00",
    six_children: "1,677.00"
  },
  {
    adjusted: "3,750.00",
    one_child: "691.00",
    two_children: "1,053.00",
    three_children: "1,274.00",
    four_children: "1,420.00",
    five_children: "1,562.00",
    six_children: "1,698.00"
  },
  {
    adjusted: "3,800.00",
    one_child: "700.00",
    two_children: "1,066.00",
    three_children: "1,290.00",
    four_children: "1,438.00",
    five_children: "1,581.00",
    six_children: "1,719.00"
  },
  {
    adjusted: "3,850.00",
    one_child: "709.00",
    two_children: "1,079.00",
    three_children: "1,305.00",
    four_children: "1,455.00",
    five_children: "1,601.00",
    six_children: "1,740.00"
  },
  {
    adjusted: "3,900.00",
    one_child: "717.00",
    two_children: "1,093.00",
    three_children: "1,321.00",
    four_children: "1,473.00",
    five_children: "1,620.00",
    six_children: "1,761.00"
  },
  {
    adjusted: "3,950.00",
    one_child: "726.00",
    two_children: "1,105.00",
    three_children: "1,336.00",
    four_children: "1,492.00",
    five_children: "1,641.00",
    six_children: "1,784.00"
  },
  {
    adjusted: "4,000.00",
    one_child: "733.00",
    two_children: "1,116.00",
    three_children: "1,349.00",
    four_children: "1,507.00",
    five_children: "1,657.00",
    six_children: "1,802.00"
  },
  {
    adjusted: "4,050.00",
    one_child: "741.00",
    two_children: "1,128.00",
    three_children: "1,362.00",
    four_children: "1,521.00",
    five_children: "1,674.00",
    six_children: "1,819.00"
  },
  {
    adjusted: "4,100.00",
    one_child: "749.00",
    two_children: "1,139.00",
    three_children: "1,375.00",
    four_children: "1,536.00",
    five_children: "1,690.00",
    six_children: "1,837.00"
  },
  {
    adjusted: "4,150.00",
    one_child: "757.00",
    two_children: "1,151.00",
    three_children: "1,388.00",
    four_children: "1,551.00",
    five_children: "1,706.00",
    six_children: "1,854.00"
  },
  {
    adjusted: "4,200.00",
    one_child: "765.00",
    two_children: "1,162.00",
    three_children: "1,401.00",
    four_children: "1,565.00",
    five_children: "1,722.00",
    six_children: "1,872.00"
  },
  {
    adjusted: "4,250.00",
    one_child: "773.00",
    two_children: "1,173.00",
    three_children: "1,414.00",
    four_children: "1,580.00",
    five_children: "1,738.00",
    six_children: "1,889.00"
  },
  {
    adjusted: "4,300.00",
    one_child: "781.00",
    two_children: "1,185.00",
    three_children: "1,428.00",
    four_children: "1,595.00",
    five_children: "1,754.00",
    six_children: "1,907.00"
  },
  {
    adjusted: "4,350.00",
    one_child: "788.00",
    two_children: "1,196.00",
    three_children: "1,441.00",
    four_children: "1,609.00",
    five_children: "1,770.00",
    six_children: "1,924.00"
  },
  {
    adjusted: "4,400.00",
    one_child: "796.00",
    two_children: "1,208.00",
    three_children: "1,454.00",
    four_children: "1,624.00",
    five_children: "1,786.00",
    six_children: "1,942.00"
  },
  {
    adjusted: "4,450.00",
    one_child: "804.00",
    two_children: "1,219.00",
    three_children: "1,467.00",
    four_children: "1,638.00",
    five_children: "1,802.00",
    six_children: "1,959.00"
  },
  {
    adjusted: "4,500.00",
    one_child: "812.00",
    two_children: "1,230.00",
    three_children: "1,480.00",
    four_children: "1,653.00",
    five_children: "1,818.00",
    six_children: "1,977.00"
  },
  {
    adjusted: "4,550.00",
    one_child: "820.00",
    two_children: "1,242.00",
    three_children: "1,493.00",
    four_children: "1,668.00",
    five_children: "1,834.00",
    six_children: "1,994.00"
  },
  {
    adjusted: "4,600.00",
    one_child: "828.00",
    two_children: "1,253.00",
    three_children: "1,506.00",
    four_children: "1,682.00",
    five_children: "1,850.00",
    six_children: "2,011.00"
  },
  {
    adjusted: "4,650.00",
    one_child: "835.00",
    two_children: "1,264.00",
    three_children: "1,519.00",
    four_children: "1,696.00",
    five_children: "1,866.00",
    six_children: "2,028.00"
  },
  {
    adjusted: "4,700.00",
    one_child: "843.00",
    two_children: "1,275.00",
    three_children: "1,532.00",
    four_children: "1,711.00",
    five_children: "1,882.00",
    six_children: "2,046.00"
  },
  {
    adjusted: "4,750.00",
    one_child: "850.00",
    two_children: "1,286.00",
    three_children: "1,545.00",
    four_children: "1,726.00",
    five_children: "1,898.00",
    six_children: "2,063.00"
  },
  {
    adjusted: "4,800.00",
    one_child: "857.00",
    two_children: "1,297.00",
    three_children: "1,558.00",
    four_children: "1,740.00",
    five_children: "1,914.00",
    six_children: "2,081.00"
  },
  {
    adjusted: "4,850.00",
    one_child: "865.00",
    two_children: "1,308.00",
    three_children: "1,571.00",
    four_children: "1,755.00",
    five_children: "1,930.00",
    six_children: "2,098.00"
  },
  {
    adjusted: "4,900.00",
    one_child: "872.00",
    two_children: "1,319.00",
    three_children: "1,584.00",
    four_children: "1,770.00",
    five_children: "1,947.00",
    six_children: "2,116.00"
  },
  {
    adjusted: "4,950.00",
    one_child: "880.00",
    two_children: "1,330.00",
    three_children: "1,597.00",
    four_children: "1,784.00",
    five_children: "1,963.00",
    six_children: "2,133.00"
  },
  {
    adjusted: "5,000.00",
    one_child: "887.00",
    two_children: "1,341.00",
    three_children: "1,610.00",
    four_children: "1,799.00",
    five_children: "1,979.00",
    six_children: "2,151.00"
  },
  {
    adjusted: "5,050.00",
    one_child: "894.00",
    two_children: "1,352.00",
    three_children: "1,624.00",
    four_children: "1,814.00",
    five_children: "1,995.00",
    six_children: "2,168.00"
  },
  {
    adjusted: "5,100.00",
    one_child: "902.00",
    two_children: "1,363.00",
    three_children: "1,637.00",
    four_children: "1,828.00",
    five_children: "2,011.00",
    six_children: "2,186.00"
  },
  {
    adjusted: "5,150.00",
    one_child: "909.00",
    two_children: "1,374.00",
    three_children: "1,650.00",
    four_children: "1,843.00",
    five_children: "2,027.00",
    six_children: "2,203.00"
  },
  {
    adjusted: "5,200.00",
    one_child: "917.00",
    two_children: "1,385.00",
    three_children: "1,663.00",
    four_children: "1,857.00",
    five_children: "2,043.00",
    six_children: "2,221.00"
  },
  {
    adjusted: "5,250.00",
    one_child: "924.00",
    two_children: "1,396.00",
    three_children: "1,676.00",
    four_children: "1,872.00",
    five_children: "2,059.00",
    six_children: "2,238.00"
  },
  {
    adjusted: "5,300.00",
    one_child: "931.00",
    two_children: "1,407.00",
    three_children: "1,689.00",
    four_children: "1,887.00",
    five_children: "2,075.00",
    six_children: "2,256.00"
  },
  {
    adjusted: "5,350.00",
    one_child: "937.00",
    two_children: "1,416.00",
    three_children: "1,700.00",
    four_children: "1,899.00",
    five_children: "2,089.00",
    six_children: "2,271.00"
  },
  {
    adjusted: "5,400.00",
    one_child: "943.00",
    two_children: "1,424.00",
    three_children: "1,710.00",
    four_children: "1,910.00",
    five_children: "2,101.00",
    six_children: "2,284.00"
  },
  {
    adjusted: "5,450.00",
    one_child: "948.00",
    two_children: "1,433.00",
    three_children: "1,719.00",
    four_children: "1,921.00",
    five_children: "2,113.00",
    six_children: "2,297.00"
  },
  {
    adjusted: "5,500.00",
    one_child: "954.00",
    two_children: "1,441.00",
    three_children: "1,729.00",
    four_children: "1,931.00",
    five_children: "2,125.00",
    six_children: "2,309.00"
  },
  {
    adjusted: "5,550.00",
    one_child: "959.00",
    two_children: "1,449.00",
    three_children: "1,739.00",
    four_children: "1,942.00",
    five_children: "2,136.00",
    six_children: "2,322.00"
  },
  {
    adjusted: "5,600.00",
    one_child: "965.00",
    two_children: "1,457.00",
    three_children: "1,748.00",
    four_children: "1,953.00",
    five_children: "2,148.00",
    six_children: "2,335.00"
  },
  {
    adjusted: "5,650.00",
    one_child: "970.00",
    two_children: "1,466.00",
    three_children: "1,758.00",
    four_children: "1,964.00",
    five_children: "2,160.00",
    six_children: "2,348.00"
  },
  {
    adjusted: "5,700.00",
    one_child: "976.00",
    two_children: "1,474.00",
    three_children: "1,768.00",
    four_children: "1,975.00",
    five_children: "2,172.00",
    six_children: "2,361.00"
  },
  {
    adjusted: "5,750.00",
    one_child: "981.00",
    two_children: "1,482.00",
    three_children: "1,777.00",
    four_children: "1,985.00",
    five_children: "2,184.00",
    six_children: "2,374.00"
  },
  {
    adjusted: "5,800.00",
    one_child: "987.00",
    two_children: "1,490.00",
    three_children: "1,787.00",
    four_children: "1,996.00",
    five_children: "2,196.00",
    six_children: "2,387.00"
  },
  {
    adjusted: "5,850.00",
    one_child: "992.00",
    two_children: "1,498.00",
    three_children: "1,797.00",
    four_children: "2,007.00",
    five_children: "2,208.00",
    six_children: "2,400.00"
  },
  {
    adjusted: "5,900.00",
    one_child: "998.00",
    two_children: "1,507.00",
    three_children: "1,806.00",
    four_children: "2,018.00",
    five_children: "2,220.00",
    six_children: "2,413.00"
  },
  {
    adjusted: "5,950.00",
    one_child: "1,003.00",
    two_children: "1,515.00",
    three_children: "1,816.00",
    four_children: "2,029.00",
    five_children: "2,231.00",
    six_children: "2,426.00"
  },
  {
    adjusted: "6,000.00",
    one_child: "1,009.00",
    two_children: "1,523.00",
    three_children: "1,826.00",
    four_children: "2,039.00",
    five_children: "2,243.00",
    six_children: "2,438.00"
  },
  {
    adjusted: "6,050.00",
    one_child: "1,014.00",
    two_children: "1,531.00",
    three_children: "1,835.00",
    four_children: "2,050.00",
    five_children: "2,255.00",
    six_children: "2,451.00"
  },
  {
    adjusted: "6,100.00",
    one_child: "1,018.00",
    two_children: "1,537.00",
    three_children: "1,842.00",
    four_children: "2,058.00",
    five_children: "2,264.00",
    six_children: "2,460.00"
  },
  {
    adjusted: "6,150.00",
    one_child: "1,022.00",
    two_children: "1,542.00",
    three_children: "1,848.00",
    four_children: "2,064.00",
    five_children: "2,270.00",
    six_children: "2,468.00"
  },
  {
    adjusted: "6,200.00",
    one_child: "1,025.00",
    two_children: "1,547.00",
    three_children: "1,853.00",
    four_children: "2,070.00",
    five_children: "2,277.00",
    six_children: "2,475.00"
  },
  {
    adjusted: "6,250.00",
    one_child: "1,028.00",
    two_children: "1,552.00",
    three_children: "1,859.00",
    four_children: "2,076.00",
    five_children: "2,284.00",
    six_children: "2,483.00"
  },
  {
    adjusted: "6,300.00",
    one_child: "1,032.00",
    two_children: "1,557.00",
    three_children: "1,864.00",
    four_children: "2,082.00",
    five_children: "2,291.00",
    six_children: "2,490.00"
  },
  {
    adjusted: "6,350.00",
    one_child: "1,035.00",
    two_children: "1,561.00",
    three_children: "1,870.00",
    four_children: "2,089.00",
    five_children: "2,298.00",
    six_children: "2,497.00"
  },
  {
    adjusted: "6,400.00",
    one_child: "1,038.00",
    two_children: "1,566.00",
    three_children: "1,875.00",
    four_children: "2,095.00",
    five_children: "2,304.00",
    six_children: "2,505.00"
  },
  {
    adjusted: "6,450.00",
    one_child: "1,042.00",
    two_children: "1,571.00",
    three_children: "1,881.00",
    four_children: "2,101.00",
    five_children: "2,311.00",
    six_children: "2,512.00"
  },
  {
    adjusted: "6,500.00",
    one_child: "1,045.00",
    two_children: "1,576.00",
    three_children: "1,886.00",
    four_children: "2,107.00",
    five_children: "2,318.00",
    six_children: "2,520.00"
  },
  {
    adjusted: "6,550.00",
    one_child: "1,049.00",
    two_children: "1,581.00",
    three_children: "1,892.00",
    four_children: "2,113.00",
    five_children: "2,325.00",
    six_children: "2,527.00"
  },
  {
    adjusted: "6,600.00",
    one_child: "1,052.00",
    two_children: "1,586.00",
    three_children: "1,898.00",
    four_children: "2,120.00",
    five_children: "2,331.00",
    six_children: "2,534.00"
  },
  {
    adjusted: "6,650.00",
    one_child: "1,055.00",
    two_children: "1,591.00",
    three_children: "1,903.00",
    four_children: "2,126.00",
    five_children: "2,338.00",
    six_children: "2,542.00"
  },
  {
    adjusted: "6,700.00",
    one_child: "1,059.00",
    two_children: "1,595.00",
    three_children: "1,909.00",
    four_children: "2,132.00",
    five_children: "2,345.00",
    six_children: "2,549.00"
  },
  {
    adjusted: "6,750.00",
    one_child: "1,062.00",
    two_children: "1,600.00",
    three_children: "1,914.00",
    four_children: "2,138.00",
    five_children: "2,352.00",
    six_children: "2,556.00"
  },
  {
    adjusted: "6,800.00",
    one_child: "1,066.00",
    two_children: "1,605.00",
    three_children: "1,920.00",
    four_children: "2,144.00",
    five_children: "2,359.00",
    six_children: "2,564.00"
  },
  {
    adjusted: "6,850.00",
    one_child: "1,069.00",
    two_children: "1,609.00",
    three_children: "1,923.00",
    four_children: "2,148.00",
    five_children: "2,363.00",
    six_children: "2,569.00"
  },
  {
    adjusted: "6,900.00",
    one_child: "1,072.00",
    two_children: "1,613.00",
    three_children: "1,926.00",
    four_children: "2,152.00",
    five_children: "2,367.00",
    six_children: "2,573.00"
  },
  {
    adjusted: "6,950.00",
    one_child: "1,075.00",
    two_children: "1,616.00",
    three_children: "1,929.00",
    four_children: "2,155.00",
    five_children: "2,370.00",
    six_children: "2,577.00"
  },
  {
    adjusted: "7,000.00",
    one_child: "1,078.00",
    two_children: "1,620.00",
    three_children: "1,932.00",
    four_children: "2,158.00",
    five_children: "2,374.00",
    six_children: "2,581.00"
  },
  {
    adjusted: "7,050.00",
    one_child: "1,081.00",
    two_children: "1,624.00",
    three_children: "1,935.00",
    four_children: "2,162.00",
    five_children: "2,378.00",
    six_children: "2,585.00"
  },
  {
    adjusted: "7,100.00",
    one_child: "1,084.00",
    two_children: "1,627.00",
    three_children: "1,938.00",
    four_children: "2,165.00",
    five_children: "2,382.00",
    six_children: "2,589.00"
  },
  {
    adjusted: "7,150.00",
    one_child: "1,087.00",
    two_children: "1,631.00",
    three_children: "1,941.00",
    four_children: "2,169.00",
    five_children: "2,385.00",
    six_children: "2,593.00"
  },
  {
    adjusted: "7,200.00",
    one_child: "1,090.00",
    two_children: "1,634.00",
    three_children: "1,944.00",
    four_children: "2,172.00",
    five_children: "2,389.00",
    six_children: "2,597.00"
  },
  {
    adjusted: "7,250.00",
    one_child: "1,093.00",
    two_children: "1,638.00",
    three_children: "1,947.00",
    four_children: "2,175.00",
    five_children: "2,393.00",
    six_children: "2,601.00"
  },
  {
    adjusted: "7,300.00",
    one_child: "1,097.00",
    two_children: "1,642.00",
    three_children: "1,950.00",
    four_children: "2,179.00",
    five_children: "2,397.00",
    six_children: "2,605.00"
  },
  {
    adjusted: "7,350.00",
    one_child: "1,100.00",
    two_children: "1,645.00",
    three_children: "1,953.00",
    four_children: "2,182.00",
    five_children: "2,400.00",
    six_children: "2,609.00"
  },
  {
    adjusted: "7,400.00",
    one_child: "1,103.00",
    two_children: "1,649.00",
    three_children: "1,956.00",
    four_children: "2,185.00",
    five_children: "2,404.00",
    six_children: "2,613.00"
  },
  {
    adjusted: "7,450.00",
    one_child: "1,106.00",
    two_children: "1,652.00",
    three_children: "1,960.00",
    four_children: "2,189.00",
    five_children: "2,408.00",
    six_children: "2,617.00"
  },
  {
    adjusted: "7,500.00",
    one_child: "1,109.00",
    two_children: "1,656.00",
    three_children: "1,963.00",
    four_children: "2,192.00",
    five_children: "2,411.00",
    six_children: "2,621.00"
  },
  {
    adjusted: "7,550.00",
    one_child: "1,112.00",
    two_children: "1,660.00",
    three_children: "1,966.00",
    four_children: "2,196.00",
    five_children: "2,415.00",
    six_children: "2,625.00"
  },
  {
    adjusted: "7,600.00",
    one_child: "1,116.00",
    two_children: "1,666.00",
    three_children: "1,974.00",
    four_children: "2,205.00",
    five_children: "2,426.00",
    six_children: "2,637.00"
  },
  {
    adjusted: "7,650.00",
    one_child: "1,120.00",
    two_children: "1,673.00",
    three_children: "1,984.00",
    four_children: "2,216.00",
    five_children: "2,437.00",
    six_children: "2,649.00"
  },
  {
    adjusted: "7,700.00",
    one_child: "1,125.00",
    two_children: "1,681.00",
    three_children: "1,993.00",
    four_children: "2,226.00",
    five_children: "2,449.00",
    six_children: "2,662.00"
  },
  {
    adjusted: "7,750.00",
    one_child: "1,129.00",
    two_children: "1,688.00",
    three_children: "2,003.00",
    four_children: "2,237.00",
    five_children: "2,461.00",
    six_children: "2,675.00"
  },
  {
    adjusted: "7,800.00",
    one_child: "1,133.00",
    two_children: "1,695.00",
    three_children: "2,012.00",
    four_children: "2,247.00",
    five_children: "2,472.00",
    six_children: "2,687.00"
  },
  {
    adjusted: "7,850.00",
    one_child: "1,137.00",
    two_children: "1,702.00",
    three_children: "2,022.00",
    four_children: "2,258.00",
    five_children: "2,484.00",
    six_children: "2,700.00"
  },
  {
    adjusted: "7,900.00",
    one_child: "1,142.00",
    two_children: "1,709.00",
    three_children: "2,031.00",
    four_children: "2,269.00",
    five_children: "2,495.00",
    six_children: "2,713.00"
  },
  {
    adjusted: "7,950.00",
    one_child: "1,146.00",
    two_children: "1,716.00",
    three_children: "2,040.00",
    four_children: "2,279.00",
    five_children: "2,507.00",
    six_children: "2,725.00"
  },
  {
    adjusted: "8,000.00",
    one_child: "1,150.00",
    two_children: "1,723.00",
    three_children: "2,050.00",
    four_children: "2,290.00",
    five_children: "2,519.00",
    six_children: "2,738.00"
  },
  {
    adjusted: "8,050.00",
    one_child: "1,154.00",
    two_children: "1,731.00",
    three_children: "2,059.00",
    four_children: "2,300.00",
    five_children: "2,530.00",
    six_children: "2,750.00"
  },
  {
    adjusted: "8,100.00",
    one_child: "1,159.00",
    two_children: "1,738.00",
    three_children: "2,069.00",
    four_children: "2,311.00",
    five_children: "2,542.00",
    six_children: "2,763.00"
  },
  {
    adjusted: "8,150.00",
    one_child: "1,163.00",
    two_children: "1,745.00",
    three_children: "2,078.00",
    four_children: "2,321.00",
    five_children: "2,554.00",
    six_children: "2,776.00"
  },
  {
    adjusted: "8,200.00",
    one_child: "1,167.00",
    two_children: "1,752.00",
    three_children: "2,088.00",
    four_children: "2,332.00",
    five_children: "2,565.00",
    six_children: "2,788.00"
  },
  {
    adjusted: "8,250.00",
    one_child: "1,171.00",
    two_children: "1,759.00",
    three_children: "2,097.00",
    four_children: "2,342.00",
    five_children: "2,577.00",
    six_children: "2,801.00"
  },
  {
    adjusted: "8,300.00",
    one_child: "1,176.00",
    two_children: "1,766.00",
    three_children: "2,107.00",
    four_children: "2,353.00",
    five_children: "2,588.00",
    six_children: "2,814.00"
  },
  {
    adjusted: "8,350.00",
    one_child: "1,178.00",
    two_children: "1,769.00",
    three_children: "2,109.00",
    four_children: "2,356.00",
    five_children: "2,592.00",
    six_children: "2,817.00"
  },
  {
    adjusted: "8,400.00",
    one_child: "1,181.00",
    two_children: "1,772.00",
    three_children: "2,112.00",
    four_children: "2,359.00",
    five_children: "2,595.00",
    six_children: "2,820.00"
  },
  {
    adjusted: "8,450.00",
    one_child: "1,183.00",
    two_children: "1,775.00",
    three_children: "2,114.00",
    four_children: "2,361.00",
    five_children: "2,598.00",
    six_children: "2,824.00"
  },
  {
    adjusted: "8,500.00",
    one_child: "1,186.00",
    two_children: "1,778.00",
    three_children: "2,117.00",
    four_children: "2,364.00",
    five_children: "2,601.00",
    six_children: "2,827.00"
  },
  {
    adjusted: "8,550.00",
    one_child: "1,189.00",
    two_children: "1,781.00",
    three_children: "2,119.00",
    four_children: "2,367.00",
    five_children: "2,604.00",
    six_children: "2,830.00"
  },
  {
    adjusted: "8,600.00",
    one_child: "1,191.00",
    two_children: "1,784.00",
    three_children: "2,121.00",
    four_children: "2,370.00",
    five_children: "2,606.00",
    six_children: "2,833.00"
  },
  {
    adjusted: "8,650.00",
    one_child: "1,194.00",
    two_children: "1,787.00",
    three_children: "2,124.00",
    four_children: "2,372.00",
    five_children: "2,609.00",
    six_children: "2,836.00"
  },
  {
    adjusted: "8,700.00",
    one_child: "1,196.00",
    two_children: "1,790.00",
    three_children: "2,126.00",
    four_children: "2,375.00",
    five_children: "2,612.00",
    six_children: "2,840.00"
  },
  {
    adjusted: "8,750.00",
    one_child: "1,199.00",
    two_children: "1,793.00",
    three_children: "2,128.00",
    four_children: "2,377.00",
    five_children: "2,615.00",
    six_children: "2,843.00"
  },
  {
    adjusted: "8,800.00",
    one_child: "1,201.00",
    two_children: "1,796.00",
    three_children: "2,131.00",
    four_children: "2,380.00",
    five_children: "2,618.00",
    six_children: "2,846.00"
  },
  {
    adjusted: "8,850.00",
    one_child: "1,204.00",
    two_children: "1,799.00",
    three_children: "2,133.00",
    four_children: "2,383.00",
    five_children: "2,621.00",
    six_children: "2,849.00"
  },
  {
    adjusted: "8,900.00",
    one_child: "1,206.00",
    two_children: "1,801.00",
    three_children: "2,136.00",
    four_children: "2,385.00",
    five_children: "2,624.00",
    six_children: "2,852.00"
  },
  {
    adjusted: "8,950.00",
    one_child: "1,209.00",
    two_children: "1,804.00",
    three_children: "2,138.00",
    four_children: "2,388.00",
    five_children: "2,627.00",
    six_children: "2,855.00"
  },
  {
    adjusted: "9,000.00",
    one_child: "1,211.00",
    two_children: "1,807.00",
    three_children: "2,140.00",
    four_children: "2,391.00",
    five_children: "2,630.00",
    six_children: "2,859.00"
  },
  {
    adjusted: "9,050.00",
    one_child: "1,214.00",
    two_children: "1,810.00",
    three_children: "2,143.00",
    four_children: "2,393.00",
    five_children: "2,633.00",
    six_children: "2,862.00"
  },
  {
    adjusted: "9,100.00",
    one_child: "1,217.00",
    two_children: "1,814.00",
    three_children: "2,147.00",
    four_children: "2,398.00",
    five_children: "2,638.00",
    six_children: "2,867.00"
  },
  {
    adjusted: "9,150.00",
    one_child: "1,220.00",
    two_children: "1,819.00",
    three_children: "2,151.00",
    four_children: "2,403.00",
    five_children: "2,643.00",
    six_children: "2,873.00"
  },
  {
    adjusted: "9,200.00",
    one_child: "1,224.00",
    two_children: "1,823.00",
    three_children: "2,156.00",
    four_children: "2,408.00",
    five_children: "2,649.00",
    six_children: "2,880.00"
  },
  {
    adjusted: "9,250.00",
    one_child: "1,227.00",
    two_children: "1,828.00",
    three_children: "2,161.00",
    four_children: "2,413.00",
    five_children: "2,655.00",
    six_children: "2,886.00"
  },
  {
    adjusted: "9,300.00",
    one_child: "1,231.00",
    two_children: "1,833.00",
    three_children: "2,165.00",
    four_children: "2,419.00",
    five_children: "2,661.00",
    six_children: "2,892.00"
  },
  {
    adjusted: "9,350.00",
    one_child: "1,234.00",
    two_children: "1,837.00",
    three_children: "2,170.00",
    four_children: "2,424.00",
    five_children: "2,666.00",
    six_children: "2,898.00"
  },
  {
    adjusted: "9,400.00",
    one_child: "1,238.00",
    two_children: "1,842.00",
    three_children: "2,175.00",
    four_children: "2,429.00",
    five_children: "2,672.00",
    six_children: "2,904.00"
  },
  {
    adjusted: "9,450.00",
    one_child: "1,241.00",
    two_children: "1,846.00",
    three_children: "2,179.00",
    four_children: "2,434.00",
    five_children: "2,678.00",
    six_children: "2,911.00"
  },
  {
    adjusted: "9,500.00",
    one_child: "1,245.00",
    two_children: "1,851.00",
    three_children: "2,184.00",
    four_children: "2,440.00",
    five_children: "2,683.00",
    six_children: "2,917.00"
  },
  {
    adjusted: "9,550.00",
    one_child: "1,248.00",
    two_children: "1,856.00",
    three_children: "2,189.00",
    four_children: "2,445.00",
    five_children: "2,689.00",
    six_children: "2,923.00"
  },
  {
    adjusted: "9,600.00",
    one_child: "1,252.00",
    two_children: "1,860.00",
    three_children: "2,193.00",
    four_children: "2,450.00",
    five_children: "2,695.00",
    six_children: "2,929.00"
  },
  {
    adjusted: "9,650.00",
    one_child: "1,255.00",
    two_children: "1,865.00",
    three_children: "2,198.00",
    four_children: "2,455.00",
    five_children: "2,701.00",
    six_children: "2,936.00"
  },
  {
    adjusted: "9,700.00",
    one_child: "1,259.00",
    two_children: "1,869.00",
    three_children: "2,203.00",
    four_children: "2,460.00",
    five_children: "2,706.00",
    six_children: "2,942.00"
  },
  {
    adjusted: "9,750.00",
    one_child: "1,262.00",
    two_children: "1,874.00",
    three_children: "2,207.00",
    four_children: "2,466.00",
    five_children: "2,712.00",
    six_children: "2,948.00"
  },
  {
    adjusted: "9,800.00",
    one_child: "1,266.00",
    two_children: "1,879.00",
    three_children: "2,212.00",
    four_children: "2,471.00",
    five_children: "2,718.00",
    six_children: "2,954.00"
  },
  {
    adjusted: "9,850.00",
    one_child: "1,270.00",
    two_children: "1,884.00",
    three_children: "2,218.00",
    four_children: "2,477.00",
    five_children: "2,725.00",
    six_children: "2,962.00"
  },
  {
    adjusted: "9,900.00",
    one_child: "1,275.00",
    two_children: "1,891.00",
    three_children: "2,226.00",
    four_children: "2,486.00",
    five_children: "2,735.00",
    six_children: "2,973.00"
  },
  {
    adjusted: "9,950.00",
    one_child: "1,280.00",
    two_children: "1,899.00",
    three_children: "2,234.00",
    four_children: "2,495.00",
    five_children: "2,745.00",
    six_children: "2,984.00"
  },
  {
    adjusted: "10,000.00",
    one_child: "1,285.00",
    two_children: "1,906.00",
    three_children: "2,242.00",
    four_children: "2,505.00",
    five_children: "2,755.00",
    six_children: "2,995.00"
  },
  {
    adjusted: "10,050.00",
    one_child: "1,291.00",
    two_children: "1,913.00",
    three_children: "2,250.00",
    four_children: "2,514.00",
    five_children: "2,765.00",
    six_children: "3,006.00"
  },
  {
    adjusted: "10,100.00",
    one_child: "1,296.00",
    two_children: "1,921.00",
    three_children: "2,258.00",
    four_children: "2,523.00",
    five_children: "2,775.00",
    six_children: "3,016.00"
  },
  {
    adjusted: "10,150.00",
    one_child: "1,301.00",
    two_children: "1,928.00",
    three_children: "2,267.00",
    four_children: "2,532.00",
    five_children: "2,785.00",
    six_children: "3,027.00"
  },
  {
    adjusted: "10,200.00",
    one_child: "1,306.00",
    two_children: "1,935.00",
    three_children: "2,275.00",
    four_children: "2,541.00",
    five_children: "2,795.00",
    six_children: "3,038.00"
  },
  {
    adjusted: "10,250.00",
    one_child: "1,311.00",
    two_children: "1,943.00",
    three_children: "2,283.00",
    four_children: "2,550.00",
    five_children: "2,805.00",
    six_children: "3,049.00"
  },
  {
    adjusted: "10,300.00",
    one_child: "1,317.00",
    two_children: "1,950.00",
    three_children: "2,291.00",
    four_children: "2,559.00",
    five_children: "2,815.00",
    six_children: "3,060.00"
  },
  {
    adjusted: "10,350.00",
    one_child: "1,322.00",
    two_children: "1,957.00",
    three_children: "2,299.00",
    four_children: "2,568.00",
    five_children: "2,825.00",
    six_children: "3,071.00"
  },
  {
    adjusted: "10,400.00",
    one_child: "1,327.00",
    two_children: "1,965.00",
    three_children: "2,307.00",
    four_children: "2,577.00",
    five_children: "2,835.00",
    six_children: "3,082.00"
  },
  {
    adjusted: "10,450.00",
    one_child: "1,332.00",
    two_children: "1,972.00",
    three_children: "2,315.00",
    four_children: "2,586.00",
    five_children: "2,845.00",
    six_children: "3,092.00"
  },
  {
    adjusted: "10,500.00",
    one_child: "1,337.00",
    two_children: "1,979.00",
    three_children: "2,324.00",
    four_children: "2,595.00",
    five_children: "2,855.00",
    six_children: "3,103.00"
  },
  {
    adjusted: "10,550.00",
    one_child: "1,343.00",
    two_children: "1,987.00",
    three_children: "2,332.00",
    four_children: "2,605.00",
    five_children: "2,865.00",
    six_children: "3,114.00"
  },
  {
    adjusted: "10,600.00",
    one_child: "1,348.00",
    two_children: "1,994.00",
    three_children: "2,340.00",
    four_children: "2,614.00",
    five_children: "2,875.00",
    six_children: "3,125.00"
  },
  {
    adjusted: "10,650.00",
    one_child: "1,353.00",
    two_children: "2,001.00",
    three_children: "2,348.00",
    four_children: "2,623.00",
    five_children: "2,885.00",
    six_children: "3,136.00"
  },
  {
    adjusted: "10,700.00",
    one_child: "1,358.00",
    two_children: "2,009.00",
    three_children: "2,356.00",
    four_children: "2,632.00",
    five_children: "2,895.00",
    six_children: "3,147.00"
  },
  {
    adjusted: "10,750.00",
    one_child: "1,363.00",
    two_children: "2,016.00",
    three_children: "2,364.00",
    four_children: "2,641.00",
    five_children: "2,905.00",
    six_children: "3,158.00"
  },
  {
    adjusted: "10,800.00",
    one_child: "1,369.00",
    two_children: "2,023.00",
    three_children: "2,372.00",
    four_children: "2,650.00",
    five_children: "2,915.00",
    six_children: "3,169.00"
  },
  {
    adjusted: "10,850.00",
    one_child: "1,374.00",
    two_children: "2,031.00",
    three_children: "2,381.00",
    four_children: "2,659.00",
    five_children: "2,925.00",
    six_children: "3,179.00"
  },
  {
    adjusted: "10,900.00",
    one_child: "1,379.00",
    two_children: "2,038.00",
    three_children: "2,389.00",
    four_children: "2,668.00",
    five_children: "2,935.00",
    six_children: "3,190.00"
  },
  {
    adjusted: "10,950.00",
    one_child: "1,384.00",
    two_children: "2,045.00",
    three_children: "2,397.00",
    four_children: "2,677.00",
    five_children: "2,945.00",
    six_children: "3,201.00"
  },
  {
    adjusted: "11,000.00",
    one_child: "1,389.00",
    two_children: "2,052.00",
    three_children: "2,405.00",
    four_children: "2,686.00",
    five_children: "2,955.00",
    six_children: "3,212.00"
  },
  {
    adjusted: "11,050.00",
    one_child: "1,393.00",
    two_children: "2,058.00",
    three_children: "2,413.00",
    four_children: "2,695.00",
    five_children: "2,965.00",
    six_children: "3,223.00"
  },
  {
    adjusted: "11,100.00",
    one_child: "1,397.00",
    two_children: "2,065.00",
    three_children: "2,421.00",
    four_children: "2,704.00",
    five_children: "2,975.00",
    six_children: "3,234.00"
  },
  {
    adjusted: "11,150.00",
    one_child: "1,400.00",
    two_children: "2,071.00",
    three_children: "2,429.00",
    four_children: "2,713.00",
    five_children: "2,985.00",
    six_children: "3,244.00"
  },
  {
    adjusted: "11,200.00",
    one_child: "1,404.00",
    two_children: "2,077.00",
    three_children: "2,437.00",
    four_children: "2,722.00",
    five_children: "2,995.00",
    six_children: "3,255.00"
  },
  {
    adjusted: "11,250.00",
    one_child: "1,408.00",
    two_children: "2,083.00",
    three_children: "2,445.00",
    four_children: "2,731.00",
    five_children: "3,005.00",
    six_children: "3,266.00"
  },
  {
    adjusted: "11,300.00",
    one_child: "1,412.00",
    two_children: "2,090.00",
    three_children: "2,453.00",
    four_children: "2,740.00",
    five_children: "3,014.00",
    six_children: "3,277.00"
  },
  {
    adjusted: "11,350.00",
    one_child: "1,416.00",
    two_children: "2,096.00",
    three_children: "2,461.00",
    four_children: "2,749.00",
    five_children: "3,024.00",
    six_children: "3,287.00"
  },
  {
    adjusted: "11,400.00",
    one_child: "1,419.00",
    two_children: "2,102.00",
    three_children: "2,470.00",
    four_children: "2,758.00",
    five_children: "3,034.00",
    six_children: "3,298.00"
  },
  {
    adjusted: "11,450.00",
    one_child: "1,423.00",
    two_children: "2,108.00",
    three_children: "2,478.00",
    four_children: "2,767.00",
    five_children: "3,044.00",
    six_children: "3,309.00"
  },
  {
    adjusted: "11,500.00",
    one_child: "1,427.00",
    two_children: "2,114.00",
    three_children: "2,486.00",
    four_children: "2,776.00",
    five_children: "3,054.00",
    six_children: "3,320.00"
  },
  {
    adjusted: "11,550.00",
    one_child: "1,431.00",
    two_children: "2,121.00",
    three_children: "2,494.00",
    four_children: "2,786.00",
    five_children: "3,064.00",
    six_children: "3,331.00"
  },
  {
    adjusted: "11,600.00",
    one_child: "1,434.00",
    two_children: "2,127.00",
    three_children: "2,502.00",
    four_children: "2,795.00",
    five_children: "3,074.00",
    six_children: "3,341.00"
  },
  {
    adjusted: "11,650.00",
    one_child: "1,438.00",
    two_children: "2,133.00",
    three_children: "2,510.00",
    four_children: "2,804.00",
    five_children: "3,084.00",
    six_children: "3,352.00"
  },
  {
    adjusted: "11,700.00",
    one_child: "1,442.00",
    two_children: "2,139.00",
    three_children: "2,518.00",
    four_children: "2,813.00",
    five_children: "3,094.00",
    six_children: "3,363.00"
  },
  {
    adjusted: "11,750.00",
    one_child: "1,446.00",
    two_children: "2,145.00",
    three_children: "2,526.00",
    four_children: "2,822.00",
    five_children: "3,104.00",
    six_children: "3,374.00"
  },
  {
    adjusted: "11,800.00",
    one_child: "1,449.00",
    two_children: "2,152.00",
    three_children: "2,534.00",
    four_children: "2,831.00",
    five_children: "3,114.00",
    six_children: "3,385.00"
  },
  {
    adjusted: "11,850.00",
    one_child: "1,453.00",
    two_children: "2,158.00",
    three_children: "2,542.00",
    four_children: "2,840.00",
    five_children: "3,124.00",
    six_children: "3,395.00"
  },
  {
    adjusted: "11,900.00",
    one_child: "1,457.00",
    two_children: "2,164.00",
    three_children: "2,550.00",
    four_children: "2,849.00",
    five_children: "3,134.00",
    six_children: "3,406.00"
  },
  {
    adjusted: "11,950.00",
    one_child: "1,461.00",
    two_children: "2,170.00",
    three_children: "2,558.00",
    four_children: "2,858.00",
    five_children: "3,143.00",
    six_children: "3,417.00"
  },
  {
    adjusted: "12,000.00",
    one_child: "1,465.00",
    two_children: "2,176.00",
    three_children: "2,566.00",
    four_children: "2,867.00",
    five_children: "3,153.00",
    six_children: "3,428.00"
  },
  {
    adjusted: "12,050.00",
    one_child: "1,468.00",
    two_children: "2,183.00",
    three_children: "2,574.00",
    four_children: "2,876.00",
    five_children: "3,163.00",
    six_children: "3,438.00"
  },
  {
    adjusted: "12,100.00",
    one_child: "1,472.00",
    two_children: "2,189.00",
    three_children: "2,583.00",
    four_children: "2,885.00",
    five_children: "3,173.00",
    six_children: "3,449.00"
  },
  {
    adjusted: "12,150.00",
    one_child: "1,476.00",
    two_children: "2,195.00",
    three_children: "2,590.00",
    four_children: "2,893.00",
    five_children: "3,182.00",
    six_children: "3,459.00"
  },
  {
    adjusted: "12,200.00",
    one_child: "1,479.00",
    two_children: "2,199.00",
    three_children: "2,594.00",
    four_children: "2,898.00",
    five_children: "3,188.00",
    six_children: "3,465.00"
  },
  {
    adjusted: "12,250.00",
    one_child: "1,482.00",
    two_children: "2,203.00",
    three_children: "2,599.00",
    four_children: "2,904.00",
    five_children: "3,194.00",
    six_children: "3,472.00"
  },
  {
    adjusted: "12,300.00",
    one_child: "1,485.00",
    two_children: "2,207.00",
    three_children: "2,604.00",
    four_children: "2,909.00",
    five_children: "3,199.00",
    six_children: "3,478.00"
  },
  {
    adjusted: "12,350.00",
    one_child: "1,488.00",
    two_children: "2,212.00",
    three_children: "2,609.00",
    four_children: "2,914.00",
    five_children: "3,205.00",
    six_children: "3,484.00"
  },
  {
    adjusted: "12,400.00",
    one_child: "1,491.00",
    two_children: "2,216.00",
    three_children: "2,614.00",
    four_children: "2,920.00",
    five_children: "3,212.00",
    six_children: "3,491.00"
  },
  {
    adjusted: "12,450.00",
    one_child: "1,495.00",
    two_children: "2,221.00",
    three_children: "2,619.00",
    four_children: "2,925.00",
    five_children: "3,218.00",
    six_children: "3,497.00"
  },
  {
    adjusted: "12,500.00",
    one_child: "1,498.00",
    two_children: "2,225.00",
    three_children: "2,624.00",
    four_children: "2,931.00",
    five_children: "3,224.00",
    six_children: "3,504.00"
  },
  {
    adjusted: "12,550.00",
    one_child: "1,501.00",
    two_children: "2,230.00",
    three_children: "2,629.00",
    four_children: "2,936.00",
    five_children: "3,230.00",
    six_children: "3,511.00"
  },
  {
    adjusted: "12,600.00",
    one_child: "1,504.00",
    two_children: "2,234.00",
    three_children: "2,633.00",
    four_children: "2,942.00",
    five_children: "3,236.00",
    six_children: "3,517.00"
  },
  {
    adjusted: "12,650.00",
    one_child: "1,508.00",
    two_children: "2,239.00",
    three_children: "2,638.00",
    four_children: "2,947.00",
    five_children: "3,242.00",
    six_children: "3,524.00"
  },
  {
    adjusted: "12,700.00",
    one_child: "1,511.00",
    two_children: "2,243.00",
    three_children: "2,643.00",
    four_children: "2,953.00",
    five_children: "3,248.00",
    six_children: "3,530.00"
  },
  {
    adjusted: "12,750.00",
    one_child: "1,514.00",
    two_children: "2,248.00",
    three_children: "2,648.00",
    four_children: "2,958.00",
    five_children: "3,254.00",
    six_children: "3,537.00"
  },
  {
    adjusted: "12,800.00",
    one_child: "1,517.00",
    two_children: "2,252.00",
    three_children: "2,653.00",
    four_children: "2,964.00",
    five_children: "3,260.00",
    six_children: "3,544.00"
  },
  {
    adjusted: "12,850.00",
    one_child: "1,520.00",
    two_children: "2,257.00",
    three_children: "2,658.00",
    four_children: "2,969.00",
    five_children: "3,266.00",
    six_children: "3,550.00"
  },
  {
    adjusted: "12,900.00",
    one_child: "1,524.00",
    two_children: "2,261.00",
    three_children: "2,663.00",
    four_children: "2,975.00",
    five_children: "3,272.00",
    six_children: "3,557.00"
  },
  {
    adjusted: "12,950.00",
    one_child: "1,527.00",
    two_children: "2,266.00",
    three_children: "2,668.00",
    four_children: "2,980.00",
    five_children: "3,278.00",
    six_children: "3,563.00"
  },
  {
    adjusted: "13,000.00",
    one_child: "1,530.00",
    two_children: "2,270.00",
    three_children: "2,673.00",
    four_children: "2,986.00",
    five_children: "3,284.00",
    six_children: "3,570.00"
  },
  {
    adjusted: "13,050.00",
    one_child: "1,533.00",
    two_children: "2,275.00",
    three_children: "2,678.00",
    four_children: "2,991.00",
    five_children: "3,290.00",
    six_children: "3,577.00"
  },
  {
    adjusted: "13,100.00",
    one_child: "1,537.00",
    two_children: "2,279.00",
    three_children: "2,683.00",
    four_children: "2,997.00",
    five_children: "3,296.00",
    six_children: "3,583.00"
  },
  {
    adjusted: "13,150.00",
    one_child: "1,540.00",
    two_children: "2,284.00",
    three_children: "2,688.00",
    four_children: "3,002.00",
    five_children: "3,302.00",
    six_children: "3,590.00"
  },
  {
    adjusted: "13,200.00",
    one_child: "1,543.00",
    two_children: "2,288.00",
    three_children: "2,693.00",
    four_children: "3,008.00",
    five_children: "3,308.00",
    six_children: "3,596.00"
  },
  {
    adjusted: "13,250.00",
    one_child: "1,547.00",
    two_children: "2,294.00",
    three_children: "2,698.00",
    four_children: "3,014.00",
    five_children: "3,315.00",
    six_children: "3,604.00"
  },
  {
    adjusted: "13,300.00",
    one_child: "1,551.00",
    two_children: "2,300.00",
    three_children: "2,704.00",
    four_children: "3,020.00",
    five_children: "3,323.00",
    six_children: "3,612.00"
  },
  {
    adjusted: "13,350.00",
    one_child: "1,556.00",
    two_children: "2,305.00",
    three_children: "2,710.00",
    four_children: "3,027.00",
    five_children: "3,330.00",
    six_children: "3,619.00"
  },
  {
    adjusted: "13,400.00",
    one_child: "1,560.00",
    two_children: "2,311.00",
    three_children: "2,716.00",
    four_children: "3,033.00",
    five_children: "3,337.00",
    six_children: "3,627.00"
  },
  {
    adjusted: "13,450.00",
    one_child: "1,564.00",
    two_children: "2,316.00",
    three_children: "2,721.00",
    four_children: "3,040.00",
    five_children: "3,344.00",
    six_children: "3,635.00"
  },
  {
    adjusted: "13,500.00",
    one_child: "1,568.00",
    two_children: "2,322.00",
    three_children: "2,727.00",
    four_children: "3,046.00",
    five_children: "3,351.00",
    six_children: "3,642.00"
  },
  {
    adjusted: "13,550.00",
    one_child: "1,572.00",
    two_children: "2,327.00",
    three_children: "2,733.00",
    four_children: "3,053.00",
    five_children: "3,358.00",
    six_children: "3,650.00"
  },
  {
    adjusted: "13,600.00",
    one_child: "1,577.00",
    two_children: "2,333.00",
    three_children: "2,739.00",
    four_children: "3,059.00",
    five_children: "3,365.00",
    six_children: "3,658.00"
  },
  {
    adjusted: "13,650.00",
    one_child: "1,581.00",
    two_children: "2,338.00",
    three_children: "2,744.00",
    four_children: "3,065.00",
    five_children: "3,372.00",
    six_children: "3,665.00"
  },
  {
    adjusted: "13,700.00",
    one_child: "1,585.00",
    two_children: "2,344.00",
    three_children: "2,750.00",
    four_children: "3,072.00",
    five_children: "3,379.00",
    six_children: "3,673.00"
  },
  {
    adjusted: "13,750.00",
    one_child: "1,589.00",
    two_children: "2,350.00",
    three_children: "2,756.00",
    four_children: "3,078.00",
    five_children: "3,386.00",
    six_children: "3,681.00"
  },
  {
    adjusted: "13,800.00",
    one_child: "1,593.00",
    two_children: "2,355.00",
    three_children: "2,761.00",
    four_children: "3,085.00",
    five_children: "3,393.00",
    six_children: "3,688.00"
  },
  {
    adjusted: "13,850.00",
    one_child: "1,598.00",
    two_children: "2,361.00",
    three_children: "2,767.00",
    four_children: "3,091.00",
    five_children: "3,400.00",
    six_children: "3,696.00"
  },
  {
    adjusted: "13,900.00",
    one_child: "1,602.00",
    two_children: "2,366.00",
    three_children: "2,773.00",
    four_children: "3,097.00",
    five_children: "3,407.00",
    six_children: "3,704.00"
  },
  {
    adjusted: "13,950.00",
    one_child: "1,606.00",
    two_children: "2,372.00",
    three_children: "2,779.00",
    four_children: "3,104.00",
    five_children: "3,414.00",
    six_children: "3,711.00"
  },
  {
    adjusted: "14,000.00",
    one_child: "1,610.00",
    two_children: "2,377.00",
    three_children: "2,784.00",
    four_children: "3,110.00",
    five_children: "3,421.00",
    six_children: "3,719.00"
  },
  {
    adjusted: "14,050.00",
    one_child: "1,614.00",
    two_children: "2,383.00",
    three_children: "2,790.00",
    four_children: "3,117.00",
    five_children: "3,428.00",
    six_children: "3,726.00"
  },
  {
    adjusted: "14,100.00",
    one_child: "1,618.00",
    two_children: "2,388.00",
    three_children: "2,796.00",
    four_children: "3,123.00",
    five_children: "3,435.00",
    six_children: "3,734.00"
  },
  {
    adjusted: "14,150.00",
    one_child: "1,623.00",
    two_children: "2,394.00",
    three_children: "2,802.00",
    four_children: "3,129.00",
    five_children: "3,442.00",
    six_children: "3,742.00"
  },
  {
    adjusted: "14,200.00",
    one_child: "1,627.00",
    two_children: "2,400.00",
    three_children: "2,807.00",
    four_children: "3,136.00",
    five_children: "3,449.00",
    six_children: "3,749.00"
  },
  {
    adjusted: "14,250.00",
    one_child: "1,631.00",
    two_children: "2,405.00",
    three_children: "2,813.00",
    four_children: "3,142.00",
    five_children: "3,456.00",
    six_children: "3,757.00"
  },
  {
    adjusted: "14,300.00",
    one_child: "1,635.00",
    two_children: "2,411.00",
    three_children: "2,819.00",
    four_children: "3,149.00",
    five_children: "3,463.00",
    six_children: "3,765.00"
  },
  {
    adjusted: "14,350.00",
    one_child: "1,639.00",
    two_children: "2,416.00",
    three_children: "2,825.00",
    four_children: "3,155.00",
    five_children: "3,471.00",
    six_children: "3,772.00"
  },
  {
    adjusted: "14,400.00",
    one_child: "1,644.00",
    two_children: "2,422.00",
    three_children: "2,830.00",
    four_children: "3,161.00",
    five_children: "3,478.00",
    six_children: "3,780.00"
  },
  {
    adjusted: "14,450.00",
    one_child: "1,648.00",
    two_children: "2,427.00",
    three_children: "2,836.00",
    four_children: "3,168.00",
    five_children: "3,485.00",
    six_children: "3,788.00"
  },
  {
    adjusted: "14,500.00",
    one_child: "1,652.00",
    two_children: "2,433.00",
    three_children: "2,842.00",
    four_children: "3,174.00",
    five_children: "3,492.00",
    six_children: "3,795.00"
  },
  {
    adjusted: "14,550.00",
    one_child: "1,656.00",
    two_children: "2,438.00",
    three_children: "2,847.00",
    four_children: "3,181.00",
    five_children: "3,499.00",
    six_children: "3,803.00"
  },
  {
    adjusted: "14,600.00",
    one_child: "1,660.00",
    two_children: "2,444.00",
    three_children: "2,853.00",
    four_children: "3,187.00",
    five_children: "3,506.00",
    six_children: "3,811.00"
  },
  {
    adjusted: "14,650.00",
    one_child: "1,663.00",
    two_children: "2,447.00",
    three_children: "2,856.00",
    four_children: "3,191.00",
    five_children: "3,510.00",
    six_children: "3,815.00"
  },
  {
    adjusted: "14,700.00",
    one_child: "1,666.00",
    two_children: "2,451.00",
    three_children: "2,860.00",
    four_children: "3,194.00",
    five_children: "3,514.00",
    six_children: "3,819.00"
  },
  {
    adjusted: "14,750.00",
    one_child: "1,668.00",
    two_children: "2,454.00",
    three_children: "2,863.00",
    four_children: "3,198.00",
    five_children: "3,518.00",
    six_children: "3,824.00"
  },
  {
    adjusted: "14,800.00",
    one_child: "1,671.00",
    two_children: "2,457.00",
    three_children: "2,866.00",
    four_children: "3,201.00",
    five_children: "3,522.00",
    six_children: "3,828.00"
  },
  {
    adjusted: "14,850.00",
    one_child: "1,674.00",
    two_children: "2,461.00",
    three_children: "2,869.00",
    four_children: "3,205.00",
    five_children: "3,525.00",
    six_children: "3,832.00"
  },
  {
    adjusted: "14,900.00",
    one_child: "1,676.00",
    two_children: "2,464.00",
    three_children: "2,872.00",
    four_children: "3,209.00",
    five_children: "3,529.00",
    six_children: "3,836.00"
  },
  {
    adjusted: "14,950.00",
    one_child: "1,679.00",
    two_children: "2,468.00",
    three_children: "2,876.00",
    four_children: "3,212.00",
    five_children: "3,533.00",
    six_children: "3,841.00"
  },
  {
    adjusted: "15,000.00",
    one_child: "1,682.00",
    two_children: "2,471.00",
    three_children: "2,879.00",
    four_children: "3,216.00",
    five_children: "3,537.00",
    six_children: "3,845.00"
  },
  {
    adjusted: "15,050.00",
    one_child: "1,684.00",
    two_children: "2,474.00",
    three_children: "2,882.00",
    four_children: "3,219.00",
    five_children: "3,541.00",
    six_children: "3,849.00"
  },
  {
    adjusted: "15,100.00",
    one_child: "1,687.00",
    two_children: "2,478.00",
    three_children: "2,885.00",
    four_children: "3,223.00",
    five_children: "3,545.00",
    six_children: "3,854.00"
  },
  {
    adjusted: "15,150.00",
    one_child: "1,690.00",
    two_children: "2,481.00",
    three_children: "2,889.00",
    four_children: "3,227.00",
    five_children: "3,549.00",
    six_children: "3,858.00"
  },
  {
    adjusted: "15,200.00",
    one_child: "1,692.00",
    two_children: "2,484.00",
    three_children: "2,892.00",
    four_children: "3,230.00",
    five_children: "3,553.00",
    six_children: "3,862.00"
  },
  {
    adjusted: "15,250.00",
    one_child: "1,695.00",
    two_children: "2,488.00",
    three_children: "2,895.00",
    four_children: "3,234.00",
    five_children: "3,557.00",
    six_children: "3,867.00"
  },
  {
    adjusted: "15,300.00",
    one_child: "1,697.00",
    two_children: "2,491.00",
    three_children: "2,898.00",
    four_children: "3,237.00",
    five_children: "3,561.00",
    six_children: "3,870.00"
  },
  {
    adjusted: "15,350.00",
    one_child: "1,700.00",
    two_children: "2,494.00",
    three_children: "2,901.00",
    four_children: "3,240.00",
    five_children: "3,564.00",
    six_children: "3,874.00"
  },
  {
    adjusted: "15,400.00",
    one_child: "1,702.00",
    two_children: "2,497.00",
    three_children: "2,904.00",
    four_children: "3,243.00",
    five_children: "3,568.00",
    six_children: "3,878.00"
  },
  {
    adjusted: "15,450.00",
    one_child: "1,704.00",
    two_children: "2,500.00",
    three_children: "2,906.00",
    four_children: "3,246.00",
    five_children: "3,571.00",
    six_children: "3,882.00"
  },
  {
    adjusted: "15,500.00",
    one_child: "1,707.00",
    two_children: "2,502.00",
    three_children: "2,909.00",
    four_children: "3,250.00",
    five_children: "3,575.00",
    six_children: "3,885.00"
  },
  {
    adjusted: "15,550.00",
    one_child: "1,709.00",
    two_children: "2,505.00",
    three_children: "2,912.00",
    four_children: "3,253.00",
    five_children: "3,578.00",
    six_children: "3,889.00"
  },
  {
    adjusted: "15,600.00",
    one_child: "1,711.00",
    two_children: "2,508.00",
    three_children: "2,915.00",
    four_children: "3,256.00",
    five_children: "3,581.00",
    six_children: "3,893.00"
  },
  {
    adjusted: "15,650.00",
    one_child: "1,714.00",
    two_children: "2,511.00",
    three_children: "2,918.00",
    four_children: "3,259.00",
    five_children: "3,585.00",
    six_children: "3,897.00"
  },
  {
    adjusted: "15,700.00",
    one_child: "1,716.00",
    two_children: "2,514.00",
    three_children: "2,921.00",
    four_children: "3,262.00",
    five_children: "3,588.00",
    six_children: "3,901.00"
  },
  {
    adjusted: "15,750.00",
    one_child: "1,718.00",
    two_children: "2,517.00",
    three_children: "2,923.00",
    four_children: "3,265.00",
    five_children: "3,592.00",
    six_children: "3,904.00"
  },
  {
    adjusted: "15,800.00",
    one_child: "1,721.00",
    two_children: "2,520.00",
    three_children: "2,926.00",
    four_children: "3,269.00",
    five_children: "3,595.00",
    six_children: "3,908.00"
  },
  {
    adjusted: "15,850.00",
    one_child: "1,723.00",
    two_children: "2,523.00",
    three_children: "2,929.00",
    four_children: "3,272.00",
    five_children: "3,599.00",
    six_children: "3,912.00"
  },
  {
    adjusted: "15,900.00",
    one_child: "1,725.00",
    two_children: "2,526.00",
    three_children: "2,932.00",
    four_children: "3,275.00",
    five_children: "3,602.00",
    six_children: "3,916.00"
  },
  {
    adjusted: "15,950.00",
    one_child: "1,728.00",
    two_children: "2,529.00",
    three_children: "2,935.00",
    four_children: "3,278.00",
    five_children: "3,606.00",
    six_children: "3,920.00"
  },
  {
    adjusted: "16,000.00",
    one_child: "1,730.00",
    two_children: "2,532.00",
    three_children: "2,938.00",
    four_children: "3,281.00",
    five_children: "3,609.00",
    six_children: "3,923.00"
  },
  {
    adjusted: "16,050.00",
    one_child: "1,733.00",
    two_children: "2,535.00",
    three_children: "2,940.00",
    four_children: "3,284.00",
    five_children: "3,613.00",
    six_children: "3,927.00"
  },
  {
    adjusted: "16,100.00",
    one_child: "1,735.00",
    two_children: "2,538.00",
    three_children: "2,943.00",
    four_children: "3,288.00",
    five_children: "3,617.00",
    six_children: "3,931.00"
  },
  {
    adjusted: "16,150.00",
    one_child: "1,739.00",
    two_children: "2,544.00",
    three_children: "2,950.00",
    four_children: "3,296.00",
    five_children: "3,625.00",
    six_children: "3,940.00"
  },
  {
    adjusted: "16,200.00",
    one_child: "1,743.00",
    two_children: "2,550.00",
    three_children: "2,957.00",
    four_children: "3,303.00",
    five_children: "3,634.00",
    six_children: "3,950.00"
  },
  {
    adjusted: "16,250.00",
    one_child: "1,747.00",
    two_children: "2,556.00",
    three_children: "2,964.00",
    four_children: "3,311.00",
    five_children: "3,642.00",
    six_children: "3,959.00"
  },
  {
    adjusted: "16,300.00",
    one_child: "1,752.00",
    two_children: "2,562.00",
    three_children: "2,971.00",
    four_children: "3,319.00",
    five_children: "3,651.00",
    six_children: "3,968.00"
  },
  {
    adjusted: "16,350.00",
    one_child: "1,756.00",
    two_children: "2,568.00",
    three_children: "2,978.00",
    four_children: "3,326.00",
    five_children: "3,659.00",
    six_children: "3,977.00"
  },
  {
    adjusted: "16,400.00",
    one_child: "1,760.00",
    two_children: "2,574.00",
    three_children: "2,985.00",
    four_children: "3,334.00",
    five_children: "3,668.00",
    six_children: "3,987.00"
  },
  {
    adjusted: "16,450.00",
    one_child: "1,764.00",
    two_children: "2,580.00",
    three_children: "2,992.00",
    four_children: "3,342.00",
    five_children: "3,676.00",
    six_children: "3,996.00"
  },
  {
    adjusted: "16,500.00",
    one_child: "1,768.00",
    two_children: "2,586.00",
    three_children: "2,999.00",
    four_children: "3,350.00",
    five_children: "3,685.00",
    six_children: "4,005.00"
  },
  {
    adjusted: "16,550.00",
    one_child: "1,772.00",
    two_children: "2,593.00",
    three_children: "3,006.00",
    four_children: "3,357.00",
    five_children: "3,693.00",
    six_children: "4,014.00"
  },
  {
    adjusted: "16,600.00",
    one_child: "1,777.00",
    two_children: "2,599.00",
    three_children: "3,013.00",
    four_children: "3,365.00",
    five_children: "3,702.00",
    six_children: "4,024.00"
  },
  {
    adjusted: "16,650.00",
    one_child: "1,781.00",
    two_children: "2,605.00",
    three_children: "3,020.00",
    four_children: "3,373.00",
    five_children: "3,710.00",
    six_children: "4,033.00"
  },
  {
    adjusted: "16,700.00",
    one_child: "1,785.00",
    two_children: "2,611.00",
    three_children: "3,026.00",
    four_children: "3,381.00",
    five_children: "3,719.00",
    six_children: "4,042.00"
  },
  {
    adjusted: "16,750.00",
    one_child: "1,789.00",
    two_children: "2,617.00",
    three_children: "3,033.00",
    four_children: "3,388.00",
    five_children: "3,727.00",
    six_children: "4,051.00"
  },
  {
    adjusted: "16,800.00",
    one_child: "1,793.00",
    two_children: "2,622.00",
    three_children: "3,040.00",
    four_children: "3,396.00",
    five_children: "3,735.00",
    six_children: "4,060.00"
  },
  {
    adjusted: "16,850.00",
    one_child: "1,797.00",
    two_children: "2,628.00",
    three_children: "3,047.00",
    four_children: "3,403.00",
    five_children: "3,744.00",
    six_children: "4,069.00"
  },
  {
    adjusted: "16,900.00",
    one_child: "1,801.00",
    two_children: "2,634.00",
    three_children: "3,054.00",
    four_children: "3,411.00",
    five_children: "3,752.00",
    six_children: "4,078.00"
  },
  {
    adjusted: "16,950.00",
    one_child: "1,806.00",
    two_children: "2,640.00",
    three_children: "3,060.00",
    four_children: "3,419.00",
    five_children: "3,760.00",
    six_children: "4,088.00"
  },
  {
    adjusted: "17,000.00",
    one_child: "1,810.00",
    two_children: "2,646.00",
    three_children: "3,067.00",
    four_children: "3,426.00",
    five_children: "3,769.00",
    six_children: "4,097.00"
  },
  {
    adjusted: "17,050.00",
    one_child: "1,814.00",
    two_children: "2,652.00",
    three_children: "3,074.00",
    four_children: "3,434.00",
    five_children: "3,777.00",
    six_children: "4,106.00"
  },
  {
    adjusted: "17,100.00",
    one_child: "1,818.00",
    two_children: "2,658.00",
    three_children: "3,081.00",
    four_children: "3,441.00",
    five_children: "3,785.00",
    six_children: "4,115.00"
  },
  {
    adjusted: "17,150.00",
    one_child: "1,822.00",
    two_children: "2,664.00",
    three_children: "3,088.00",
    four_children: "3,449.00",
    five_children: "3,794.00",
    six_children: "4,124.00"
  },
  {
    adjusted: "17,200.00",
    one_child: "1,826.00",
    two_children: "2,670.00",
    three_children: "3,095.00",
    four_children: "3,457.00",
    five_children: "3,802.00",
    six_children: "4,133.00"
  },
  {
    adjusted: "17,250.00",
    one_child: "1,830.00",
    two_children: "2,676.00",
    three_children: "3,101.00",
    four_children: "3,464.00",
    five_children: "3,811.00",
    six_children: "4,142.00"
  },
  {
    adjusted: "17,300.00",
    one_child: "1,834.00",
    two_children: "2,682.00",
    three_children: "3,108.00",
    four_children: "3,472.00",
    five_children: "3,819.00",
    six_children: "4,151.00"
  },
  {
    adjusted: "17,350.00",
    one_child: "1,838.00",
    two_children: "2,688.00",
    three_children: "3,115.00",
    four_children: "3,479.00",
    five_children: "3,827.00",
    six_children: "4,160.00"
  },
  {
    adjusted: "17,400.00",
    one_child: "1,843.00",
    two_children: "2,694.00",
    three_children: "3,122.00",
    four_children: "3,487.00",
    five_children: "3,836.00",
    six_children: "4,169.00"
  },
  {
    adjusted: "17,450.00",
    one_child: "1,847.00",
    two_children: "2,700.00",
    three_children: "3,129.00",
    four_children: "3,495.00",
    five_children: "3,844.00",
    six_children: "4,179.00"
  },
  {
    adjusted: "17,500.00",
    one_child: "1,851.00",
    two_children: "2,706.00",
    three_children: "3,135.00",
    four_children: "3,502.00",
    five_children: "3,852.00",
    six_children: "4,188.00"
  },
  {
    adjusted: "17,550.00",
    one_child: "1,855.00",
    two_children: "2,712.00",
    three_children: "3,142.00",
    four_children: "3,510.00",
    five_children: "3,861.00",
    six_children: "4,197.00"
  },
  {
    adjusted: "17,600.00",
    one_child: "1,859.00",
    two_children: "2,718.00",
    three_children: "3,149.00",
    four_children: "3,517.00",
    five_children: "3,869.00",
    six_children: "4,206.00"
  },
  {
    adjusted: "17,650.00",
    one_child: "1,863.00",
    two_children: "2,724.00",
    three_children: "3,156.00",
    four_children: "3,525.00",
    five_children: "3,878.00",
    six_children: "4,215.00"
  },
  {
    adjusted: "17,700.00",
    one_child: "1,867.00",
    two_children: "2,730.00",
    three_children: "3,163.00",
    four_children: "3,533.00",
    five_children: "3,886.00",
    six_children: "4,224.00"
  },
  {
    adjusted: "17,750.00",
    one_child: "1,871.00",
    two_children: "2,736.00",
    three_children: "3,170.00",
    four_children: "3,540.00",
    five_children: "3,894.00",
    six_children: "4,233.00"
  },
  {
    adjusted: "17,800.00",
    one_child: "1,875.00",
    two_children: "2,741.00",
    three_children: "3,176.00",
    four_children: "3,548.00",
    five_children: "3,903.00",
    six_children: "4,242.00"
  },
  {
    adjusted: "17,850.00",
    one_child: "1,880.00",
    two_children: "2,747.00",
    three_children: "3,183.00",
    four_children: "3,556.00",
    five_children: "3,911.00",
    six_children: "4,251.00"
  },
  {
    adjusted: "17,900.00",
    one_child: "1,884.00",
    two_children: "2,753.00",
    three_children: "3,190.00",
    four_children: "3,563.00",
    five_children: "3,919.00",
    six_children: "4,260.00"
  },
  {
    adjusted: "17,950.00",
    one_child: "1,888.00",
    two_children: "2,759.00",
    three_children: "3,197.00",
    four_children: "3,571.00",
    five_children: "3,928.00",
    six_children: "4,270.00"
  },
  {
    adjusted: "18,000.00",
    one_child: "1,892.00",
    two_children: "2,765.00",
    three_children: "3,204.00",
    four_children: "3,578.00",
    five_children: "3,936.00",
    six_children: "4,279.00"
  },
  {
    adjusted: "18,050.00",
    one_child: "1,896.00",
    two_children: "2,771.00",
    three_children: "3,210.00",
    four_children: "3,586.00",
    five_children: "3,945.00",
    six_children: "4,288.00"
  },
  {
    adjusted: "18,100.00",
    one_child: "1,900.00",
    two_children: "2,777.00",
    three_children: "3,217.00",
    four_children: "3,594.00",
    five_children: "3,953.00",
    six_children: "4,297.00"
  },
  {
    adjusted: "18,150.00",
    one_child: "1,904.00",
    two_children: "2,783.00",
    three_children: "3,224.00",
    four_children: "3,601.00",
    five_children: "3,961.00",
    six_children: "4,306.00"
  },
  {
    adjusted: "18,200.00",
    one_child: "1,908.00",
    two_children: "2,789.00",
    three_children: "3,231.00",
    four_children: "3,609.00",
    five_children: "3,970.00",
    six_children: "4,315.00"
  },
  {
    adjusted: "18,250.00",
    one_child: "1,912.00",
    two_children: "2,795.00",
    three_children: "3,238.00",
    four_children: "3,616.00",
    five_children: "3,978.00",
    six_children: "4,324.00"
  },
  {
    adjusted: "18,300.00",
    one_child: "1,917.00",
    two_children: "2,801.00",
    three_children: "3,244.00",
    four_children: "3,624.00",
    five_children: "3,987.00",
    six_children: "4,333.00"
  },
  {
    adjusted: "18,350.00",
    one_child: "1,921.00",
    two_children: "2,807.00",
    three_children: "3,251.00",
    four_children: "3,632.00",
    five_children: "3,995.00",
    six_children: "4,342.00"
  },
  {
    adjusted: "18,400.00",
    one_child: "1,925.00",
    two_children: "2,813.00",
    three_children: "3,258.00",
    four_children: "3,639.00",
    five_children: "4,003.00",
    six_children: "4,352.00"
  },
  {
    adjusted: "18,450.00",
    one_child: "1,929.00",
    two_children: "2,819.00",
    three_children: "3,265.00",
    four_children: "3,647.00",
    five_children: "4,012.00",
    six_children: "4,361.00"
  },
  {
    adjusted: "18,500.00",
    one_child: "1,933.00",
    two_children: "2,825.00",
    three_children: "3,272.00",
    four_children: "3,655.00",
    five_children: "4,020.00",
    six_children: "4,370.00"
  },
  {
    adjusted: "18,550.00",
    one_child: "1,937.00",
    two_children: "2,831.00",
    three_children: "3,279.00",
    four_children: "3,662.00",
    five_children: "4,028.00",
    six_children: "4,379.00"
  },
  {
    adjusted: "18,600.00",
    one_child: "1,941.00",
    two_children: "2,837.00",
    three_children: "3,285.00",
    four_children: "3,670.00",
    five_children: "4,037.00",
    six_children: "4,388.00"
  },
  {
    adjusted: "18,650.00",
    one_child: "1,945.00",
    two_children: "2,843.00",
    three_children: "3,292.00",
    four_children: "3,677.00",
    five_children: "4,045.00",
    six_children: "4,397.00"
  },
  {
    adjusted: "18,700.00",
    one_child: "1,949.00",
    two_children: "2,849.00",
    three_children: "3,299.00",
    four_children: "3,685.00",
    five_children: "4,054.00",
    six_children: "4,406.00"
  },
  {
    adjusted: "18,750.00",
    one_child: "1,954.00",
    two_children: "2,854.00",
    three_children: "3,306.00",
    four_children: "3,693.00",
    five_children: "4,062.00",
    six_children: "4,415.00"
  },
  {
    adjusted: "18,800.00",
    one_child: "1,958.00",
    two_children: "2,860.00",
    three_children: "3,313.00",
    four_children: "3,700.00",
    five_children: "4,070.00",
    six_children: "4,424.00"
  },
  {
    adjusted: "18,850.00",
    one_child: "1,962.00",
    two_children: "2,866.00",
    three_children: "3,319.00",
    four_children: "3,708.00",
    five_children: "4,079.00",
    six_children: "4,433.00"
  },
  {
    adjusted: "18,900.00",
    one_child: "1,966.00",
    two_children: "2,872.00",
    three_children: "3,326.00",
    four_children: "3,715.00",
    five_children: "4,087.00",
    six_children: "4,443.00"
  },
  {
    adjusted: "18,950.00",
    one_child: "1,970.00",
    two_children: "2,878.00",
    three_children: "3,333.00",
    four_children: "3,723.00",
    five_children: "4,095.00",
    six_children: "4,452.00"
  },
  {
    adjusted: "19,000.00",
    one_child: "1,974.00",
    two_children: "2,884.00",
    three_children: "3,340.00",
    four_children: "3,731.00",
    five_children: "4,104.00",
    six_children: "4,461.00"
  },
  {
    adjusted: "19,050.00",
    one_child: "1,978.00",
    two_children: "2,890.00",
    three_children: "3,347.00",
    four_children: "3,738.00",
    five_children: "4,112.00",
    six_children: "4,470.00"
  },
  {
    adjusted: "19,100.00",
    one_child: "1,982.00",
    two_children: "2,896.00",
    three_children: "3,353.00",
    four_children: "3,746.00",
    five_children: "4,120.00",
    six_children: "4,479.00"
  },
  {
    adjusted: "19,150.00",
    one_child: "1,986.00",
    two_children: "2,902.00",
    three_children: "3,360.00",
    four_children: "3,753.00",
    five_children: "4,128.00",
    six_children: "4,487.00"
  },
  {
    adjusted: "19,200.00",
    one_child: "1,990.00",
    two_children: "2,907.00",
    three_children: "3,366.00",
    four_children: "3,760.00",
    five_children: "4,136.00",
    six_children: "4,496.00"
  },
  {
    adjusted: "19,250.00",
    one_child: "1,994.00",
    two_children: "2,913.00",
    three_children: "3,373.00",
    four_children: "3,767.00",
    five_children: "4,144.00",
    six_children: "4,505.00"
  },
  {
    adjusted: "19,300.00",
    one_child: "1,998.00",
    two_children: "2,919.00",
    three_children: "3,379.00",
    four_children: "3,775.00",
    five_children: "4,152.00",
    six_children: "4,513.00"
  },
  {
    adjusted: "19,350.00",
    one_child: "2,002.00",
    two_children: "2,924.00",
    three_children: "3,386.00",
    four_children: "3,782.00",
    five_children: "4,160.00",
    six_children: "4,522.00"
  },
  {
    adjusted: "19,400.00",
    one_child: "2,006.00",
    two_children: "2,930.00",
    three_children: "3,392.00",
    four_children: "3,789.00",
    five_children: "4,168.00",
    six_children: "4,531.00"
  },
  {
    adjusted: "19,450.00",
    one_child: "2,010.00",
    two_children: "2,936.00",
    three_children: "3,399.00",
    four_children: "3,796.00",
    five_children: "4,176.00",
    six_children: "4,539.00"
  },
  {
    adjusted: "19,500.00",
    one_child: "2,014.00",
    two_children: "2,941.00",
    three_children: "3,405.00",
    four_children: "3,804.00",
    five_children: "4,184.00",
    six_children: "4,548.00"
  },
  {
    adjusted: "19,550.00",
    one_child: "2,017.00",
    two_children: "2,947.00",
    three_children: "3,412.00",
    four_children: "3,811.00",
    five_children: "4,192.00",
    six_children: "4,557.00"
  },
  {
    adjusted: "19,600.00",
    one_child: "2,021.00",
    two_children: "2,952.00",
    three_children: "3,418.00",
    four_children: "3,818.00",
    five_children: "4,200.00",
    six_children: "4,565.00"
  },
  {
    adjusted: "19,650.00",
    one_child: "2,025.00",
    two_children: "2,958.00",
    three_children: "3,425.00",
    four_children: "3,825.00",
    five_children: "4,208.00",
    six_children: "4,574.00"
  },
  {
    adjusted: "19,700.00",
    one_child: "2,029.00",
    two_children: "2,964.00",
    three_children: "3,431.00",
    four_children: "3,833.00",
    five_children: "4,216.00",
    six_children: "4,583.00"
  },
  {
    adjusted: "19,750.00",
    one_child: "2,033.00",
    two_children: "2,969.00",
    three_children: "3,438.00",
    four_children: "3,840.00",
    five_children: "4,224.00",
    six_children: "4,591.00"
  },
  {
    adjusted: "19,800.00",
    one_child: "2,037.00",
    two_children: "2,975.00",
    three_children: "3,444.00",
    four_children: "3,847.00",
    five_children: "4,232.00",
    six_children: "4,600.00"
  },
  {
    adjusted: "19,850.00",
    one_child: "2,041.00",
    two_children: "2,981.00",
    three_children: "3,451.00",
    four_children: "3,854.00",
    five_children: "4,240.00",
    six_children: "4,608.00"
  },
  {
    adjusted: "19,900.00",
    one_child: "2,045.00",
    two_children: "2,986.00",
    three_children: "3,457.00",
    four_children: "3,861.00",
    five_children: "4,248.00",
    six_children: "4,617.00"
  },
  {
    adjusted: "19,950.00",
    one_child: "2,049.00",
    two_children: "2,992.00",
    three_children: "3,463.00",
    four_children: "3,869.00",
    five_children: "4,256.00",
    six_children: "4,626.00"
  },
  {
    adjusted: "20,000.00",
    one_child: "2,053.00",
    two_children: "2,998.00",
    three_children: "3,470.00",
    four_children: "3,876.00",
    five_children: "4,264.00",
    six_children: "4,634.00"
  },
  {
    adjusted: "20,050.00",
    one_child: "2,056.00",
    two_children: "3,003.00",
    three_children: "3,476.00",
    four_children: "3,883.00",
    five_children: "4,271.00",
    six_children: "4,643.00"
  },
  {
    adjusted: "20,100.00",
    one_child: "2,060.00",
    two_children: "3,009.00",
    three_children: "3,483.00",
    four_children: "3,890.00",
    five_children: "4,279.00",
    six_children: "4,652.00"
  },
  {
    adjusted: "20,150.00",
    one_child: "2,064.00",
    two_children: "3,015.00",
    three_children: "3,489.00",
    four_children: "3,898.00",
    five_children: "4,287.00",
    six_children: "4,660.00"
  },
  {
    adjusted: "20,200.00",
    one_child: "2,067.00",
    two_children: "3,019.00",
    three_children: "3,495.00",
    four_children: "3,904.00",
    five_children: "4,295.00",
    six_children: "4,668.00"
  },
  {
    adjusted: "20,250.00",
    one_child: "2,070.00",
    two_children: "3,024.00",
    three_children: "3,501.00",
    four_children: "3,911.00",
    five_children: "4,302.00",
    six_children: "4,676.00"
  },
  {
    adjusted: "20,300.00",
    one_child: "2,073.00",
    two_children: "3,029.00",
    three_children: "3,507.00",
    four_children: "3,917.00",
    five_children: "4,309.00",
    six_children: "4,684.00"
  },
  {
    adjusted: "20,350.00",
    one_child: "2,076.00",
    two_children: "3,033.00",
    three_children: "3,513.00",
    four_children: "3,924.00",
    five_children: "4,316.00",
    six_children: "4,691.00"
  },
  {
    adjusted: "20,400.00",
    one_child: "2,079.00",
    two_children: "3,038.00",
    three_children: "3,518.00",
    four_children: "3,930.00",
    five_children: "4,323.00",
    six_children: "4,699.00"
  },
  {
    adjusted: "20,450.00",
    one_child: "2,082.00",
    two_children: "3,042.00",
    three_children: "3,524.00",
    four_children: "3,936.00",
    five_children: "4,330.00",
    six_children: "4,707.00"
  },
  {
    adjusted: "20,500.00",
    one_child: "2,084.00",
    two_children: "3,047.00",
    three_children: "3,530.00",
    four_children: "3,943.00",
    five_children: "4,337.00",
    six_children: "4,715.00"
  },
  {
    adjusted: "20,550.00",
    one_child: "2,087.00",
    two_children: "3,051.00",
    three_children: "3,536.00",
    four_children: "3,949.00",
    five_children: "4,344.00",
    six_children: "4,722.00"
  },
  {
    adjusted: "20,600.00",
    one_child: "2,090.00",
    two_children: "3,056.00",
    three_children: "3,541.00",
    four_children: "3,956.00",
    five_children: "4,351.00",
    six_children: "4,730.00"
  },
  {
    adjusted: "20,650.00",
    one_child: "2,093.00",
    two_children: "3,060.00",
    three_children: "3,547.00",
    four_children: "3,962.00",
    five_children: "4,358.00",
    six_children: "4,738.00"
  },
  {
    adjusted: "20,700.00",
    one_child: "2,096.00",
    two_children: "3,065.00",
    three_children: "3,553.00",
    four_children: "3,969.00",
    five_children: "4,366.00",
    six_children: "4,745.00"
  },
  {
    adjusted: "20,750.00",
    one_child: "2,099.00",
    two_children: "3,069.00",
    three_children: "3,559.00",
    four_children: "3,975.00",
    five_children: "4,373.00",
    six_children: "4,753.00"
  },
  {
    adjusted: "20,800.00",
    one_child: "2,101.00",
    two_children: "3,074.00",
    three_children: "3,565.00",
    four_children: "3,982.00",
    five_children: "4,380.00",
    six_children: "4,761.00"
  },
  {
    adjusted: "20,850.00",
    one_child: "2,104.00",
    two_children: "3,079.00",
    three_children: "3,570.00",
    four_children: "3,988.00",
    five_children: "4,387.00",
    six_children: "4,768.00"
  },
  {
    adjusted: "20,900.00",
    one_child: "2,107.00",
    two_children: "3,083.00",
    three_children: "3,576.00",
    four_children: "3,994.00",
    five_children: "4,394.00",
    six_children: "4,776.00"
  },
  {
    adjusted: "20,950.00",
    one_child: "2,110.00",
    two_children: "3,088.00",
    three_children: "3,582.00",
    four_children: "4,001.00",
    five_children: "4,401.00",
    six_children: "4,784.00"
  },
  {
    adjusted: "21,000.00",
    one_child: "2,113.00",
    two_children: "3,092.00",
    three_children: "3,588.00",
    four_children: "4,007.00",
    five_children: "4,408.00",
    six_children: "4,792.00"
  },
  {
    adjusted: "21,050.00",
    one_child: "2,116.00",
    two_children: "3,097.00",
    three_children: "3,593.00",
    four_children: "4,014.00",
    five_children: "4,415.00",
    six_children: "4,799.00"
  },
  {
    adjusted: "21,100.00",
    one_child: "2,119.00",
    two_children: "3,101.00",
    three_children: "3,599.00",
    four_children: "4,020.00",
    five_children: "4,422.00",
    six_children: "4,807.00"
  },
  {
    adjusted: "21,150.00",
    one_child: "2,121.00",
    two_children: "3,106.00",
    three_children: "3,605.00",
    four_children: "4,027.00",
    five_children: "4,429.00",
    six_children: "4,815.00"
  },
  {
    adjusted: "21,200.00",
    one_child: "2,124.00",
    two_children: "3,110.00",
    three_children: "3,611.00",
    four_children: "4,033.00",
    five_children: "4,436.00",
    six_children: "4,822.00"
  },
  {
    adjusted: "21,250.00",
    one_child: "2,127.00",
    two_children: "3,115.00",
    three_children: "3,616.00",
    four_children: "4,040.00",
    five_children: "4,443.00",
    six_children: "4,830.00"
  },
  {
    adjusted: "21,300.00",
    one_child: "2,130.00",
    two_children: "3,119.00",
    three_children: "3,622.00",
    four_children: "4,046.00",
    five_children: "4,451.00",
    six_children: "4,838.00"
  },
  {
    adjusted: "21,350.00",
    one_child: "2,133.00",
    two_children: "3,124.00",
    three_children: "3,628.00",
    four_children: "4,052.00",
    five_children: "4,458.00",
    six_children: "4,845.00"
  },
  {
    adjusted: "21,400.00",
    one_child: "2,136.00",
    two_children: "3,129.00",
    three_children: "3,634.00",
    four_children: "4,059.00",
    five_children: "4,465.00",
    six_children: "4,853.00"
  },
  {
    adjusted: "21,450.00",
    one_child: "2,138.00",
    two_children: "3,133.00",
    three_children: "3,639.00",
    four_children: "4,065.00",
    five_children: "4,472.00",
    six_children: "4,861.00"
  },
  {
    adjusted: "21,500.00",
    one_child: "2,141.00",
    two_children: "3,138.00",
    three_children: "3,645.00",
    four_children: "4,072.00",
    five_children: "4,479.00",
    six_children: "4,869.00"
  },
  {
    adjusted: "21,550.00",
    one_child: "2,144.00",
    two_children: "3,142.00",
    three_children: "3,651.00",
    four_children: "4,078.00",
    five_children: "4,486.00",
    six_children: "4,876.00"
  },
  {
    adjusted: "21,600.00",
    one_child: "2,147.00",
    two_children: "3,147.00",
    three_children: "3,657.00",
    four_children: "4,085.00",
    five_children: "4,493.00",
    six_children: "4,884.00"
  },
  {
    adjusted: "21,650.00",
    one_child: "2,150.00",
    two_children: "3,151.00",
    three_children: "3,663.00",
    four_children: "4,091.00",
    five_children: "4,500.00",
    six_children: "4,892.00"
  },
  {
    adjusted: "21,700.00",
    one_child: "2,153.00",
    two_children: "3,156.00",
    three_children: "3,668.00",
    four_children: "4,098.00",
    five_children: "4,507.00",
    six_children: "4,899.00"
  },
  {
    adjusted: "21,750.00",
    one_child: "2,155.00",
    two_children: "3,160.00",
    three_children: "3,674.00",
    four_children: "4,104.00",
    five_children: "4,514.00",
    six_children: "4,907.00"
  },
  {
    adjusted: "21,800.00",
    one_child: "2,158.00",
    two_children: "3,165.00",
    three_children: "3,680.00",
    four_children: "4,110.00",
    five_children: "4,521.00",
    six_children: "4,915.00"
  },
  {
    adjusted: "21,850.00",
    one_child: "2,161.00",
    two_children: "3,169.00",
    three_children: "3,686.00",
    four_children: "4,117.00",
    five_children: "4,529.00",
    six_children: "4,923.00"
  },
  {
    adjusted: "21,900.00",
    one_child: "2,164.00",
    two_children: "3,174.00",
    three_children: "3,691.00",
    four_children: "4,123.00",
    five_children: "4,536.00",
    six_children: "4,930.00"
  },
  {
    adjusted: "21,950.00",
    one_child: "2,167.00",
    two_children: "3,179.00",
    three_children: "3,697.00",
    four_children: "4,130.00",
    five_children: "4,543.00",
    six_children: "4,938.00"
  },
  {
    adjusted: "22,000.00",
    one_child: "2,170.00",
    two_children: "3,183.00",
    three_children: "3,703.00",
    four_children: "4,136.00",
    five_children: "4,550.00",
    six_children: "4,946.00"
  },
  {
    adjusted: "22,050.00",
    one_child: "2,172.00",
    two_children: "3,188.00",
    three_children: "3,709.00",
    four_children: "4,143.00",
    five_children: "4,557.00",
    six_children: "4,953.00"
  },
  {
    adjusted: "22,100.00",
    one_child: "2,175.00",
    two_children: "3,192.00",
    three_children: "3,714.00",
    four_children: "4,149.00",
    five_children: "4,564.00",
    six_children: "4,961.00"
  },
  {
    adjusted: "22,150.00",
    one_child: "2,178.00",
    two_children: "3,197.00",
    three_children: "3,720.00",
    four_children: "4,155.00",
    five_children: "4,571.00",
    six_children: "4,969.00"
  },
  {
    adjusted: "22,200.00",
    one_child: "2,181.00",
    two_children: "3,201.00",
    three_children: "3,726.00",
    four_children: "4,162.00",
    five_children: "4,578.00",
    six_children: "4,976.00"
  },
  {
    adjusted: "22,250.00",
    one_child: "2,184.00",
    two_children: "3,206.00",
    three_children: "3,732.00",
    four_children: "4,168.00",
    five_children: "4,585.00",
    six_children: "4,984.00"
  },
  {
    adjusted: "22,300.00",
    one_child: "2,187.00",
    two_children: "3,210.00",
    three_children: "3,738.00",
    four_children: "4,175.00",
    five_children: "4,592.00",
    six_children: "4,992.00"
  },
  {
    adjusted: "22,350.00",
    one_child: "2,189.00",
    two_children: "3,215.00",
    three_children: "3,743.00",
    four_children: "4,181.00",
    five_children: "4,599.00",
    six_children: "5,000.00"
  },
  {
    adjusted: "22,400.00",
    one_child: "2,192.00",
    two_children: "3,219.00",
    three_children: "3,749.00",
    four_children: "4,188.00",
    five_children: "4,606.00",
    six_children: "5,007.00"
  },
  {
    adjusted: "22,450.00",
    one_child: "2,195.00",
    two_children: "3,224.00",
    three_children: "3,755.00",
    four_children: "4,194.00",
    five_children: "4,614.00",
    six_children: "5,015.00"
  },
  {
    adjusted: "22,500.00",
    one_child: "2,198.00",
    two_children: "3,228.00",
    three_children: "3,761.00",
    four_children: "4,201.00",
    five_children: "4,621.00",
    six_children: "5,023.00"
  },
  {
    adjusted: "22,550.00",
    one_child: "2,201.00",
    two_children: "3,233.00",
    three_children: "3,766.00",
    four_children: "4,207.00",
    five_children: "4,628.00",
    six_children: "5,030.00"
  },
  {
    adjusted: "22,600.00",
    one_child: "2,204.00",
    two_children: "3,238.00",
    three_children: "3,772.00",
    four_children: "4,213.00",
    five_children: "4,635.00",
    six_children: "5,038.00"
  },
  {
    adjusted: "22,650.00",
    one_child: "2,207.00",
    two_children: "3,242.00",
    three_children: "3,778.00",
    four_children: "4,220.00",
    five_children: "4,642.00",
    six_children: "5,046.00"
  },
  {
    adjusted: "22,700.00",
    one_child: "2,209.00",
    two_children: "3,247.00",
    three_children: "3,784.00",
    four_children: "4,226.00",
    five_children: "4,649.00",
    six_children: "5,053.00"
  },
  {
    adjusted: "22,750.00",
    one_child: "2,212.00",
    two_children: "3,251.00",
    three_children: "3,789.00",
    four_children: "4,233.00",
    five_children: "4,656.00",
    six_children: "5,061.00"
  },
  {
    adjusted: "22,800.00",
    one_child: "2,215.00",
    two_children: "3,256.00",
    three_children: "3,795.00",
    four_children: "4,239.00",
    five_children: "4,663.00",
    six_children: "5,069.00"
  },
  {
    adjusted: "22,850.00",
    one_child: "2,218.00",
    two_children: "3,260.00",
    three_children: "3,801.00",
    four_children: "4,246.00",
    five_children: "4,670.00",
    six_children: "5,077.00"
  },
  {
    adjusted: "22,900.00",
    one_child: "2,221.00",
    two_children: "3,265.00",
    three_children: "3,807.00",
    four_children: "4,252.00",
    five_children: "4,677.00",
    six_children: "5,084.00"
  },
  {
    adjusted: "22,950.00",
    one_child: "2,224.00",
    two_children: "3,269.00",
    three_children: "3,812.00",
    four_children: "4,259.00",
    five_children: "4,684.00",
    six_children: "5,092.00"
  },
  {
    adjusted: "23,000.00",
    one_child: "2,226.00",
    two_children: "3,274.00",
    three_children: "3,818.00",
    four_children: "4,265.00",
    five_children: "4,691.00",
    six_children: "5,100.00"
  },
  {
    adjusted: "23,050.00",
    one_child: "2,229.00",
    two_children: "3,278.00",
    three_children: "3,824.00",
    four_children: "4,271.00",
    five_children: "4,699.00",
    six_children: "5,107.00"
  },
  {
    adjusted: "23,100.00",
    one_child: "2,232.00",
    two_children: "3,283.00",
    three_children: "3,830.00",
    four_children: "4,278.00",
    five_children: "4,706.00",
    six_children: "5,115.00"
  },
  {
    adjusted: "23,150.00",
    one_child: "2,235.00",
    two_children: "3,288.00",
    three_children: "3,836.00",
    four_children: "4,284.00",
    five_children: "4,713.00",
    six_children: "5,123.00"
  },
  {
    adjusted: "23,200.00",
    one_child: "2,238.00",
    two_children: "3,292.00",
    three_children: "3,841.00",
    four_children: "4,291.00",
    five_children: "4,720.00",
    six_children: "5,130.00"
  },
  {
    adjusted: "23,250.00",
    one_child: "2,241.00",
    two_children: "3,297.00",
    three_children: "3,847.00",
    four_children: "4,297.00",
    five_children: "4,727.00",
    six_children: "5,138.00"
  },
  {
    adjusted: "23,300.00",
    one_child: "2,243.00",
    two_children: "3,301.00",
    three_children: "3,853.00",
    four_children: "4,304.00",
    five_children: "4,734.00",
    six_children: "5,146.00"
  },
  {
    adjusted: "23,350.00",
    one_child: "2,246.00",
    two_children: "3,306.00",
    three_children: "3,859.00",
    four_children: "4,310.00",
    five_children: "4,741.00",
    six_children: "5,154.00"
  },
  {
    adjusted: "23,400.00",
    one_child: "2,249.00",
    two_children: "3,310.00",
    three_children: "3,864.00",
    four_children: "4,317.00",
    five_children: "4,748.00",
    six_children: "5,161.00"
  },
  {
    adjusted: "23,450.00",
    one_child: "2,252.00",
    two_children: "3,315.00",
    three_children: "3,870.00",
    four_children: "4,323.00",
    five_children: "4,755.00",
    six_children: "5,169.00"
  },
  {
    adjusted: "23,500.00",
    one_child: "2,255.00",
    two_children: "3,319.00",
    three_children: "3,876.00",
    four_children: "4,329.00",
    five_children: "4,762.00",
    six_children: "5,177.00"
  },
  {
    adjusted: "23,550.00",
    one_child: "2,258.00",
    two_children: "3,324.00",
    three_children: "3,882.00",
    four_children: "4,336.00",
    five_children: "4,769.00",
    six_children: "5,184.00"
  },
  {
    adjusted: "23,600.00",
    one_child: "2,260.00",
    two_children: "3,328.00",
    three_children: "3,887.00",
    four_children: "4,342.00",
    five_children: "4,777.00",
    six_children: "5,192.00"
  },
  {
    adjusted: "23,650.00",
    one_child: "2,263.00",
    two_children: "3,333.00",
    three_children: "3,893.00",
    four_children: "4,349.00",
    five_children: "4,784.00",
    six_children: "5,200.00"
  },
  {
    adjusted: "23,700.00",
    one_child: "2,266.00",
    two_children: "3,338.00",
    three_children: "3,899.00",
    four_children: "4,355.00",
    five_children: "4,791.00",
    six_children: "5,207.00"
  },
  {
    adjusted: "23,750.00",
    one_child: "2,269.00",
    two_children: "3,342.00",
    three_children: "3,905.00",
    four_children: "4,362.00",
    five_children: "4,798.00",
    six_children: "5,215.00"
  },
  {
    adjusted: "23,800.00",
    one_child: "2,272.00",
    two_children: "3,347.00",
    three_children: "3,911.00",
    four_children: "4,368.00",
    five_children: "4,805.00",
    six_children: "5,223.00"
  },
  {
    adjusted: "23,850.00",
    one_child: "2,275.00",
    two_children: "3,351.00",
    three_children: "3,916.00",
    four_children: "4,375.00",
    five_children: "4,812.00",
    six_children: "5,231.00"
  },
  {
    adjusted: "23,900.00",
    one_child: "2,277.00",
    two_children: "3,356.00",
    three_children: "3,922.00",
    four_children: "4,381.00",
    five_children: "4,819.00",
    six_children: "5,238.00"
  },
  {
    adjusted: "23,950.00",
    one_child: "2,280.00",
    two_children: "3,360.00",
    three_children: "3,928.00",
    four_children: "4,387.00",
    five_children: "4,826.00",
    six_children: "5,246.00"
  },
  {
    adjusted: "24,000.00",
    one_child: "2,283.00",
    two_children: "3,365.00",
    three_children: "3,934.00",
    four_children: "4,394.00",
    five_children: "4,833.00",
    six_children: "5,254.00"
  },
  {
    adjusted: "24,050.00",
    one_child: "2,286.00",
    two_children: "3,369.00",
    three_children: "3,939.00",
    four_children: "4,400.00",
    five_children: "4,840.00",
    six_children: "5,261.00"
  },
  {
    adjusted: "24,100.00",
    one_child: "2,289.00",
    two_children: "3,374.00",
    three_children: "3,945.00",
    four_children: "4,407.00",
    five_children: "4,847.00",
    six_children: "5,269.00"
  },
  {
    adjusted: "24,150.00",
    one_child: "2,292.00",
    two_children: "3,378.00",
    three_children: "3,951.00",
    four_children: "4,413.00",
    five_children: "4,854.00",
    six_children: "5,277.00"
  },
  {
    adjusted: "24,200.00",
    one_child: "2,295.00",
    two_children: "3,383.00",
    three_children: "3,957.00",
    four_children: "4,420.00",
    five_children: "4,862.00",
    six_children: "5,285.00"
  },
  {
    adjusted: "24,250.00",
    one_child: "2,297.00",
    two_children: "3,388.00",
    three_children: "3,962.00",
    four_children: "4,426.00",
    five_children: "4,869.00",
    six_children: "5,292.00"
  },
  {
    adjusted: "24,300.00",
    one_child: "2,300.00",
    two_children: "3,392.00",
    three_children: "3,968.00",
    four_children: "4,432.00",
    five_children: "4,876.00",
    six_children: "5,300.00"
  },
  {
    adjusted: "24,350.00",
    one_child: "2,303.00",
    two_children: "3,397.00",
    three_children: "3,974.00",
    four_children: "4,439.00",
    five_children: "4,883.00",
    six_children: "5,308.00"
  },
  {
    adjusted: "24,400.00",
    one_child: "2,306.00",
    two_children: "3,401.00",
    three_children: "3,980.00",
    four_children: "4,445.00",
    five_children: "4,890.00",
    six_children: "5,315.00"
  },
  {
    adjusted: "24,450.00",
    one_child: "2,309.00",
    two_children: "3,406.00",
    three_children: "3,985.00",
    four_children: "4,452.00",
    five_children: "4,897.00",
    six_children: "5,323.00"
  },
  {
    adjusted: "24,500.00",
    one_child: "2,312.00",
    two_children: "3,410.00",
    three_children: "3,991.00",
    four_children: "4,458.00",
    five_children: "4,904.00",
    six_children: "5,331.00"
  },
  {
    adjusted: "24,550.00",
    one_child: "2,314.00",
    two_children: "3,415.00",
    three_children: "3,997.00",
    four_children: "4,465.00",
    five_children: "4,911.00",
    six_children: "5,338.00"
  },
  {
    adjusted: "24,600.00",
    one_child: "2,317.00",
    two_children: "3,419.00",
    three_children: "4,003.00",
    four_children: "4,471.00",
    five_children: "4,918.00",
    six_children: "5,346.00"
  },
  {
    adjusted: "24,650.00",
    one_child: "2,320.00",
    two_children: "3,424.00",
    three_children: "4,009.00",
    four_children: "4,478.00",
    five_children: "4,925.00",
    six_children: "5,354.00"
  },
  {
    adjusted: "24,700.00",
    one_child: "2,323.00",
    two_children: "3,428.00",
    three_children: "4,014.00",
    four_children: "4,484.00",
    five_children: "4,932.00",
    six_children: "5,362.00"
  },
  {
    adjusted: "24,750.00",
    one_child: "2,326.00",
    two_children: "3,433.00",
    three_children: "4,020.00",
    four_children: "4,490.00",
    five_children: "4,939.00",
    six_children: "5,369.00"
  },
  {
    adjusted: "24,800.00",
    one_child: "2,329.00",
    two_children: "3,437.00",
    three_children: "4,026.00",
    four_children: "4,497.00",
    five_children: "4,947.00",
    six_children: "5,377.00"
  },
  {
    adjusted: "24,850.00",
    one_child: "2,331.00",
    two_children: "3,442.00",
    three_children: "4,032.00",
    four_children: "4,503.00",
    five_children: "4,954.00",
    six_children: "5,385.00"
  },
  {
    adjusted: "24,900.00",
    one_child: "2,334.00",
    two_children: "3,447.00",
    three_children: "4,037.00",
    four_children: "4,510.00",
    five_children: "4,961.00",
    six_children: "5,392.00"
  },
  {
    adjusted: "24,950.00",
    one_child: "2,337.00",
    two_children: "3,451.00",
    three_children: "4,043.00",
    four_children: "4,516.00",
    five_children: "4,968.00",
    six_children: "5,400.00"
  },
  {
    adjusted: "25,000.00",
    one_child: "2,340.00",
    two_children: "3,456.00",
    three_children: "4,049.00",
    four_children: "4,523.00",
    five_children: "4,975.00",
    six_children: "5,408.00"
  },
  {
    adjusted: "25,050.00",
    one_child: "2,343.00",
    two_children: "3,460.00",
    three_children: "4,055.00",
    four_children: "4,529.00",
    five_children: "4,982.00",
    six_children: "5,415.00"
  },
  {
    adjusted: "25,100.00",
    one_child: "2,346.00",
    two_children: "3,465.00",
    three_children: "4,060.00",
    four_children: "4,536.00",
    five_children: "4,989.00",
    six_children: "5,423.00"
  },
  {
    adjusted: "25,150.00",
    one_child: "2,348.00",
    two_children: "3,469.00",
    three_children: "4,066.00",
    four_children: "4,542.00",
    five_children: "4,996.00",
    six_children: "5,431.00"
  },
  {
    adjusted: "25,200.00",
    one_child: "2,351.00",
    two_children: "3,474.00",
    three_children: "4,072.00",
    four_children: "4,548.00",
    five_children: "5,003.00",
    six_children: "5,439.00"
  },
  {
    adjusted: "25,250.00",
    one_child: "2,354.00",
    two_children: "3,478.00",
    three_children: "4,078.00",
    four_children: "4,555.00",
    five_children: "5,010.00",
    six_children: "5,446.00"
  },
  {
    adjusted: "25,300.00",
    one_child: "2,357.00",
    two_children: "3,483.00",
    three_children: "4,084.00",
    four_children: "4,561.00",
    five_children: "5,017.00",
    six_children: "5,454.00"
  },
  {
    adjusted: "25,350.00",
    one_child: "2,360.00",
    two_children: "3,487.00",
    three_children: "4,089.00",
    four_children: "4,568.00",
    five_children: "5,025.00",
    six_children: "5,462.00"
  },
  {
    adjusted: "25,400.00",
    one_child: "2,363.00",
    two_children: "3,492.00",
    three_children: "4,095.00",
    four_children: "4,574.00",
    five_children: "5,032.00",
    six_children: "5,469.00"
  },
  {
    adjusted: "25,450.00",
    one_child: "2,365.00",
    two_children: "3,497.00",
    three_children: "4,101.00",
    four_children: "4,581.00",
    five_children: "5,039.00",
    six_children: "5,477.00"
  },
  {
    adjusted: "25,500.00",
    one_child: "2,368.00",
    two_children: "3,501.00",
    three_children: "4,107.00",
    four_children: "4,587.00",
    five_children: "5,046.00",
    six_children: "5,485.00"
  },
  {
    adjusted: "25,550.00",
    one_child: "2,371.00",
    two_children: "3,506.00",
    three_children: "4,112.00",
    four_children: "4,594.00",
    five_children: "5,053.00",
    six_children: "5,492.00"
  },
  {
    adjusted: "25,600.00",
    one_child: "2,374.00",
    two_children: "3,510.00",
    three_children: "4,118.00",
    four_children: "4,600.00",
    five_children: "5,060.00",
    six_children: "5,500.00"
  },
  {
    adjusted: "25,650.00",
    one_child: "2,377.00",
    two_children: "3,515.00",
    three_children: "4,124.00",
    four_children: "4,606.00",
    five_children: "5,067.00",
    six_children: "5,508.00"
  },
  {
    adjusted: "25,700.00",
    one_child: "2,380.00",
    two_children: "3,519.00",
    three_children: "4,130.00",
    four_children: "4,613.00",
    five_children: "5,074.00",
    six_children: "5,516.00"
  },
  {
    adjusted: "25,750.00",
    one_child: "2,383.00",
    two_children: "3,524.00",
    three_children: "4,135.00",
    four_children: "4,619.00",
    five_children: "5,081.00",
    six_children: "5,523.00"
  },
  {
    adjusted: "25,800.00",
    one_child: "2,385.00",
    two_children: "3,528.00",
    three_children: "4,141.00",
    four_children: "4,626.00",
    five_children: "5,088.00",
    six_children: "5,531.00"
  },
  {
    adjusted: "25,850.00",
    one_child: "2,388.00",
    two_children: "3,533.00",
    three_children: "4,147.00",
    four_children: "4,632.00",
    five_children: "5,095.00",
    six_children: "5,539.00"
  },
  {
    adjusted: "25,900.00",
    one_child: "2,391.00",
    two_children: "3,537.00",
    three_children: "4,153.00",
    four_children: "4,639.00",
    five_children: "5,102.00",
    six_children: "5,546.00"
  },
  {
    adjusted: "25,950.00",
    one_child: "2,394.00",
    two_children: "3,542.00",
    three_children: "4,159.00",
    four_children: "4,645.00",
    five_children: "5,110.00",
    six_children: "5,554.00"
  },
  {
    adjusted: "26,000.00",
    one_child: "2,397.00",
    two_children: "3,547.00",
    three_children: "4,164.00",
    four_children: "4,651.00",
    five_children: "5,117.00",
    six_children: "5,562.00"
  },
  {
    adjusted: "26,050.00",
    one_child: "2,400.00",
    two_children: "3,551.00",
    three_children: "4,170.00",
    four_children: "4,658.00",
    five_children: "5,124.00",
    six_children: "5,569.00"
  },
  {
    adjusted: "26,100.00",
    one_child: "2,402.00",
    two_children: "3,556.00",
    three_children: "4,176.00",
    four_children: "4,664.00",
    five_children: "5,131.00",
    six_children: "5,577.00"
  },
  {
    adjusted: "26,150.00",
    one_child: "2,405.00",
    two_children: "3,560.00",
    three_children: "4,182.00",
    four_children: "4,671.00",
    five_children: "5,138.00",
    six_children: "5,585.00"
  },
  {
    adjusted: "26,200.00",
    one_child: "2,408.00",
    two_children: "3,565.00",
    three_children: "4,187.00",
    four_children: "4,677.00",
    five_children: "5,145.00",
    six_children: "5,593.00"
  },
  {
    adjusted: "26,250.00",
    one_child: "2,411.00",
    two_children: "3,569.00",
    three_children: "4,193.00",
    four_children: "4,684.00",
    five_children: "5,152.00",
    six_children: "5,600.00"
  },
  {
    adjusted: "26,300.00",
    one_child: "2,414.00",
    two_children: "3,574.00",
    three_children: "4,199.00",
    four_children: "4,690.00",
    five_children: "5,159.00",
    six_children: "5,608.00"
  },
  {
    adjusted: "26,350.00",
    one_child: "2,417.00",
    two_children: "3,578.00",
    three_children: "4,205.00",
    four_children: "4,697.00",
    five_children: "5,166.00",
    six_children: "5,616.00"
  },
  {
    adjusted: "26,400.00",
    one_child: "2,419.00",
    two_children: "3,583.00",
    three_children: "4,210.00",
    four_children: "4,703.00",
    five_children: "5,173.00",
    six_children: "5,623.00"
  },
  {
    adjusted: "26,450.00",
    one_child: "2,422.00",
    two_children: "3,587.00",
    three_children: "4,216.00",
    four_children: "4,709.00",
    five_children: "5,180.00",
    six_children: "5,631.00"
  },
  {
    adjusted: "26,500.00",
    one_child: "2,425.00",
    two_children: "3,592.00",
    three_children: "4,222.00",
    four_children: "4,716.00",
    five_children: "5,187.00",
    six_children: "5,639.00"
  },
  {
    adjusted: "26,550.00",
    one_child: "2,428.00",
    two_children: "3,597.00",
    three_children: "4,228.00",
    four_children: "4,722.00",
    five_children: "5,195.00",
    six_children: "5,647.00"
  },
  {
    adjusted: "26,600.00",
    one_child: "2,431.00",
    two_children: "3,601.00",
    three_children: "4,233.00",
    four_children: "4,729.00",
    five_children: "5,202.00",
    six_children: "5,654.00"
  },
  {
    adjusted: "26,650.00",
    one_child: "2,434.00",
    two_children: "3,606.00",
    three_children: "4,239.00",
    four_children: "4,735.00",
    five_children: "5,209.00",
    six_children: "5,662.00"
  },
  {
    adjusted: "26,700.00",
    one_child: "2,436.00",
    two_children: "3,610.00",
    three_children: "4,245.00",
    four_children: "4,742.00",
    five_children: "5,216.00",
    six_children: "5,670.00"
  },
  {
    adjusted: "26,750.00",
    one_child: "2,439.00",
    two_children: "3,615.00",
    three_children: "4,251.00",
    four_children: "4,748.00",
    five_children: "5,223.00",
    six_children: "5,677.00"
  },
  {
    adjusted: "26,800.00",
    one_child: "2,442.00",
    two_children: "3,619.00",
    three_children: "4,257.00",
    four_children: "4,755.00",
    five_children: "5,230.00",
    six_children: "5,685.00"
  },
  {
    adjusted: "26,850.00",
    one_child: "2,445.00",
    two_children: "3,624.00",
    three_children: "4,262.00",
    four_children: "4,761.00",
    five_children: "5,237.00",
    six_children: "5,693.00"
  },
  {
    adjusted: "26,900.00",
    one_child: "2,448.00",
    two_children: "3,628.00",
    three_children: "4,268.00",
    four_children: "4,767.00",
    five_children: "5,244.00",
    six_children: "5,700.00"
  },
  {
    adjusted: "26,950.00",
    one_child: "2,451.00",
    two_children: "3,633.00",
    three_children: "4,273.00",
    four_children: "4,773.00",
    five_children: "5,251.00",
    six_children: "5,708.00"
  },
  {
    adjusted: "27,000.00",
    one_child: "2,454.00",
    two_children: "3,637.00",
    three_children: "4,278.00",
    four_children: "4,779.00",
    five_children: "5,257.00",
    six_children: "5,714.00"
  },
  {
    adjusted: "27,050.00",
    one_child: "2,457.00",
    two_children: "3,641.00",
    three_children: "4,283.00",
    four_children: "4,784.00",
    five_children: "5,263.00",
    six_children: "5,720.00"
  },
  {
    adjusted: "27,100.00",
    one_child: "2,460.00",
    two_children: "3,645.00",
    three_children: "4,288.00",
    four_children: "4,790.00",
    five_children: "5,268.00",
    six_children: "5,727.00"
  },
  {
    adjusted: "27,150.00",
    one_child: "2,463.00",
    two_children: "3,650.00",
    three_children: "4,293.00",
    four_children: "4,795.00",
    five_children: "5,274.00",
    six_children: "5,733.00"
  },
  {
    adjusted: "27,200.00",
    one_child: "2,465.00",
    two_children: "3,654.00",
    three_children: "4,297.00",
    four_children: "4,800.00",
    five_children: "5,280.00",
    six_children: "5,740.00"
  },
  {
    adjusted: "27,250.00",
    one_child: "2,468.00",
    two_children: "3,658.00",
    three_children: "4,302.00",
    four_children: "4,806.00",
    five_children: "5,286.00",
    six_children: "5,746.00"
  },
  {
    adjusted: "27,300.00",
    one_child: "2,471.00",
    two_children: "3,662.00",
    three_children: "4,307.00",
    four_children: "4,811.00",
    five_children: "5,292.00",
    six_children: "5,752.00"
  },
  {
    adjusted: "27,350.00",
    one_child: "2,474.00",
    two_children: "3,667.00",
    three_children: "4,312.00",
    four_children: "4,816.00",
    five_children: "5,298.00",
    six_children: "5,759.00"
  },
  {
    adjusted: "27,400.00",
    one_child: "2,477.00",
    two_children: "3,671.00",
    three_children: "4,317.00",
    four_children: "4,822.00",
    five_children: "5,304.00",
    six_children: "5,765.00"
  },
  {
    adjusted: "27,450.00",
    one_child: "2,480.00",
    two_children: "3,675.00",
    three_children: "4,321.00",
    four_children: "4,827.00",
    five_children: "5,310.00",
    six_children: "5,772.00"
  },
  {
    adjusted: "27,500.00",
    one_child: "2,483.00",
    two_children: "3,679.00",
    three_children: "4,326.00",
    four_children: "4,832.00",
    five_children: "5,315.00",
    six_children: "5,778.00"
  },
  {
    adjusted: "27,550.00",
    one_child: "2,486.00",
    two_children: "3,683.00",
    three_children: "4,331.00",
    four_children: "4,838.00",
    five_children: "5,321.00",
    six_children: "5,784.00"
  },
  {
    adjusted: "27,600.00",
    one_child: "2,489.00",
    two_children: "3,688.00",
    three_children: "4,336.00",
    four_children: "4,843.00",
    five_children: "5,327.00",
    six_children: "5,791.00"
  },
  {
    adjusted: "27,650.00",
    one_child: "2,492.00",
    two_children: "3,692.00",
    three_children: "4,340.00",
    four_children: "4,848.00",
    five_children: "5,333.00",
    six_children: "5,797.00"
  },
  {
    adjusted: "27,700.00",
    one_child: "2,495.00",
    two_children: "3,696.00",
    three_children: "4,345.00",
    four_children: "4,854.00",
    five_children: "5,339.00",
    six_children: "5,803.00"
  },
  {
    adjusted: "27,750.00",
    one_child: "2,498.00",
    two_children: "3,700.00",
    three_children: "4,350.00",
    four_children: "4,859.00",
    five_children: "5,345.00",
    six_children: "5,810.00"
  },
  {
    adjusted: "27,800.00",
    one_child: "2,501.00",
    two_children: "3,705.00",
    three_children: "4,355.00",
    four_children: "4,864.00",
    five_children: "5,351.00",
    six_children: "5,816.00"
  },
  {
    adjusted: "27,850.00",
    one_child: "2,504.00",
    two_children: "3,709.00",
    three_children: "4,360.00",
    four_children: "4,870.00",
    five_children: "5,357.00",
    six_children: "5,823.00"
  },
  {
    adjusted: "27,900.00",
    one_child: "2,507.00",
    two_children: "3,713.00",
    three_children: "4,364.00",
    four_children: "4,875.00",
    five_children: "5,362.00",
    six_children: "5,829.00"
  },
  {
    adjusted: "27,950.00",
    one_child: "2,510.00",
    two_children: "3,717.00",
    three_children: "4,369.00",
    four_children: "4,880.00",
    five_children: "5,368.00",
    six_children: "5,835.00"
  },
  {
    adjusted: "28,000.00",
    one_child: "2,513.00",
    two_children: "3,722.00",
    three_children: "4,374.00",
    four_children: "4,886.00",
    five_children: "5,374.00",
    six_children: "5,842.00"
  },
  {
    adjusted: "28,050.00",
    one_child: "2,516.00",
    two_children: "3,726.00",
    three_children: "4,379.00",
    four_children: "4,891.00",
    five_children: "5,380.00",
    six_children: "5,848.00"
  },
  {
    adjusted: "28,100.00",
    one_child: "2,519.00",
    two_children: "3,730.00",
    three_children: "4,383.00",
    four_children: "4,896.00",
    five_children: "5,386.00",
    six_children: "5,855.00"
  },
  {
    adjusted: "28,150.00",
    one_child: "2,522.00",
    two_children: "3,734.00",
    three_children: "4,388.00",
    four_children: "4,902.00",
    five_children: "5,392.00",
    six_children: "5,861.00"
  },
  {
    adjusted: "28,200.00",
    one_child: "2,525.00",
    two_children: "3,738.00",
    three_children: "4,393.00",
    four_children: "4,907.00",
    five_children: "5,398.00",
    six_children: "5,867.00"
  },
  {
    adjusted: "28,250.00",
    one_child: "2,528.00",
    two_children: "3,743.00",
    three_children: "4,398.00",
    four_children: "4,912.00",
    five_children: "5,404.00",
    six_children: "5,874.00"
  },
  {
    adjusted: "28,300.00",
    one_child: "2,530.00",
    two_children: "3,747.00",
    three_children: "4,403.00",
    four_children: "4,918.00",
    five_children: "5,410.00",
    six_children: "5,880.00"
  },
  {
    adjusted: "28,350.00",
    one_child: "2,533.00",
    two_children: "3,751.00",
    three_children: "4,407.00",
    four_children: "4,923.00",
    five_children: "5,415.00",
    six_children: "5,887.00"
  },
  {
    adjusted: "28,400.00",
    one_child: "2,536.00",
    two_children: "3,755.00",
    three_children: "4,412.00",
    four_children: "4,928.00",
    five_children: "5,421.00",
    six_children: "5,893.00"
  },
  {
    adjusted: "28,450.00",
    one_child: "2,539.00",
    two_children: "3,760.00",
    three_children: "4,417.00",
    four_children: "4,934.00",
    five_children: "5,427.00",
    six_children: "5,899.00"
  },
  {
    adjusted: "28,500.00",
    one_child: "2,542.00",
    two_children: "3,764.00",
    three_children: "4,422.00",
    four_children: "4,939.00",
    five_children: "5,433.00",
    six_children: "5,906.00"
  },
  {
    adjusted: "28,550.00",
    one_child: "2,545.00",
    two_children: "3,768.00",
    three_children: "4,427.00",
    four_children: "4,944.00",
    five_children: "5,439.00",
    six_children: "5,912.00"
  },
  {
    adjusted: "28,600.00",
    one_child: "2,548.00",
    two_children: "3,772.00",
    three_children: "4,431.00",
    four_children: "4,950.00",
    five_children: "5,445.00",
    six_children: "5,918.00"
  },
  {
    adjusted: "28,650.00",
    one_child: "2,551.00",
    two_children: "3,776.00",
    three_children: "4,436.00",
    four_children: "4,955.00",
    five_children: "5,451.00",
    six_children: "5,925.00"
  },
  {
    adjusted: "28,700.00",
    one_child: "2,554.00",
    two_children: "3,781.00",
    three_children: "4,441.00",
    four_children: "4,960.00",
    five_children: "5,457.00",
    six_children: "5,931.00"
  },
  {
    adjusted: "28,750.00",
    one_child: "2,557.00",
    two_children: "3,785.00",
    three_children: "4,446.00",
    four_children: "4,966.00",
    five_children: "5,462.00",
    six_children: "5,938.00"
  },
  {
    adjusted: "28,800.00",
    one_child: "2,560.00",
    two_children: "3,789.00",
    three_children: "4,450.00",
    four_children: "4,971.00",
    five_children: "5,468.00",
    six_children: "5,944.00"
  },
  {
    adjusted: "28,850.00",
    one_child: "2,563.00",
    two_children: "3,793.00",
    three_children: "4,455.00",
    four_children: "4,976.00",
    five_children: "5,474.00",
    six_children: "5,950.00"
  },
  {
    adjusted: "28,900.00",
    one_child: "2,566.00",
    two_children: "3,798.00",
    three_children: "4,460.00",
    four_children: "4,982.00",
    five_children: "5,480.00",
    six_children: "5,957.00"
  },
  {
    adjusted: "28,950.00",
    one_child: "2,569.00",
    two_children: "3,802.00",
    three_children: "4,465.00",
    four_children: "4,987.00",
    five_children: "5,486.00",
    six_children: "5,963.00"
  },
  {
    adjusted: "29,000.00",
    one_child: "2,572.00",
    two_children: "3,806.00",
    three_children: "4,470.00",
    four_children: "4,993.00",
    five_children: "5,492.00",
    six_children: "5,970.00"
  },
  {
    adjusted: "29,050.00",
    one_child: "2,575.00",
    two_children: "3,810.00",
    three_children: "4,474.00",
    four_children: "4,998.00",
    five_children: "5,498.00",
    six_children: "5,976.00"
  },
  {
    adjusted: "29,100.00",
    one_child: "2,578.00",
    two_children: "3,814.00",
    three_children: "4,479.00",
    four_children: "5,003.00",
    five_children: "5,504.00",
    six_children: "5,982.00"
  },
  {
    adjusted: "29,150.00",
    one_child: "2,581.00",
    two_children: "3,819.00",
    three_children: "4,484.00",
    four_children: "5,009.00",
    five_children: "5,509.00",
    six_children: "5,989.00"
  },
  {
    adjusted: "29,200.00",
    one_child: "2,584.00",
    two_children: "3,823.00",
    three_children: "4,489.00",
    four_children: "5,014.00",
    five_children: "5,515.00",
    six_children: "5,995.00"
  },
  {
    adjusted: "29,250.00",
    one_child: "2,587.00",
    two_children: "3,827.00",
    three_children: "4,493.00",
    four_children: "5,019.00",
    five_children: "5,521.00",
    six_children: "6,001.00"
  },
  {
    adjusted: "29,300.00",
    one_child: "2,590.00",
    two_children: "3,831.00",
    three_children: "4,498.00",
    four_children: "5,025.00",
    five_children: "5,527.00",
    six_children: "6,008.00"
  },
  {
    adjusted: "29,350.00",
    one_child: "2,593.00",
    two_children: "3,836.00",
    three_children: "4,503.00",
    four_children: "5,030.00",
    five_children: "5,533.00",
    six_children: "6,014.00"
  },
  {
    adjusted: "29,400.00",
    one_child: "2,595.00",
    two_children: "3,840.00",
    three_children: "4,508.00",
    four_children: "5,035.00",
    five_children: "5,539.00",
    six_children: "6,021.00"
  },
  {
    adjusted: "29,450.00",
    one_child: "2,598.00",
    two_children: "3,844.00",
    three_children: "4,513.00",
    four_children: "5,041.00",
    five_children: "5,545.00",
    six_children: "6,027.00"
  },
  {
    adjusted: "29,500.00",
    one_child: "2,601.00",
    two_children: "3,848.00",
    three_children: "4,517.00",
    four_children: "5,046.00",
    five_children: "5,551.00",
    six_children: "6,033.00"
  },
  {
    adjusted: "29,550.00",
    one_child: "2,604.00",
    two_children: "3,853.00",
    three_children: "4,522.00",
    four_children: "5,051.00",
    five_children: "5,556.00",
    six_children: "6,040.00"
  },
  {
    adjusted: "29,600.00",
    one_child: "2,607.00",
    two_children: "3,857.00",
    three_children: "4,527.00",
    four_children: "5,057.00",
    five_children: "5,562.00",
    six_children: "6,046.00"
  },
  {
    adjusted: "29,650.00",
    one_child: "2,610.00",
    two_children: "3,861.00",
    three_children: "4,532.00",
    four_children: "5,062.00",
    five_children: "5,568.00",
    six_children: "6,053.00"
  },
  {
    adjusted: "29,700.00",
    one_child: "2,613.00",
    two_children: "3,865.00",
    three_children: "4,537.00",
    four_children: "5,067.00",
    five_children: "5,574.00",
    six_children: "6,059.00"
  },
  {
    adjusted: "29,750.00",
    one_child: "2,616.00",
    two_children: "3,869.00",
    three_children: "4,541.00",
    four_children: "5,073.00",
    five_children: "5,580.00",
    six_children: "6,065.00"
  },
  {
    adjusted: "29,800.00",
    one_child: "2,619.00",
    two_children: "3,874.00",
    three_children: "4,546.00",
    four_children: "5,078.00",
    five_children: "5,586.00",
    six_children: "6,072.00"
  },
  {
    adjusted: "29,850.00",
    one_child: "2,622.00",
    two_children: "3,878.00",
    three_children: "4,551.00",
    four_children: "5,083.00",
    five_children: "5,592.00",
    six_children: "6,078.00"
  },
  {
    adjusted: "29,900.00",
    one_child: "2,625.00",
    two_children: "3,882.00",
    three_children: "4,556.00",
    four_children: "5,089.00",
    five_children: "5,598.00",
    six_children: "6,085.00"
  },
  {
    adjusted: "29,950.00",
    one_child: "2,628.00",
    two_children: "3,886.00",
    three_children: "4,560.00",
    four_children: "5,094.00",
    five_children: "5,603.00",
    six_children: "6,091.00"
  },
  {
    adjusted: "30,000.00",
    one_child: "2,631.00",
    two_children: "3,891.00",
    three_children: "4,565.00",
    four_children: "5,099.00",
    five_children: "5,609.00",
    six_children: "6,097.00"
  },
  {
    adjusted: "30,050.00",
    one_child: "2,634.00",
    two_children: "3,895.00",
    three_children: "4,570.00",
    four_children: "5,105.00",
    five_children: "5,615.00",
    six_children: "6,104.00"
  },
  {
    adjusted: "30,100.00",
    one_child: "2,637.00",
    two_children: "3,899.00",
    three_children: "4,575.00",
    four_children: "5,110.00",
    five_children: "5,621.00",
    six_children: "6,110.00"
  },
  {
    adjusted: "30,150.00",
    one_child: "2,640.00",
    two_children: "3,903.00",
    three_children: "4,580.00",
    four_children: "5,115.00",
    five_children: "5,627.00",
    six_children: "6,116.00"
  },
  {
    adjusted: "30,200.00",
    one_child: "2,643.00",
    two_children: "3,907.00",
    three_children: "4,584.00",
    four_children: "5,121.00",
    five_children: "5,633.00",
    six_children: "6,123.00"
  },
  {
    adjusted: "30,250.00",
    one_child: "2,646.00",
    two_children: "3,912.00",
    three_children: "4,589.00",
    four_children: "5,126.00",
    five_children: "5,639.00",
    six_children: "6,129.00"
  },
  {
    adjusted: "30,300.00",
    one_child: "2,649.00",
    two_children: "3,916.00",
    three_children: "4,594.00",
    four_children: "5,131.00",
    five_children: "5,645.00",
    six_children: "6,136.00"
  },
  {
    adjusted: "30,350.00",
    one_child: "2,652.00",
    two_children: "3,920.00",
    three_children: "4,599.00",
    four_children: "5,137.00",
    five_children: "5,650.00",
    six_children: "6,142.00"
  },
  {
    adjusted: "30,400.00",
    one_child: "2,655.00",
    two_children: "3,924.00",
    three_children: "4,603.00",
    four_children: "5,142.00",
    five_children: "5,656.00",
    six_children: "6,148.00"
  },
  {
    adjusted: "30,450.00",
    one_child: "2,658.00",
    two_children: "3,929.00",
    three_children: "4,608.00",
    four_children: "5,147.00",
    five_children: "5,662.00",
    six_children: "6,155.00"
  },
  {
    adjusted: "30,500.00",
    one_child: "2,660.00",
    two_children: "3,933.00",
    three_children: "4,613.00",
    four_children: "5,153.00",
    five_children: "5,668.00",
    six_children: "6,161.00"
  },
  {
    adjusted: "30,550.00",
    one_child: "2,663.00",
    two_children: "3,937.00",
    three_children: "4,618.00",
    four_children: "5,158.00",
    five_children: "5,674.00",
    six_children: "6,168.00"
  },
  {
    adjusted: "30,600.00",
    one_child: "2,666.00",
    two_children: "3,941.00",
    three_children: "4,623.00",
    four_children: "5,163.00",
    five_children: "5,680.00",
    six_children: "6,174.00"
  },
  {
    adjusted: "30,650.00",
    one_child: "2,669.00",
    two_children: "3,946.00",
    three_children: "4,627.00",
    four_children: "5,169.00",
    five_children: "5,686.00",
    six_children: "6,180.00"
  },
  {
    adjusted: "30,700.00",
    one_child: "2,672.00",
    two_children: "3,950.00",
    three_children: "4,632.00",
    four_children: "5,174.00",
    five_children: "5,692.00",
    six_children: "6,187.00"
  },
  {
    adjusted: "30,750.00",
    one_child: "2,675.00",
    two_children: "3,954.00",
    three_children: "4,637.00",
    four_children: "5,179.00",
    five_children: "5,697.00",
    six_children: "6,193.00"
  },
  {
    adjusted: "30,800.00",
    one_child: "2,678.00",
    two_children: "3,958.00",
    three_children: "4,642.00",
    four_children: "5,185.00",
    five_children: "5,703.00",
    six_children: "6,199.00"
  },
  {
    adjusted: "30,850.00",
    one_child: "2,681.00",
    two_children: "3,962.00",
    three_children: "4,647.00",
    four_children: "5,190.00",
    five_children: "5,709.00",
    six_children: "6,206.00"
  },
  {
    adjusted: "30,900.00",
    one_child: "2,684.00",
    two_children: "3,967.00",
    three_children: "4,651.00",
    four_children: "5,196.00",
    five_children: "5,715.00",
    six_children: "6,212.00"
  },
  {
    adjusted: "30,950.00",
    one_child: "2,687.00",
    two_children: "3,971.00",
    three_children: "4,656.00",
    four_children: "5,201.00",
    five_children: "5,721.00",
    six_children: "6,219.00"
  },
  {
    adjusted: "31,000.00",
    one_child: "2,690.00",
    two_children: "3,975.00",
    three_children: "4,661.00",
    four_children: "5,206.00",
    five_children: "5,727.00",
    six_children: "6,225.00"
  },
  {
    adjusted: "31,050.00",
    one_child: "2,693.00",
    two_children: "3,979.00",
    three_children: "4,666.00",
    four_children: "5,212.00",
    five_children: "5,733.00",
    six_children: "6,231.00"
  },
  {
    adjusted: "31,100.00",
    one_child: "2,696.00",
    two_children: "3,984.00",
    three_children: "4,670.00",
    four_children: "5,217.00",
    five_children: "5,739.00",
    six_children: "6,238.00"
  },
  {
    adjusted: "31,150.00",
    one_child: "2,699.00",
    two_children: "3,988.00",
    three_children: "4,675.00",
    four_children: "5,222.00",
    five_children: "5,744.00",
    six_children: "6,244.00"
  },
  {
    adjusted: "31,200.00",
    one_child: "2,702.00",
    two_children: "3,992.00",
    three_children: "4,680.00",
    four_children: "5,228.00",
    five_children: "5,750.00",
    six_children: "6,251.00"
  },
  {
    adjusted: "31,250.00",
    one_child: "2,705.00",
    two_children: "3,996.00",
    three_children: "4,685.00",
    four_children: "5,233.00",
    five_children: "5,756.00",
    six_children: "6,257.00"
  },
  {
    adjusted: "31,300.00",
    one_child: "2,708.00",
    two_children: "4,000.00",
    three_children: "4,690.00",
    four_children: "5,238.00",
    five_children: "5,762.00",
    six_children: "6,263.00"
  },
  {
    adjusted: "31,350.00",
    one_child: "2,711.00",
    two_children: "4,005.00",
    three_children: "4,694.00",
    four_children: "5,244.00",
    five_children: "5,768.00",
    six_children: "6,270.00"
  },
  {
    adjusted: "31,400.00",
    one_child: "2,714.00",
    two_children: "4,009.00",
    three_children: "4,699.00",
    four_children: "5,249.00",
    five_children: "5,774.00",
    six_children: "6,276.00"
  },
  {
    adjusted: "31,450.00",
    one_child: "2,717.00",
    two_children: "4,013.00",
    three_children: "4,704.00",
    four_children: "5,254.00",
    five_children: "5,780.00",
    six_children: "6,283.00"
  },
  {
    adjusted: "31,500.00",
    one_child: "2,720.00",
    two_children: "4,017.00",
    three_children: "4,709.00",
    four_children: "5,260.00",
    five_children: "5,786.00",
    six_children: "6,289.00"
  },
  {
    adjusted: "31,550.00",
    one_child: "2,723.00",
    two_children: "4,022.00",
    three_children: "4,713.00",
    four_children: "5,265.00",
    five_children: "5,791.00",
    six_children: "6,295.00"
  },
  {
    adjusted: "31,600.00",
    one_child: "2,726.00",
    two_children: "4,026.00",
    three_children: "4,718.00",
    four_children: "5,270.00",
    five_children: "5,797.00",
    six_children: "6,302.00"
  },
  {
    adjusted: "31,650.00",
    one_child: "2,728.00",
    two_children: "4,030.00",
    three_children: "4,723.00",
    four_children: "5,276.00",
    five_children: "5,803.00",
    six_children: "6,308.00"
  },
  {
    adjusted: "31,700.00",
    one_child: "2,731.00",
    two_children: "4,034.00",
    three_children: "4,728.00",
    four_children: "5,281.00",
    five_children: "5,809.00",
    six_children: "6,314.00"
  },
  {
    adjusted: "31,750.00",
    one_child: "2,734.00",
    two_children: "4,038.00",
    three_children: "4,733.00",
    four_children: "5,286.00",
    five_children: "5,815.00",
    six_children: "6,321.00"
  },
  {
    adjusted: "31,800.00",
    one_child: "2,737.00",
    two_children: "4,043.00",
    three_children: "4,737.00",
    four_children: "5,292.00",
    five_children: "5,821.00",
    six_children: "6,327.00"
  },
  {
    adjusted: "31,850.00",
    one_child: "2,740.00",
    two_children: "4,047.00",
    three_children: "4,742.00",
    four_children: "5,297.00",
    five_children: "5,827.00",
    six_children: "6,334.00"
  },
  {
    adjusted: "31,900.00",
    one_child: "2,743.00",
    two_children: "4,051.00",
    three_children: "4,747.00",
    four_children: "5,302.00",
    five_children: "5,833.00",
    six_children: "6,340.00"
  },
  {
    adjusted: "31,950.00",
    one_child: "2,746.00",
    two_children: "4,055.00",
    three_children: "4,752.00",
    four_children: "5,308.00",
    five_children: "5,838.00",
    six_children: "6,346.00"
  },
  {
    adjusted: "32,000.00",
    one_child: "2,749.00",
    two_children: "4,060.00",
    three_children: "4,757.00",
    four_children: "5,313.00",
    five_children: "5,844.00",
    six_children: "6,353.00"
  },
  {
    adjusted: "32,050.00",
    one_child: "2,752.00",
    two_children: "4,064.00",
    three_children: "4,761.00",
    four_children: "5,318.00",
    five_children: "5,850.00",
    six_children: "6,359.00"
  },
  {
    adjusted: "32,100.00",
    one_child: "2,755.00",
    two_children: "4,068.00",
    three_children: "4,766.00",
    four_children: "5,324.00",
    five_children: "5,856.00",
    six_children: "6,366.00"
  },
  {
    adjusted: "32,150.00",
    one_child: "2,758.00",
    two_children: "4,072.00",
    three_children: "4,771.00",
    four_children: "5,329.00",
    five_children: "5,862.00",
    six_children: "6,372.00"
  },
  {
    adjusted: "32,200.00",
    one_child: "2,761.00",
    two_children: "4,077.00",
    three_children: "4,776.00",
    four_children: "5,334.00",
    five_children: "5,868.00",
    six_children: "6,378.00"
  },
  {
    adjusted: "32,250.00",
    one_child: "2,764.00",
    two_children: "4,081.00",
    three_children: "4,780.00",
    four_children: "5,340.00",
    five_children: "5,874.00",
    six_children: "6,385.00"
  },
  {
    adjusted: "32,300.00",
    one_child: "2,767.00",
    two_children: "4,085.00",
    three_children: "4,785.00",
    four_children: "5,345.00",
    five_children: "5,880.00",
    six_children: "6,391.00"
  },
  {
    adjusted: "32,350.00",
    one_child: "2,770.00",
    two_children: "4,089.00",
    three_children: "4,790.00",
    four_children: "5,350.00",
    five_children: "5,885.00",
    six_children: "6,398.00"
  },
  {
    adjusted: "32,400.00",
    one_child: "2,773.00",
    two_children: "4,093.00",
    three_children: "4,795.00",
    four_children: "5,356.00",
    five_children: "5,891.00",
    six_children: "6,404.00"
  },
  {
    adjusted: "32,450.00",
    one_child: "2,776.00",
    two_children: "4,098.00",
    three_children: "4,800.00",
    four_children: "5,361.00",
    five_children: "5,897.00",
    six_children: "6,410.00"
  },
  {
    adjusted: "32,500.00",
    one_child: "2,779.00",
    two_children: "4,102.00",
    three_children: "4,804.00",
    four_children: "5,366.00",
    five_children: "5,903.00",
    six_children: "6,417.00"
  },
  {
    adjusted: "32,550.00",
    one_child: "2,782.00",
    two_children: "4,106.00",
    three_children: "4,809.00",
    four_children: "5,372.00",
    five_children: "5,909.00",
    six_children: "6,423.00"
  },
  {
    adjusted: "32,600.00",
    one_child: "2,785.00",
    two_children: "4,110.00",
    three_children: "4,814.00",
    four_children: "5,377.00",
    five_children: "5,915.00",
    six_children: "6,429.00"
  },
  {
    adjusted: "32,650.00",
    one_child: "2,788.00",
    two_children: "4,115.00",
    three_children: "4,819.00",
    four_children: "5,382.00",
    five_children: "5,921.00",
    six_children: "6,436.00"
  },
  {
    adjusted: "32,700.00",
    one_child: "2,791.00",
    two_children: "4,119.00",
    three_children: "4,823.00",
    four_children: "5,388.00",
    five_children: "5,927.00",
    six_children: "6,442.00"
  },
  {
    adjusted: "32,750.00",
    one_child: "2,793.00",
    two_children: "4,123.00",
    three_children: "4,828.00",
    four_children: "5,393.00",
    five_children: "5,932.00",
    six_children: "6,449.00"
  },
  {
    adjusted: "32,800.00",
    one_child: "2,796.00",
    two_children: "4,127.00",
    three_children: "4,833.00",
    four_children: "5,399.00",
    five_children: "5,938.00",
    six_children: "6,455.00"
  },
  {
    adjusted: "32,850.00",
    one_child: "2,799.00",
    two_children: "4,131.00",
    three_children: "4,838.00",
    four_children: "5,404.00",
    five_children: "5,944.00",
    six_children: "6,461.00"
  },
  {
    adjusted: "32,900.00",
    one_child: "2,802.00",
    two_children: "4,136.00",
    three_children: "4,843.00",
    four_children: "5,409.00",
    five_children: "5,950.00",
    six_children: "6,468.00"
  },
  {
    adjusted: "32,950.00",
    one_child: "2,805.00",
    two_children: "4,140.00",
    three_children: "4,847.00",
    four_children: "5,415.00",
    five_children: "5,956.00",
    six_children: "6,474.00"
  },
  {
    adjusted: "33,000.00",
    one_child: "2,808.00",
    two_children: "4,144.00",
    three_children: "4,852.00",
    four_children: "5,420.00",
    five_children: "5,962.00",
    six_children: "6,481.00"
  },
  {
    adjusted: "33,050.00",
    one_child: "2,811.00",
    two_children: "4,148.00",
    three_children: "4,857.00",
    four_children: "5,425.00",
    five_children: "5,968.00",
    six_children: "6,487.00"
  },
  {
    adjusted: "33,100.00",
    one_child: "2,814.00",
    two_children: "4,153.00",
    three_children: "4,862.00",
    four_children: "5,431.00",
    five_children: "5,974.00",
    six_children: "6,493.00"
  },
  {
    adjusted: "33,150.00",
    one_child: "2,817.00",
    two_children: "4,157.00",
    three_children: "4,867.00",
    four_children: "5,436.00",
    five_children: "5,979.00",
    six_children: "6,500.00"
  },
  {
    adjusted: "33,200.00",
    one_child: "2,820.00",
    two_children: "4,161.00",
    three_children: "4,871.00",
    four_children: "5,441.00",
    five_children: "5,985.00",
    six_children: "6,506.00"
  },
  {
    adjusted: "33,250.00",
    one_child: "2,823.00",
    two_children: "4,165.00",
    three_children: "4,876.00",
    four_children: "5,447.00",
    five_children: "5,991.00",
    six_children: "6,512.00"
  },
  {
    adjusted: "33,300.00",
    one_child: "2,826.00",
    two_children: "4,169.00",
    three_children: "4,881.00",
    four_children: "5,452.00",
    five_children: "5,997.00",
    six_children: "6,519.00"
  },
  {
    adjusted: "33,350.00",
    one_child: "2,829.00",
    two_children: "4,174.00",
    three_children: "4,886.00",
    four_children: "5,457.00",
    five_children: "6,003.00",
    six_children: "6,525.00"
  },
  {
    adjusted: "33,400.00",
    one_child: "2,832.00",
    two_children: "4,178.00",
    three_children: "4,890.00",
    four_children: "5,463.00",
    five_children: "6,009.00",
    six_children: "6,532.00"
  },
  {
    adjusted: "33,450.00",
    one_child: "2,835.00",
    two_children: "4,182.00",
    three_children: "4,895.00",
    four_children: "5,468.00",
    five_children: "6,015.00",
    six_children: "6,538.00"
  },
  {
    adjusted: "33,500.00",
    one_child: "2,838.00",
    two_children: "4,186.00",
    three_children: "4,900.00",
    four_children: "5,473.00",
    five_children: "6,021.00",
    six_children: "6,544.00"
  },
  {
    adjusted: "33,550.00",
    one_child: "2,841.00",
    two_children: "4,191.00",
    three_children: "4,905.00",
    four_children: "5,479.00",
    five_children: "6,026.00",
    six_children: "6,551.00"
  },
  {
    adjusted: "33,600.00",
    one_child: "2,844.00",
    two_children: "4,195.00",
    three_children: "4,910.00",
    four_children: "5,484.00",
    five_children: "6,032.00",
    six_children: "6,557.00"
  },
  {
    adjusted: "33,650.00",
    one_child: "2,847.00",
    two_children: "4,199.00",
    three_children: "4,914.00",
    four_children: "5,489.00",
    five_children: "6,038.00",
    six_children: "6,564.00"
  },
  {
    adjusted: "33,700.00",
    one_child: "2,850.00",
    two_children: "4,203.00",
    three_children: "4,919.00",
    four_children: "5,495.00",
    five_children: "6,044.00",
    six_children: "6,570.00"
  },
  {
    adjusted: "33,750.00",
    one_child: "2,853.00",
    two_children: "4,208.00",
    three_children: "4,924.00",
    four_children: "5,500.00",
    five_children: "6,050.00",
    six_children: "6,576.00"
  },
  {
    adjusted: "33,800.00",
    one_child: "2,856.00",
    two_children: "4,212.00",
    three_children: "4,929.00",
    four_children: "5,505.00",
    five_children: "6,056.00",
    six_children: "6,583.00"
  },
  {
    adjusted: "33,850.00",
    one_child: "2,858.00",
    two_children: "4,216.00",
    three_children: "4,933.00",
    four_children: "5,511.00",
    five_children: "6,062.00",
    six_children: "6,589.00"
  },
  {
    adjusted: "33,900.00",
    one_child: "2,861.00",
    two_children: "4,220.00",
    three_children: "4,938.00",
    four_children: "5,516.00",
    five_children: "6,068.00",
    six_children: "6,596.00"
  },
  {
    adjusted: "33,950.00",
    one_child: "2,864.00",
    two_children: "4,224.00",
    three_children: "4,943.00",
    four_children: "5,521.00",
    five_children: "6,074.00",
    six_children: "6,602.00"
  },
  {
    adjusted: "34,000.00",
    one_child: "2,867.00",
    two_children: "4,229.00",
    three_children: "4,948.00",
    four_children: "5,527.00",
    five_children: "6,079.00",
    six_children: "6,608.00"
  },
  {
    adjusted: "34,050.00",
    one_child: "2,870.00",
    two_children: "4,233.00",
    three_children: "4,953.00",
    four_children: "5,532.00",
    five_children: "6,085.00",
    six_children: "6,615.00"
  },
  {
    adjusted: "34,100.00",
    one_child: "2,873.00",
    two_children: "4,237.00",
    three_children: "4,957.00",
    four_children: "5,537.00",
    five_children: "6,091.00",
    six_children: "6,621.00"
  },
  {
    adjusted: "34,150.00",
    one_child: "2,876.00",
    two_children: "4,241.00",
    three_children: "4,962.00",
    four_children: "5,543.00",
    five_children: "6,097.00",
    six_children: "6,627.00"
  },
  {
    adjusted: "34,200.00",
    one_child: "2,879.00",
    two_children: "4,246.00",
    three_children: "4,967.00",
    four_children: "5,548.00",
    five_children: "6,103.00",
    six_children: "6,634.00"
  },
  {
    adjusted: "34,250.00",
    one_child: "2,882.00",
    two_children: "4,250.00",
    three_children: "4,972.00",
    four_children: "5,553.00",
    five_children: "6,109.00",
    six_children: "6,640.00"
  },
  {
    adjusted: "34,300.00",
    one_child: "2,885.00",
    two_children: "4,254.00",
    three_children: "4,977.00",
    four_children: "5,559.00",
    five_children: "6,115.00",
    six_children: "6,647.00"
  },
  {
    adjusted: "34,350.00",
    one_child: "2,888.00",
    two_children: "4,258.00",
    three_children: "4,981.00",
    four_children: "5,564.00",
    five_children: "6,121.00",
    six_children: "6,653.00"
  },
  {
    adjusted: "34,400.00",
    one_child: "2,891.00",
    two_children: "4,262.00",
    three_children: "4,986.00",
    four_children: "5,569.00",
    five_children: "6,126.00",
    six_children: "6,659.00"
  },
  {
    adjusted: "34,450.00",
    one_child: "2,894.00",
    two_children: "4,267.00",
    three_children: "4,991.00",
    four_children: "5,575.00",
    five_children: "6,132.00",
    six_children: "6,666.00"
  },
  {
    adjusted: "34,500.00",
    one_child: "2,897.00",
    two_children: "4,271.00",
    three_children: "4,996.00",
    four_children: "5,580.00",
    five_children: "6,138.00",
    six_children: "6,672.00"
  },
  {
    adjusted: "34,550.00",
    one_child: "2,900.00",
    two_children: "4,275.00",
    three_children: "5,000.00",
    four_children: "5,585.00",
    five_children: "6,144.00",
    six_children: "6,679.00"
  },
  {
    adjusted: "34,600.00",
    one_child: "2,903.00",
    two_children: "4,279.00",
    three_children: "5,005.00",
    four_children: "5,591.00",
    five_children: "6,150.00",
    six_children: "6,685.00"
  },
  {
    adjusted: "34,650.00",
    one_child: "2,906.00",
    two_children: "4,284.00",
    three_children: "5,010.00",
    four_children: "5,596.00",
    five_children: "6,156.00",
    six_children: "6,691.00"
  },
  {
    adjusted: "34,700.00",
    one_child: "2,909.00",
    two_children: "4,288.00",
    three_children: "5,015.00",
    four_children: "5,602.00",
    five_children: "6,162.00",
    six_children: "6,698.00"
  },
  {
    adjusted: "34,750.00",
    one_child: "2,912.00",
    two_children: "4,292.00",
    three_children: "5,020.00",
    four_children: "5,607.00",
    five_children: "6,168.00",
    six_children: "6,704.00"
  },
  {
    adjusted: "34,800.00",
    one_child: "2,915.00",
    two_children: "4,296.00",
    three_children: "5,024.00",
    four_children: "5,612.00",
    five_children: "6,173.00",
    six_children: "6,710.00"
  },
  {
    adjusted: "34,850.00",
    one_child: "2,918.00",
    two_children: "4,301.00",
    three_children: "5,029.00",
    four_children: "5,618.00",
    five_children: "6,179.00",
    six_children: "6,717.00"
  },
  {
    adjusted: "34,900.00",
    one_child: "2,921.00",
    two_children: "4,305.00",
    three_children: "5,034.00",
    four_children: "5,623.00",
    five_children: "6,185.00",
    six_children: "6,723.00"
  },
  {
    adjusted: "34,950.00",
    one_child: "2,923.00",
    two_children: "4,309.00",
    three_children: "5,039.00",
    four_children: "5,628.00",
    five_children: "6,191.00",
    six_children: "6,730.00"
  },
  {
    adjusted: "35,000.00",
    one_child: "2,926.00",
    two_children: "4,313.00",
    three_children: "5,043.00",
    four_children: "5,634.00",
    five_children: "6,197.00",
    six_children: "6,736.00"
  },
  {
    adjusted: "35,050.00",
    one_child: "2,929.00",
    two_children: "4,317.00",
    three_children: "5,048.00",
    four_children: "5,639.00",
    five_children: "6,203.00",
    six_children: "6,742.00"
  },
  {
    adjusted: "35,100.00",
    one_child: "2,932.00",
    two_children: "4,322.00",
    three_children: "5,053.00",
    four_children: "5,644.00",
    five_children: "6,209.00",
    six_children: "6,749.00"
  },
  {
    adjusted: "35,150.00",
    one_child: "2,935.00",
    two_children: "4,326.00",
    three_children: "5,058.00",
    four_children: "5,650.00",
    five_children: "6,215.00",
    six_children: "6,755.00"
  },
  {
    adjusted: "35,200.00",
    one_child: "2,938.00",
    two_children: "4,330.00",
    three_children: "5,063.00",
    four_children: "5,655.00",
    five_children: "6,220.00",
    six_children: "6,762.00"
  },
  {
    adjusted: "35,250.00",
    one_child: "2,941.00",
    two_children: "4,334.00",
    three_children: "5,067.00",
    four_children: "5,660.00",
    five_children: "6,226.00",
    six_children: "6,768.00"
  },
  {
    adjusted: "35,300.00",
    one_child: "2,944.00",
    two_children: "4,339.00",
    three_children: "5,072.00",
    four_children: "5,666.00",
    five_children: "6,232.00",
    six_children: "6,774.00"
  },
  {
    adjusted: "35,350.00",
    one_child: "2,947.00",
    two_children: "4,343.00",
    three_children: "5,077.00",
    four_children: "5,671.00",
    five_children: "6,238.00",
    six_children: "6,781.00"
  },
  {
    adjusted: "35,400.00",
    one_child: "2,950.00",
    two_children: "4,347.00",
    three_children: "5,082.00",
    four_children: "5,676.00",
    five_children: "6,244.00",
    six_children: "6,787.00"
  },
  {
    adjusted: "35,450.00",
    one_child: "2,953.00",
    two_children: "4,351.00",
    three_children: "5,087.00",
    four_children: "5,682.00",
    five_children: "6,250.00",
    six_children: "6,794.00"
  },
  {
    adjusted: "35,500.00",
    one_child: "2,956.00",
    two_children: "4,355.00",
    three_children: "5,091.00",
    four_children: "5,687.00",
    five_children: "6,256.00",
    six_children: "6,800.00"
  },
  {
    adjusted: "35,550.00",
    one_child: "2,959.00",
    two_children: "4,360.00",
    three_children: "5,096.00",
    four_children: "5,692.00",
    five_children: "6,262.00",
    six_children: "6,806.00"
  },
  {
    adjusted: "35,600.00",
    one_child: "2,962.00",
    two_children: "4,364.00",
    three_children: "5,101.00",
    four_children: "5,698.00",
    five_children: "6,267.00",
    six_children: "6,813.00"
  },
  {
    adjusted: "35,650.00",
    one_child: "2,965.00",
    two_children: "4,368.00",
    three_children: "5,106.00",
    four_children: "5,703.00",
    five_children: "6,273.00",
    six_children: "6,819.00"
  },
  {
    adjusted: "35,700.00",
    one_child: "2,968.00",
    two_children: "4,372.00",
    three_children: "5,110.00",
    four_children: "5,708.00",
    five_children: "6,279.00",
    six_children: "6,825.00"
  },
  {
    adjusted: "35,750.00",
    one_child: "2,971.00",
    two_children: "4,377.00",
    three_children: "5,115.00",
    four_children: "5,714.00",
    five_children: "6,285.00",
    six_children: "6,832.00"
  },
  {
    adjusted: "35,800.00",
    one_child: "2,974.00",
    two_children: "4,381.00",
    three_children: "5,120.00",
    four_children: "5,719.00",
    five_children: "6,291.00",
    six_children: "6,838.00"
  },
  {
    adjusted: "35,850.00",
    one_child: "2,977.00",
    two_children: "4,385.00",
    three_children: "5,125.00",
    four_children: "5,724.00",
    five_children: "6,297.00",
    six_children: "6,845.00"
  },
  {
    adjusted: "35,900.00",
    one_child: "2,980.00",
    two_children: "4,389.00",
    three_children: "5,130.00",
    four_children: "5,730.00",
    five_children: "6,303.00",
    six_children: "6,851.00"
  },
  {
    adjusted: "35,950.00",
    one_child: "2,983.00",
    two_children: "4,393.00",
    three_children: "5,134.00",
    four_children: "5,735.00",
    five_children: "6,309.00",
    six_children: "6,857.00"
  },
  {
    adjusted: "36,000.00",
    one_child: "2,986.00",
    two_children: "4,398.00",
    three_children: "5,139.00",
    four_children: "5,740.00",
    five_children: "6,314.00",
    six_children: "6,864.00"
  },
  {
    adjusted: "36,050.00",
    one_child: "2,988.00",
    two_children: "4,402.00",
    three_children: "5,144.00",
    four_children: "5,746.00",
    five_children: "6,320.00",
    six_children: "6,870.00"
  },
  {
    adjusted: "36,100.00",
    one_child: "2,991.00",
    two_children: "4,406.00",
    three_children: "5,149.00",
    four_children: "5,751.00",
    five_children: "6,326.00",
    six_children: "6,877.00"
  },
  {
    adjusted: "36,150.00",
    one_child: "2,994.00",
    two_children: "4,410.00",
    three_children: "5,153.00",
    four_children: "5,756.00",
    five_children: "6,332.00",
    six_children: "6,883.00"
  },
  {
    adjusted: "36,200.00",
    one_child: "2,997.00",
    two_children: "4,415.00",
    three_children: "5,158.00",
    four_children: "5,762.00",
    five_children: "6,338.00",
    six_children: "6,889.00"
  },
  {
    adjusted: "36,250.00",
    one_child: "3,000.00",
    two_children: "4,419.00",
    three_children: "5,163.00",
    four_children: "5,767.00",
    five_children: "6,344.00",
    six_children: "6,896.00"
  },
  {
    adjusted: "36,300.00",
    one_child: "3,003.00",
    two_children: "4,423.00",
    three_children: "5,168.00",
    four_children: "5,772.00",
    five_children: "6,350.00",
    six_children: "6,902.00"
  },
  {
    adjusted: "36,350.00",
    one_child: "3,006.00",
    two_children: "4,427.00",
    three_children: "5,173.00",
    four_children: "5,778.00",
    five_children: "6,356.00",
    six_children: "6,909.00"
  },
  {
    adjusted: "36,400.00",
    one_child: "3,009.00",
    two_children: "4,432.00",
    three_children: "5,177.00",
    four_children: "5,783.00",
    five_children: "6,361.00",
    six_children: "6,915.00"
  },
  {
    adjusted: "36,450.00",
    one_child: "3,012.00",
    two_children: "4,436.00",
    three_children: "5,182.00",
    four_children: "5,788.00",
    five_children: "6,367.00",
    six_children: "6,921.00"
  },
  {
    adjusted: "36,500.00",
    one_child: "3,015.00",
    two_children: "4,440.00",
    three_children: "5,187.00",
    four_children: "5,794.00",
    five_children: "6,373.00",
    six_children: "6,928.00"
  },
  {
    adjusted: "36,550.00",
    one_child: "3,018.00",
    two_children: "4,444.00",
    three_children: "5,192.00",
    four_children: "5,799.00",
    five_children: "6,379.00",
    six_children: "6,934.00"
  },
  {
    adjusted: "36,600.00",
    one_child: "3,021.00",
    two_children: "4,448.00",
    three_children: "5,197.00",
    four_children: "5,804.00",
    five_children: "6,385.00",
    six_children: "6,940.00"
  },
  {
    adjusted: "36,650.00",
    one_child: "3,024.00",
    two_children: "4,453.00",
    three_children: "5,201.00",
    four_children: "5,810.00",
    five_children: "6,391.00",
    six_children: "6,947.00"
  },
  {
    adjusted: "36,700.00",
    one_child: "3,027.00",
    two_children: "4,457.00",
    three_children: "5,206.00",
    four_children: "5,815.00",
    five_children: "6,397.00",
    six_children: "6,953.00"
  },
  {
    adjusted: "36,750.00",
    one_child: "3,030.00",
    two_children: "4,461.00",
    three_children: "5,211.00",
    four_children: "5,821.00",
    five_children: "6,403.00",
    six_children: "6,960.00"
  },
  {
    adjusted: "36,800.00",
    one_child: "3,033.00",
    two_children: "4,465.00",
    three_children: "5,216.00",
    four_children: "5,826.00",
    five_children: "6,408.00",
    six_children: "6,966.00"
  },
  {
    adjusted: "36,850.00",
    one_child: "3,036.00",
    two_children: "4,470.00",
    three_children: "5,220.00",
    four_children: "5,831.00",
    five_children: "6,414.00",
    six_children: "6,972.00"
  },
  {
    adjusted: "36,900.00",
    one_child: "3,039.00",
    two_children: "4,474.00",
    three_children: "5,225.00",
    four_children: "5,837.00",
    five_children: "6,420.00",
    six_children: "6,979.00"
  },
  {
    adjusted: "36,950.00",
    one_child: "3,042.00",
    two_children: "4,478.00",
    three_children: "5,230.00",
    four_children: "5,842.00",
    five_children: "6,426.00",
    six_children: "6,985.00"
  },
  {
    adjusted: "37,000.00",
    one_child: "3,045.00",
    two_children: "4,482.00",
    three_children: "5,235.00",
    four_children: "5,847.00",
    five_children: "6,432.00",
    six_children: "6,992.00"
  },
  {
    adjusted: "37,050.00",
    one_child: "3,048.00",
    two_children: "4,486.00",
    three_children: "5,240.00",
    four_children: "5,853.00",
    five_children: "6,438.00",
    six_children: "6,998.00"
  },
  {
    adjusted: "37,100.00",
    one_child: "3,051.00",
    two_children: "4,491.00",
    three_children: "5,244.00",
    four_children: "5,858.00",
    five_children: "6,444.00",
    six_children: "7,004.00"
  },
  {
    adjusted: "37,150.00",
    one_child: "3,053.00",
    two_children: "4,495.00",
    three_children: "5,249.00",
    four_children: "5,863.00",
    five_children: "6,450.00",
    six_children: "7,011.00"
  },
  {
    adjusted: "37,200.00",
    one_child: "3,056.00",
    two_children: "4,499.00",
    three_children: "5,254.00",
    four_children: "5,869.00",
    five_children: "6,455.00",
    six_children: "7,017.00"
  },
  {
    adjusted: "37,250.00",
    one_child: "3,059.00",
    two_children: "4,503.00",
    three_children: "5,259.00",
    four_children: "5,874.00",
    five_children: "6,461.00",
    six_children: "7,023.00"
  },
  {
    adjusted: "37,300.00",
    one_child: "3,062.00",
    two_children: "4,508.00",
    three_children: "5,263.00",
    four_children: "5,879.00",
    five_children: "6,467.00",
    six_children: "7,030.00"
  },
  {
    adjusted: "37,350.00",
    one_child: "3,065.00",
    two_children: "4,512.00",
    three_children: "5,268.00",
    four_children: "5,885.00",
    five_children: "6,473.00",
    six_children: "7,036.00"
  },
  {
    adjusted: "37,400.00",
    one_child: "3,068.00",
    two_children: "4,516.00",
    three_children: "5,273.00",
    four_children: "5,890.00",
    five_children: "6,479.00",
    six_children: "7,043.00"
  },
  {
    adjusted: "37,450.00",
    one_child: "3,071.00",
    two_children: "4,520.00",
    three_children: "5,278.00",
    four_children: "5,895.00",
    five_children: "6,485.00",
    six_children: "7,049.00"
  },
  {
    adjusted: "37,500.00",
    one_child: "3,074.00",
    two_children: "4,525.00",
    three_children: "5,283.00",
    four_children: "5,901.00",
    five_children: "6,491.00",
    six_children: "7,055.00"
  },
  {
    adjusted: "37,550.00",
    one_child: "3,077.00",
    two_children: "4,529.00",
    three_children: "5,287.00",
    four_children: "5,906.00",
    five_children: "6,497.00",
    six_children: "7,062.00"
  },
  {
    adjusted: "37,600.00",
    one_child: "3,080.00",
    two_children: "4,533.00",
    three_children: "5,292.00",
    four_children: "5,911.00",
    five_children: "6,502.00",
    six_children: "7,068.00"
  },
  {
    adjusted: "37,650.00",
    one_child: "3,083.00",
    two_children: "4,537.00",
    three_children: "5,297.00",
    four_children: "5,917.00",
    five_children: "6,508.00",
    six_children: "7,075.00"
  },
  {
    adjusted: "37,700.00",
    one_child: "3,086.00",
    two_children: "4,541.00",
    three_children: "5,302.00",
    four_children: "5,922.00",
    five_children: "6,514.00",
    six_children: "7,081.00"
  },
  {
    adjusted: "37,750.00",
    one_child: "3,089.00",
    two_children: "4,546.00",
    three_children: "5,307.00",
    four_children: "5,927.00",
    five_children: "6,520.00",
    six_children: "7,087.00"
  },
  {
    adjusted: "37,800.00",
    one_child: "3,092.00",
    two_children: "4,550.00",
    three_children: "5,311.00",
    four_children: "5,933.00",
    five_children: "6,526.00",
    six_children: "7,094.00"
  },
  {
    adjusted: "37,850.00",
    one_child: "3,095.00",
    two_children: "4,554.00",
    three_children: "5,316.00",
    four_children: "5,938.00",
    five_children: "6,532.00",
    six_children: "7,100.00"
  },
  {
    adjusted: "37,900.00",
    one_child: "3,098.00",
    two_children: "4,558.00",
    three_children: "5,321.00",
    four_children: "5,943.00",
    five_children: "6,538.00",
    six_children: "7,107.00"
  },
  {
    adjusted: "37,950.00",
    one_child: "3,101.00",
    two_children: "4,563.00",
    three_children: "5,326.00",
    four_children: "5,949.00",
    five_children: "6,544.00",
    six_children: "7,113.00"
  },
  {
    adjusted: "38,000.00",
    one_child: "3,104.00",
    two_children: "4,567.00",
    three_children: "5,330.00",
    four_children: "5,954.00",
    five_children: "6,549.00",
    six_children: "7,119.00"
  },
  {
    adjusted: "38,050.00",
    one_child: "3,107.00",
    two_children: "4,571.00",
    three_children: "5,335.00",
    four_children: "5,959.00",
    five_children: "6,555.00",
    six_children: "7,126.00"
  },
  {
    adjusted: "38,100.00",
    one_child: "3,110.00",
    two_children: "4,575.00",
    three_children: "5,340.00",
    four_children: "5,965.00",
    five_children: "6,561.00",
    six_children: "7,132.00"
  },
  {
    adjusted: "38,150.00",
    one_child: "3,113.00",
    two_children: "4,579.00",
    three_children: "5,345.00",
    four_children: "5,970.00",
    five_children: "6,567.00",
    six_children: "7,138.00"
  },
  {
    adjusted: "38,200.00",
    one_child: "3,116.00",
    two_children: "4,584.00",
    three_children: "5,350.00",
    four_children: "5,975.00",
    five_children: "6,573.00",
    six_children: "7,145.00"
  },
  {
    adjusted: "38,250.00",
    one_child: "3,118.00",
    two_children: "4,588.00",
    three_children: "5,354.00",
    four_children: "5,981.00",
    five_children: "6,579.00",
    six_children: "7,151.00"
  },
  {
    adjusted: "38,300.00",
    one_child: "3,121.00",
    two_children: "4,592.00",
    three_children: "5,359.00",
    four_children: "5,986.00",
    five_children: "6,585.00",
    six_children: "7,158.00"
  },
  {
    adjusted: "38,350.00",
    one_child: "3,124.00",
    two_children: "4,596.00",
    three_children: "5,364.00",
    four_children: "5,991.00",
    five_children: "6,591.00",
    six_children: "7,164.00"
  },
  {
    adjusted: "38,400.00",
    one_child: "3,127.00",
    two_children: "4,601.00",
    three_children: "5,369.00",
    four_children: "5,997.00",
    five_children: "6,596.00",
    six_children: "7,170.00"
  },
  {
    adjusted: "38,450.00",
    one_child: "3,130.00",
    two_children: "4,605.00",
    three_children: "5,373.00",
    four_children: "6,002.00",
    five_children: "6,602.00",
    six_children: "7,177.00"
  },
  {
    adjusted: "38,500.00",
    one_child: "3,133.00",
    two_children: "4,609.00",
    three_children: "5,378.00",
    four_children: "6,007.00",
    five_children: "6,608.00",
    six_children: "7,183.00"
  },
  {
    adjusted: "38,550.00",
    one_child: "3,136.00",
    two_children: "4,613.00",
    three_children: "5,383.00",
    four_children: "6,013.00",
    five_children: "6,614.00",
    six_children: "7,190.00"
  },
  {
    adjusted: "38,600.00",
    one_child: "3,139.00",
    two_children: "4,617.00",
    three_children: "5,388.00",
    four_children: "6,018.00",
    five_children: "6,620.00",
    six_children: "7,196.00"
  },
  {
    adjusted: "38,650.00",
    one_child: "3,142.00",
    two_children: "4,622.00",
    three_children: "5,393.00",
    four_children: "6,024.00",
    five_children: "6,626.00",
    six_children: "7,202.00"
  },
  {
    adjusted: "38,700.00",
    one_child: "3,145.00",
    two_children: "4,626.00",
    three_children: "5,397.00",
    four_children: "6,029.00",
    five_children: "6,632.00",
    six_children: "7,209.00"
  },
  {
    adjusted: "38,750.00",
    one_child: "3,148.00",
    two_children: "4,630.00",
    three_children: "5,402.00",
    four_children: "6,034.00",
    five_children: "6,638.00",
    six_children: "7,215.00"
  },
  {
    adjusted: "38,800.00",
    one_child: "3,151.00",
    two_children: "4,634.00",
    three_children: "5,407.00",
    four_children: "6,040.00",
    five_children: "6,643.00",
    six_children: "7,221.00"
  },
  {
    adjusted: "38,850.00",
    one_child: "3,154.00",
    two_children: "4,639.00",
    three_children: "5,412.00",
    four_children: "6,045.00",
    five_children: "6,649.00",
    six_children: "7,228.00"
  },
  {
    adjusted: "38,900.00",
    one_child: "3,157.00",
    two_children: "4,643.00",
    three_children: "5,416.00",
    four_children: "6,050.00",
    five_children: "6,655.00",
    six_children: "7,234.00"
  },
  {
    adjusted: "38,950.00",
    one_child: "3,160.00",
    two_children: "4,647.00",
    three_children: "5,421.00",
    four_children: "6,056.00",
    five_children: "6,661.00",
    six_children: "7,241.00"
  },
  {
    adjusted: "39,000.00",
    one_child: "3,163.00",
    two_children: "4,651.00",
    three_children: "5,426.00",
    four_children: "6,061.00",
    five_children: "6,667.00",
    six_children: "7,247.00"
  },
  {
    adjusted: "39,050.00",
    one_child: "3,166.00",
    two_children: "4,656.00",
    three_children: "5,431.00",
    four_children: "6,066.00",
    five_children: "6,673.00",
    six_children: "7,253.00"
  },
  {
    adjusted: "39,100.00",
    one_child: "3,169.00",
    two_children: "4,660.00",
    three_children: "5,436.00",
    four_children: "6,072.00",
    five_children: "6,679.00",
    six_children: "7,260.00"
  },
  {
    adjusted: "39,150.00",
    one_child: "3,172.00",
    two_children: "4,664.00",
    three_children: "5,440.00",
    four_children: "6,077.00",
    five_children: "6,685.00",
    six_children: "7,266.00"
  },
  {
    adjusted: "39,200.00",
    one_child: "3,175.00",
    two_children: "4,668.00",
    three_children: "5,445.00",
    four_children: "6,082.00",
    five_children: "6,691.00",
    six_children: "7,273.00"
  },
  {
    adjusted: "39,250.00",
    one_child: "3,178.00",
    two_children: "4,672.00",
    three_children: "5,450.00",
    four_children: "6,088.00",
    five_children: "6,696.00",
    six_children: "7,279.00"
  },
  {
    adjusted: "39,300.00",
    one_child: "3,181.00",
    two_children: "4,677.00",
    three_children: "5,455.00",
    four_children: "6,093.00",
    five_children: "6,702.00",
    six_children: "7,285.00"
  },
  {
    adjusted: "39,350.00",
    one_child: "3,184.00",
    two_children: "4,681.00",
    three_children: "5,460.00",
    four_children: "6,098.00",
    five_children: "6,708.00",
    six_children: "7,292.00"
  },
  {
    adjusted: "39,400.00",
    one_child: "3,186.00",
    two_children: "4,685.00",
    three_children: "5,464.00",
    four_children: "6,104.00",
    five_children: "6,714.00",
    six_children: "7,298.00"
  },
  {
    adjusted: "39,450.00",
    one_child: "3,189.00",
    two_children: "4,689.00",
    three_children: "5,469.00",
    four_children: "6,109.00",
    five_children: "6,720.00",
    six_children: "7,305.00"
  },
  {
    adjusted: "39,500.00",
    one_child: "3,192.00",
    two_children: "4,694.00",
    three_children: "5,474.00",
    four_children: "6,114.00",
    five_children: "6,726.00",
    six_children: "7,311.00"
  },
  {
    adjusted: "39,550.00",
    one_child: "3,195.00",
    two_children: "4,698.00",
    three_children: "5,479.00",
    four_children: "6,120.00",
    five_children: "6,732.00",
    six_children: "7,317.00"
  },
  {
    adjusted: "39,600.00",
    one_child: "3,198.00",
    two_children: "4,702.00",
    three_children: "5,483.00",
    four_children: "6,125.00",
    five_children: "6,738.00",
    six_children: "7,324.00"
  },
  {
    adjusted: "39,650.00",
    one_child: "3,201.00",
    two_children: "4,706.00",
    three_children: "5,488.00",
    four_children: "6,130.00",
    five_children: "6,743.00",
    six_children: "7,330.00"
  },
  {
    adjusted: "39,700.00",
    one_child: "3,204.00",
    two_children: "4,710.00",
    three_children: "5,493.00",
    four_children: "6,136.00",
    five_children: "6,749.00",
    six_children: "7,336.00"
  },
  {
    adjusted: "39,750.00",
    one_child: "3,207.00",
    two_children: "4,715.00",
    three_children: "5,498.00",
    four_children: "6,141.00",
    five_children: "6,755.00",
    six_children: "7,343.00"
  },
  {
    adjusted: "39,800.00",
    one_child: "3,210.00",
    two_children: "4,719.00",
    three_children: "5,503.00",
    four_children: "6,146.00",
    five_children: "6,761.00",
    six_children: "7,349.00"
  },
  {
    adjusted: "39,850.00",
    one_child: "3,213.00",
    two_children: "4,723.00",
    three_children: "5,507.00",
    four_children: "6,152.00",
    five_children: "6,767.00",
    six_children: "7,356.00"
  },
  {
    adjusted: "39,900.00",
    one_child: "3,216.00",
    two_children: "4,727.00",
    three_children: "5,512.00",
    four_children: "6,157.00",
    five_children: "6,773.00",
    six_children: "7,362.00"
  },
  {
    adjusted: "39,950.00",
    one_child: "3,219.00",
    two_children: "4,732.00",
    three_children: "5,517.00",
    four_children: "6,162.00",
    five_children: "6,779.00",
    six_children: "7,368.00"
  },
  {
    adjusted: "40,000.00",
    one_child: "3,222.00",
    two_children: "4,736.00",
    three_children: "5,522.00",
    four_children: "6,168.00",
    five_children: "6,785.00",
    six_children: "7,375.00"
  }
];
