import { SET_HEADERLINK,SET_HEADERNULL } from "../../actions-Types/authActionTypes";

const initialState = {
  headerActiveURL: null
};

export default (
  state = initialState,
  { type, payload }: { type: string; payload: string }
) => {
  switch (type) {
    case SET_HEADERLINK:
      return { ...state, headerActiveURL: payload };
      case SET_HEADERNULL:
        return { ...state, headerActiveURL: "" };
    default:
      return state;
  }
};
