import React, { ChangeEvent, useEffect, useRef, FocusEvent } from "react";
import {
  TextField,
  Typography,
  Box,
  InputAdornment,
  GlobalStyles,
} from "@mui/material";
import CustomTooltip from "../tooltip";
import interrogation from "../../assets/images/interrogation.svg";


interface InputTextFieldProps {
  type?: string;
  name: string;
  placeholder: string;
  value: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  error?: string;
  required?: boolean;
  label?: string;
  tooltip?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  backgroundColor?: string;
  disabled?: boolean;
  color?: string;
  inputProps?: object;
  fontWeight?: string;

}

const CustomInput: React.FC<InputTextFieldProps> = ({
  type,
  name,
  label,
  value,
  onChange,
  onBlur,
  placeholder,
  required,
  error,
  tooltip,
  startAdornment,
  endAdornment,
  backgroundColor,
  disabled,
  inputProps,
  fontWeight,
  color,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // const handleKeyDown = (event: KeyboardEvent) => {
    //   if (type === "number") {
    //     if (
    //       event.key === "ArrowUp" ||
    //       event.key === "ArrowDown" ||
    //       event.key === "e" ||
    //       event.key === "E" ||
    //       event.key === "-"
    //     ) {
    //       event.preventDefault();
    //     }
    //   }
    // };

    // const input = inputRef.current;
    // if (input) {
    //   input.addEventListener("keydown", handleKeyDown);
    // }

    // return () => {
    //   if (input) {
    //     input.removeEventListener("keydown", handleKeyDown);
    //   }
    // };
  }, [type]);

  return (
    <>
      <GlobalStyles
        styles={{
          "input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset",
            WebkitTextFillColor: "#000",
          },
          "textarea:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "select:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "input:-webkit-autofill:hover": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "textarea:-webkit-autofill:hover": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "select:-webkit-autofill:hover": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "input:-webkit-autofill:focus": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "textarea:-webkit-autofill:focus": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
          "select:-webkit-autofill:focus": {
            WebkitBoxShadow: "0 0 0 1000px white inset !important",
            WebkitTextFillColor: "#000 !important",
          },
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {label && (
          <Typography
            variant="subtitle1"
            sx={{
              mb: 1,
              fontWeight: "medium",
              fontSize: "12px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>{label} {required && <span style={{ color: "red" }}>*</span>}{" "}{tooltip && (
              <CustomTooltip title={tooltip}>
                <img
                  src={interrogation}
                  alt="Logo"
                  height={13}
                  style={{ marginLeft: "5px", verticalAlign: 'middle' }}
                />
              </CustomTooltip>
            )}</div>


          </Typography>
        )}

        <TextField
          variant="outlined"
          required={required}
          fullWidth
          value={value}
          type={type}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          error={!!error}
          onBlur={onBlur}
          ref={inputRef}
          inputProps={{
            ...inputProps,
            onWheel: (event) => event.currentTarget.blur(),
          }}

          // inputProps={inputProps}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "28px",
              height: "35px",
              borderColor: error ? "#d32f2f!important" : "#E2E0E0",
              fontSize: "14px",
              color: color || "#9C9BAA",
              fontWeight: fontWeight || "",

              backgroundColor: backgroundColor || "#fff",
              "& input[type=number]": {
                MozAppearance: "textfield",
              },
              "& input[type=number]::-webkit-outer-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "& input[type=number]::-webkit-inner-spin-button": {
                WebkitAppearance: "none",
                margin: 0,
              },
              "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                borderColor: "#d32f2f !important",
              },
              "&.MuiInputBase-root": {
                paddingRight: '8px',
              }
            },
            "& .Mui-error": {
              color: "#d32f2f",
            },
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0)",
            },
            "&.Mui-focused": {
              backgroundColor: "rgba(0, 0, 0, 0)",
            },
          }}
          InputProps={{
            startAdornment: startAdornment ? (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ) : null,
            endAdornment: endAdornment ? (
              <InputAdornment position="end" sx={{ marginLeft: '3px' }}>{endAdornment}</InputAdornment>
            ) : null,
          }}
          disabled={disabled}
        />
        {error && (
          <Typography
            variant="subtitle1"
            sx={{

              fontWeight: "medium",
              textAlign: "left",
              color: "#d32f2f",
              paddingTop: "5px",
            }}
          >
            {error}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default CustomInput;
