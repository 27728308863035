import React from "react";
import style from "../searchWorksheet.module.css";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchWorksheetButtons from "../../../components/searchWorksheetButtons";
import TextareaField from "../../../components/text-area";
import { useAppSelector } from "../../../globalState/hooks";
import {
  getTotalJudicialDeviation,
  getTotalProposedDeviation,
} from "../../worksheet/DeviationsTab/DeviationsValidationTabs";
import { getBasicFinalValueFor2Line } from "../../../utils/getBasicInfoCalculate";
import { CalculateNarestChildValues } from "../../../utils/calculateNarestChildValues";
import FootnoteImage from "../../../assets/images/FootnoteImg.png";
import {
  JudicialDiscretionForLowIncome,
  ProposedLowIncomeDeviation,
} from "../../../types/create-worksheet";
import { GetLowIncomeDeviationAmt } from "../../../utils/getLowIncomeDeviationAmt";
import {
  formatNumber,
  formatNumberWithDigit,
} from "../../../utils/formatNumber";

const DeviationsTab: React.FC = () => {
  const WorkSheetData = useAppSelector((state) => state.createWorksheet);
  const { FinalValue1, FinalValue2 } =
    getBasicFinalValueFor2Line(WorkSheetData);

  //// Code for Deviation Tab calculation start
  const proposedFinalResultDeviation = getProposedLowIncomeDeviationAmount(
    WorkSheetData.deviations_schedule_e.proposed_low_income_deviation,
    WorkSheetData.parent_name_1,
    WorkSheetData.parent_name_2
  );
  const judicialFinalResultDeviation = getJudicialDiscretionAppliedAmount(
    WorkSheetData.deviations_schedule_e.judicial_discretion_for_low_income,
    WorkSheetData.parent_name_1,
    WorkSheetData.parent_name_2
  );

  /// handle get Proposed Low Income Deviations Amount
  function getProposedLowIncomeDeviationAmount(
    arrayList: ProposedLowIncomeDeviation[],
    parent1: string,
    parent2: string
  ) {
    const resultP1 = arrayList.find(
      (item) => item.deviation_is_for === parent1
    )?.low_income_deviation;
    const resultP2 = arrayList.find(
      (item) => item.deviation_is_for === parent2
    )?.low_income_deviation;

    return { parent1: resultP1, parent2: resultP2 };
  }

  /// handle get Judicial Discretion Applied Amount
  function getJudicialDiscretionAppliedAmount(
    arrayList: JudicialDiscretionForLowIncome[],
    parent1: string,
    parent2: string
  ) {
    const resultP1 = arrayList.find(
      (item) => item.deviation_is_for === parent1
    )?.low_income_deviation;
    const resultP2 = arrayList.find(
      (item) => item.deviation_is_for === parent2
    )?.low_income_deviation;

    return { parent1: resultP1, parent2: resultP2 };
  }

  const line1AValue1 = proposedFinalResultDeviation;
  const lineABValue1 = judicialFinalResultDeviation;
  //// Code for Deviation Tab calculation end

  //// get 1B Low Income Deviation Amount Used code start
  //   const DeviationValue1 = WorkSheetData.deviations_schedule_e
  //   .proposed_low_income_deviation
  //   ? WorkSheetData.deviations_schedule_e.proposed_low_income_deviation[0]
  //       .low_income_deviation_explanation
  //   : "";
  // const DeviationValue2 =
  //   WorkSheetData.deviations_schedule_e.proposed_low_income_deviation.length ===
  //   2
  //     ? WorkSheetData.deviations_schedule_e.proposed_low_income_deviation[1]
  //         .low_income_deviation_explanation
  //     : "";

  const finalline1bResult = GetLowIncomeDeviationAmt(
    WorkSheetData.deviations_schedule_e.proposed_low_income_deviation,
    WorkSheetData.deviations_schedule_e.judicial_discretion_for_low_income,
    WorkSheetData.parent_name_1,
    WorkSheetData.parent_name_2
  );

  const line1BValue1 = finalline1bResult.result1;
  const line1BValue2 = finalline1bResult.result2;
  //// get 1B Low Income Deviation Amount Used code end

  //get Explanation data start
  //// code for Proposed explanation
  const ExPropoValue1 = WorkSheetData.deviations_schedule_e
    .proposed_low_income_deviation
    ? WorkSheetData.deviations_schedule_e.proposed_low_income_deviation[0]
        ?.low_income_deviation_explanation
    : "";
  const ExPropoValue2 =
    WorkSheetData.deviations_schedule_e.proposed_low_income_deviation.length ===
    2
      ? WorkSheetData.deviations_schedule_e.proposed_low_income_deviation[1]
          ?.low_income_deviation_explanation
      : "";

  //// code for judicial explanation
  const ExJudiValue1 = WorkSheetData.deviations_schedule_e
    .judicial_discretion_for_low_income
    ? WorkSheetData.deviations_schedule_e.judicial_discretion_for_low_income[0]
        ?.low_income_deviation_explanation
    : "";
  const ExJudiValue2 =
    WorkSheetData.deviations_schedule_e.judicial_discretion_for_low_income
      .length === 2
      ? WorkSheetData.deviations_schedule_e
          .judicial_discretion_for_low_income[1]
          ?.low_income_deviation_explanation
      : "";

  const line1AValueExplanation = ExPropoValue1 || ExJudiValue1;
  const lineABValueExplanation = ExPropoValue2 || ExJudiValue2;
  // get Explanation data end

  const parentsTotal = getTotalProposedDeviation(
    WorkSheetData.deviations_schedule_e.specific_nonspecific_deviation || []
  );
  const ProposedParent1Total = parentsTotal[WorkSheetData.parent_name_1];
  const ProposedParent2Total = parentsTotal[WorkSheetData.parent_name_2];

  const JudicialParentsTotal = getTotalJudicialDeviation(
    WorkSheetData.deviations_schedule_e.specific_nonspecific_deviation || []
  );
  const JudicialParent1Total =
    JudicialParentsTotal[WorkSheetData.parent_name_1];
  const JudicialParent2Total =
    JudicialParentsTotal[WorkSheetData.parent_name_2];

  /// line no 12 a total
  const line12ATotal =
    (WorkSheetData.deviations_schedule_e
      .extraordinary_educational_expenses_total1 ||
      WorkSheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total2 ||
      WorkSheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total3) &&
    (
      Number(
        WorkSheetData.deviations_schedule_e
          .extraordinary_educational_expenses_total1
      ) /
        12 +
      Number(
        WorkSheetData.deviations_schedule_e
          .extraordinary_educational_expenses_total2
      ) /
        12 +
      Number(
        WorkSheetData.deviations_schedule_e
          .extraordinary_educational_expenses_total3
      ) /
        12
    ).toFixed(2);

  /// line no 12 b total
  const line12BTotal =
    (WorkSheetData.deviations_schedule_e
      .extraordinary_medical_expenses_total1 ||
      WorkSheetData.deviations_schedule_e
        .extraordinary_medical_expenses_total2 ||
      WorkSheetData.deviations_schedule_e
        .extraordinary_medical_expenses_total3) &&
    (
      Number(
        WorkSheetData.deviations_schedule_e
          .extraordinary_medical_expenses_total1
      ) /
        12 +
      Number(
        WorkSheetData.deviations_schedule_e
          .extraordinary_medical_expenses_total2
      ) /
        12 +
      Number(
        WorkSheetData.deviations_schedule_e
          .extraordinary_medical_expenses_total3
      ) /
        12
    ).toFixed(2);

  const line12DTotal1 =
    Number(
      WorkSheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total1
    ) /
      12 +
    Number(
      WorkSheetData.deviations_schedule_e
        .extraordinary_medical_expenses_total1 / 12
    );
  const line12DTotal2 =
    Number(
      WorkSheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total2
    ) /
      12 +
    Number(
      WorkSheetData.deviations_schedule_e
        .extraordinary_medical_expenses_total2 / 12
    );
  const line12DTotal3 =
    Number(
      WorkSheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total3
    ) /
      12 +
    Number(
      WorkSheetData.deviations_schedule_e
        .extraordinary_medical_expenses_total3 / 12
    );

  const line12DConbine = line12DTotal1 + line12DTotal2 + line12DTotal3;

  // code for line 12 E
  const line2ValueTotal = (Number(FinalValue1) + Number(FinalValue2)).toFixed(
    2
  );
  const line3Value1 =
    Number(FinalValue1) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue1) * 100) / Number(line2ValueTotal), 0),
          100
        ).toFixed(2);

  const line3Value2 =
    Number(FinalValue2) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue2) * 100) / Number(line2ValueTotal), 0),
          100
        ).toFixed(2);

  const line12FValue1 = (line12DConbine * Number(line3Value1)) / 100;
  const line12FValue2 = (line12DConbine * Number(line3Value2)) / 100;

  const line12FConbine = line12FValue1 + line12FValue2;

  const line12GValue1 = line12FValue1 - line12DTotal1;
  const line12GValue2 = line12FValue2 - line12DTotal2;

  const calculationExtraEEME = [
    {
      serialNo: "12(a).",
      label:
        " Extraordinary Educational Expenses Total amounts display under the labeled columns",
      parents1:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total1 / 12
        ),
      parents2:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total2 / 12
        ),
      nonparentCustodian:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total3 / 12
        ),
      combined: line12ATotal ? "$" + line12ATotal : "",
    },
    {
      serialNo: "12(b).",
      label:
        " Extraordinary Medical Expenses Total amounts display under the labeled columns",
      parents1:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total1 / 12
        ),
      parents2:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total2 / 12
        ),
      nonparentCustodian:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total3 / 12
        ),
      combined: line12BTotal ? "$" + line12BTotal : "",
    },
    {
      serialNo: "12(c).",
      label:
        " Allowable Special Expenses Total amounts display under the labeled columns",
      parents1: "$",
      parents2: "$",
      nonparentCustodian: "$",
      combined: "$",
    },
    {
      serialNo: "12(d).",
      label:
        " Total Extraordinary and Allowable Special Expenses. Lines 12(a), 12(b) and 12(c) added",
      parents1: line12DTotal1 ? "$" + formatNumberWithDigit(line12DTotal1) : "",
      parents2: line12DTotal2 ? "$" + formatNumberWithDigit(line12DTotal2) : "",
      nonparentCustodian: line12DTotal3
        ? "$" + formatNumberWithDigit(line12DTotal3)
        : "",
      combined: line12DConbine
        ? "$" + formatNumberWithDigit(line12DConbine)
        : "",
    },
    {
      serialNo: "12(e).",
      label:
        " Parent's Pro Rata Share of Income from Child Support Worksheet, Line 3",
      parents1: line3Value1 ? line3Value1 + "%" : "",
      parents2: line3Value2 ? line3Value2 + "%" : "",
      nonparentCustodian: "",
      combined: "100%",
    },
    {
      serialNo: "12(f).",
      label:
        " Parent's share of extraordinary/special expenses. Line 12(d) multiplied by percentages for each Parent on Line 12(e)",
      parents1: line12FValue1 ? "$" + formatNumberWithDigit(line12FValue1) : "",
      parents2: line12FValue2 ? "$" + formatNumberWithDigit(line12FValue2) : "",
      nonparentCustodian: "",
      combined: line12FConbine
        ? "$" + formatNumberWithDigit(line12FConbine)
        : "",
    },
    {
      serialNo: "12(g).",
      label:
        " Deviation for extraordinary/special expenses. Line 12(f) minus 12(d)",
      parents1: line12GValue1 ? "$" + formatNumberWithDigit(line12GValue1) : "",
      parents2: line12GValue2 ? "$" + formatNumberWithDigit(line12GValue2) : "",
      nonparentCustodian: "",
      combined: "",
    },
  ];

  const deviationExtraEducationExpenseSummery = [
    {
      label: `${WorkSheetData.parent_name_1} Yearly Total:`,
      amountPaid:
        "$" +
        formatNumber(
          WorkSheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total1
        ),
      paidByTotal:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total1 / 12
        ),
      extraordinaryEducationExpenses: `${WorkSheetData.parent_name_1} Monthly Total:`,
    },
    {
      label: `${WorkSheetData.parent_name_2} Yearly Total:`,
      amountPaid:
        "$" +
        formatNumber(
          WorkSheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total2
        ),
      paidByTotal:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total2 / 12
        ),
      extraordinaryEducationExpenses: `${WorkSheetData.parent_name_2} Monthly Total:`,
    },
    {
      label: "Nonparent Custodian Yearly Total:",
      amountPaid:
        "$" +
        formatNumber(
          WorkSheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total3
        ),
      paidByTotal:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .extraordinary_educational_expenses_total3 / 12
        ),
      extraordinaryEducationExpenses: "Nonparent Custodian Monthly Total:",
    },
  ];

  const deviationExtraMedicalExpenseSumery = [
    {
      label: `${WorkSheetData.parent_name_1} Yearly Total:`,
      value:
        "$" +
        formatNumber(
          WorkSheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total1
        ),
      paidBy: `${WorkSheetData.parent_name_1} Monthly Total:`,
      extraordinaryMedicalExpenses:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total1 / 12
        ),
    },
    {
      label: `${WorkSheetData.parent_name_2} Yearly Total:`,
      value:
        "$" +
        formatNumber(
          WorkSheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total2
        ),
      paidBy: `${WorkSheetData.parent_name_2} Monthly Total:`,
      extraordinaryMedicalExpenses:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total2 / 12
        ),
    },
    {
      label: "Nonparent Custodian Yearly Total:",
      value:
        "$" +
        formatNumber(
          WorkSheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total3
        ),
      paidBy: "Nonparent Custodian Monthly Total:",
      extraordinaryMedicalExpenses:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .extraordinary_medical_expenses_total3 / 12
        ),
    },
  ];

  const deviationSpecialExpenseSummery = [
    {
      label: `${WorkSheetData.parent_name_1} Yearly Total:`,
      amountPaid:
        "$" +
        formatNumber(
          WorkSheetData.deviations_schedule_e
            .special_expenses_child_rearing_total1
        ),
      paidBy: `${WorkSheetData.parent_name_1} Monthly Total:`,
      explanation:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .special_expenses_child_rearing_total1 / 12
        ),
    },
    {
      label: `${WorkSheetData.parent_name_2} Yearly Total:`,
      amountPaid:
        "$" +
        formatNumber(
          WorkSheetData.deviations_schedule_e
            .special_expenses_child_rearing_total2
        ),
      paidBy: `${WorkSheetData.parent_name_2} Monthly Total:`,
      explanation:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .special_expenses_child_rearing_total2 / 12
        ),
    },
    {
      label: "Nonparent Custodian Yearly Total:",
      amountPaid:
        "$" +
        formatNumber(
          WorkSheetData.deviations_schedule_e
            .special_expenses_child_rearing_total3
        ),
      paidBy: "Nonparent Custodian Monthly Total:",
      explanation:
        "$" +
        formatNumberWithDigit(
          WorkSheetData.deviations_schedule_e
            .special_expenses_child_rearing_total3 / 12
        ),
    },
  ];

  const lineATotalValue =
    Number(
      WorkSheetData.deviations_schedule_e.special_expenses_child_rearing_total1
    ) +
    Number(
      WorkSheetData.deviations_schedule_e.special_expenses_child_rearing_total2
    ) +
    Number(
      WorkSheetData.deviations_schedule_e.special_expenses_child_rearing_total3
    );

  const lineBTotalValue = Number(lineATotalValue) / 12;

  const lineCTotalValue = CalculateNarestChildValues(
    Number(line2ValueTotal),
    WorkSheetData?.add_child_to_worksheet?.length
  );

  const lineDTotalValue = (Number(lineCTotalValue) * 7) / 100;

  const allowableSpecialExpenses = [
    {
      id: "a",
      label: "(a) Total Yearly Special Expenses",
      value: lineATotalValue ? "$" + Number(lineATotalValue).toFixed(2) : "",
    },
    {
      id: "b",
      label: "(b) Monthly Average of Special Expenses",
      value: lineBTotalValue ? "$" + Number(lineBTotalValue).toFixed(2) : "",
    },
    {
      id: "c",
      label: "(c) Basic Child Support Obligation (from Line 4)",
      value: lineCTotalValue ? "$" + lineCTotalValue : "",
    },
    {
      id: "d",
      label: "(d) Special Expenses Limitation (Letter c x 7%)",
      value: lineDTotalValue ? "$" + lineDTotalValue : "",
    },
    {
      id: "e",
      label:
        "(e) If Line b is greater than Line d, Line d will be subtracted from Line b and the answer will display here; If Line b is less than Line d, zero will display here",
      value: "$",
    },
    {
      id: "f",
      label: "(f) Harsh's Monthly Allowable Special Expenses for Child Rearing",
      value: "$",
    },
    {
      id: "g",
      label: "(g) Mina's Monthly Allowable Special Expenses for Child Rearing",
      value: "$",
    },
    {
      id: "h",
      label:
        "(h) Nonparent's Monthly Allowable Special Expenses for Child Rearing",
      value: "$",
    },
  ];

  //// line 14 calculation code start
  const line2AValueCheckP1 = JudicialParent1Total
    ? JudicialParent1Total
    : ProposedParent1Total;
  const line2AValueCheckP2 = JudicialParent2Total
    ? JudicialParent2Total
    : ProposedParent2Total;

  const line14Value1 =
    Number(line1BValue1) +
    Number(line2AValueCheckP1) +
    Number(line12GValue1) +
    Number(WorkSheetData.deviations_schedule_e?.parenting_time_deviation1);
  const line14Value2 =
    Number(line1BValue2) +
    Number(line2AValueCheckP2) +
    Number(line12GValue2) +
    Number(WorkSheetData.deviations_schedule_e?.parenting_time_deviation2);

  //// line 14 calculation code end
  return (
    <>
      {WorkSheetData.deviations_schedule_e.proposed_low_income_deviation
        ?.length ||
      WorkSheetData.deviations_schedule_e.judicial_discretion_for_low_income
        ?.length ||
      WorkSheetData.deviations_schedule_e.specific_nonspecific_deviation
        ?.length ||
      WorkSheetData.deviations_schedule_e.extra_ordinary_educational_expenses
        ?.length ||
      WorkSheetData.deviations_schedule_e.extra_ordinary_medical_expenses
        ?.length ||
      WorkSheetData.deviations_schedule_e.special_expenses_child_rearing
        ?.length ||
      WorkSheetData.deviations_schedule_e.parenting_time_deviation1 ||
      WorkSheetData.deviations_schedule_e.parenting_time_deviation2 ||
      WorkSheetData.deviations_schedule_e.unjust_or_inappropriate ||
      WorkSheetData.deviations_schedule_e.best_interests_of_children ||
      WorkSheetData.deviations_schedule_e.impair_ability_to_maintain ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              className={style.contentHeading}
              marginTop={"15px !important"}
            >
              CHILD SUPPORT SCHEDULE E DEVIATIONS AND SPECIAL CIRCUMSTANCES{" "}
            </Typography>
          </Box>
          <Box pt={3}>
            <Box>
              <Typography
                p={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  background: "#F8F8F8",
                  border: "1px solid #DFDFDF",
                  fontWeight: "600",
                  color: "#404040",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                Schedule E - All amounts/data that display on Schedule E were
                entered using the Online Child Support Calculator and can only
                be changed by selecting the button &quot;Open This worksheet.
                &quot; For each section completed, calculations for monthly
                amounts (annual amounts as entered in certain areas)
                automatically display in the appropriate fields. Amounts from
                Lines 1, 11, 12(g) and 13 will be totaled and display on Line 10
                of the Worksheet.
              </Typography>
              <Typography
                p={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  background: "#F8F8F8",
                  border: "1px solid #DFDFDF",
                  fontWeight: "600",
                  color: "#404040",
                }}
              >
                Low Income Deviation
              </Typography>
              <Typography
                p={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  border: "1px solid #DFDFDF",
                  color: "#404040",
                }}
              >
                The Court or Jury has discretion to allow or not allow the
                noncustodial parent to receive a Low Income Deviation that will
                reduce the Presumptive Amount of Child Support.
                <br /> <br />
                If Low Income Deviation does not apply in this case, fields in
                this section will be blank or equal to zero .
              </Typography>
              <Typography
                p={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  background: "#F8F8F8",
                  border: "1px solid #DFDFDF",
                  fontWeight: "600",
                  color: "#404040",
                }}
              >
                Weighing Considerations Based Upon Sufficient Evidence
              </Typography>
              <Typography
                p={2}
                sx={{
                  border: "1px solid #DFDFDF",
                  color: "#404040",
                }}
              >
                When considering a Low Income Deviation, please read the statute
                at O.C.G.A. §19-6-15(1)(2)(8) or review the User Guide for the
                appropriate criteria for this deviation. In weighing the income
                sources and expenses of both parents, and taking into account
                each parent&apos;s basic child support obligation as adjusted by
                health insurance and work related child care costs:
                <br /> <br />
                <ul style={{ marginLeft: "25px" }}>
                  <li>
                    <Typography>
                      Can the noncustodial parent provide evidence sufficient to
                      demonstrate no earning capacity? Or, does his/her pro rata
                      share of the presumptive amount of child support create an
                      extreme economic hardship for such parent?
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      What will be the relative hardship that a reduction in the
                      amount of child support would have on the custodial
                      parent&apos;s household? The needs of each parent? The
                      needs of the child(ren) for whom child support is being
                      determined? The ability of the noncustodial parent to pay
                      child support?
                    </Typography>
                  </li>
                </ul>
              </Typography>
              <TableContainer>
                <Table
                  className="custom-table"
                  aria-label="customized table"
                  sx={{ minWidth: 900 }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        className={style.searchDeviationsCellStyle}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          NOTE: Low Income Deviation amount displays as a
                          negative number and included as a subtraction with all
                          other deviations. By use of this deviation, the court
                          or jury is not prohibited from granting an increase or
                          decrease to the presumptive amount of child support by
                          use of other deviations.
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={style.searchDeviationsCellStyle}
                        colSpan={2}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Court or Jury Allowable Deviations Only amounts
                          entered by the Court or Jury display here
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className={style.searchDeviationsCellStyle}
                      ></TableCell>
                      <TableCell className={style.searchDeviationsCellStyle}>
                        {WorkSheetData.parent_name_1}
                      </TableCell>
                      <TableCell className={style.searchDeviationsCellStyle}>
                        {WorkSheetData.parent_name_2}
                      </TableCell>
                      <TableCell className={style.searchDeviationsCellStyle}>
                        {WorkSheetData.parent_name_1}
                      </TableCell>
                      <TableCell className={style.searchDeviationsCellStyle}>
                        {WorkSheetData.parent_name_2}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className={style.searchDeviationsCellStyle}>
                        1(a). Proposed Low Income Deviation Amount
                      </TableCell>
                      <TableCell
                        className={style.searchDeviationsCellStyle}
                        sx={{ bgcolor: "#D5F5E3" }}
                      >
                        {line1AValue1.parent1 ? "$" + line1AValue1.parent1 : ""}
                      </TableCell>
                      <TableCell
                        className={style.searchDeviationsCellStyle}
                        sx={{ bgcolor: "#D5F5E3" }}
                      >
                        {line1AValue1.parent2 ? "$" + line1AValue1.parent2 : ""}
                      </TableCell>
                      <TableCell
                        className={style.searchDeviationsCellStyle}
                        sx={{ bgcolor: "#D5F5E3" }}
                      >
                        {lineABValue1.parent1 ? "$" + lineABValue1.parent1 : ""}
                      </TableCell>
                      <TableCell
                        className={style.searchDeviationsCellStyle}
                        sx={{ bgcolor: "#D5F5E3" }}
                      >
                        {lineABValue1.parent2 ? "$" + lineABValue1.parent2 : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={style.searchDeviationsCellStyle}>
                        1(b). Low Income Deviation Amount Used
                      </TableCell>
                      <TableCell className={style.searchDeviationsCellStyle}>
                        {line1BValue1 ? "$" + line1BValue1 : ""}
                      </TableCell>
                      <TableCell className={style.searchDeviationsCellStyle}>
                        {line1BValue2 ? "$" + line1BValue2 : ""}
                      </TableCell>
                      <TableCell
                        className={style.searchDeviationsCellStyle}
                      ></TableCell>
                      <TableCell
                        className={style.searchDeviationsCellStyle}
                      ></TableCell>
                    </TableRow>
                    {line1AValueExplanation && (
                      <TableRow>
                        <TableCell className={style.searchDeviationsCellStyle}>
                          {WorkSheetData.parent_name_1} Explanation for
                          Requesting a Low Income Deviation
                        </TableCell>
                        <TableCell
                          className={style.searchDeviationsCellStyle}
                          colSpan={4}
                        >
                          <TextareaField
                            name="value1"
                            placeholder={""}
                            value={line1AValueExplanation}
                            onChange={() => {}}
                            minRows={2}
                            disabled
                          />
                        </TableCell>
                      </TableRow>
                    )}
                    {lineABValueExplanation && (
                      <TableRow>
                        <TableCell className={style.searchDeviationsCellStyle}>
                          {WorkSheetData.parent_name_2} Explanation for
                          Requesting a Low Income Deviation
                        </TableCell>
                        <TableCell
                          className={style.searchDeviationsCellStyle}
                          colSpan={4}
                        >
                          <TextareaField
                            name="value2"
                            placeholder={""}
                            value={lineABValueExplanation}
                            onChange={() => {}}
                            minRows={2}
                            disabled
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box></Box>
          </Box>

          {WorkSheetData.deviations_schedule_e.specific_nonspecific_deviation &&
          WorkSheetData.deviations_schedule_e.specific_nonspecific_deviation
            ?.length > 0 ? (
            <Box pt={2}>
              <Typography
                p={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  background: "#F8F8F8",
                  border: "1px solid #DFDFDF",
                  fontWeight: "600",
                  color: "#404040",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                Specific and Nonspecific Deviations - High Income and Other
                Amounts
              </Typography>
              <TableContainer
                sx={{
                  border: "1px solid #DFDFDF ",
                }}
              >
                <Table
                  className="custom-table"
                  aria-label="customized table"
                  sx={{ minWidth: 900 }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" className={style.cellStylehealth}>
                        2(a).
                      </TableCell>
                      <TableCell align="left" className={style.cellStylehealth}>
                        High Income - Combined Adjusted Income of the parents
                        that is greater than $40,000/month will display for the
                        court/jury to consider.
                      </TableCell>
                      <TableCell
                        align="right"
                        className={style.cellStylehealth}
                        sx={{ minWidth: "90px" }}
                      >
                        <Box sx={{ float: "right" }}>$</Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Typography
                p={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  background: "#F8F8F8",
                  border: "1px solid #DFDFDF",
                  fontWeight: "600",
                  color: "#404040",
                }}
              >
                The amount of any requested deviation must display in the
                noncustodial parent’s column. Only amounts listed in the
                noncustodial parent’s column will affect the final child support
                calculation. Requested deviation amounts will display as a
                “positive (+)” number for an upward deviation or as a “negative
                (-)” number for a downward deviation. The numbers represent the
                amounts you want the court to consider as requested deviations
                from the Presumptive Amount of Child Support.
              </Typography>

              <TableContainer sx={{ border: "1px solid #DFDFDF" }}>
                <Table
                  className="custom-table"
                  aria-label="customized table"
                  sx={{ minWidth: 900 }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={style.headerStyle}
                        sx={{ minWidth: "170px" }}
                      >
                        <Box>Specific Deviation Type</Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={style.headerStyle}
                        sx={{ minWidth: "170px" }}
                      >
                        <Box> Requested By</Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={style.headerStyle}
                        sx={{ minWidth: "170px" }}
                      >
                        <Box>Proposed Deviation Amount</Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={style.headerStyle}
                        sx={{ minWidth: "170px" }}
                      >
                        <Box>Judicial Discretion Applied</Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {WorkSheetData.deviations_schedule_e
                      .specific_nonspecific_deviation &&
                      WorkSheetData.deviations_schedule_e.specific_nonspecific_deviation.map(
                        (
                          row: {
                            deviation_type: string;
                            deviation_is_for: string;
                            proposed_deviation_amount: string;
                            judicial_discretion_applied_deviation_amount: string;
                          },
                          index: number
                        ) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor: "#CEFBC9",
                              justifyContent: index === 0 ? "left" : "inherit",
                            }}
                          >
                            <TableCell
                              align="left"
                              className={style.cellStylehealth}
                            >
                              {row.deviation_type}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={style.cellStylehealth}
                            >
                              {row.deviation_is_for}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={style.cellStylehealth}
                            >
                              <Box sx={{ float: "right" }}>
                                {row.proposed_deviation_amount}
                              </Box>
                            </TableCell>
                            <TableCell
                              align="right"
                              className={style.cellStylehealth}
                            >
                              <Box sx={{ float: "right" }}>
                                {
                                  row.judicial_discretion_applied_deviation_amount
                                }
                              </Box>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={style.cellStylehealth}
                        sx={{ minWidth: "250px" }}
                      >
                        Proposed Deviation Total {WorkSheetData.parent_name_1}:
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ minWidth: "55px", textAlign: "right!important" }}
                        className={style.cellStylehealth}
                      >
                        {ProposedParent1Total ? "$" + ProposedParent1Total : ""}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={style.cellStylehealth}
                        sx={{ minWidth: "250px" }}
                      >
                        Proposed Deviation Total {WorkSheetData.parent_name_2}:
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ minWidth: "55px", textAlign: "right!important" }}
                        className={style.cellStylehealth}
                      >
                        {ProposedParent2Total ? "$" + ProposedParent2Total : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={style.cellStylehealth}
                        sx={{ minWidth: "250px" }}
                      >
                        Judicial Discretion Deviation For{" "}
                        {WorkSheetData.parent_name_1}:
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ minWidth: "55px", textAlign: "right!important" }}
                        className={style.cellStylehealth}
                      >
                        {JudicialParent1Total ? "$" + JudicialParent1Total : ""}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={style.cellStylehealth}
                        sx={{ minWidth: "250px" }}
                      >
                        Judicial Discretion Deviation For{" "}
                        {WorkSheetData.parent_name_2}:
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ minWidth: "55px", textAlign: "right!important" }}
                        className={style.cellStylehealth}
                      >
                        {JudicialParent2Total ? "$" + JudicialParent2Total : ""}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : null}

          {/* Table 1 */}
          <Box pt={3}>
            <Typography
              p={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                background: "#F8F8F8",
                border: "1px solid #DFDFDF",
                fontWeight: "600",
                color: "#404040",
                borderRadius: "5px 5px 0px 0px",
              }}
            >
              Calculations of Extraordinary Educational, Medical and Special
              Expenses
            </Typography>
            <TableContainer
              sx={{ borderRadius: "0px 0px 5px 5px" }}
              component={Paper}
            >
              <Table
                className="custom-table"
                aria-label="customized table"
                sx={{ minWidth: 900 }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      sx={{ minWidth: "10px" }}
                    ></TableCell>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                    ></TableCell>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      sx={{ minWidth: "80px" }}
                    >
                      <Box>{WorkSheetData.parent_name_1}</Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      sx={{ minWidth: "80px" }}
                    >
                      <Box>{WorkSheetData.parent_name_2}</Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      sx={{ minWidth: "80px" }}
                    >
                      <Box>Nonparent Custodian</Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      sx={{ minWidth: "80px" }}
                    >
                      <Box>Combined</Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calculationExtraEEME.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        align="left"
                        style={{ minWidth: "10px!important", width: "10px" }}
                      >
                        {row.serialNo}
                      </TableCell>

                      <TableCell
                        component="th"
                        scope="row"
                        className={style.firstColumnStyle}
                      >
                        <Box>{row.label}</Box>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${style.searchDeviationsCellStyle} ${
                          !row.parents1 && style.grayBackground
                        }`}
                      >
                        {row.parents1 || ""}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${style.searchDeviationsCellStyle} ${
                          !row.parents2 && style.grayBackground
                        }`}
                      >
                        {row.parents2 || ""}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${style.searchDeviationsCellStyle} ${
                          !row.nonparentCustodian && style.grayBackground
                        }`}
                      >
                        {row.nonparentCustodian || ""}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${style.searchDeviationsCellStyle} ${
                          !row.combined && style.grayBackground
                        }`}
                      >
                        {row.combined || ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* Table 2 Parenting Time Deviation */}
          {WorkSheetData.deviations_schedule_e?.parenting_time_deviation1 ||
          WorkSheetData.deviations_schedule_e?.parenting_time_deviation2 ? (
            <Box mt={2}>
              <Typography
                p={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  background: "#F8F8F8",
                  border: "1px solid #DFDFDF",
                  fontWeight: "600",
                  color: "#404040",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                Parenting Time Deviation
              </Typography>
              <TableContainer
                sx={{
                  borderRadius: "0px 0px 5px 5px",
                  border: "1px solid #DFDFDF ",
                }}
              >
                <Table
                  className="custom-table"
                  aria-label="customized table"
                  sx={{ minWidth: 900 }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        className={style.headerStyle}
                        style={{ minWidth: "15px", width: "15px!important" }}
                      >
                        <Box></Box>
                      </TableCell>
                      <TableCell
                        className={style.headerStyle}
                        sx={{ minWidth: "80px" }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                        ></Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={style.headerStyle}
                        sx={{ minWidth: "80px" }}
                      >
                        <Box>{WorkSheetData.parent_name_1}</Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={style.headerStyle}
                        sx={{ minWidth: "80px" }}
                      >
                        <Box>{WorkSheetData.parent_name_2}</Box>
                      </TableCell>

                      <TableCell
                        align="center"
                        className={style.headerStyle}
                        sx={{ minWidth: "180px" }}
                      >
                        <Box></Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          minWidth: "15px!important",
                          width: "15px",
                          paddingRight: "30px",
                        }}
                      >
                        13
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={style.firstColumnStyle}
                      >
                        <Box>
                          A Parenting Time Deviation will display for the
                          Noncustodial Parent, if requested, as based on court
                          ordered visitation
                        </Box>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={style.searchDeviationsCellStyle}
                      >
                        {WorkSheetData.deviations_schedule_e
                          ?.parenting_time_deviation1
                          ? "$" +
                            WorkSheetData.deviations_schedule_e
                              ?.parenting_time_deviation1
                          : ""}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={style.searchDeviationsCellStyle}
                      >
                        {WorkSheetData.deviations_schedule_e
                          ?.parenting_time_deviation2
                          ? "$" +
                            WorkSheetData.deviations_schedule_e
                              ?.parenting_time_deviation2
                          : ""}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={style.searchDeviationsCellStyle}
                      ></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : null}

          {/* Table 3 Total Allowable Deviation */}
          <Box mt={2}>
            <Typography
              p={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                background: "#F8F8F8",
                border: "1px solid #DFDFDF",
                fontWeight: "600",
                color: "#404040",
                borderRadius: "5px 5px 0px 0px",
              }}
            >
              Total Allowable Deviation
            </Typography>
            <TableContainer
              sx={{
                borderRadius: "0px 0px 5px 5px",
                border: "1px solid #DFDFDF ",
              }}
            >
              <Table
                className="custom-table"
                aria-label="customized table"
                sx={{ minWidth: 900 }}
              >
                <caption style={{ padding: 0 }}>
                  <Box sx={{ border: "1px solid #DFDFDF" }}>
                    <Typography marginY={1} px={2} pb={1}>
                      B. Would the presumptive amount be unjust or
                      inappropriate?
                    </Typography>
                    <Typography
                      marginY={1}
                      p={1}
                      paddingBottom="2px"
                      sx={{ bgcolor: "#CEFBC9" }}
                    >
                      <TextareaField
                        name="messsage"
                        placeholder={""}
                        value={
                          WorkSheetData.deviations_schedule_e
                            .unjust_or_inappropriate
                        }
                        onChange={() => {}}
                        minRows={1}
                      />
                    </Typography>
                    <Typography marginY={1} px={2} pb={1}>
                      C. Would deviation serve the best interests of the
                      children for whom support is being determined?{" "}
                    </Typography>
                    <Typography
                      marginY={1}
                      padding={1}
                      paddingBottom="2px"
                      sx={{ bgcolor: "#CEFBC9" }}
                    >
                      <TextareaField
                        name="messsage"
                        placeholder={""}
                        value={
                          WorkSheetData.deviations_schedule_e
                            .best_interests_of_children
                        }
                        onChange={() => {}}
                        minRows={1}
                      />
                    </Typography>
                    <Typography marginY={1} px={2} pb={1}>
                      D. Would deviation seriously impair the ability of the
                      CUSTODIAL Parent or NONPARENT Custodian to maintain
                      minimally adequate housing, food and clothing for the
                      children being supported by the order and to provide other
                      basic necessities?{" "}
                    </Typography>
                    <Typography
                      marginTop={1}
                      p={1}
                      paddingBottom="2px"
                      sx={{ bgcolor: "#CEFBC9" }}
                    >
                      <TextareaField
                        name="messsage"
                        placeholder={""}
                        value={
                          WorkSheetData.deviations_schedule_e
                            .impair_ability_to_maintain
                        }
                        onChange={() => {}}
                        minRows={1}
                      />
                    </Typography>
                  </Box>
                </caption>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      style={{ minWidth: "15px", width: "15px!important" }}
                    >
                      <Box></Box>
                    </TableCell>
                    <TableCell
                      className={style.headerStyle}
                      sx={{ minWidth: "80px" }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}></Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      sx={{ minWidth: "80px" }}
                    >
                      <Box>{WorkSheetData.parent_name_1}</Box>
                    </TableCell>
                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      sx={{ minWidth: "80px" }}
                    >
                      <Box>{WorkSheetData.parent_name_2}</Box>
                    </TableCell>

                    <TableCell
                      align="center"
                      className={style.headerStyle}
                      sx={{ minWidth: "180px" }}
                    >
                      <Box></Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        minWidth: "15px!important",
                        width: "15px",
                        paddingRight: "30px",
                      }}
                    >
                      14
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.firstColumnStyle}
                    >
                      <Box>
                        Important Requirement About Deviations – No Deviation is
                        permitted unless all three Findings of Fact questions
                        ((B), (C), and (D)) have been answered for EACH
                        requested deviation{" "}
                      </Box>
                    </TableCell>
                    <TableCell
                      align="right"
                      className={style.searchDeviationsCellStyle}
                    >
                      {line14Value1
                        ? "$" + Number(line14Value1).toFixed(2)
                        : ""}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={style.searchDeviationsCellStyle}
                    >
                      {line14Value2
                        ? "$" + Number(line14Value2).toFixed(2)
                        : ""}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={style.searchDeviationsCellStyle}
                    ></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {/* Table-3  Deviation for Extraordinary Educational Expenses For Children*/}
          {WorkSheetData.deviations_schedule_e
            .extra_ordinary_educational_expenses &&
          WorkSheetData.deviations_schedule_e
            .extra_ordinary_educational_expenses?.length > 0 ? (
            <Box mt={2}>
              <div
                style={{
                  background: "#F8F8F8",
                  border: "1px solid #DFDFDF",
                  padding: "10px",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    background: "#F8F8F8",
                    fontWeight: "600",
                    color: "#404040",
                  }}
                >
                  Deviation for Extraordinary Educational Expenses For Children
                </Typography>

                <Typography sx={{ display: "block", textAlign: "center" }}>
                  Final calculations for this deviation display on Line 12(a)
                </Typography>
              </div>
              <TableContainer
                sx={{ borderRadius: "0px 0px 5px 5px" }}
                component={Paper}
              >
                <Table
                  className="custom-table"
                  aria-label="customized table"
                  sx={{ minWidth: 900 }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={style.headerStyle}
                        sx={{ minWidth: "80px" }}
                      >
                        Child Name
                      </TableCell>
                      <TableCell align="center" className={style.headerStyle}>
                        Tuition, room & board, fees and books
                      </TableCell>
                      <TableCell align="center" className={style.headerStyle}>
                        Other extraordinary educational expenses
                      </TableCell>
                      <TableCell align="center" className={style.headerStyle}>
                        Paid By
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {WorkSheetData.deviations_schedule_e
                      .extra_ordinary_educational_expenses &&
                      WorkSheetData.deviations_schedule_e.extra_ordinary_educational_expenses.map(
                        (
                          row: {
                            child_name: string;
                            tuition_room_board_books_yearly: string;
                            other_extraordinary_expenses_yearly: string;
                            paid_by: string;
                          },
                          index: number
                        ) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor: "#CEFBC9",
                              justifyContent: index === 0 ? "left" : "inherit",
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={style.searchDeviationsCellStyle}
                            >
                              {row.child_name}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={style.searchDeviationsCellStyle}
                            >
                              <Box display={"flex"} justifyContent={"right"}>
                                {row.tuition_room_board_books_yearly}
                              </Box>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={style.searchDeviationsCellStyle}
                            >
                              <Box display={"flex"} justifyContent={"right"}>
                                {row.other_extraordinary_expenses_yearly}
                              </Box>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={style.searchDeviationsCellStyle}
                            >
                              {row.paid_by}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                  <TableBody>
                    {deviationExtraEducationExpenseSummery.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor: "inherit",
                          justifyContent: index === 0 ? "left" : "inherit",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={style.searchDeviationsCellStyle}
                        >
                          {row.label}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={style.searchDeviationsCellStyle}
                        >
                          <Box display={"flex"} justifyContent={"right"}>
                            {row.amountPaid}
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={style.searchDeviationsCellStyle}
                        >
                          {row.extraordinaryEducationExpenses}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={style.searchDeviationsCellStyle}
                        >
                          {row.paidByTotal}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : null}

          {/* Table -4 Medical Expenses */}
          {WorkSheetData.deviations_schedule_e
            .extra_ordinary_medical_expenses &&
          WorkSheetData.deviations_schedule_e.extra_ordinary_medical_expenses
            ?.length > 0 ? (
            <Box mt={2}>
              <div
                style={{
                  background: "#F8F8F8",
                  border: "1px solid #DFDFDF",
                  padding: "10px",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    background: "#F8F8F8",
                    fontWeight: "600",
                    color: "#404040",
                  }}
                >
                  Deviation for Extraordinary Medical Expenses For Children
                </Typography>
                <Typography sx={{ display: "block", textAlign: "center" }}>
                  Final calculations for this deviation display on Line 12(b)
                </Typography>
              </div>
              <TableContainer
                sx={{ borderRadius: "0px 0px 5px 5px" }}
                component={Paper}
              >
                <Table
                  className="custom-table"
                  aria-label="customized table"
                  sx={{ minWidth: 900 }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={style.headerStyle}
                        sx={{ minWidth: "80px" }}
                        colSpan={1}
                      >
                        Child Name
                      </TableCell>
                      <TableCell className={style.headerStyle} align="center">
                        Paid By
                      </TableCell>
                      <TableCell
                        className={style.headerStyle}
                        align="center"
                        colSpan={2}
                      >
                        Extraordinary Medical Expenses
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {WorkSheetData.deviations_schedule_e
                      .extra_ordinary_medical_expenses &&
                      WorkSheetData.deviations_schedule_e.extra_ordinary_medical_expenses.map(
                        (
                          row: {
                            child_name: string;
                            paid_by: string;
                            extraordinary_medical_expenses_yearly: string;
                          },
                          index: number
                        ) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor: "#CEFBC9",
                              justifyContent: index === 0 ? "left" : "inherit",
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={style.searchDeviationsCellStyle}
                            >
                              {row.child_name}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={style.searchDeviationsCellStyle}
                            >
                              {row.paid_by}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={style.searchDeviationsCellStyle}
                              colSpan={2}
                            >
                              {row.extraordinary_medical_expenses_yearly}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                  <TableBody>
                    {deviationExtraMedicalExpenseSumery.map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          backgroundColor: "inherit",
                          justifyContent: i === 0 ? "left" : "inherit",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={style.searchDeviationsCellStyle}
                        >
                          {row.label}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={style.searchDeviationsCellStyle}
                        >
                          <Box display={"flex"} justifyContent={"right"}>
                            {row.value}
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={style.searchDeviationsCellStyle}
                        >
                          {row.paidBy}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={style.searchDeviationsCellStyle}
                          style={{ minWidth: "110px" }}
                        >
                          {row.extraordinaryMedicalExpenses}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : null}

          {/* Table-5 Deviation for Special Expenses For Child Rearing */}
          {WorkSheetData.deviations_schedule_e.special_expenses_child_rearing &&
          WorkSheetData.deviations_schedule_e.special_expenses_child_rearing
            ?.length > 0 ? (
            <Box mt={2}>
              <div
                style={{
                  background: "#F8F8F8",
                  border: "1px solid #DFDFDF",
                  padding: "10px",
                  borderRadius: "5px 5px 0px 0px",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    background: "#F8F8F8",
                    fontWeight: "600",
                    color: "#404040",
                  }}
                >
                  Deviation for Special Expenses For Child Rearing
                </Typography>

                <Typography sx={{ display: "block", textAlign: "center" }}>
                  Final calculations for this deviation display on Line 12(c)
                </Typography>
              </div>
              <TableContainer
                sx={{ borderRadius: "0px 0px 5px 5px" }}
                component={Paper}
              >
                <Table
                  className="custom-table"
                  aria-label="customized table"
                  sx={{ minWidth: 900 }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={style.headerStyle}
                        sx={{ minWidth: "80px" }}
                      >
                        Child Name
                      </TableCell>
                      <TableCell align="center" className={style.headerStyle}>
                        Amount Paid
                      </TableCell>
                      <TableCell align="center" className={style.headerStyle}>
                        Paid By
                      </TableCell>
                      <TableCell align="center" className={style.headerStyle}>
                        Explanation Of Expense
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {WorkSheetData.deviations_schedule_e
                      .special_expenses_child_rearing &&
                      WorkSheetData.deviations_schedule_e.special_expenses_child_rearing.map(
                        (
                          row: {
                            child_name: string;
                            amount_paid_yearly: string;
                            paid_by: string;
                            explanation_of_expense: string;
                          },
                          k: number
                        ) => (
                          <TableRow
                            key={k}
                            sx={{
                              backgroundColor: "#CEFBC9",
                              justifyContent: k === 0 ? "left" : "inherit",
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              className={style.searchDeviationsCellStyle}
                            >
                              {row.child_name}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={style.searchDeviationsCellStyle}
                            >
                              <Box display={"flex"} justifyContent={"right"}>
                                {row.amount_paid_yearly}
                              </Box>
                            </TableCell>
                            <TableCell
                              align="left"
                              className={style.searchDeviationsCellStyle}
                            >
                              {row.paid_by}
                            </TableCell>
                            <TableCell
                              align="left"
                              className={style.searchDeviationsCellStyle}
                            >
                              {row.explanation_of_expense}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                  <TableBody>
                    {deviationSpecialExpenseSummery.map((row, k) => (
                      <TableRow
                        key={k}
                        sx={{
                          backgroundColor: "inherit",
                          justifyContent: k === 0 ? "left" : "inherit",
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={style.searchDeviationsCellStyle}
                        >
                          {row.label}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={style.searchDeviationsCellStyle}
                        >
                          <Box display={"flex"} justifyContent={"right"}>
                            {row.amountPaid}
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={style.searchDeviationsCellStyle}
                        >
                          {row.paidBy}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={style.searchDeviationsCellStyle}
                        >
                          {row.explanation}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : null}

          {/* Table 6- 7% Test to Calculate Allowable Special Expenses For Children */}
          <Box mt={2}>
            <Typography
              p={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                background: "#F8F8F8",
                border: "1px solid #DFDFDF",
                fontWeight: "600",
                color: "#404040",
                borderRadius: "5px 5px 0px 0px",
              }}
            >
              7% Test to Calculate Allowable Special Expenses For Children
            </Typography>
            <TableContainer
              sx={{ borderRadius: "0px 0px 5px 5px" }}
              component={Paper}
            >
              <Table
                className="custom-table"
                aria-label="customized table"
                sx={{ minWidth: 900 }}
              >
                <TableHead></TableHead>
                <TableBody>
                  {allowableSpecialExpenses.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={style.firstColumnStyle}
                        style={{ width: "80%", padding: "12px" }}
                      >
                        {row.label}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={style.searchDeviationsCellStyle}
                      >
                        <Box display={"flex"} justifyContent={"right"}>
                          {row.value}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Box textAlign={"center"} my={"4rem"}>
          <Box
            component="img"
            src={FootnoteImage}
            alt="Footnotes"
            sx={{
              width: { xs: "70%", md: "25%" },
              height: "auto",
            }}
          />
          <Typography className={style.contentHeading}>
            No Information To Display
          </Typography>
        </Box>
      )}

      {/* Buttons */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SearchWorksheetButtons />
      </Box>
    </>
  );
};

export default DeviationsTab;
