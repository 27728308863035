import {
  SET_LOGIN,
  SetLoginAction,
  SET_LOGOUT,
  SetLogoutAction
} from "../actions-Types/authActionTypes";
import { Dispatch } from "redux";

interface LoginData {
  email?: string;
  password?: string;
  username?: string;
}

export const loginDetails =
  (data: LoginData) => async (dispatch: Dispatch<SetLoginAction>) => {
    try {
      dispatch({
        type: SET_LOGIN,
        payload: data
      });
    } catch (err) {
      console.error("Failed to dispatch login action", err);
    }
  };
export const setUserLogoutData =
  () => async (dispatch: Dispatch<SetLogoutAction>) => {
    try {
      dispatch({
        type: SET_LOGOUT,
        payload: undefined
      });
    } catch (err) {
      console.error("Failed to dispatch Logout action", err);
    }
  };
