import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
  SelectChangeEvent,
} from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../assets/images/info.svg";
import CustomTooltip from "../../../components/tooltip";
import interrogation from "../../../assets/images/interrogation.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";
import style from "../workSheet.module.css";
import { styled } from "@mui/material/styles";
import SettingIcons from "../../../assets/images/SettingIcon.svg";
import WorksheetButtons from "../../../components/worksheetButtons";
import CustomModal from "../../../components/customModal";
import CurrencyValue from "../../../components/currencyInputWithoutSelect";
import { useAppDispatch, useAppSelector } from "../../../globalState/hooks";
import {
  deleteFromArray,
  updateArrayInfo,
  updateBasicInfo,
} from "../../../globalState/actions/create-worksheet-action";
import ReportTechnicalForm, {
  ReportTechnicalFormHandle,
} from "../../../components/reportTechnicalIssue";
import CustomInput from "../../../components/inputField";
import CustomDropdown from "../../../components/drop-down";
import { GenerateCaptcha } from "../../../utils/generateCaptcha";
import technicalReportService from "../../../services/technicalReport-service";
import { showToast } from "../../../components/toastNotification";
import { AddHealthWorkRelatedChildCare } from "../../../types/create-worksheet/HealthInsuranceTab";
import { DropDown, FormErrors } from "../../../types/global";
import { AddHealthInsuranceValidation } from "./HealthInsuranceTabValidation";
import ValidationErrorModal from "../../../components/validationErrorModal";
import { HealthWorkRelatedChildCareData } from "../../../types/create-worksheet";
import DeleteModal from "../../../components/globalDeleteModal";

interface TechnicalReportInput {
  worksheet_id: string;
  email: string;
  browser: string;
  name: string;
  phone: string;
  technical_issue: string;
}

const HealthInsuranceTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const workSheetStates = useAppSelector((state) => state.createWorksheet);
  const formRef = useRef<ReportTechnicalFormHandle>(null);

  console.log(workSheetStates, "***WorkSheetStates***");

  const [inputCaptcha, setInputCaptcha] = useState("");
  const [firstModalOpen, setFirstModalOpen] = useState(false);
  const [reportTechnicalModal, setReportTechnicalModal] = useState(false);
  const [isEdit, setIsEdit] = useState<string>("");
  const [addModalData, setAddModalData] =
    useState<AddHealthWorkRelatedChildCare>({
      child_name: "",
      paid_by: "",
      child_care_school_yearly: "",
      child_care_summer_yearly: "",
      child_care_other_breaks_yearly: "",
      child_care_preschool_disability_yearly: "",
    });
  const [healthFormErrors, setHealthFormErrors] = useState<FormErrors>({});
  const [inputValidationErrorModal, setInputValidationErrorModal] =
    useState<boolean>(false);
  const [inputValidationMessage, setInputValidationMessage] = useState("");
  const [secondValidationErrorModalOpen, setSecondValidationErrorModalOpen] =
    useState<boolean>(false);
  const [childNameData, setChildNameData] = useState<DropDown[]>([]);
  const [paidByData, setPaidbyData] = useState<DropDown[]>([]);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const handleReportModalOpen = () => {
    setInputCaptcha(GenerateCaptcha);
    setReportTechnicalModal(true);
  };
  const handleReportModalClose = () => setReportTechnicalModal(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSaveData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  //// API calling function start
  /// API called for Technical Report
  const handleSubmitTechnicalReport = async (
    reportInputValues: TechnicalReportInput
  ) => {
    const inputData = {
      worksheet_id: Number(reportInputValues.worksheet_id),
      name: reportInputValues.name !== "" ? reportInputValues.name : null,
      email: reportInputValues.email,
      phone:
        reportInputValues.phone !== "" ? Number(reportInputValues.phone) : null,
      browser:
        reportInputValues.browser !== "" ? reportInputValues.browser : null,
      technical_issue: reportInputValues.technical_issue,
    };
    await technicalReportService
      .reportTechnicalIssueAPI(inputData)
      .then((response) => {
        if (response && response.data) {
          showToast(response.data.message, "success");
          setReportTechnicalModal(false);
        } else {
          showToast(response?.data?.message, "error");
        }
      })
      .catch((error) => {
        console.error(error, "API Error");
      });
  };
  //// API calling function end

  ///create option drop-down data
  function createOptionsValue(firstName: string, lastName: string): DropDown[] {
    return [
      { value: firstName, label: firstName },
      { value: lastName, label: lastName },
      {
        value: "Nonparent Custodian",
        label: "Nonparent Custodian",
      },
    ];
  }
  // Modal's visibility Modals
  const handleFirstModalOpen = () => {
    if (workSheetStates.add_child_to_worksheet.length > 0) {
      setFirstModalOpen(true);
      const optionsData = createOptionsValue(
        workSheetStates.parent_name_1,
        workSheetStates.parent_name_2
      );
      setPaidbyData(optionsData);
      const getChildName = workSheetStates.add_child_to_worksheet.map((child, index) => ({
        value: child.child_name,
        label: `${(index + 1).toString().padStart(2, "0")}. ${child.child_name
          }`,
      }));
      setChildNameData(getChildName);
    } else {
      setInputValidationErrorModal(true);
      setInputValidationMessage(
        "Please Add A Child on the Basic Tab Before Adding Work Related Child Care"
      );
    }
  };
  /// show Input validation messages
  const handleInputValidationErrorModal = () => {
    setInputValidationErrorModal(!inputValidationErrorModal);
    if (inputValidationErrorModal) {
      dispatch(updateBasicInfo("activeTab", "1"));
    }
  };
  const handleInputValidationErrorModalOnCancel = () => {
    setInputValidationErrorModal(!inputValidationErrorModal);

  };
  ///handle input onchange code
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(updateBasicInfo(name, value));
  };
  /// handle add Modal Input and drop-down onchange code
  const handleAddModalDataChange = (
    event: ChangeEvent<HTMLInputElement> | SelectChangeEvent
  ) => {
    const { name, value } = event.target;

    setAddModalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setHealthFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  ///get parents wise total data sum
  const getTotalParentsSums = (array: HealthWorkRelatedChildCareData[]) => {
    return array.reduce((acc, item) => {
      const total =
        (parseFloat(item.child_care_school_yearly) || 0) +
        (parseFloat(item.child_care_summer_yearly) || 0) +
        (parseFloat(item.child_care_other_breaks_yearly) || 0) +
        (parseFloat(item.child_care_preschool_disability_yearly) || 0);

      if (acc[item.paid_by]) {
        acc[item.paid_by] += total;
      } else {
        acc[item.paid_by] = total;
      }

      return acc;
    }, {} as Record<string, number>);
  };
  const handleSecondValidationErrorModal = () => {
    setSecondValidationErrorModalOpen(false);
  };
  // add Health Insurance function code
  const addHealthInsuranceData = () => {
    const formErrors = AddHealthInsuranceValidation(addModalData);
    setHealthFormErrors((prevErrors) => {
      return { ...prevErrors, ...formErrors };
    });

    const isFormValid = Object.keys(formErrors).length === 0;

    if (isFormValid) {
      const newData = {
        arrayKey: "work_related_child_care",
        itemId: isEdit ? isEdit : "",
        newItemValues: {
          child_name: addModalData.child_name,
          paid_by: addModalData.paid_by,
          child_care_school_yearly: addModalData.child_care_school_yearly,
          child_care_summer_yearly: addModalData.child_care_summer_yearly,
          child_care_other_breaks_yearly:
            addModalData.child_care_other_breaks_yearly,
          child_care_preschool_disability_yearly:
            addModalData.child_care_preschool_disability_yearly,
        },
      };

      const updatedArray = isEdit
        ? workSheetStates.work_related_child_care.map((item) =>
          item.id === isEdit ? { ...item, ...newData.newItemValues } : item
        )
        : [...workSheetStates.work_related_child_care, newData.newItemValues];

      // Calculate the totals using the updated array
      const parentsTotal = getTotalParentsSums(updatedArray);
      const parent1Total = parentsTotal[workSheetStates.parent_name_1];
      const parent2Total = parentsTotal[workSheetStates.parent_name_2];

      dispatch(updateArrayInfo(newData));
      dispatch(
        updateBasicInfo(
          "health_insurance_premiums_parent1",
          parent1Total !== undefined ? parent1Total.toString() : "0"
        )
      );
      dispatch(
        updateBasicInfo(
          "health_insurance_premiums_parent2",
          parent2Total !== undefined ? parent2Total.toString() : "0"
        )
      );
      dispatch(
        updateBasicInfo(
          "health_insurance_premiums_parent3",
          parentsTotal["Nonparent Custodian"] !== undefined
            ? parentsTotal["Nonparent Custodian"].toString()
            : "0"
        )
      );

      setModalDataEmpty();
    } else {
      // Show the validation error modal if validation fails
      if (formErrors.child_care_school_yearly) {
        setInputValidationMessage(formErrors.child_care_school_yearly);
        setSecondValidationErrorModalOpen(true);
      }
    }
  };

  // update Health Insurance function
  const editHealthInsuranceDataHandler = (
    item: HealthWorkRelatedChildCareData
  ) => {
    setIsEdit(item.id!);
    setAddModalData((prev) => {
      return {
        ...prev,
        child_name: item.child_name,
        paid_by: item.paid_by,
        child_care_school_yearly: item.child_care_school_yearly,
        child_care_summer_yearly: item.child_care_summer_yearly,
        child_care_other_breaks_yearly: item.child_care_other_breaks_yearly,
        child_care_preschool_disability_yearly:
          item.child_care_preschool_disability_yearly,
      };
    });
    const updatedArray = workSheetStates.work_related_child_care.map((el) =>
      el.id === item.id ? { ...el, ...item } : el
    );

    // Calculate the totals using the updated array
    const parentsTotal = getTotalParentsSums(updatedArray);
    const parent1Total = parentsTotal[workSheetStates.parent_name_1];
    const parent2Total = parentsTotal[workSheetStates.parent_name_2];

    dispatch(
      updateBasicInfo(
        "health_insurance_premiums_parent1",
        parent1Total !== undefined ? parent1Total.toString() : "0"
      )
    );
    dispatch(
      updateBasicInfo(
        "health_insurance_premiums_parent2",
        parent2Total !== undefined ? parent2Total.toString() : "0"
      )
    );
    dispatch(
      updateBasicInfo(
        "health_insurance_premiums_parent3",
        parentsTotal["Nonparent Custodian"] !== undefined
          ? parentsTotal["Nonparent Custodian"].toString()
          : "0"
      )
    );
    handleFirstModalOpen();
  };
  /// handle modal close
  const handleDeleteMdlClose = () => {
    setDeleteModal(false);
  };
  /// delete health Insurance modal open
  const deletChildDataModalOpen = (id: string) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  // delete health Insurance function
  const deleteHealthInsuranceDataHandler = () => {
    dispatch(
      deleteFromArray({
        arrayKey: "work_related_child_care",
        itemId: deleteId,
      })
    );

    const updatedArray = workSheetStates.work_related_child_care.filter(
      (item) => item.id !== deleteId
    );

    const parentsTotal = getTotalParentsSums(updatedArray);
    const parent1Total = parentsTotal[workSheetStates.parent_name_1];
    const parent2Total = parentsTotal[workSheetStates.parent_name_2];

    dispatch(
      updateBasicInfo(
        "health_insurance_premiums_parent1",
        parent1Total !== undefined ? parent1Total.toString() : "0"
      )
    );
    dispatch(
      updateBasicInfo(
        "health_insurance_premiums_parent2",
        parent2Total !== undefined ? parent2Total.toString() : "0"
      )
    );
    dispatch(
      updateBasicInfo(
        "health_insurance_premiums_parent3",
        parentsTotal["Nonparent Custodian"] !== undefined
          ? parentsTotal["Nonparent Custodian"].toString()
          : "0"
      )
    );
    setDeleteModal(false);
  };
  /// set modal empty data
  const setModalDataEmpty = () => {
    setFirstModalOpen(false);
    setAddModalData((prev) => {
      return {
        ...prev,
        child_name: "",
        paid_by: "",
        child_care_school_yearly: "",
        child_care_summer_yearly: "",
        child_care_other_breaks_yearly: "",
        child_care_preschool_disability_yearly: "",
      };
    });
    setHealthFormErrors({});
    setIsEdit("");
  };

  const RedAsteriskTypography = styled(Typography)({
    "& span": {
      color: "#FF0000",
    },
  });
  const backgroundColor = "#D5F6CE";
  const currencyBoxStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#7FDB75",
    borderRadius: "5px",
    padding: "0 8px",
    height: "40px",
    width: { sm: "50%", xs: "95%" },
    justifyContent: "center",
  };

  return (
    <>
      <Box>
        <Card
          elevation={0}
          sx={{
            bgcolor: "#E6F5FF",
            border: "1px solid #0464AB80",
            borderRadius: "20px 20px",
            opacity: 1,
          }}
        >
          <Typography
            sx={{ px: 1, pt: 1, display: "flex", alignItems: "center" }}
            color="#0464AB"
            fontSize={14}
            fontWeight="bold"
          >
            <InfoIcon style={{ height: "17px" }} />
            <Box sx={{ p: "4px" }}>
              D. This section concerns Health Insurance and Work Related Child
              Care
            </Box>
          </Typography>
          <RedAsteriskTypography
            sx={{ px: 1, pb: 1, color: "#404040" }}
            fontSize={14}
          >
            <Box sx={{ pl: "30px" }}>
              <Typography>
                {" "}
                Information entered in this section, including the calculations,
                will display on Schedule D.
              </Typography>
              <Typography mt={2}>
                <span style={{ fontWeight: 600, color: "#404040" }}>
                  Health Insurance -
                </span>{" "}
                Enter the monthly amount paid or that will be paid by each
                Parent or by the Nonparent Custodian, for the children&apos;s
                portion (children of the current case) of the health insurance
                premium. See O.C.G.A. § 19-6-15(h)(2)
              </Typography>
              <Typography mt={2}>
                <span style={{ fontWeight: 600, color: "#404040" }}>
                  Work Related Child Care -{" "}
                </span>{" "}
                Enter the annual average amount paid by each Parent or by the
                Nonparent Custodian, for work related child care for the
                children for whom support is being determined in this case. See
                O.C.G.A. § 19-6-15(h)(1).{" "}
              </Typography>
            </Box>
          </RedAsteriskTypography>
        </Card>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography className={style.contentHeading}>
          Health Insurance for Children
        </Typography>
      </Box>
      <Box></Box>
      <Box mt={2}>
        <TableContainer>
          <Typography
            mt={2}
            px={2}
            py={1}
            sx={{ minWidth: "900px", border: "1px solid #DFDFDF" }}
          >
            Enter at line 46 the cost of Health Insurance for child(ren)
            included in the current case. Amounts entered must be manually
            recalculated, if any child is either removed or excluded from the
            calculations on the Basic Information Worksheet tab at line 14(b).
          </Typography>
          <Table
            className="custom-table"
            aria-label="customized table"
            sx={{ minWidth: "900px" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className={style.headerStyle}
                  sx={{ minWidth: "80px" }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
                    46. Health Insurance Premiums Paid for the Children
                    <CustomTooltip title="If portion is unknown, prorate for children by dividing total premium by number of persons covered, then multiply by number of covered children in this action.">
                      <img
                        height={13}
                        src={interrogation}
                        alt="Info"
                        style={{ marginLeft: "5px", verticalAlign: "middle" }}
                      />
                    </CustomTooltip>
                  </Box>
                </TableCell>
                <TableCell align="center" className={style.headerStyle}>
                  <Box className={style.table_headingDiv}>
                    {workSheetStates.parent_name_1 || "ParentName"}
                  </Box>
                </TableCell>
                <TableCell align="center" className={style.headerStyle}>
                  <Box className={style.table_headingDiv}>
                    {workSheetStates.parent_name_2 || "ParentName"}
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  className={style.headerStyle}
                  sx={{ mt: 1 }}
                >
                  <Box>Nonparent Custodian</Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={style.healthFirstColumnStyle}
                >
                  <Box>Monthly Amount:</Box>
                </TableCell>
                <TableCell align="center" className={style.cellStyle}>
                  <CurrencyValue
                    type={"number"}
                    value={workSheetStates.monthly_total1}
                    onChange={handleInputChange}
                    name="monthly_total1"
                    backgroundColor="#fff"
                  />
                </TableCell>
                <TableCell align="center" className={style.cellStyle}>
                  <CurrencyValue
                    type={"number"}
                    value={workSheetStates.monthly_total2}
                    onChange={handleInputChange}
                    name="monthly_total2"
                    backgroundColor="#fff"
                  />
                </TableCell>
                <TableCell align="center" className={style.cellStyle}>
                  <CurrencyValue
                    type={"number"}
                    value={workSheetStates.monthly_total3}
                    onChange={handleInputChange}
                    name="monthly_total3"
                    backgroundColor="#fff"
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Typography className={style.contentHeading}>
          Work Related Child Care Expenses for Children
        </Typography>
      </Box>
      <Box
        fontSize={12}
        sx={{
          mt: 2,
          background: "#F8F8F8",
          padding: "13px",
          border: "1px solid #DFDFDF",
          fontSize: "12px",
          borderRadius: "5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography>
          Enter information on Work Related Child Care by selecting the button
          at line 47(a) to add Work Related Child Care or 47(b) to Edit/Remove
          Work Related Child Care. Expenses entered will be removed from the
          calculations for any child who is either removed from the worksheet or
          excluded from the calculations on the Basic Information Worksheet tab,
          line 14(b).{" "}
          <CustomTooltip title="XYZ">
            <img
              height={13}
              src={interrogation}
              alt="Info"
              style={{ verticalAlign: " middle" }}
            />
          </CustomTooltip>
        </Typography>
      </Box>

      <Box mt={2} textAlign={"right"}>
        <Button
          className={style.globalButtonStyle}
          onClick={handleFirstModalOpen}
          variant="outlined"
        >
          47(a). Add Work Related Child Care for the Children in this Case
        </Button>
        <CustomModal
          open={firstModalOpen}
          actionButtonLabel="Save"
          title={isEdit
            ? "47(a). Edit Work Related Child Care To Worksheet"
            : "47(a). Add Work Related Child Care To Worksheet"}
          borderBottom="0px "
          onClose={setModalDataEmpty}
          save={addHealthInsuranceData}
          pb="0px"
        >
          <Typography
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontWeight: "bold", fontSize: 13 }}
          >
            SECTION 1. Preexisting Child Support Order
          </Typography>
          <Typography
            gutterBottom
            id="modal-modal-description"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              borderBottom: "1px solid #DFDFDF",
              p: 1,
            }}
          >
            Enter separately, for each child, the Work Related Child Care
            expenses. Children included on the Basic Information Worksheet tab,
            as children in the current case, will appear in the “Child Name”
            display list. Select the child by name. Use the “Paid by” drop-down
            and select the parent or nonparent custodian as the person who pays
            or will pay the expense. Enter the ANNUAL amount of the expense
            using the fields provided. Click the Save button.
          </Typography>
          <Box sx={{ pt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomDropdown
                  name="child_name"
                  label="Child Name"
                  value={addModalData.child_name}
                  onChange={handleAddModalDataChange}
                  options={childNameData}
                  placeholder="- Choose A Child -"
                  error={healthFormErrors.child_name}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomDropdown
                  name="paid_by"
                  label="Paid By"
                  value={addModalData.paid_by}
                  onChange={handleAddModalDataChange}
                  options={paidByData}
                  placeholder="- Choose Who Paid -"
                  error={healthFormErrors.paid_by}
                  required
                />
              </Grid>
              {/* {healthFormErrors.child_care_school_yearly && (
                <Typography
                  variant="subtitle1"
                  sx={{ mt: 2, ml: 2, fontSize: "1rem", color: "error.main" }}
                >
                  {healthFormErrors.child_care_school_yearly}
                </Typography>
              )} */}
              <Grid item xs={12} md={12}>
                <CustomInput
                  type="number"
                  name="child_care_school_yearly"
                  label="Child Care During School (Yearly)"
                  value={addModalData.child_care_school_yearly}
                  onChange={handleAddModalDataChange}
                  placeholder="0.00"
                  startAdornment="$"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomInput
                  type="number"
                  name="child_care_summer_yearly"
                  label="Child Care During Summer (Yearly)"
                  value={addModalData.child_care_summer_yearly}
                  onChange={handleAddModalDataChange}
                  placeholder="0.00"
                  startAdornment="$"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomInput
                  type="number"
                  name="child_care_other_breaks_yearly"
                  label="Child Care During Other Breaks (Yearly)"
                  value={addModalData.child_care_other_breaks_yearly}
                  onChange={handleAddModalDataChange}
                  placeholder="0.00"
                  startAdornment="$"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomInput
                  type="number"
                  name="child_care_preschool_disability_yearly"
                  label="Child Care - Pre-school age child or child with disability (Yearly)"
                  value={addModalData.child_care_preschool_disability_yearly}
                  onChange={handleAddModalDataChange}
                  placeholder="0.00"
                  startAdornment="$"
                />
              </Grid>
            </Grid>
          </Box>
        </CustomModal>
      </Box>
      {/*--------------- Table 2------------- */}
      <Box sx={{ mt: 2 }}>
        <TableContainer>
          <div className={style.tableWrapper}>
            <Table className={style.customTable} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>Child Name</Box>
                  </TableCell>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>Paid By</Box>
                  </TableCell>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>During School</Box>
                  </TableCell>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>During Summer</Box>
                  </TableCell>
                  <TableCell align="center" className={style.headerStyle}>
                    <Box>During School Breaks</Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={style.headerStyle}
                    sx={{ minWidth: "80px" }}
                  >
                    <Box>Other</Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={style.headerStyle}
                    sx={{ minWidth: "70px", width: "90px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      Action
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workSheetStates.work_related_child_care &&
                  workSheetStates.work_related_child_care.map(
                    (item: HealthWorkRelatedChildCareData, i: number) => (
                      <TableRow key={i} className="WorkFormB">
                        <TableCell component="th" scope="row">
                          {item.child_name}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          {item.paid_by}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          {Number(item.child_care_school_yearly).toFixed(2)}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          {Number(item.child_care_summer_yearly).toFixed(2)}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          {Number(item.child_care_other_breaks_yearly).toFixed(
                            2
                          )}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          {Number(
                            item.child_care_preschool_disability_yearly
                          ).toFixed(2)}
                        </TableCell>
                        <TableCell align="center" className={style.cellStyle}>
                          <Box display="flex" justifyContent="space-between">
                            <IconButton
                              onClick={() =>
                                editHealthInsuranceDataHandler(item)
                              }
                            >
                              <EditIcon height={13} />
                            </IconButton>
                            <IconButton
                              onClick={() => deletChildDataModalOpen(item.id!)}
                            >
                              <DeleteIcon height={13} />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )
                  )}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </Box>

      <Box>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row", md: "row" },
                alignItems: { xs: "flex-start", md: "center" },
                backgroundColor: backgroundColor,
                borderRadius: "4px",
                px: { xs: "16px", md: 0 },
                py: { xs: "8px", md: 0 },
                pl: { md: "16px" },
                height: { xs: "auto", md: "40px" },
                mb: { xs: 2, md: 0 },
              }}
            >
              <Typography variant="body1" sx={{ flex: 1, fontWeight: "bold" }}>
                {workSheetStates.parent_name_1} Total:
              </Typography>
              <Box sx={currencyBoxStyle}>
                <CurrencyValue
                  name=""
                  value={Number(
                    workSheetStates.health_insurance_premiums_parent1
                  )}
                  onChange={() => { }}
                  backgroundColor="#CFFAC9"
                  disabled
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row", md: "row" },
                alignItems: { xs: "flex-start", md: "center" },
                backgroundColor: backgroundColor,
                borderRadius: "4px",
                px: { xs: "16px", md: 0 },
                pl: { md: "16px" },
                py: { xs: "8px", md: 0 },
                height: { xs: "auto", md: "40px" },
                mb: { xs: 2, md: 0 },
              }}
            >
              <Typography variant="body1" sx={{ flex: 1, fontWeight: "bold" }}>
                {workSheetStates.parent_name_2} Total:
              </Typography>
              <Box sx={currencyBoxStyle}>
                <CurrencyValue
                  value={Number(
                    workSheetStates.health_insurance_premiums_parent2
                  )}
                  name=""
                  onChange={() => { }}
                  backgroundColor="#CFFAC9"
                  disabled
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row", md: "row" },
                alignItems: { xs: "flex-start", md: "center" },
                backgroundColor: backgroundColor,
                borderRadius: "4px",
                px: { xs: "16px", md: 0 },
                pl: { md: "16px" },
                py: { xs: "8px", md: 0 },
                height: { xs: "auto", md: "40px" },
                mb: { xs: 2, md: 0 },
              }}
            >
              <Typography variant="body1" sx={{ flex: 1, fontWeight: "bold" }}>
                Nonparent Custodian Total:
              </Typography>
              <Box sx={currencyBoxStyle}>
                <CurrencyValue
                  name=""
                  value={Number(
                    workSheetStates.health_insurance_premiums_parent3
                  )}
                  onChange={() => { }}
                  backgroundColor="#CFFAC9"
                  disabled
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <WorksheetButtons />
      </Box>
      <Grid item xs={12} md={12}>
        <Grid
          sx={{
            mb: 0,
            borderTop: "2px solid #DFDFDF",
            py: 2,
            px: 1,
            textDecoration: "underline",
            cursor: "pointer",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex">
            <img src={SettingIcons} alt="img" style={{ height: "15px" }} />
            <Link
              onClick={handleReportModalOpen}
              sx={{
                marginLeft: "5px",
                color: "#147AFF",
                textDecoration: "underline",
              }}
            >
              Report Technical Issue
            </Link>

            <CustomModal
              open={reportTechnicalModal}
              onClose={handleReportModalClose}
              actionButtonLabel="Save"
              title="Report Technical Issue"
              save={handleSaveData}
              borderBottom="0px"
              pb="0px"
            >
              <ReportTechnicalForm
                ref={formRef}
                inputCaptcha={inputCaptcha}
                handleSubmitTechnicalReport={handleSubmitTechnicalReport}
              />
            </CustomModal>
          </Box>
          <Box sx={{ p: { xs: 2, md: 1 } }}>
            <Button
              className={style.globalButtonStyle}
              variant="outlined"
              onClick={() => {
                dispatch(updateBasicInfo("activeTab", "3"));
              }}
              sx={{ marginX: 2 }}
            >
              <span style={{ padding: "0 4px" }}>{"<"}</span>Back Step
            </Button>
            <Button
              variant="contained"
              className={style.globalContainedButton}
              onClick={() => {
                dispatch(updateBasicInfo("activeTab", "5"));
              }}
            >
              Next Step <span style={{ padding: "0 4px" }}>{">"}</span>
            </Button>
          </Box>
        </Grid>
        <ValidationErrorModal
          onClose={handleInputValidationErrorModal}
          onCancel={handleInputValidationErrorModalOnCancel}
          open={inputValidationErrorModal}
          title="octestphp.georgiacourts.gov says"
          descriptionMessage={inputValidationMessage}
        />
        <DeleteModal
          onClose={handleDeleteMdlClose}
          open={deleteModal}
          delete={deleteHealthInsuranceDataHandler}
          title="Delete Health Insurance Records"
          deleteMessage="Are you sure you want permanently remove this child care record from the system?"
        />
      </Grid>
      <ValidationErrorModal
        onClose={handleSecondValidationErrorModal}
        onCancel={handleSecondValidationErrorModal}
        open={secondValidationErrorModalOpen}
        title="octestphp.georgiacourts.gov says"
        descriptionMessage={inputValidationMessage}
      />
    </>
  );
};

export default HealthInsuranceTab;
