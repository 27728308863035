import { SET_LOGIN, SET_LOGOUT } from "../../actions-Types/authActionTypes";

const initialState = {
  loginDetials: null,
};

export default (
  state = initialState,
  { type, payload }: { type: string; payload: React.ReactNode }
) => {
  switch (type) {
    case SET_LOGIN:
      return { ...state, loginDetials: payload };
    case SET_LOGOUT:
      return { ...state, loginDetials: undefined };

    default:
      return state;
  }
};
