export const getFileName = (key: string, pageName: string): string => {
  switch (key) {
    case "text":
      return `${pageName}.txt`;
    case "html":
      return `${pageName}.html`;
    case "excel":
      return `${pageName}.xlsx`;
    case "json":
      return `${pageName}.json`;
    case "csv":
      return `${pageName}.csv`;
    case "pdf":
      return `${pageName}.pdf`;
    default:
      return `${pageName}.txt"`;
  }
};
