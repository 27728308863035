import { httpTokenCommon } from "../interceptor";

class reportMonthlyNumber {
  getWorksheetReportMonthlyNumAPI() {
    return httpTokenCommon.get("reports/report/worksheets/");
  }
  getUserReportMonthlyNumAPI() {
    return httpTokenCommon.get("reports/report/users/");
  }
}

const reportMonthlyNum = new reportMonthlyNumber();
export default reportMonthlyNum;
