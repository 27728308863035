import { httpCommon } from "./interceptor";

class OtpVerificationService {
  verifyOTPforForgetPassAPI(email: string, payload: { otp: string }) {
    return httpCommon.post(`users/otp-verify/?email=${email}`, payload);
  }
}

const otpVerificationService = new OtpVerificationService();
export default otpVerificationService;
