import { AddHealthWorkRelatedChildCare } from "../../../types/create-worksheet/HealthInsuranceTab";
import { FormErrors } from "../../../types/global";

export const AddHealthInsuranceValidation = (
  formData: AddHealthWorkRelatedChildCare
): { [key: string]: string } => {
  const formErrors: FormErrors = {};

  if (!formData.child_name.trim()) {
    formErrors.child_name = "Please enter a child";
  }

  if (!formData.paid_by.trim()) {
    formErrors.paid_by = "Please enter who paid";
  }

  if (
    !formData.child_care_school_yearly.trim() &&
    !formData.child_care_summer_yearly.trim() &&
    !formData.child_care_other_breaks_yearly.trim()
  ) {
    formErrors.child_care_school_yearly =
      "Please enter an amount paid during school, summer breaks, or other breaks.";
  } else {
    delete formErrors.child_care_school_yearly;
  }

  return formErrors;
};
