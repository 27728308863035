import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material";

// Props definition for the tooltip component
interface TooltipProps {
  title: string;
  children: React.ReactElement;
  fontSize?: string;
  placement?:
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";
}

const CustomTooltip: React.FC<TooltipProps> = ({
  title,
  fontSize = "10px", // Default fontSize
  children,
  placement = "left-end",
}) => {
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: "#fff",
            color: "#000",
            fontSize: fontSize,
            borderRadius: "20px",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
            padding: "15px",
          },

        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        title={title}
        placement={placement}
        // arrow
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 8],
              },
            },
          ],
        }}
      >
        {children}
      </Tooltip>
    </ThemeProvider>
  );
};

export default CustomTooltip;
