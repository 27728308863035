import { httpCommon } from "./interceptor";

class ContactFormService {
  contactFormAPI(inputdata: {
    name: string;
    email: string;
    phone: string;
    assistance: string;
  }) {
    return httpCommon.post("contact_us_api/contact_us/", inputdata);
  }
}

const contactFormService = new ContactFormService();
export default contactFormService;
