import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  Typography,
  Paper,
  Grid,
  Button,
  IconButton,
  SelectChangeEvent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "../../components/inputField";
import CustomDropdown from "../../components/drop-down";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { headerActiveLink } from "../../globalState/actions/headerAction";
import organizationService from "../../services/organizationService/organization-service";
import { showToast } from "../../components/toastNotification";
import DeleteModal from "../../components/globalDeleteModal";
import CustomMessageModal from "../../components/globalCustomMessageModal";
import { RootState } from "../../utils/userDetailsCommonType";
import { loginDetails } from "../../globalState/actions/authAction";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import styles from "./viewOrg.module.css";
import { DropDown } from "../../types/global";

interface Column {
  id: keyof MemberData;
  label: string;
}
interface MemberData {
  user_id: number;
  user_name: string;
  user_email: string;
  administrator: string;
}
interface TableSearchData {
  user_name: string;
  user_email: string;
}
interface organizationUserList {
  member_email: string;
  status: string;
}
const ViewOrganizationMember = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootState) => state.authReducer);

  const [orgMemberEmail, setOrgMemberEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectRemoveMember, setSelectRemoveMember] = useState("");
  const [selectReassignNew, setSelectReassignNew] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgMemberData, setOrgMemberData] = useState<MemberData[]>([]);
  const [orgMemberInvitedData, setOrgMemberInvitedData] = useState<
    organizationUserList[]
  >([]);
  const [orgUserData, setOrgUserData] = useState<DropDown[]>([]);
  const [userReassignData, setUserReassignData] = useState<DropDown[]>([]);
  const [deleteOrgUserModal, setDeleteOrgUserModal] = useState<boolean>(false);
  const [deleteOrganizationModal, setDeleteOrganizationModal] =
    useState<boolean>(false);
  const [reassignAdministratorMdl, setReassignAdministratorMdl] =
    useState<boolean>(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({
    key: "number",
    direction: "asc"
  });
  const [tableSearchData, setTableSearchData] = useState<TableSearchData>({
    user_name: "",
    user_email: ""
  });

  useEffect(() => {
    if (userDetails.loginDetials.organization) {
      handleGetOrganizationData();
    }
  }, [orgName]);

  useEffect(() => {
    const searchTime = setTimeout(() => {
      if (userDetails.loginDetials.organization) {
        handleGetOrganizationMemberList();
      }
    }, 500);

    return () => {
      clearTimeout(searchTime);
    };
  }, [tableSearchData, sortConfig]);

  useEffect(() => {
    if (userDetails.loginDetials.organization) {
      handleGetOrganizationUserList();
      handleGetOrganizationMemberInvitedList();
    }
  }, []);

  const handleEmailonChane = (e: ChangeEvent<HTMLInputElement>) => {
    setOrgMemberEmail(e.target.value);
    setEmailError("");
  };

  const handleSelectOnChange = (event: SelectChangeEvent, name: string) => {
    if (name === "removeMember") {
      setSelectRemoveMember(event.target.value);
    } else {
      setSelectReassignNew(event.target.value);
    }
  };

  /// email input validation
  const EmailValidate = (): boolean => {
    let isValide = true;
    if (!orgMemberEmail.trim()) {
      setEmailError("Email Address cannot be blank");
      isValide = false;
    } else if (!/\S+@\S+\.\S+/.test(orgMemberEmail)) {
      setEmailError("Email Address is invalid");
      isValide = false;
    }

    return isValide;
  };

  /// APi calling code start
  /// API calling for Invite organization member
  const handleInviteMember = async () => {
    if (EmailValidate()) {
      const inputData = {
        member_email: orgMemberEmail.trim()
      };
      await organizationService
        .inviteOrganizationMemberAPI(inputData)
        .then((response) => {
          if (
            response &&
            response.data.message === "Invitation sent successfully"
          ) {
            setOrgMemberEmail("");
            handleGetOrganizationMemberInvitedList();
            showToast(response.data.message, "success");
          } else {
            showToast(response.data.message, "error");
          }
        })
        .catch((error) => {
          console.error(error, "API Error");
        });
    }
  };
  /// API calling for get Organization Data
  const handleGetOrganizationData = async () => {
    await organizationService
      .getOrganizationAPI()
      .then((response) => {
        if (response.data) {
          setOrgName(response.data.description);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// API calling for get Organization Member List
  const handleGetOrganizationMemberList = async () => {
    await organizationService
      .getOrganizationMemberListAPI(sortConfig, tableSearchData)
      .then((response) => {
        const responseData = response.data.organization_members;
        if (responseData?.length > 0) {
          const apiResponseData = responseData[0].members;
          const isAdminExist = apiResponseData.some(
            (item: { user_email: string; administrator: string }) =>
              item.user_email === userDetails.loginDetials.user_email &&
              item.administrator === "Yes"
          );
          if (isAdminExist) {
            const userData = {
              ...userDetails?.loginDetials,
              is_organization_admin: true,
              organization: true
            };

            dispatch(loginDetails(userData));
          }
          setOrgMemberData(apiResponseData);
        } else {
          setOrgMemberData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// API calling for get Organization Member Invited List
  const handleGetOrganizationMemberInvitedList = async () => {
    await organizationService
      .getOrganizationMemberInvitedListAPI()
      .then((response) => {
        const responseData = response.data.members_invited;
        if (responseData?.length > 0) {
          setOrgMemberInvitedData(responseData);
        } else {
          setOrgMemberInvitedData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// API calling for get Organization user List
  const handleGetOrganizationUserList = async () => {
    await organizationService
      .getOrganizationUserListAPI()
      .then((response) => {
        const responseData = response.data.organization_members;
        if (responseData?.length > 0) {
          const finalData: DropDown[] = responseData[0].members.map(
            (item: {
              user_name: string;
              user_email: string;
              administrator: string;
            }) => ({
              value: item.user_email,
              label: `${item.user_name} - ${item.user_email} - ${
                item.administrator === "Yes" ? "Admin" : "User"
              }`
            })
          );
          setOrgUserData(finalData);

          const reAssignData: DropDown[] = responseData[0].members
            .filter(
              (item: { administrator: string }) => item.administrator === "No"
            )
            .map(
              (item: {
                user_name: string;
                user_email: string;
                administrator: string;
              }) => ({
                value: item.user_email,
                label: `${item.user_name} - ${item.user_email} - ${
                  item.administrator === "Yes" ? "Admin" : "User"
                }`
              })
            );
          setUserReassignData(reAssignData);
        } else {
          setOrgUserData([]);
          setUserReassignData([]);
        }
      })
      .catch((error) => {
        console.log(error, "API Error");
      });
  };
  /// API calling for Delete Organization user data
  const handleDeleteOrganizationUser = async () => {
    const inputData = {
      email: selectRemoveMember.trim()
    };
    await organizationService
      .deleteOrganizationUserAPI(inputData)
      .then((response) => {
        if (response && response.data.message === "User removed successfully") {
          setSelectRemoveMember("");
          setDeleteOrgUserModal(false);
          handleGetOrganizationUserList();
          handleGetOrganizationMemberList();
          showToast(response.data.message, "success");
        } else {
          showToast(response.data.message, "error");
        }
      })
      .catch((error) => {
        console.error(error, "API Error");
      });
  };
  /// API calling for Reassigng Organization admin access
  const handleOrgReassignAdministrator = async () => {
    const inputData = {
      email: selectReassignNew.trim()
    };
    await organizationService
      .changeOrganizationUserAPI(inputData)
      .then((response) => {
        if (
          response &&
          response.data.message === "Administrator updated successfully"
        ) {
          setSelectReassignNew("");
          setReassignAdministratorMdl(false);
          handleGetOrganizationUserList();
          handleGetOrganizationMemberList();
          showToast(response.data.message, "success");
        } else {
          showToast(response.data.message, "error");
        }
      })
      .catch((error) => {
        console.error(error, "API Error");
      });
  };
  /// API calling for delete Organization
  const handleDeleteOrganization = async () => {
    await organizationService
      .deleteOrganizationAPI()
      .then((response) => {
        if (
          response &&
          response.data.message === "Organization deleted successfully"
        ) {
          setDeleteOrganizationModal(false);
          const userData = {
            ...userDetails?.loginDetials,
            is_organization_admin: false,
            organization: false
          };

          dispatch(loginDetails(userData));
          handlePageRedirection();
          showToast(response.data.message, "success");
        } else {
          showToast(response.data.message, "error");
        }
      })
      .catch((error) => {
        console.error(error, "API Error");
      });
  };
  /// APi calling code end
  /// delete Organization user modal open
  const handleDeleteOrgUserMdlOpen = () => {
    if (selectRemoveMember !== "") {
      setDeleteOrgUserModal(true);
    } else {
      showToast("No User Selected", "error");
    }
  };
  /// delete Organization user modal close
  const handleDeleteorgUserMdlClose = () => {
    setDeleteOrgUserModal(false);
  };

  const handleReassignAdministratorMdl = () => {
    if (selectReassignNew !== "") {
      setReassignAdministratorMdl(!reassignAdministratorMdl);
    } else {
      showToast("No User Selected", "error");
    }
  };

  const handleDeleteOrganizationMdl = () => {
    if (userReassignData.length > 0) {
      showToast(
        "You cannot delete an organization until you remove all of the organizations members.",
        "error"
      );
    } else {
      setDeleteOrganizationModal(!deleteOrganizationModal);
    }
  };

  const searchInputOnchange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setTableSearchData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSort = (columnId: keyof MemberData) => {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === columnId &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key: columnId, direction });
  };

  const sortedRows = React.useMemo(() => {
    if (
      sortConfig !== null &&
      sortConfig.key !== null &&
      sortConfig.key !== undefined
    ) {
      const key = sortConfig.key as keyof MemberData;
      return [...orgMemberData].sort((a: MemberData, b: MemberData) => {
        const aValue = a[key];
        const bValue = b[key];

        if (aValue == null && bValue != null)
          return sortConfig.direction === "asc" ? -1 : 1;
        if (aValue != null && bValue == null)
          return sortConfig.direction === "asc" ? 1 : -1;
        if (aValue == null && bValue == null) return 0;

        if (aValue !== null && bValue !== null) {
          if (aValue < bValue) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (aValue > bValue) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
        }

        return 0;
      });
    }
    return orgMemberData;
  }, [orgMemberData, sortConfig]);

  const TableColumns: Column[] = [
    { id: "user_name", label: "Member Name" },
    { id: "user_email", label: "Member Email" },
    { id: "administrator", label: "Administrator" }
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#404040",
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12
    },
    border: "1px solid #DFDFDF"
  }));
  const StyledTableRow = styled(TableRow)(() => ({
    border: "1px solid #DFDFDF"
  }));

  const handlePageRedirection = () => {
    navigate("/");
    dispatch(headerActiveLink(""));
  };
  return (
    <>
      <Box className="contain__area">
        <Box
          className="breadcrumbs"
          sx={{
            margin: {
              xs: "10px 0px 0px 1px",
              sm: "10px 56px 0px 1px",
              md: "30px 56px 0px 56px"
            }
          }}
        >
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/"
                className="LinkColorBlue"
                onClick={handlePageRedirection}
              >
                Home
              </Link>
              <Link
                to="/administration/viewOrganizationMember"
                className="LinkColorDark"
              >
                Invite/Remove/View Organization Members
              </Link>
            </Breadcrumbs>
          </div>
        </Box>
        <Box sx={{ marginTop: "2rem" }}>
          {!userDetails?.loginDetials?.is_organization_admin &&
          !userDetails?.loginDetials?.invite_from_org &&
          !userDetails?.loginDetials?.organization ? (
            <Typography
              m={2}
              variant="h5"
              color="#404040;"
              gutterBottom
              sx={{
                fontSize: 25,
                letterSpacing: "1px",

                margin: { md: "20px 56px", sm: "10px 0px", xs: "15px 0px" }
              }}
              className="h5"
            >
              You Must Create an Organization or Be A Member of An Organization
              to Add/Remove/View Members
            </Typography>
          ) : (
            <>
              {userDetails?.loginDetials?.is_organization_admin && (
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: "5px",
                    border: "1px solid #DFDFDF",
                    margin: { md: "0px 56px" }
                  }}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Typography
                          m={2}
                          variant="h5"
                          color="#0464AB;"
                          gutterBottom
                          sx={{
                            fontSize: 25,
                            letterSpacing: "1px",
                            marginBottom: "1px"
                          }}
                          className="h5"
                        >
                          Invite/Remove/View Organization Members to {orgName}
                        </Typography>
                        <Typography
                          m={2}
                          sx={{ borderBottom: "1px solid #DFDFDF" }}
                        ></Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mb={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="subtitle1"
                          mx={2}
                          fontWeight="bold"
                          gutterBottom
                          sx={{
                            color: "#022E4C",
                            fontSize: "14px"
                          }}
                        >
                          Invite Organization Member
                        </Typography>
                        <Box m={2}>
                          <CustomInput
                            name="orgMemberEmail"
                            label="Organization Email"
                            value={orgMemberEmail}
                            onChange={handleEmailonChane}
                            placeholder="Enter Organization Email"
                            type="text"
                            required={true}
                            error={emailError}
                          />
                        </Box>
                        <Box>
                          <Button
                            variant="contained"
                            disableElevation
                            onClick={handleInviteMember}
                            sx={{
                              borderRadius: "20px",
                              width: 150,
                              mx: 2,
                              fontWeight: "600",
                              backgroundColor: "#0464AB",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#FBB522",
                                color: "#022E4C",
                                fontWeight: "600"
                              }
                            }}
                          >
                            Invite Member
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="subtitle1"
                          mx={2}
                          fontWeight="bold"
                          gutterBottom
                          sx={{
                            color: "#022E4C",
                            fontSize: "14px"
                          }}
                        >
                          Remove Organization Member
                        </Typography>
                        <Box m={2}>
                          <CustomDropdown
                            name="removeMember"
                            label="User To Remove"
                            value={selectRemoveMember}
                            onChange={(e) =>
                              handleSelectOnChange(e, "removeMember")
                            }
                            options={orgUserData}
                            placeholder="- Choose -"
                          />
                        </Box>
                        <Box>
                          <Button
                            variant="contained"
                            disableElevation
                            sx={{
                              borderRadius: "20px",
                              width: 150,
                              mx: 2,
                              fontWeight: "600",
                              backgroundColor: "#0464AB",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#FBB522",
                                color: "#022E4C",
                                fontWeight: "600"
                              }
                            }}
                            onClick={handleDeleteOrgUserMdlOpen}
                          >
                            Remove Member
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography
                          variant="subtitle1"
                          mx={2}
                          fontWeight="bold"
                          gutterBottom
                          sx={{
                            color: "#022E4C",
                            fontSize: "14px"
                          }}
                        >
                          Reassign Administrator
                        </Typography>
                        <Box m={2}>
                          <CustomDropdown
                            name="reassignAdmin"
                            label="Assign New Administrator"
                            value={selectReassignNew}
                            onChange={(e) =>
                              handleSelectOnChange(e, "reassignAdmin")
                            }
                            options={userReassignData}
                            placeholder="- Choose -"
                          />
                        </Box>
                        <Box>
                          <Button
                            variant="contained"
                            disableElevation
                            sx={{
                              borderRadius: "20px",
                              width: 200,
                              mx: 2,
                              fontWeight: "600",
                              backgroundColor: "#0464AB",
                              textTransform: "none",
                              "&:hover": {
                                backgroundColor: "#FBB522",
                                color: "#022E4C",
                                fontWeight: "600"
                              }
                            }}
                            onClick={handleReassignAdministratorMdl}
                          >
                            Reassign Administrator
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <Typography></Typography>
                        <Box m={2}></Box>
                        <Box
                          m={2}
                          display="flex"
                          justifyContent={{
                            md: "flex-end",
                            sm: "flex-end",
                            xs: "flex-start"
                          }}
                          sx={{ marginTop: { md: "120px", sm: "120px" } }}
                        >
                          <Button
                            variant="outlined"
                            disableElevation
                            sx={{
                              borderRadius: "20px",
                              width: 200,
                              textTransform: "none",
                              backgroundColor: "#fff",
                              border: "1px solid #0464AB",
                              color: "#0464AB",
                              fontWeight: "600",
                              "&:hover": {
                                backgroundColor: "#FBB522",
                                color: "#022E4C",
                                border: "1px solid #FBB522",
                                fontWeight: "600"
                              }
                            }}
                            onClick={handleDeleteOrganizationMdl}
                          >
                            Delete Organization
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              )}

              <Typography
                m={2}
                variant="h5"
                color="#0464AB;"
                gutterBottom
                sx={{
                  fontSize: 25,
                  letterSpacing: "1px",

                  margin: { md: "20px 56px", sm: "10px 0px", xs: "15px 0px" }
                }}
                className="h5"
              >
                Organization Members
              </Typography>

              <Box
                sx={{
                  margin: { md: "0px 56px", xs: "0px 0px" }
                }}
              >
                <TableContainer>
                  <Table
                    sx={{ minWidth: 700 }}
                    className={styles.memTable}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        {TableColumns.map((data, index) => (
                          <StyledTableCell key={data.id}>
                            <Box display="flex" alignItems="center">
                              {data.label}
                              {index !== 2 && (
                                <IconButton
                                  onClick={() => handleSort(data.id)}
                                  size="small"
                                  color="inherit"
                                >
                                  {sortConfig.key === data.id ? (
                                    sortConfig.direction === "asc" ? (
                                      <ArrowUpwardIcon fontSize="inherit" />
                                    ) : (
                                      <ArrowDownwardIcon fontSize="inherit" />
                                    )
                                  ) : (
                                    <ArrowUpwardIcon fontSize="inherit" />
                                  )}
                                </IconButton>
                              )}
                            </Box>
                          </StyledTableCell>
                        ))}
                      </TableRow>
                      <TableRow>
                        {TableColumns.map((item, index) => (
                          <TableCell
                            sx={{ border: "1px solid #DFDFDF", padding: "6px" }}
                            key={item.id}
                          >
                            {index !== TableColumns.length - 1 && (
                              <CustomInput
                                key={`search-${item.id}`}
                                name={item.id}
                                value={
                                  tableSearchData[
                                    item.id as keyof TableSearchData
                                  ] || ""
                                }
                                onChange={searchInputOnchange}
                                placeholder="Search"
                                endAdornment={<SearchOutlinedIcon />}
                              />
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sortedRows.map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>{row.user_name}</StyledTableCell>
                          <StyledTableCell>{row.user_email}</StyledTableCell>
                          <StyledTableCell>{row.administrator}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {userDetails?.loginDetials?.is_organization_admin && (
                    <Table
                      sx={{ minWidth: 700 }}
                      className={styles.invTable}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            colSpan={3}
                            align="center"
                            sx={{
                              backgroundColor: "#E6F5FF",
                              color: "#404040",
                              fontSize: "13px"
                            }}
                          >
                            Members Invited to Join Organization
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orgMemberInvitedData.map((row, j) => (
                          <StyledTableRow key={j}>
                            <StyledTableCell>
                              {row.member_email}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{
                                color:
                                  row.status === "Rejected" ? "red" : "black"
                              }}
                            >
                              {row.status === "Rejected"
                                ? "Rejected"
                                : "Pending"}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </Box>
            </>
          )}

          <Box
            sx={{
              margin: { md: "20px 56px", xs: " 10px 0px 30px 0px" }
            }}
          >
            <Button
              variant="outlined"
              disableElevation
              onClick={handlePageRedirection}
              sx={{
                borderRadius: "20px",
                minWidth: "fit-content",
                width: 150,
                textTransform: "none",
                backgroundColor: "#fff",
                border: "1px solid #0464AB",
                color: "#0464AB",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#FBB522",
                  color: "#022E4C",
                  border: "none",
                  fontWeight: "600"
                }
              }}
            >
              Return To Home Page
            </Button>
          </Box>
          <DeleteModal
            onClose={handleDeleteorgUserMdlClose}
            open={deleteOrgUserModal}
            delete={handleDeleteOrganizationUser}
            title="Delete Organization User"
            deleteMessage="Are you sure you want permanently remove this user from the organization"
          />
          <CustomMessageModal
            onClose={handleReassignAdministratorMdl}
            open={reassignAdministratorMdl}
            submit={handleOrgReassignAdministrator}
            title="Assign New Administrator"
            descriptionMessage="Are you sure you want assign a new administrator for this organization"
            buttonName="Submit"
          />
          <CustomMessageModal
            onClose={handleDeleteOrganizationMdl}
            open={deleteOrganizationModal}
            submit={handleDeleteOrganization}
            title="Delete Organization"
            descriptionMessage="Are you sure you want permanently delete this organization?"
            buttonName="Delete"
          />
        </Box>
      </Box>
    </>
  );
};

export default ViewOrganizationMember;
