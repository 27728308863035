import {
  getTotalJudicialDeviation,
  getTotalProposedDeviation,
} from "../pages/worksheet/DeviationsTab/DeviationsValidationTabs";
import { CreateWorksheetInitialState } from "../types/create-worksheet";
import { CalculateNarestChildValues } from "./calculateNarestChildValues";
import { GetLowIncomeDeviationAmt } from "./getLowIncomeDeviationAmt";

export const CalculateFinalValue = (
  totalGrossIncome: number | undefined,
  monthlySelfEmploymentIncome: number | undefined,
  preexistingChildSupportAmount: number | undefined,
  numberOfChildren: number | undefined
): number => {
  const ficaValue = monthlySelfEmploymentIncome
    ? (Number(monthlySelfEmploymentIncome) * 0.062).toFixed(2)
    : "0";

  const line4Value = monthlySelfEmploymentIncome
    ? (Number(monthlySelfEmploymentIncome) * 0.0145).toFixed(2)
    : "0";

  const line5Value = Number(ficaValue) + Number(line4Value);

  const line6Value = totalGrossIncome
    ? Number(totalGrossIncome) - line5Value
    : 0;

  const line9Value =
    Number(line6Value) - Number(preexistingChildSupportAmount || 0);

  if (Number(numberOfChildren) > 0) {
    if (line6Value && line6Value > 0) {
      const line12Value = CalculateNarestChildValues(
        Number(line6Value),
        Number(numberOfChildren)
      );

      const line13Value = (line12Value * 75) / 100;

      const line14Value = line13Value ? line9Value - line13Value : 0;

      return line14Value;
    } else {
      return line9Value;
    }
  } else {
    return line9Value;
  }
};

/// get the final basic value for line 2 in 1st tab
export const getBasicFinalValueFor2Line = (
  WorkSheetData: CreateWorksheetInitialState
) => {
  let FinalValue1 = 0;
  let FinalValue2 = 0;

  const ChildValues = WorkSheetData.adjusted_income_schedule_b
    ?.proposed_adjustment_for_qualified_child
    ? WorkSheetData.adjusted_income_schedule_b.proposed_adjustment_for_qualified_child.reduce(
        (
          acc: { parent1: number; parent2: number },
          item: { adjustment_requested_by: string }
        ) => {
          if (item.adjustment_requested_by === WorkSheetData.parent_name_1) {
            acc.parent1 = (acc.parent1 || 0) + 1;
          }

          if (item.adjustment_requested_by === WorkSheetData.parent_name_2) {
            acc.parent2 = (acc.parent2 || 0) + 1;
          }

          return acc;
        },
        { parent1: 0, parent2: 0 }
      )
    : { parent1: 0, parent2: 0 };

  if (
    WorkSheetData.adjusted_income_schedule_b?.monthly_self_employment_income1 ||
    WorkSheetData.adjusted_income_schedule_b?.monthly_self_employment_income2 ||
    WorkSheetData.adjusted_income_schedule_b
      ?.preexisting_child_support_amount1 ||
    WorkSheetData.adjusted_income_schedule_b?.preexisting_child_support_amount2
  ) {
    FinalValue1 = CalculateFinalValue(
      WorkSheetData.gross_income_schedule_a?.total_gross_income1,
      WorkSheetData.adjusted_income_schedule_b?.monthly_self_employment_income1,
      WorkSheetData.adjusted_income_schedule_b
        ?.preexisting_child_support_amount1,
      ChildValues.parent1
    );

    FinalValue2 = CalculateFinalValue(
      WorkSheetData.gross_income_schedule_a?.total_gross_income2,
      WorkSheetData.adjusted_income_schedule_b?.monthly_self_employment_income2,
      WorkSheetData.adjusted_income_schedule_b
        ?.preexisting_child_support_amount2,
      ChildValues.parent2
    );
  } else {
    FinalValue1 = WorkSheetData.gross_income_schedule_a?.total_gross_income1
      ? WorkSheetData.gross_income_schedule_a?.total_gross_income1
      : 0;
    FinalValue2 = WorkSheetData.gross_income_schedule_a?.total_gross_income2
      ? WorkSheetData.gross_income_schedule_a?.total_gross_income2
      : 0;
  }

  return { FinalValue1, FinalValue2 };
};

/// get the final basic value for line 6 in 1st tab
export const getBasicFinalValueFor6Line = (
  WorkSheetData: CreateWorksheetInitialState
) => {
  const { FinalValue1, FinalValue2 } =
    getBasicFinalValueFor2Line(WorkSheetData);

  const line1Total =
    (WorkSheetData.health_insurance_child_care_schedule_d
      ?.health_insurance_premiums_parent1 ||
      WorkSheetData.health_insurance_child_care_schedule_d
        ?.health_insurance_premiums_parent2 ||
      WorkSheetData.health_insurance_child_care_schedule_d
        ?.health_insurance_premiums_parent3) &&
    (
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d
          ?.health_insurance_premiums_parent1
      ) /
        12 +
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d
          ?.health_insurance_premiums_parent2
      ) /
        12 +
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d
          ?.health_insurance_premiums_parent3
      ) /
        12
    ).toFixed(2);

  const line2Total =
    (WorkSheetData?.health_insurance_child_care_schedule_d?.monthly_total1 ||
      WorkSheetData?.health_insurance_child_care_schedule_d?.monthly_total2 ||
      WorkSheetData?.health_insurance_child_care_schedule_d?.monthly_total3) &&
    Number(
      WorkSheetData.health_insurance_child_care_schedule_d.monthly_total1 || 0
    ) +
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d.monthly_total2 || 0
      ) +
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d.monthly_total3 || 0
      );
  const fetchValueTotal = (Number(FinalValue1) + Number(FinalValue2)).toFixed(
    2
  );

  const line3TotalValue = Number(line1Total) + Number(line2Total);

  const line4Value1 =
    Number(FinalValue1) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue1) * 100) / Number(fetchValueTotal), 0),
          100
        ).toFixed(2);

  const line4Value2 =
    Number(FinalValue2) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue2) * 100) / Number(fetchValueTotal), 0),
          100
        ).toFixed(2);

  const finalLine6Value1 = (Number(line4Value1) * line3TotalValue) / 100;
  const finalLine6Value2 = (Number(line4Value2) * line3TotalValue) / 100;

  return { finalLine6Value1, finalLine6Value2 };
};

/// get the final basic value for line 10 in 1st tab
export const getBasicFinalValueFor10Line = (
  WorkSheetData: CreateWorksheetInitialState
) => {
  const { FinalValue1, FinalValue2 } =
    getBasicFinalValueFor2Line(WorkSheetData);

  const finalline1bResult = GetLowIncomeDeviationAmt(
    WorkSheetData.deviations_schedule_e?.proposed_low_income_deviation,
    WorkSheetData.deviations_schedule_e?.judicial_discretion_for_low_income,
    WorkSheetData.parent_name_1,
    WorkSheetData.parent_name_2
  );

  const JudicialParentsTotal = getTotalJudicialDeviation(
    WorkSheetData.deviations_schedule_e?.specific_nonspecific_deviation || []
  );
  const parentsTotal = getTotalProposedDeviation(
    WorkSheetData.deviations_schedule_e?.specific_nonspecific_deviation || []
  );
  const line1BValue1 = finalline1bResult.result1;
  const line1BValue2 = finalline1bResult.result2;

  const ProposedParent1Total = parentsTotal[WorkSheetData.parent_name_1];
  const ProposedParent2Total = parentsTotal[WorkSheetData.parent_name_2];

  const JudicialParent1Total =
    JudicialParentsTotal[WorkSheetData.parent_name_1];
  const JudicialParent2Total =
    JudicialParentsTotal[WorkSheetData.parent_name_2];

  const line2AValueCheckP1 = JudicialParent1Total
    ? JudicialParent1Total
    : ProposedParent1Total;
  const line2AValueCheckP2 = JudicialParent2Total
    ? JudicialParent2Total
    : ProposedParent2Total;

  const line12DTotal1 =
    Number(
      WorkSheetData.deviations_schedule_e
        ?.extraordinary_educational_expenses_total1
    ) /
      12 +
    Number(
      WorkSheetData.deviations_schedule_e
        ?.extraordinary_medical_expenses_total1 / 12
    );
  const line12DTotal2 =
    Number(
      WorkSheetData.deviations_schedule_e
        ?.extraordinary_educational_expenses_total2
    ) /
      12 +
    Number(
      WorkSheetData.deviations_schedule_e
        ?.extraordinary_medical_expenses_total2 / 12
    );
  const line12DTotal3 =
    Number(
      WorkSheetData.deviations_schedule_e
        ?.extraordinary_educational_expenses_total3
    ) /
      12 +
    Number(
      WorkSheetData.deviations_schedule_e
        ?.extraordinary_medical_expenses_total3 / 12
    );

  const line12DConbine = line12DTotal1 + line12DTotal2 + line12DTotal3;

  const line2ValueTotal = (Number(FinalValue1) + Number(FinalValue2)).toFixed(
    2
  );

  const line3Value1 =
    Number(FinalValue1) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue1) * 100) / Number(line2ValueTotal), 0),
          100
        ).toFixed(2);

  const line3Value2 =
    Number(FinalValue2) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue2) * 100) / Number(line2ValueTotal), 0),
          100
        ).toFixed(2);

  const line12FValue1 = (line12DConbine * Number(line3Value1)) / 100;
  const line12FValue2 = (line12DConbine * Number(line3Value2)) / 100;

  const line12GValue1 = line12FValue1 - line12DTotal1;
  const line12GValue2 = line12FValue2 - line12DTotal2;

  const finalLine10Value1 =
    Number(line1BValue1) +
    Number(line2AValueCheckP1) +
    Number(line12GValue1) +
    Number(WorkSheetData.deviations_schedule_e?.parenting_time_deviation1);
  const finalLine10Value2 =
    Number(line1BValue2) +
    Number(line2AValueCheckP2) +
    Number(line12GValue2) +
    Number(WorkSheetData.deviations_schedule_e?.parenting_time_deviation2);

  return { finalLine10Value1, finalLine10Value2 };
};
