import React from "react";
import { ReactComponent as Csvicon } from "../../assets/images/csv.svg";
import { ReactComponent as Excelicon } from "../../assets/images/excel.svg";
import { ReactComponent as FileExporticon } from "../../assets/images/file-export.svg";
import { ReactComponent as Htmlicon } from "../../assets/images/html.svg";
import { ReactComponent as Jsonicon } from "../../assets/images/json.svg";
import { ReactComponent as Pdficon } from "../../assets/images/pdf.svg";
import { ReactComponent as Texticon } from "../../assets/images/Text.svg";
import Modulecss from "./export.module.css";

interface CommonIconProps {
  type: string;
  selectedValue: string;
}

const CommonIcon: React.FC<CommonIconProps> = ({ type, selectedValue }) => {
  const iconClass =
    selectedValue === type ? Modulecss.activeSVG : Modulecss.inactiveSVG;

  switch (type) {
    case "Export":
      return <FileExporticon className={iconClass} />;
    case "HTML":
      return <Htmlicon className={iconClass} />;
    case "CSV":
      return <Csvicon className={iconClass} />;
    case "Text":
      return <Texticon className={iconClass} />;
    case "Excel":
      return <Excelicon className={iconClass} />;
    case "PDF":
      return <Pdficon className={iconClass} />;
    case "JSON":
      return <Jsonicon className={iconClass} />;
    default:
      return null;
  }
};

export default CommonIcon;
