import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useLocation } from "react-router-dom";
import CertificateOfService from "../../services/certificateService/certificateOfService";

interface LocationState {
  worksheetId: number;
}

const PDFViewPage: React.FC = () => {
  const location = useLocation();
  const { worksheetId } = location.state as LocationState;

  const navigate = useNavigate();
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null); // Store Blob for download
  const [isAccordionExpanded, setIsAccordionExpanded] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPDF = async () => {
      try {
        setLoading(true);
        const response = await CertificateOfService.PDFDownload(worksheetId);
        // Create a Blob from the PDF Stream
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        setPdfUrl(url);
        setPdfBlob(blob);
      } catch (fetchError) {
        console.error("Failed to fetch PDF:", fetchError);
        setError("Failed to load PDF.");
      } finally {
        setLoading(false);
      }
    };

    if (isAccordionExpanded && worksheetId) {
      fetchPDF();
    }

    // Clean up when the accordion is collapsed
    if (!isAccordionExpanded) {
      setPdfUrl(null);
      setPdfBlob(null);
      setError(null);
    }
  }, [worksheetId, isAccordionExpanded]);

  const downloadPDF = () => {
    if (pdfBlob) {
      const link: HTMLAnchorElement = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.setAttribute("download", "CertificateOfService.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("PDF Blob is null or undefined");
    }
  };

  return (
    <>
      <Box
        className="contain__area"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 3,
        }}
      >
        <Paper
          sx={{
            width: {
              xs: "100%",
              sm: "90%",
            },
            borderRadius: "5px",
            border: "1px solid #DFDFDF",
            marginTop: "20px",
            marginBottom: "20px",
            paddingBottom: 2,
          }}
        >
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item xs={12} sm={6}>
                  <Typography
                    className="h4"
                    fontSize={22}
                    sx={{
                      color: "#0464AB",
                    }}
                  >
                    Certificate of Service
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box mx={2}>
            <Button
              variant="contained"
              disableElevation
              onClick={downloadPDF} // Trigger the downloadPDF function on click
              sx={{
                borderRadius: "21px",
                backgroundColor: "#0464AB",
                height: 38,
                fontSize: "14px",
                fontWeight: "600",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#FBB522",
                  color: "#022E4C",
                  fontWeight: "600",
                  borderColor: "#FBB522",
                },
              }}
            >
              Print Certificate of Service
            </Button>
            <Button
              variant="outlined"
              disableElevation
              onClick={() => {
                navigate("/searchFolderWorksheet");
              }}
              sx={{
                borderRadius: "21px",
                mx: 1,
                height: 38,
                fontSize: "14px",
                fontWeight: "600",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#FBB522",
                  color: "#022E4C",
                  fontWeight: "600",
                  borderColor: "#FBB522",
                },
              }}
            >
              Go To Search Screen
            </Button>
          </Box>

          <Box mx={2} my={2}>
            <Accordion
              expanded={isAccordionExpanded}
              onChange={() => setIsAccordionExpanded(!isAccordionExpanded)}
            >
              <AccordionSummary
                sx={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography fontWeight={"bold"}>
                  Certificate of Service
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {loading ? (
                  <Typography>Loading...</Typography>
                ) : error ? (
                  <Typography>{error}</Typography>
                ) : (
                  pdfUrl && (
                    <iframe
                      src={pdfUrl}
                      style={{
                        flex: 1,
                        minHeight: 'fit-content!important',
                        width: "100%",
                        height: "1200px",
                        border: "none",
                      }}
                      frameBorder="0"
                    />
                  )
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box mx={2}>
            <Button
              variant="contained"
              disableElevation
              onClick={downloadPDF} // Trigger the downloadPDF function on click
              sx={{
                borderRadius: "21px",
                backgroundColor: "#0464AB",
                height: 38,
                fontSize: "14px",
                fontWeight: "600",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#FBB522",
                  color: "#022E4C",
                  fontWeight: "600",
                  borderColor: "#FBB522",
                },
              }}
            >
              Print Certificate of Service
            </Button>
            <Button
              variant="outlined"
              disableElevation
              onClick={() => {
                navigate("/searchFolderWorksheet");
              }}
              sx={{
                borderRadius: "21px",
                mx: 1,
                height: 38,
                fontSize: "14px",
                fontWeight: "600",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#FBB522",
                  color: "#022E4C",
                  fontWeight: "600",
                  borderColor: "#FBB522",
                },
              }}
            >
              Go To Search Screen
            </Button>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default PDFViewPage;
