import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Fade,
  Modal,
  Backdrop,
  IconButton,
  CircularProgress
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  save?: () => void;
  title: string;
  children: React.ReactNode;
  actionButtonLabel: string;
  button3?: string;
  onClickButton3?: () => void;
  boxStyle?: object;
  borderBottom?: string;
  pb?: string;
  btnLoader?: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  save,
  title,
  children,
  actionButtonLabel,
  button3,
  boxStyle,
  onClickButton3,
  borderBottom,
  pb,
  btnLoader
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "@media (max-width: 600px)": {
          p: 2,
          width: "90%"
        }
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            marginTop: "3%",
            marginBottom: "3%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            width: 550,
            maxHeight: "85vh",
            bgcolor: "background.paper",
            border: "none",
            borderRadius: "25px",
            boxShadow: 24,
            p: 4,
            ...boxStyle
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 20,
              top: 10,
              color: "red"
            }}
          >
            <CancelOutlinedIcon />
          </IconButton>
          {title !== "" && (
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "#0464AB"
              }}
              className="h4 main_heading"
            >
              {title}
            </Typography>
          )}
          <Box
            sx={{
              borderBottom: borderBottom || "1px solid #DFDFDF",
              pb: pb || 2
            }}
          ></Box>
          <Box
            sx={{
              overflowY: "auto",
              flexGrow: 1,
              // borderBottom: "1px solid #DFDFDF",
              pt: 2
            }}
          >
            {children}
          </Box>
          <Box sx={{ pt: 1, display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {actionButtonLabel !== "" && (
                  <Button
                    variant="contained"
                    disableElevation
                    disabled={btnLoader}
                    sx={{
                      borderRadius: "21px",
                      backgroundColor: "#0464AB",
                      minWidth: "fit-content",
                      width: "70px",
                      height: 30,
                      fontSize: "12px",
                      fontWeight: "600",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#FBB522",
                        color: "#022E4C",
                        fontWeight: "600"
                      }
                    }}
                    onClick={save}
                  >
                    {actionButtonLabel}{" "}
                    {btnLoader && (
                      <CircularProgress size={18} sx={{ padding: "0px 8px" }} />
                    )}
                  </Button>
                )}
                {button3 && (
                  <Button
                    variant="outlined"
                    disableElevation
                    onClick={onClickButton3}
                    sx={{
                      borderRadius: "20px",
                      p: 1,
                      m: 1,
                      minWidth: "fit-content",
                      width: 80,
                      height: 30,
                      fontSize: "12px",
                      fontWeight: "600",
                      textTransform: "none",
                      "&:hover": {
                        backgroundColor: "#FBB522",
                        color: "#022E4C",
                        fontWeight: "600",
                        border: "none"
                      }
                    }}
                  >
                    {button3}
                  </Button>
                )}
                <Button
                  onClick={onClose}
                  variant="outlined"
                  disableElevation
                  sx={{
                    borderRadius: "20px",
                    p: 1,
                    m: 1,
                    minWidth: "fit-content",
                    width: 80,

                    height: 30,
                    fontSize: "12px",
                    fontWeight: "600",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#FBB522",
                      color: "#022E4C",
                      fontWeight: "600",
                      border: "none"
                    }
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
