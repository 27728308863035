import React, { ChangeEvent, useEffect, useRef, FocusEvent } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";

// Styled components
const CustomBox = styled(Box)<{ CurrencyBackground?: string }>(
  ({ CurrencyBackground }) => ({
    display: "flex",
    alignItems: "center",
    borderRadius: "25px",
    border: "1px solid #ccc",
    width: "100%",
    background: CurrencyBackground || "#F8F8F8",
    position: "relative",
    height: "30px!important"
  })
);

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "25px",
    border: "none"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none"
  },
  "& .MuiInputBase-input": {
    //padding: "6.5px 20px",
    alignItems: "center",
    "-moz-appearance": "textfield",
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    padding: "6.5px 20px",
    [theme.breakpoints.up("xs")]: {
      padding: "6.5px 20px"
    },
    [theme.breakpoints.up("md")]: {
      padding: "6.5px 20px"
    },
    [theme.breakpoints.up("lg")]: {
      padding: "6.5px 20px"
    },
    [theme.breakpoints.up("xl")]: {
      padding: "4.5px 20px"
    }
  }
}));

const GrayBox = styled(Box)({
  padding: "6.5px 10px",
  borderRadius: "25px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer"
});

// Component Props Interface
interface CurrencyValueProps {
  value: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  backgroundColor?: string;
  CurrencyBackground?: string;
  disabled?: boolean;
  type?: string;
  name: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
}

// Main Component
const CurrencyValue: React.FC<CurrencyValueProps> = ({
  value,
  onChange,
  backgroundColor,
  CurrencyBackground,
  disabled,
  type,
  name,
  onBlur
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // useEffect(() => {
  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     if (
  //       event.key === "ArrowUp" ||
  //       event.key === "ArrowDown" ||
  //       event.key === "e" ||
  //       event.key === "E" ||
  //       event.key === "-"
  //     ) {
  //       event.preventDefault();
  //     }
  //   };

  //   const input = inputRef.current;
  //   if (input) {
  //     input.addEventListener("keydown", handleKeyDown);
  //   }

  //   return () => {
  //     if (input) {
  //       input.removeEventListener("keydown", handleKeyDown);
  //     }
  //   };
  // }, [type]);
  useEffect(() => {
    const input = inputRef.current;
    if (input && type === "number") {
      input.addEventListener("wheel", (event) => event.preventDefault()); // Prevent scroll
    }
    return () => {
      if (input && type === "number") {
        input.removeEventListener("wheel", (event) => event.preventDefault());
      }
    };
  }, [type]);

  return (
    <CustomBox CurrencyBackground={CurrencyBackground}>
      <GrayBox sx={{}}>
        <div style={{ margin: "0px 6px" }}>$</div>
      </GrayBox>
      <CustomTextField
        variant="outlined"
        size="small"
        sx={{
          backgroundColor: backgroundColor,
          borderRadius: "0px 30px 30px 0px",
          height: "30px",
          width: "100%",
          borderLeft: "1px solid #E2E0E0 !important",
          "& .Mui-disabled": {
            opacity: 1,
            WebkitTextFillColor: "unset",
            color: "black"
          }
        }}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        type={type}
        name={name}
        ref={inputRef}
      />
    </CustomBox>
  );
};

export default CurrencyValue;
