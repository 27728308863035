import { httpCommon, httpTokenCommon } from "../interceptor";
import { CreateWorksheetDataTypeState } from "./create-worksheet-datatype";

class CreateWorksheetService {
  /// fetch court list Api
  getCourtListAPI() {
    return httpCommon.get("users/court-list");
  }

  /// fetch country list Api
  getCountyListAPI() {
    return httpCommon.get("users/county-list");
  }

  /// submit Worksheet data Api
  createUpdateWorksheetBasicInfoAPI(inputData: CreateWorksheetDataTypeState,worksheetId:number) {
    if(worksheetId){
      return httpTokenCommon.put(`worksheet/basic_info/${worksheetId}/`, inputData);
    }else{
      return httpTokenCommon.post("worksheet/basic_info/", inputData);
    }
  }

  /// fetch worksheet data by ID
  getWorksheetInfoDataAPI(id: number) {
    return httpTokenCommon.get(`worksheet/basic_info/${id}/`);
  }
}

const createWorksheetService = new CreateWorksheetService();
export default createWorksheetService;
