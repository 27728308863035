import React, { ChangeEvent, useState } from "react";
import {
  Box,
  IconButton,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import CustomInput from "../../components/inputField";
import { useNavigate, useLocation } from "react-router-dom";

import forgetPasswordService from "../../services/forget-password-service";
import { showToast } from "../../components/toastNotification";

interface newPasswordData {
  newPassword: string;
  confirmPassword: string;
}

const NewPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state as { email: string };

  const [data, setData] = useState<newPasswordData>({
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [newPasswordValidation, setNewPasswordValidation] =
    useState<string>("");
  const [confirmPasswordValidation, setConfirmPasswordValidation] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleTextData = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "newPassword") {
      setNewPasswordValidation("");
    }
    if (name === "confirmPassword") {
      setConfirmPasswordValidation("");
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };
  const formValidation = (): boolean => {
    let isValid = true;

    if (!data.newPassword.trim()) {
      setNewPasswordValidation("Password cannot be blank");
      isValid = false;
    } else if (data.newPassword.length < 8) {
      setNewPasswordValidation("Password must be 8 characters or more");
      isValid = false;
    } else if (!/[A-Z]/.test(data.newPassword)) {
      setNewPasswordValidation(
        "Password must contain at least one uppercase letter"
      );
      isValid = false;
    } else if (!/[a-z]/.test(data.newPassword)) {
      setNewPasswordValidation(
        "Password must contain at least one lowercase letter"
      );
      isValid = false;
    } else if (!/[0-9]/.test(data.newPassword)) {
      setNewPasswordValidation("Password must contain at least one number");
      isValid = false;
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(data.newPassword)) {
      setNewPasswordValidation(
        "Password must contain at least one special character"
      );
      isValid = false;
    }

    if (!data.confirmPassword.trim()) {
      setConfirmPasswordValidation("Confirm Password cannot be blank");
      isValid = false;
    } else if (data.newPassword !== data.confirmPassword) {
      setConfirmPasswordValidation("Passwords do not match");
      isValid = false;
    }
    return isValid;
  };
  const handleSubmitForm = () => {
    console.log(email, "-----OTP-----");
    if (formValidation()) {
      setIsLoading(true);
      const payload = {
        new_password: data.newPassword,
        confirm_password: data.confirmPassword,
      };
      forgetPasswordService
        .createPasswordAPI(email, payload)
        .then((response) => {
          if (
            response &&
            response?.data.message ===
              "Your password has been reset successfully"
          ) {
            showToast(response.data.message, "success");
            navigate("/");
          } else {
            showToast(response?.data.message, "error");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error, "API Error");
        });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mb: 13,
            mt: 3,
          }}
        >
          <Card
            sx={{
              minWidth: { xs: 300, sm: 300, md: 400 },
              maxWidth: 500,
              m: 2,
              py: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "40px",
              border: "1px solid #DFDFDF",
              opacity: "1px",
              boxShadow: "none",
            }}
          >
            <CardContent sx={{ textAlign: "center", width: "80%" }}>
              <Typography
                variant="h5"
                color="#0464AB;"
                gutterBottom
                sx={{
                  fontSize: 24,
                  letterSpacing: "1px",
                  marginBottom: "0px",
                }}
                className="h5"
              >
                Enter New Password
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                fontSize={11}
                fontWeight={540}
                sx={{
                  borderBottom: "1px solid #DFDFDF",
                  p: 1,
                  color: "#000000",
                }}
              ></Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                fontSize={13}
                fontWeight={580}
                textAlign={"left"}
                sx={{
                  pt: 1,
                  color: "#000000",
                }}
              >
                Please Choose New Password
              </Typography>
              <Box sx={{ pt: 1 }}>
                <form>
                  <Box sx={{ marginBottom: "20px" }}>
                    <CustomInput
                      onChange={handleTextData}
                      value={data.newPassword}
                      label="Password"
                      name="newPassword"
                      type={showPassword ? "text" : "password"}
                      required={true}
                      placeholder="Password"
                      error={newPasswordValidation && newPasswordValidation}
                      endAdornment={
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      }
                    />
                  </Box>
                  <Box>
                    <CustomInput
                      onChange={handleTextData}
                      value={data.confirmPassword}
                      label="Confirm Password"
                      name="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      required={true}
                      placeholder="Password"
                      error={
                        confirmPasswordValidation !== ""
                          ? confirmPasswordValidation
                          : ""
                      }
                      endAdornment={
                        <IconButton
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOutlinedIcon />
                          ) : (
                            <VisibilityOffOutlinedIcon />
                          )}
                        </IconButton>
                      }
                    />
                  </Box>

                  <Button
                    variant="contained"
                    disableElevation
                    disabled={isLoading}
                    onClick={handleSubmitForm}
                    sx={{
                      borderRadius: "21px",
                      backgroundColor: "#0464AB",
                      width: 180,
                      height: 38,
                      fontSize: "14px",
                      fontWeight: "600",
                      textTransform: "none",
                      marginTop: "20px",
                      "&:hover": {
                        backgroundColor: "#FBB522",
                        color: "#022E4C",
                        fontWeight: "600",
                        borderColor: "#FBB522",
                      },
                    }}
                  >
                    Reset Password{" "}
                    {isLoading && (
                      <CircularProgress size={18} sx={{ padding: "0px 8px" }} />
                    )}
                  </Button>
                </form>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default NewPasswordForm;
