import React, { ChangeEvent, useState, useEffect } from "react";
import {
  Box,
  Card,
  SelectChangeEvent,
  CircularProgress,
  Grid,
  Typography,
  Button,
  Breadcrumbs,
  Link,
} from "@mui/material";
import CustomInput from "../../components/inputField";
import CustomDropdown from "../../components/drop-down";
import { useNavigate } from "react-router-dom";
import userInformationService from "../../services/userInformation-service";
import { showToast } from "../../components/toastNotification";
import { useDispatch, useSelector } from "react-redux";
import { loginDetails } from "../../globalState/actions/authAction";
import { RootState } from "../../utils/userDetailsCommonType";
import { headerActiveLink } from "../../globalState/actions/headerAction";
import { DropDown } from "../../types/global";

interface UserInfoData {
  first_name: string;
  last_name: string;
  email: string;
  language: string;
  county_id: string;
  court_id: string;
  bar_number: string;
}
interface CourtData {
  id: number;
  court_name: string;
}
interface CountryData {
  id: number;
  county_name: string;
}

const UpdateUserInformation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state: RootState) => state.authReducer);

  const [userInformation, setUserInformation] = useState<UserInfoData>({
    first_name: "",
    last_name: "",
    email: "",
    language: "english",
    county_id: "",
    court_id: "",
    bar_number: "",
  });
  const [courtData, setCourtData] = useState<DropDown[]>([]);
  const [countyData, setCountyData] = useState<DropDown[]>([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch court and county data
        const [courtResponse, countyResponse] = await Promise.all([
          userInformationService.getCourtListAPI(),
          userInformationService.getCountyListAPI(),
        ]);

        // Process court data
        const courts: DropDown[] = courtResponse.data.data.map(
          (court: CourtData) => ({
            value: court.id.toString(),
            label: court.court_name,
          })
        );
        setCourtData(courts);

        // Process county data
        const counties: DropDown[] = countyResponse.data.data.map(
          (item: CountryData) => ({
            value: item.id.toString(),
            label: item.county_name,
          })
        );
        setCountyData(counties);

        // Fetch user information data
        const userResponse = await userInformationService.getUserDetailsAPI();
        const userData = userResponse.data;
        setUserInformation({
          ...userData,
          court_id:
            userData.court_id !== null
              ? courts.find(
                  (court) => court.value === userData.court_id.toString()
                )?.value || ""
              : "",
          county_id:
            userData.county_id !== null
              ? counties.find(
                  (county) => county.value === userData.county_id.toString()
                )?.value || ""
              : "",
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const alphabetRegex = /^[A-Za-z\s]+$/;
    if (!userInformation.first_name.trim()) {
      newErrors.first_name = "First Name cannot be blank";
    } else if (specialCharRegex.test(userInformation.first_name.trim())) {
      newErrors.first_name = "First Name cannot contain special characters";
    } else if (!alphabetRegex.test(userInformation.first_name.trim())) {
      newErrors.first_name = "First Name can only contain alphabets";
    } else if (userInformation.first_name.length > 50) {
      newErrors.first_name =
        "Ensure this field has not more than 50 characters";
    }

    if (!userInformation.last_name.trim()) {
      newErrors.last_name = "Last name cannot be blank";
    } else if (specialCharRegex.test(userInformation.last_name.trim())) {
      newErrors.last_name = "Last Name cannot contain special characters";
    } else if (!alphabetRegex.test(userInformation.last_name.trim())) {
      newErrors.last_name = "Last Name can only contain alphabets";
    } else if (userInformation.last_name.length > 50) {
      newErrors.last_name = "Ensure this field has not more than 50 characters";
    }

    if (!userInformation.email.trim()) {
      newErrors.email = "Email Address cannot be blank";
    } else if (!emailRegex.test(userInformation.email)) {
      newErrors.email = "Email Address is invalid";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (validateForm()) {
      const inputData = {
        ...userInformation,
        language: "english",
      };
      await userInformationService
        .patchUserDetailsUpdateAPI(inputData)
        .then((response) => {
          if (response && response.data) {
            showToast("User Details Updated successfully", "success");
            const updatedUsername = `${userInformation.first_name} ${userInformation.last_name}`;
            const userData = {
              ...userDetails.loginDetials,
              username: updatedUsername,
            };

            dispatch(loginDetails(userData));
            dispatch(headerActiveLink(""));
            navigate("/");
          } else {
            showToast("Failed to Updated Data", "error");
          }
        })
        .catch((error) => {
          console.error(error, "API Error");
        });
    }
  };
  const handlePageRedirect = () => {
    navigate("/");
    dispatch(headerActiveLink(""));
  };

  /// handle Input onchange code
  const searchInputSelectOnchange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const { name, value } = e.target;
    if (name === "bar_number") {
      if (value.length <= 10) {
        setUserInformation((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    } else {
      setUserInformation((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  return (
    <>
      <Box className="contain__area">
        <Box
          className="breadcrumbs"
          sx={{
            margin: { md: "0px 56px", sm: "0px 50px", xs: "0px 0px" },
            marginBottom: "10px",
          }}
        >
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="#147AFF" href="/">
                Home
              </Link>
              <Typography color="#000000">Update User Information</Typography>
            </Breadcrumbs>
          </div>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="contain__area"
          >
            <Card
              elevation={0}
              sx={{
                bgcolor: "#fff",
                border: "1px solid #DFDFDF",
                borderRadius: "30px 30px",
                opacity: 1,
                width: "100%",
                minWidth: "200px",
                maxWidth: "750px",
                margin: "30px 8px 8px 8px",
              }}
            >
              <Box sx={{ marginTop: "3rem" }}>
                <Box>
                  <Typography
                    variant="h4"
                    mx={4}
                    color="#0464AB;"
                    textAlign="center"
                    gutterBottom
                    sx={{
                      fontFamily: "DM Serif Display",
                      borderBottom: "1px solid #DFDFDF",
                      pb: 2,
                      px: 2,
                    }}
                    className="h4 main_heading"
                  >
                    Child Support User Information
                  </Typography>
                </Box>
              </Box>

              <Box mx={3}>
                <Box mb={1}>
                  <Typography
                    variant="subtitle1"
                    mx={2}
                    fontWeight="bold"
                    gutterBottom
                    sx={{
                      color: "#022E4C",
                      fontSize: "14px",
                    }}
                  >
                    User Information
                  </Typography>
                  {/* removed the below note as per aniket bugs */}
                  {/* <Typography variant="body1" mx={2}>
                    Enter a valid email address that will serve as your user
                    name. If you do not already have an email address, please
                    create one first.
                  </Typography> */}
                </Box>

                <Box mx={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        name="first_name"
                        label="First Name"
                        value={userInformation.first_name}
                        onChange={searchInputSelectOnchange}
                        placeholder="First Name"
                        required={true}
                        type="text"
                        error={errors.first_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        name="last_name"
                        label="Last Name"
                        value={userInformation.last_name}
                        onChange={searchInputSelectOnchange}
                        placeholder="Last Name"
                        required={true}
                        type="text"
                        error={errors.last_name}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <CustomInput
                        onChange={() => {}}
                        value={userInformation.email}
                        label="Email"
                        name="email"
                        type="text"
                        required={true}
                        disabled={true}
                        placeholder="Email"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <CustomDropdown
                        label="Default Language"
                        name="language"
                        value={userInformation.language}
                        onChange={searchInputSelectOnchange}
                        options={[
                          { value: "english", label: "English" },
                          // { value: "spanish", label: "Spanish" }
                        ]}
                        placeholder="- Select -"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={1}>
                  <Typography
                    variant="subtitle1"
                    mx={2}
                    fontWeight="bold"
                    gutterBottom
                    sx={{
                      color: "#022E4C",
                      fontSize: "14px",
                      paddingTop: "10px",
                    }}
                  >
                    Administration
                  </Typography>
                </Box>
                <Box mt={1} mb={2} ml={2} mr={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <CustomDropdown
                        name="court_id"
                        label="Default Court Name"
                        value={userInformation.court_id}
                        onChange={searchInputSelectOnchange}
                        options={courtData}
                        placeholder="- Select -"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomDropdown
                        name="county_id"
                        label="Default County Name"
                        value={userInformation.county_id}
                        onChange={searchInputSelectOnchange}
                        options={countyData}
                        placeholder="- Select -"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomInput
                        name="bar_number"
                        label="Bar Number"
                        value={userInformation.bar_number}
                        onChange={searchInputSelectOnchange}
                        placeholder="Bar Number"
                        type="number"
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box display="flex" justifyContent="flex-start" mb={5}>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      borderRadius: "20px",
                      width: 150,
                      mx: 2,
                      fontWeight: "600",
                      textTransform: "none",
                      backgroundColor: "#0464AB",
                      "&:hover": {
                        backgroundColor: "#FBB522",
                        color: "#022E4C",
                        fontWeight: "600",
                      },
                    }}
                    onClick={handleSave}
                    disabled={false}
                  >
                    Save User Information
                  </Button>
                  <Button
                    variant="outlined"
                    disableElevation
                    onClick={handlePageRedirect}
                    sx={{
                      borderRadius: "20px",
                      width: 100,
                      textTransform: "none",
                      backgroundColor: "#fff",
                      fontWeight: "600",
                      "&:hover": {
                        backgroundColor: "#FBB522",
                        color: "#022E4C",
                        borderColor: "#FBB522",
                        fontWeight: "600",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UpdateUserInformation;
