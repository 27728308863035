export const SET_LOGIN = "SET_LOGIN" as const;
export const UPDATE_BASIC_INFO = "UPDATE_BASIC_INFO" as const;
export const EMPTY_BASIC_INFO_FIELDS = "EMPTY_BASIC_INFO_FIELDS" as const;
export const UPDATE_ARRAY_ITEM = "UPDATE_ARRAY_ITEM" as const;
export const DELETE_ARRAY_ITEM = "DELETE_ARRAY_ITEM" as const;
export const SET_LOGOUT = "SET_LOGOUT" as const;
export const SET_HEADERLINK = "SET_HEADERLINK" as const;
export const SET_HEADERNULL = "SET_HEADERNULL" as const;
export const CREATE_WORKSHEET_DATA_EMPTY =
"CREATE_WORKSHEET_DATA_EMPTY" as const;
export const SET_WORKSHEET_API_DATA = "SET_WORKSHEET_API_DATA" as const;

export interface SetLoginAction {
  type: typeof SET_LOGIN;
  payload: React.ReactNode;
}

export interface SetLogoutAction {
  type: typeof SET_LOGOUT;
  payload: React.ReactNode;
}
