import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  MenuItem,
  Select,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import basicChildSupportService from "../../services/basicChildSupport-service";
import styles from "./basicChildSupport.module.css";

interface TableData {
  adjusted: number;
  one_child: number;
  two_children: number;
  three_children: number;
  four_children: number;
  five_children: number;
  six_children: number;
}

interface Column {
  id: keyof TableData;
  label: string;
}

const BasicChildSupport = () => {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalTableRecords, setTotalTableRecords] = useState(0);

  useEffect(() => {
    handleGetChildSupportObligationListAPI();
  }, [pageIndex, perPage]);

  const columns: Column[] = [
    { id: "adjusted", label: "Combined Monthly Adjusted Income" },
    { id: "one_child", label: "One Child" },
    { id: "two_children", label: "Two Children" },
    { id: "three_children", label: "Three Children" },
    { id: "four_children", label: "Four Children" },
    { id: "five_children", label: "Five Children" },
    { id: "six_children", label: "Six Children" },
  ];

  const handleGetChildSupportObligationListAPI = () => {
    const inputData = {
      pageIndex,
      perPage,
    };
    basicChildSupportService
      .getChildSupportObligationListAPI(inputData)
      .then((response) => {
        const finalResponse = response?.data;
        if (response && finalResponse.results.length > 0) {
          setTableData(finalResponse.results);
          setTotalTableRecords(finalResponse.count);
        } else {
          setTableData([]);
          setTotalTableRecords(0);
        }
      })
      .catch((error) => {
        console.error(error, "API Error");
      });
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    setPerPage(Number(event.target.value));
    setPageIndex(1);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageIndex(value);
  };

  const totalPages = Math.ceil(totalTableRecords / perPage);
  const startIndex = Math.min((pageIndex - 1) * perPage + 1, totalTableRecords);
  const endIndex = Math.min(pageIndex * perPage, totalTableRecords);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#404040",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
    border: "1px solid #DFDFDF",
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    border: "1px solid #DFDFDF",
  }));

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
        className="contain__area"
      >
        <Box sx={{ marginTop: "2rem" }}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: "5px",
              border: "1px solid #DFDFDF",
              margin: { lg: "10px 56px" },
              marginBlock: { xs: "10px" },
            }}
          >
            <Box>
              <Typography
                m={2}
                variant="h5"
                className="h5 "
                color="#0464AB;"
                gutterBottom
                sx={{
                  fontSize: 25,
                  letterSpacing: "1px",
                  marginBottom: "1px",
                }}
              >
                Basic Child Support Obligation Table
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={1}
                mx={2}
                flexWrap="wrap"
              >
                <Typography
                  sx={{ marginRight: { sm: "0px", md: "20px" } }}
                  variant="body1"
                >
                  Showing{" "}
                  <strong>
                    {startIndex}-{endIndex}
                  </strong>{" "}
                  of <strong>{totalTableRecords}</strong> items.
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography variant="body1" mr={1}>
                    Show
                  </Typography>
                  <Select
                    value={perPage}
                    onChange={handleRowsPerPageChange}
                    variant="outlined"
                    size="small"
                    sx={{ borderRadius: "50px" }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                  <Typography variant="body1" ml={1}>
                    entries
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={1}>
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  className={styles.BCSOTable}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell key={column.id}>
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((row) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.adjusted}
                      >
                        {columns.map((column) => {
                          return (
                            <StyledTableCell align="right" key={column.id}>
                              ${row[column.id]}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                my={1}
                display="flex"
                justifyContent={{
                  md: "space-between",
                  sm: "center",
                  xs: "center",
                }}
                flexWrap="wrap"
              >
                <Box>
                  <Typography sx={{ marginX: "20px" }} variant="body1">
                    Showing{" "}
                    <strong>
                      {startIndex}-{endIndex}
                    </strong>{" "}
                    of <strong>{"  " + totalTableRecords + " "}</strong>
                    items.
                  </Typography>
                </Box>
                <Box>
                  <Pagination
                    count={totalPages}
                    color="primary"
                    page={pageIndex}
                    onChange={handlePageChange}
                  />
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default BasicChildSupport;
