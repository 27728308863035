import React, { ChangeEvent, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { CreateWorksheetInitialState } from "../../types/create-worksheet";
import { useAppDispatch, useAppSelector } from "../../globalState/hooks";
import { getFieldName } from "../../utils/constants";
import CustomInput from "../inputField";
import {
  createWorksheetDataEmpty,
  setWorksheetAPIData,
  updateBasicInfo,
} from "../../globalState/actions/create-worksheet-action";
import CustomModal from "../customModal";
import ValidationErrorModal from "../validationErrorModal";
import createWorksheetService from "../../services/worksheet/create-worksheet-service";
import { showToast } from "../toastNotification";
import { useSelector } from "react-redux";
import {
  HeaderActiveState,
  RootState,
} from "../../utils/userDetailsCommonType";
import { useNavigate } from "react-router-dom";
import { headerActiveLink } from "../../globalState/actions/headerAction";
import CustomMessageModal from "../globalCustomMessageModal";
import moment from "moment";
import { SetWorksheetDataToRedux } from "../../utils/setWorksheetDataToRedux";

interface ErrorResponse {
  [key: string]:
    | {
        [subKey: string]: string[];
      }
    | string[];
}

const WorksheetButtons = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const workSheetStates = useAppSelector((state) => state.createWorksheet);

  const userDetails = useSelector(
    (state: RootState) => state.authReducer.loginDetials
  );
  const activeUrl = useSelector(
    (state: HeaderActiveState) => state.headerURLData.headerActiveURL
  );
  const CurrentPageName =
    activeUrl === "/WorkSheets/createWorksheet" ? "Save" : "Update";

  const isCreateWorksheet =
    activeUrl === "/WorkSheets/createWorksheet" ? true : false;
  const [openChildInfo, setOpenChildInfo] = useState<boolean>(false);
  const [inputValidationErrorModal, setInputValidationErrorModal] =
    useState<boolean>(false);
  const [isDisplayWorksheet, setIsDisplayWorksheet] = useState(false);
  const [inputValidationMessage, setInputValidationMessage] = useState("");
  const [cancelWorksheetModal, setCancelWorksheetModal] = useState(false);
  const [inputDisplayValidationMsg, setInputDisplayValidationMsg] =
    useState("");
  const [cancelDispalyWorksheetModal, setCancelDisplayWorksheetModal] =
    useState(false);

  const handleChildInfoClose = () => setOpenChildInfo(false);

  const handleChildInfoOpen = () => {
    setOpenChildInfo(true);
    setInputValidationMessage("");
  };

  /// handle close validation modal code
  const handleInputValidationErrorModal = () => {
    setInputValidationErrorModal(!inputValidationErrorModal);
    dispatch(updateBasicInfo("activeTab", "1"));
  };

  const handleInputValidationErrorModalCancel = () => {
    setInputValidationErrorModal(!inputValidationErrorModal);
  };

  /// handle close display validation modal code
  const handleInputDispalyValidationErrorModal = () => {
    setCancelDisplayWorksheetModal(!cancelDispalyWorksheetModal);
    dispatch(updateBasicInfo("activeTab", "2"));
  };
  const handleInputDispalyValidationErrorModalOnCancel = () => {
    setCancelDisplayWorksheetModal(!cancelDispalyWorksheetModal);
  };
  const validateFields = (fields: CreateWorksheetInitialState): boolean => {
    const fieldNames: (keyof CreateWorksheetInitialState)[] = [
      "court_id",
      "county_id",
      "plaintiff_name",
      "defendant_name",
      "parent_name_1",
      "parent_name_2",
      "add_child_to_worksheet",
    ];

    for (let i = 0; i < fieldNames.length; i++) {
      const field = fields[fieldNames[i]];

      if (
        (fieldNames[i] === "add_child_to_worksheet" &&
          Array.isArray(field) &&
          field.length === 0) ||
        field === ""
      ) {
        setInputValidationMessage(
          `${getFieldName(fieldNames[i])} is empty. Please fill it out.`
        );
        setInputValidationErrorModal(true);
        return false;
      }
    }
    return true;
  };

  /// handle input onchange
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    dispatch(updateBasicInfo(name, value));
    setInputValidationMessage("");
  };

  //// worksheet validation code for save data
  const saveWorksheetHandler = async (check: string) => {
    const checkFormValidation = validateFields(workSheetStates);
    // Check if validation passes
    if (checkFormValidation) {
      if (check === "save") {
        setIsDisplayWorksheet(false);
        handleChildInfoOpen();
        return true; // Validation and action succeeded
      } else {
        if (
          workSheetStates.total_gross_income1 ||
          workSheetStates.total_gross_income2
        ) {
          const ChildCountNo = workSheetStates?.add_child_to_worksheet
            ? workSheetStates?.add_child_to_worksheet?.filter(
                (child) => child.child_status === "excluded"
              ).length
            : 0;
          if (workSheetStates?.add_child_to_worksheet.length > ChildCountNo) {
            setIsDisplayWorksheet(true);
            handleChildInfoOpen();
          } else {
            setInputDisplayValidationMsg(
              "Displaying results requires one or more children."
            );
            setCancelDisplayWorksheetModal(true);
          }

          return true;
        } else {
          setInputDisplayValidationMsg(
            "Displaying Results Requires Either Parent To Have Gross Income."
          );
          setCancelDisplayWorksheetModal(true);
          return false;
        }
      }
    } else {
      return false;
    }
  };
  // Utility function to check the value
  function getValidValue(value: string) {
    return value !== "" && value !== "NaN" ? value : null;
  }
  ///  API calling for save worksheet data
  const submitHandler = async () => {
    if (workSheetStates.description) {
      const inputData = {
        court_id: Number(workSheetStates.court_id),
        county_id: Number(workSheetStates.county_id),
        plaintiff_name: workSheetStates.plaintiff_name,
        defendant_name: workSheetStates.defendant_name,
        non_parent_custodian_name: workSheetStates.non_parent_custodian_name,
        civil_action_case_number: workSheetStates.civil_action_case_number
          ? workSheetStates.civil_action_case_number
          : null,
        dhsc_no: workSheetStates.dhsc_no ? workSheetStates.dhsc_no : null,
        type_of_action: workSheetStates.type_of_action
          ? workSheetStates.type_of_action
          : null,
        initial_support_order_date: workSheetStates.initial_support_order_date
          ? moment(workSheetStates.initial_support_order_date).format(
              "YYYY-MM-DD"
            )
          : null,
        comments_for_the_court: workSheetStates.comments_for_the_court,
        parent_name_1: workSheetStates.parent_name_1,
        parent_name_2: workSheetStates.parent_name_2,
        non_custodial_parent: workSheetStates.non_custodial_parent,
        add_child_to_worksheet: workSheetStates.add_child_to_worksheet
          ? workSheetStates.add_child_to_worksheet.map((item) => {
              if (item.id && item.id.toString().length > 3) {
                return { ...item, id: null };
              }
              return item;
            })
          : [],
        number_of_included_children:
          workSheetStates.add_child_to_worksheet.length > 0
            ? workSheetStates.add_child_to_worksheet.filter(
                (item) => item.child_status === "included"
              ).length
            : 0,
        social_security_payment_1: workSheetStates.social_security_payment_1,
        social_security_payment_2: workSheetStates.social_security_payment_2,
        veterans_affairs_disability_payment_1:
          workSheetStates.veterans_affairs_disability_payment_1,
        veterans_affairs_disability_payment_2:
          workSheetStates.veterans_affairs_disability_payment_2,
        uninsured_health_expenses_1:
          workSheetStates.uninsured_health_expenses_1,
        uninsured_health_expenses_2:
          workSheetStates.uninsured_health_expenses_2,
        prepared_by: Number(userDetails?.id),
        updated_by: Number(userDetails?.id),
        make_available_to_court_by: workSheetStates.make_available_to_court_by,
        make_available_to_court: false,

        //// Gross Income Schedule A Tab
        gross_income_schedule_a: {
          tanf1: workSheetStates.tanf1,
          tanf2: workSheetStates.tanf2,
          gross_salary1: getValidValue(workSheetStates.gross_salary1),
          gross_salary2: getValidValue(workSheetStates.gross_salary2),
          commissions1: getValidValue(workSheetStates.commissions1),
          commissions2: getValidValue(workSheetStates.commissions2),
          self_employment_income1: getValidValue(
            workSheetStates.self_employment_income1
          ),
          self_employment_income2: getValidValue(
            workSheetStates.self_employment_income2
          ),
          bonuses1: getValidValue(workSheetStates.bonuses1),
          bonuses2: getValidValue(workSheetStates.bonuses2),
          overtime_payment1: getValidValue(workSheetStates.overtime_payment1),
          overtime_payment2: getValidValue(workSheetStates.overtime_payment2),
          severence_pay1: getValidValue(workSheetStates.severence_pay1),
          severence_pay2: getValidValue(workSheetStates.severence_pay2),
          recurring_income1: getValidValue(workSheetStates.recurring_income1),
          recurring_income2: getValidValue(workSheetStates.recurring_income2),
          interest_income1: getValidValue(workSheetStates.interest_income1),
          interest_income2: getValidValue(workSheetStates.interest_income2),
          income_from_dividends1: getValidValue(
            workSheetStates.income_from_dividends1
          ),
          income_from_dividends2: getValidValue(
            workSheetStates.income_from_dividends2
          ),
          trust_income1: getValidValue(workSheetStates.trust_income1),
          trust_income2: getValidValue(workSheetStates.trust_income2),
          income_from_annuities1: getValidValue(
            workSheetStates.income_from_annuities1
          ),
          income_from_annuities2: getValidValue(
            workSheetStates.income_from_annuities2
          ),
          capital_gains1: getValidValue(workSheetStates.capital_gains1),
          capital_gains2: getValidValue(workSheetStates.capital_gains2),
          social_security_disablity_benefits1: getValidValue(
            workSheetStates.social_security_disablity_benefits1
          ),
          social_security_disablity_benefits2: getValidValue(
            workSheetStates.social_security_disablity_benefits2
          ),
          federal_veterans_disability_benefits1: getValidValue(
            workSheetStates.federal_veterans_disability_benefits1
          ),
          federal_veterans_disability_benefits2: getValidValue(
            workSheetStates.federal_veterans_disability_benefits2
          ),
          workers_compensation_benefits1: getValidValue(
            workSheetStates.workers_compensation_benefits1
          ),
          workers_compensation_benefits2: getValidValue(
            workSheetStates.workers_compensation_benefits2
          ),
          unemployment_benefits1: getValidValue(
            workSheetStates.unemployment_benefits1
          ),
          unemployment_benefits2: getValidValue(
            workSheetStates.unemployment_benefits2
          ),
          judgements1: getValidValue(workSheetStates.judgements1),
          judgements2: getValidValue(workSheetStates.judgements2),
          gifts1: getValidValue(workSheetStates.gifts1),
          gifts2: getValidValue(workSheetStates.gifts2),
          prizes1: getValidValue(workSheetStates.prizes1),
          prizes2: getValidValue(workSheetStates.prizes2),
          alimony1: getValidValue(workSheetStates.alimony1),
          alimony2: getValidValue(workSheetStates.alimony2),
          assets1: getValidValue(workSheetStates.assets1),
          assets2: getValidValue(workSheetStates.assets2),
          fringe_benefits1: getValidValue(workSheetStates.fringe_benefits1),
          fringe_benefits2: getValidValue(workSheetStates.fringe_benefits2),
          other_income1: getValidValue(workSheetStates.other_income1),
          other_income2: getValidValue(workSheetStates.other_income2),
          total_gross_income1: workSheetStates.total_gross_income1,
          total_gross_income2: workSheetStates.total_gross_income2,
          comment1: workSheetStates.comment1,
          comment2: workSheetStates.comment2,
        },
        //// Adjusted Income Schedule B Tab
        adjusted_income_schedule_b: {
          monthly_self_employment_income1:
            workSheetStates.monthly_self_employment_income1,
          monthly_self_employment_income2:
            workSheetStates.monthly_self_employment_income2,
          pre_existing_child_support: workSheetStates.pre_existing_child_support
            ? workSheetStates.pre_existing_child_support.map((item) => {
                const formattedDate = item.date_of_initial_order
                  ? moment(item.date_of_initial_order).format("YYYY-MM-DD")
                  : null;

                return {
                  ...item,
                  id: item.id && item.id.toString().length > 3 ? null : item.id,
                  date_of_initial_order: formattedDate,
                  time_of_initial_order: item.time_of_initial_order || null,
                };
              })
            : [],
          preexisting_child_support_amount1:
            workSheetStates.pre_existing_child_support.length > 0
              ? workSheetStates.pre_existing_child_support?.reduce(
                  (acc, item) => {
                    return (
                      acc + Number(item.preexisting_child_support_paid_monthly1)
                    );
                  },
                  0
                ) || 0
              : "",
          preexisting_child_support_amount2:
            workSheetStates.pre_existing_child_support.length > 0
              ? workSheetStates.pre_existing_child_support?.reduce(
                  (acc, item) => {
                    return (
                      acc + Number(item.preexisting_child_support_paid_monthly2)
                    );
                  },
                  0
                ) || 0
              : "",
          proposed_adjustment_for_qualified_child:
            workSheetStates.proposed_adjustment_for_qualified_child
              ? workSheetStates.proposed_adjustment_for_qualified_child.map(
                  (item) => {
                    if (item.id && item.id.toString().length > 3) {
                      return { ...item, id: null };
                    }
                    return item;
                  }
                )
              : [],
          proposed_adjustment_to_income1:
            workSheetStates.proposed_adjustment_for_qualified_child.length > 0
              ? workSheetStates.proposed_adjustment_for_qualified_child?.reduce(
                  (acc, item) => {
                    return acc + Number(item.parentName1);
                  },
                  0
                ) || 0
              : "",
          proposed_adjustment_to_income2:
            workSheetStates.proposed_adjustment_for_qualified_child.length > 0
              ? workSheetStates.proposed_adjustment_for_qualified_child?.reduce(
                  (acc, item) => {
                    return acc + Number(item.parentName2);
                  },
                  0
                ) || 0
              : "",
          reason_for_including_other_child1:
            workSheetStates.reason_for_including_other_child1,
          reason_for_including_other_child2:
            workSheetStates.reason_for_including_other_child2,
        },
        //// Health Insurance Child Care Schedule D Tab
        health_insurance_child_care_schedule_d: {
          monthly_total1: workSheetStates.monthly_total1,
          monthly_total2: workSheetStates.monthly_total2,
          monthly_total3: workSheetStates.monthly_total3,
          work_related_child_care: workSheetStates.work_related_child_care
            ? workSheetStates.work_related_child_care.map((item) => {
                if (item.id && item.id.toString().length > 3) {
                  return { ...item, id: null };
                }
                return item;
              })
            : [],
          health_insurance_premiums_parent1:
            workSheetStates.health_insurance_premiums_parent1,
          health_insurance_premiums_parent2:
            workSheetStates.health_insurance_premiums_parent2,
          health_insurance_premiums_parent3:
            workSheetStates.health_insurance_premiums_parent3,
        },
        //// Deviations Schedule E Tab
        deviations_schedule_e: {
          proposed_low_income_deviation:
            workSheetStates.proposed_low_income_deviation
              ? workSheetStates.proposed_low_income_deviation.map((item) => {
                  if (item.id && item.id.toString().length > 3) {
                    return { ...item, id: null };
                  }
                  return item;
                })
              : [],
          judicial_discretion_for_low_income:
            workSheetStates.judicial_discretion_for_low_income
              ? workSheetStates.judicial_discretion_for_low_income.map(
                  (item) => {
                    if (item.id && item.id.toString().length > 3) {
                      return { ...item, id: null };
                    }
                    return item;
                  }
                )
              : [],
          specific_nonspecific_deviation:
            workSheetStates.specific_nonspecific_deviation
              ? workSheetStates.specific_nonspecific_deviation.map((item) => {
                  if (item.id && item.id.toString().length > 3) {
                    return { ...item, id: null };
                  }
                  return item;
                })
              : [],
          parenting_time_deviation1: workSheetStates.parenting_time_deviation1,
          parenting_time_deviation2: workSheetStates.parenting_time_deviation2,
          extra_ordinary_educational_expenses:
            workSheetStates.extra_ordinary_educational_expenses
              ? workSheetStates.extra_ordinary_educational_expenses.map(
                  (item) => {
                    if (item.id && item.id.toString().length > 3) {
                      return { ...item, id: null };
                    }
                    return item;
                  }
                )
              : [],
          extraordinary_educational_expenses_total1:
            workSheetStates.extraordinary_educational_expenses_total1,
          extraordinary_educational_expenses_total2:
            workSheetStates.extraordinary_educational_expenses_total2,
          extraordinary_educational_expenses_total3:
            workSheetStates.extraordinary_educational_expenses_total3,
          extra_ordinary_medical_expenses:
            workSheetStates.extra_ordinary_medical_expenses
              ? workSheetStates.extra_ordinary_medical_expenses.map((item) => {
                  if (item.id && item.id.toString().length > 3) {
                    return { ...item, id: null };
                  }
                  return item;
                })
              : [],
          extraordinary_medical_expenses_total1:
            workSheetStates.extraordinary_medical_expenses_total1,
          extraordinary_medical_expenses_total2:
            workSheetStates.extraordinary_medical_expenses_total2,
          extraordinary_medical_expenses_total3:
            workSheetStates.extraordinary_medical_expenses_total3,
          special_expenses_child_rearing:
            workSheetStates.special_expenses_child_rearing
              ? workSheetStates.special_expenses_child_rearing.map((item) => {
                  if (item.id && item.id.toString().length > 3) {
                    return { ...item, id: null };
                  }
                  return item;
                })
              : [],
          special_expenses_child_rearing_total1:
            workSheetStates.special_expenses_child_rearing_total1,
          special_expenses_child_rearing_total2:
            workSheetStates.special_expenses_child_rearing_total2,
          special_expenses_child_rearing_total3:
            workSheetStates.special_expenses_child_rearing_total3,
          unjust_or_inappropriate: workSheetStates.unjust_or_inappropriate,
          best_interests_of_children:
            workSheetStates.best_interests_of_children,
          impair_ability_to_maintain:
            workSheetStates.impair_ability_to_maintain,
        },
        //// Self Employment Calculator Tab
        self_employment_calculator: {
          business_description1: workSheetStates.business_description1,
          business_description2: workSheetStates.business_description2,
          business_name1: workSheetStates.business_name1,
          business_name2: workSheetStates.business_name2,
          business_type1: workSheetStates.business_type1,
          business_type2: workSheetStates.business_type2,
          gross_receipts1: getValidValue(workSheetStates.gross_receipts1),
          gross_receipts2: getValidValue(workSheetStates.gross_receipts2),
          cost_of_sales1: getValidValue(workSheetStates.cost_of_sales1),
          cost_of_sales2: getValidValue(workSheetStates.cost_of_sales2),
          equals_gross_profit1: getValidValue(
            workSheetStates.equals_gross_profit1
          ),
          equals_gross_profit2: getValidValue(
            workSheetStates.equals_gross_profit2
          ),
          a_compensation_to_owner1: getValidValue(
            workSheetStates.a_compensation_to_owner1
          ),
          a_compensation_to_owner2: getValidValue(
            workSheetStates.a_compensation_to_owner2
          ),
          other_salaries_wages1: getValidValue(
            workSheetStates.other_salaries_wages1
          ),
          other_salaries_wages2: getValidValue(
            workSheetStates.other_salaries_wages2
          ),
          advertising_promotion1: getValidValue(
            workSheetStates.advertising_promotion1
          ),
          advertising_promotion2: getValidValue(
            workSheetStates.advertising_promotion2
          ),
          car_truck_expenses1: getValidValue(
            workSheetStates.car_truck_expenses1
          ),
          car_truck_expenses2: getValidValue(
            workSheetStates.car_truck_expenses2
          ),
          depreciation1: getValidValue(workSheetStates.depreciation1),
          depreciation2: getValidValue(workSheetStates.depreciation2),
          employee_benefits1: getValidValue(workSheetStates.employee_benefits1),
          employee_benefits2: getValidValue(workSheetStates.employee_benefits2),
          insurance_business1: getValidValue(
            workSheetStates.insurance_business1
          ),
          insurance_business2: getValidValue(
            workSheetStates.insurance_business2
          ),
          interest1: getValidValue(workSheetStates.interest1),
          interest2: getValidValue(workSheetStates.interest2),
          office_supplies_expenses1: getValidValue(
            workSheetStates.office_supplies_expenses1
          ),
          office_supplies_expenses2: getValidValue(
            workSheetStates.office_supplies_expenses2
          ),
          rent_or_lease_building1: getValidValue(
            workSheetStates.rent_or_lease_building1
          ),
          rent_or_lease_building2: getValidValue(
            workSheetStates.rent_or_lease_building2
          ),
          rent_or_lease_equipment1: getValidValue(
            workSheetStates.rent_or_lease_equipment1
          ),
          rent_or_lease_equipment2: getValidValue(
            workSheetStates.rent_or_lease_equipment2
          ),
          taxes_licenses1: getValidValue(workSheetStates.taxes_licenses1),
          taxes_licenses2: getValidValue(workSheetStates.taxes_licenses2),
          travel_entertainment1: getValidValue(
            workSheetStates.travel_entertainment1
          ),
          travel_entertainment2: getValidValue(
            workSheetStates.travel_entertainment2
          ),
          utilities1: getValidValue(workSheetStates.utilities1),
          utilities2: getValidValue(workSheetStates.utilities2),
          other_expenses1: getValidValue(workSheetStates.other_expenses1),
          other_expenses2: getValidValue(workSheetStates.other_expenses2),
          explanation_other_expenses:
            workSheetStates.explanation_other_expenses,
          total_business_expenses1: workSheetStates.total_business_expenses1,
          total_business_expenses2: workSheetStates.total_business_expenses2,
          net_income1: workSheetStates.net_income1,
          net_income2: workSheetStates.net_income2,
          compensation_to_owner1: workSheetStates.compensation_to_owner1,
          compensation_to_owner2: workSheetStates.compensation_to_owner2,
          excessive_expenses1: getValidValue(
            workSheetStates.excessive_expenses1
          ),
          excessive_expenses2: getValidValue(
            workSheetStates.excessive_expenses2
          ),
          home_office_expenses1: getValidValue(
            workSheetStates.home_office_expenses1
          ),
          home_office_expenses2: getValidValue(
            workSheetStates.home_office_expenses2
          ),
          equipment_depreciation1: getValidValue(
            workSheetStates.equipment_depreciation1
          ),
          equipment_depreciation2: getValidValue(
            workSheetStates.equipment_depreciation2
          ),
          other1: getValidValue(workSheetStates.other1),
          other2: getValidValue(workSheetStates.other2),
          explanation_non_deductible_expenses:
            workSheetStates.explanation_non_deductible_expenses,
          total_non_deductible_expenses1:
            workSheetStates.total_non_deductible_expenses1,
          total_non_deductible_expenses2:
            workSheetStates.total_non_deductible_expenses2,
          total_self_employment_income1:
            workSheetStates.total_self_employment_income1,
          total_self_employment_income2:
            workSheetStates.total_self_employment_income2,
          notes_self_employment_income:
            workSheetStates.notes_self_employment_income,
        },
        //// Foot Note Tab
        foot_note: workSheetStates.foot_note
          ? workSheetStates.foot_note.map((item) => {
              if (item.id && item.id.toString().length > 3) {
                return { ...item, id: null };
              }
              return item;
            })
          : [],
        description: workSheetStates.description,
        version_count: workSheetStates.version_count,
      };
      const worksheetID = isCreateWorksheet ? "" : workSheetStates.worksheet_id;
      createWorksheetService
        .createUpdateWorksheetBasicInfoAPI(inputData, Number(worksheetID))
        .then((response) => {
          if (response) {
            if (
              response.data.message === "Worksheet created successfully" ||
              response.data.message === "Worksheet updated successfully"
            ) {
              showToast(response.data.message, "success");
              setOpenChildInfo(false);
              if (isDisplayWorksheet) {
                navigate(`/searchWorksheet/${response.data.data.worksheet_id}`);
                dispatch(headerActiveLink(""));
              } else {
                const finalWorksheetData = SetWorksheetDataToRedux(
                  response.data.data
                );
                dispatch(setWorksheetAPIData(finalWorksheetData));
                setTimeout(() => {
                  navigate(
                    `/WorkSheets/updateWorksheet/${response.data.data.worksheet_id}`
                  );
                  dispatch(headerActiveLink("/WorkSheets/updateWorksheet"));
                }, 500);
              }
            } else {
              showToast(response.data.message, "error");
            }
          }
        })
        .catch((error) => {
          const errorData: ErrorResponse = error?.response?.data || {};
          let firstErrorMessage: string = "";

          for (const key in errorData) {
            if (Object.prototype.hasOwnProperty.call(errorData, key)) {
              const subErrors = errorData[key];
              if (Array.isArray(subErrors)) {
                firstErrorMessage = `${key}: ${JSON.stringify(subErrors)}`;
                break;
              } else {
                for (const subKey in subErrors) {
                  if (Object.prototype.hasOwnProperty.call(subErrors, subKey)) {
                    firstErrorMessage = `${key}: ${subKey}: ${JSON.stringify(
                      subErrors[subKey]
                    )}`;
                    break;
                  }
                }
              }
              break;
            }
          }
          if (firstErrorMessage) {
            showToast(firstErrorMessage, "error");
          }
        });
    } else {
      setInputValidationMessage("Worksheet Description cannot be blank.");
    }
  };

  //// handle worksheet cancel modal close
  const handleCancelWorksheetModalClose = () => {
    setCancelWorksheetModal(false);
  };
  //// handle worksheet cancel modal open
  const handleCancelWorksheetModal = () => {
    setCancelWorksheetModal(true);
  };
  /// cancel worksheet data function
  const handleCancelWorksheetData = () => {
    dispatch(createWorksheetDataEmpty());
    dispatch(headerActiveLink("/searchFolderWorksheet"));
    navigate("/searchFolderWorksheet");
  };
  return (
    <>
      <Grid item xs={12} md={3} sx={{ py: 2, width: "70%" }}>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="center"
          sx={{ alignItems: { sm: "center" } }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "20px",
              p: 1,
              m: 1,
              width: { xs: "100%", md: "100%" },
              background: "#0464AB",
              fontWeight: 600,
              border: "1px solid #0464AB",
              textTransform: "none",
              minWidth: "fit-content",
              maxWidth: { xs: "none", md: "250px" },
              "&:hover": {
                backgroundColor: "#FBB522",
                border: "1px solid #FBB522",
                color: "#022E4C",
              },
            }}
            onClick={() => saveWorksheetHandler("save")}
          >
            {CurrentPageName} Worksheet
          </Button>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "20px",
              p: 1,
              m: 1,
              color: "#0464AB",
              fontWeight: 600,
              border: "1px solid #0464AB",
              textTransform: "none",
              width: { xs: "100%", md: "100%" },
              minWidth: "fit-content",
              maxWidth: { xs: "none", md: "250px" },
              "&:hover": {
                backgroundColor: "#FBB522",
                border: "1px solid #FBB522",
                color: "#022E4C",
              },
            }}
            onClick={() => handleCancelWorksheetModal()}
          >
            Cancel Worksheet
          </Button>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "20px",
              p: 1,
              m: 1,
              color: "#0464AB",
              fontWeight: 600,
              border: "1px solid #0464AB",
              textTransform: "none",
              width: { xs: "100%", md: "100%" },
              minWidth: "fit-content",
              maxWidth: { xs: "none", md: "250px" },
              "&:hover": {
                backgroundColor: "#FBB522",
                border: "1px solid #FBB522",
                color: "#022E4C",
              },
            }}
            onClick={async () => {
              const isValid = await saveWorksheetHandler("save"); // Call validation
              if (isValid) {
                navigate("/searchFolderWorksheet"); // Only navigate if validation passes
              }
            }}
          >
            {CurrentPageName} & Close Worksheet
          </Button>
          <Button
            variant="outlined"
            sx={{
              borderRadius: "20px",
              p: 1,
              m: 1,
              color: "#0464AB",
              fontWeight: 600,
              border: "1px solid #0464AB",
              textTransform: "none",
              width: { xs: "100%", md: "100%" },
              minWidth: "fit-content",
              "&:hover": {
                backgroundColor: "#FBB522",
                border: "1px solid #FBB522",
                color: "#022E4C",
              },
            }}
            onClick={() => saveWorksheetHandler("display")}
          >
            Display Worksheet Results
          </Button>
        </Box>
      </Grid>
      <CustomModal
        open={openChildInfo}
        onClose={handleChildInfoClose}
        save={submitHandler}
        title="Enter Worksheet Description:"
        actionButtonLabel="Save"
      >
        <Box sx={{ pt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <CustomInput
                name="description"
                label="Enter Worksheet Description"
                value={workSheetStates.description}
                onChange={handleInputChange}
                placeholder="Enter Description"
                required
                error={inputValidationMessage}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></Grid>
          </Grid>
        </Box>
      </CustomModal>

      {/* validation Error Modal code */}
      <ValidationErrorModal
        onClose={handleInputValidationErrorModal}
        onCancel={handleInputValidationErrorModalCancel}
        open={inputValidationErrorModal}
        title="octestphp.georgiacourts.gov says"
        descriptionMessage={inputValidationMessage}
      />

      {/* validation Error Modal for Display code */}
      <ValidationErrorModal
        onClose={handleInputDispalyValidationErrorModal}
        open={cancelDispalyWorksheetModal}
        onCancel={handleInputDispalyValidationErrorModalOnCancel}
        title="octestphp.georgiacourts.gov says"
        descriptionMessage={inputDisplayValidationMsg}
      />

      {/* Custom modal validation pop-up code */}
      <CustomMessageModal
        onClose={handleCancelWorksheetModalClose}
        open={cancelWorksheetModal}
        submit={handleCancelWorksheetData}
        title="Cancel Worksheet"
        descriptionMessage="Are you sure you want to quit this page without saving changes?"
        buttonName="Ok"
      />
    </>
  );
};

export default WorksheetButtons;
