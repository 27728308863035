import React, { ChangeEvent, useState, useRef } from "react";
import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  SelectChangeEvent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Link,
} from "@mui/material";
import CustomDropdown from "../../../components/drop-down";
import TextareaField from "../../../components/text-area";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";
import {
  footNotesSchedule,
  footNotesScheduleLinesNo,
} from "../../../utils/constants";
import { useAppDispatch, useAppSelector } from "../../../globalState/hooks";
import {
  deleteFromArray,
  updateArrayInfo,
  updateBasicInfo,
} from "../../../globalState/actions/create-worksheet-action";
import { Footnote } from "../../../types/create-worksheet";
import WorksheetButtons from "../../../components/worksheetButtons";
import SettingIcons from "../../../assets/images/SettingIcon.svg";
import styles from "./footnotes.module.css";
import style from "../workSheet.module.css";
import CustomModal from "../../../components/customModal";
import ReportTechnicalForm, {
  ReportTechnicalFormHandle,
} from "../../../components/reportTechnicalIssue";
import { GenerateCaptcha } from "../../../utils/generateCaptcha";
import technicalReportService from "../../../services/technicalReport-service";
import { showToast } from "../../../components/toastNotification";
import DeleteModal from "../../../components/globalDeleteModal";

interface TechnicalReportInput {
  worksheet_id: string;
  email: string;
  browser: string;
  name: string;
  phone: string;
  technical_issue: string;
}
interface InputErrors {
  line_no?: string;
  schedule?: string;
  footnote?: string;
}

const FootnotesTab: React.FC = () => {
  const dispatch = useAppDispatch();
  const formRef = useRef<ReportTechnicalFormHandle>(null);
  const workSheetStates = useAppSelector((state) => state.createWorksheet);

  const [inputValues, setInputValues] = useState({
    footnote: "",
    schedule: "",
    line_no: "",
  });
  const [isEdit, setIsEdit] = useState("");
  const [openAddFootnote, setOpenAddFootnote] = useState(false);
  const [inputCaptcha, setInputCaptcha] = useState("");
  const [reportTechnicalModal, setReportTechnicalModal] =
    useState<boolean>(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [inputErrors, setInputErrors] = useState<InputErrors>({});

  const handleReportModalOpen = () => {
    setInputCaptcha(GenerateCaptcha);
    setReportTechnicalModal(true);
  };

  const handleReportModalClose = () => setReportTechnicalModal(false);
  const handleOpenAddFootnote = () => setOpenAddFootnote(true);
  const handleCloseAddFootnote = () => {
    setOpenAddFootnote(false);
    // Reset the input values and isEdit state when the modal is closed
    setInputValues({
      footnote: "",
      schedule: "",
      line_no: "",
    });
    setIsEdit("");
  };

  const handleFootnoteChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | SelectChangeEvent
      | ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setInputValues((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setInputErrors({
      ...inputErrors,
      [name]: "",
    });
  };

  const setModalDataEmpty = () => {
    handleCloseAddFootnote();
    setInputValues((prev) => {
      return {
        ...prev,
        footnote: "",
        schedule: "",
        line_no: "",
      };
    });
    setIsEdit("");
  };

  const addChildToWorksheet = () => {
    const { line_no, schedule, footnote } = inputValues;

    const errors: InputErrors = {};

    if (line_no === "") {
      errors.line_no = "Line No. is required";
    }
    if (schedule === "") {
      errors.schedule = "Schedule is required";
    }
    if (footnote === "") {
      errors.footnote = "Footnote is required";
    }

    if (Object.keys(errors).length > 0) {
      setInputErrors(errors);
    } else {
      const newchiildData = {
        arrayKey: "foot_note",
        itemId: isEdit ? isEdit : "",
        newItemValues: {
          schedule: schedule,
          line_no: line_no,
          footnote: footnote,
        },
      };
      dispatch(updateArrayInfo(newchiildData));
      setModalDataEmpty();
      setInputErrors({});
    }
  };

  /// delete footnotes modal open
  const deletChildDataModalOpen = (id: string) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  ///delete footnotes data
  const handleDeletFootnotsData = () => {
    dispatch(
      deleteFromArray({
        arrayKey: "foot_note",
        itemId: deleteId,
      })
    );
    setDeleteModal(false);
  };
  const editChildDataHandler = (item: Footnote) => {
    setIsEdit(item.id!);
    setInputValues((prev) => {
      return {
        ...prev,
        footnote: item.footnote,
        schedule: item.schedule,
        line_no: item.line_no,
      };
    });
    handleOpenAddFootnote();
  };
  const handleSaveData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };
  /// handle modal close
  const handleDeleteMdlClose = () => {
    setDeleteModal(false);
  };
  ///-------------API calling Function start-------------------
  /// APi called for Technical report issue
  const handleSubmitTechnicalReport = async (
    reportInputValues: TechnicalReportInput
  ) => {
    const inputData = {
      worksheet_id: Number(reportInputValues.worksheet_id),
      name: reportInputValues.name !== "" ? reportInputValues.name : null,
      email: reportInputValues.email,
      phone:
        reportInputValues.phone !== "" ? Number(reportInputValues.phone) : null,
      browser:
        reportInputValues.browser !== "" ? reportInputValues.browser : null,
      technical_issue: reportInputValues.technical_issue,
    };
    await technicalReportService
      .reportTechnicalIssueAPI(inputData)
      .then((response) => {
        if (response && response.data) {
          showToast(response.data.message, "success");
          setReportTechnicalModal(false);
        } else {
          showToast(response?.data?.message, "error");
        }
      })
      .catch((error) => {
        console.error(error, "API Error");
      });
  };
  ///-------------API calling Function end-------------------
  return (
    <>
      <Card
        elevation={0}
        sx={{
          bgcolor: "#E6F5FF",
          border: "1px solid #0464AB80",
          borderRadius: "20px 20px",
          opacity: 1,
        }}
      >
        <Typography sx={{ px: 2, py: 1 }} color="#404040" fontSize={14}>
          <Box sx={{ p: "4px" }}>
            Information entered in this section will display as Footnotes. The
            Footnotes page is not filed with the court and is provided as a
            means to record useful details on the facts and documents utilized
            to prepare the child support worksheet.
          </Box>
        </Typography>
      </Card>
      <Box sx={{ pt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                onClick={handleOpenAddFootnote}
                variant="outlined"
                className={style.globalButtonStyle}
              >
                Add Footnote
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CustomModal
        title={isEdit ? "Edit Footnote" : "Add Footnote"}
        open={openAddFootnote}
        onClose={handleCloseAddFootnote}
        save={addChildToWorksheet}
        actionButtonLabel="Save"
        borderBottom="0px"
        pb="0px"
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#404040",
          }}
        >
          <strong>SECTION 1. Add Footnote</strong>
        </Typography>
        <Typography
          gutterBottom
          id="modal-modal-description"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            borderBottom: "1px solid #DFDFDF",
            p: 1,
          }}
        >
          Enter each Footnote separately. Click in the &apos;Schedule&apos;
          field and select the Worksheet or Schedule name from the drop-down
          list. Click in the &apos;Line No.&apos; field and select the number
          from the drop-down list representing the line on the Worksheet or
          Schedule pertaining to the Footnote. Click in the &apos;Footnote&apos;
          field and enter notes and facts related to the worksheet. Click the
          &apos;Save&apos; button to save the entry, or the &apos;Cancel&apos;
          button to cancel the entry.
        </Typography>
        <Box sx={{ pt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CustomDropdown
                name="schedule"
                label="Schedule"
                value={inputValues.schedule}
                onChange={handleFootnoteChange}
                options={footNotesSchedule}
                placeholder="- Choose Schedule -"
                required
                error={inputErrors.schedule}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomDropdown
                name="line_no"
                label="Line No."
                value={inputValues.line_no}
                onChange={handleFootnoteChange}
                options={footNotesScheduleLinesNo}
                placeholder="- Choose Line No -"
                required
                error={inputErrors.line_no}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextareaField
                name="footnote"
                label="Footnote"
                value={inputValues.footnote}
                onChange={handleFootnoteChange}
                placeholder="Write Issue"
                minRows={2}
                required
                error={inputErrors.footnote}
              />
            </Grid>
          </Grid>
        </Box>
      </CustomModal>

      <Box sx={{ pt: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  className={style.headerStyle}
                  sx={{ bgcolor: "#e6f5ff" }}
                  align="left"
                >
                  Schedule
                </TableCell>
                <TableCell
                  className={style.headerStyle}
                  sx={{ bgcolor: "#e6f5ff" }}
                  align="left"
                >
                  Line Number
                </TableCell>
                <TableCell
                  className={style.headerStyle}
                  sx={{ bgcolor: "#e6f5ff" }}
                  align="left"
                >
                  Footnotes
                </TableCell>
                <TableCell
                  align="left"
                  className={styles.sticky}
                  sx={{ bgcolor: "#e6f5ff" }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workSheetStates.foot_note?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    className={style.cellStyle}
                    component="th"
                    scope="row"
                    style={{ textTransform: "capitalize" }}
                  >
                    {row.schedule}
                  </TableCell>
                  <TableCell className={style.cellStyle} align="left">
                    {row.line_no}
                  </TableCell>
                  <TableCell className={style.firstColumnStyle} align="left">
                    {row.footnote}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={`${style.sticky} ${style.cellStyle}`}
                    // className={style.sticky}
                  >
                    <Box style={{ backgroundColor: "white", display: "flex" }}>
                      {" "}
                      <EditIcon
                        height={13}
                        style={{ cursor: "pointer" }}
                        onClick={() => editChildDataHandler(row)}
                      />
                      <DeleteIcon
                        height={13}
                        style={{ marginLeft: "10px", cursor: "pointer" }}
                        onClick={() => deletChildDataModalOpen(row.id!)}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <WorksheetButtons />
      </Box>
      <Grid xs={12} md={12}>
        <Grid
          container
          sx={{
            mb: 0,
            borderTop: "2px solid #DFDFDF",
            p: 1,
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} md={6} sm={6}>
            <Box
              onClick={() => {}}
              display="flex"
              sx={{
                justifyContent: {
                  sm: "flex-start",
                  xs: "center",
                  md: "flex-start",
                },
                marginTop: "3%",
              }}
            >
              <img src={SettingIcons} alt="img" style={{ height: "15px" }} />
              <Link
                onClick={handleReportModalOpen}
                sx={{
                  marginLeft: "5px",
                  color: "#147AFF",
                  textDecoration: "underline",
                }}
              >
                Report Technical Issue
              </Link>
              <CustomModal
                open={reportTechnicalModal}
                onClose={handleReportModalClose}
                actionButtonLabel="Save"
                title="Report Technical Issue"
                save={handleSaveData}
                borderBottom="0px"
                pb="0px"
              >
                <ReportTechnicalForm
                  ref={formRef}
                  inputCaptcha={inputCaptcha}
                  handleSubmitTechnicalReport={handleSubmitTechnicalReport}
                />
              </CustomModal>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <Box
              sx={{
                py: 1,
                display: "flex",
                justifyContent: {
                  sm: "flex-end",
                  xs: "center",
                  md: "flex-end",
                },
              }}
            >
              <Button
                variant="outlined"
                className={style.globalButtonStyle}
                onClick={() => {
                  dispatch(updateBasicInfo("activeTab", "2"));
                }}
              >
                <Typography sx={{ px: "4px", pb: "2px" }}>{"<"}</Typography>
                Back Step
              </Button>
            </Box>
          </Grid>
        </Grid>
        <DeleteModal
          onClose={handleDeleteMdlClose}
          open={deleteModal}
          delete={handleDeletFootnotsData}
          title="Delete Footnote Records"
          deleteMessage="Are you sure you want to remove the footnote"
        />
      </Grid>
    </>
  );
};

export default FootnotesTab;
