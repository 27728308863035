import { SET_HEADERLINK } from "../actions-Types/authActionTypes";

export const headerActiveLink = (value: string) => ({
  type: SET_HEADERLINK,
  payload: value
});
export const headerNullData = () => ({
  type: SET_HEADERLINK,
  payload: ""
});
