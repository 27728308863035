import React from "react";
import "./App.css";
import RouteStart from "./routing";
import 'material-design-icons/iconfont/material-icons.css';

const App: React.FC = () => {
  return <RouteStart />;
};

export default App;
