import React, { useState, useEffect, ChangeEvent } from "react";
import styles from "../../pages/worksheet/workSheet.module.css";
import styles1 from "./userinfo.module.css";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  TextField,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { ReactComponent as FileExporticon } from "../../assets/images/file-export.svg";
import ExportFileDropdown from "../../components/exportFileDropDown";
import CustomDropdown from "../../components/drop-down";
import CustomModal from "../../components/customModal";
import CustomInput from "../../components/inputField";
import userInformationService from "../../services/userInformation-service";
import ExportOptionData from "../../components/exportDataSelection";
import { showToast } from "../../components/toastNotification";
import { getFileName } from "../../utils/fileNameUtil";
import { headerActiveLink } from "../../globalState/actions/headerAction";
import { DropDown } from "../../types/global";

interface Column {
  id: keyof Data;
  label: string;
}

interface Data {
  key: keyof Data;
  no: number;
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  message: string;
  role_name: string;
  user_type_name: string;
  action: string | JSX.Element;
}
interface UserRoleData {
  id: number;
  role_name: string;
}
interface UserTypeData {
  id: number;
  user_type_name: string;
}
interface UserTypeDetails {
  id: number;
  role_name: string;
}

interface ApiResponse<T> {
  data: T;
}
interface TableSearchData {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  message: string;
  role_name: string;
  user_type_name: string;
  roleID?: number;
  userTypeId?: number;
  organization_description?: string | null;
}

interface UserTableData {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  message: string;
  role_name: string;
  user_type_name: string;
  organization_description: string | null;
}
interface UserTableApiResponse {
  message: string;
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: UserTableData[];
  };
}

const StyledTextField = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    borderRadius: "25px",
    padding: "0 8px",
    backgroundColor: "",
    border: "1px solid #E2E0E0 ",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none",
  },
}));

const UserInformation: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");
  const [userTableData, setUserTableData] = useState<UserTableData[]>([]);
  const [userRoleData, setUserRoleData] = useState<DropDown[]>([]);
  const [userTypeData, setUserTypeData] = useState<DropDown[]>([]);
  const [userRoleDetails, setUserRoleDetails] = useState<UserTypeData[]>([]);
  const [UserTypeDetails, setUserTypeDetails] = useState<UserTypeDetails[]>([]);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [perPage, setPerPage] = useState(10);
  const [pageIndex, setPageIndex] = useState(1);
  const [totalTableRecords, setTotalTableRecords] = useState(0);
  const [errors, setErrors] = useState<Partial<TableSearchData>>({});
  const [tableSearchData, setTableSearchData] = useState<TableSearchData>({
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    message: "",
    role_name: "",
    user_type_name: "",
  });
  const [tableEditData, setTableEditData] = useState<TableSearchData>({
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    message: "",
    role_name: "",
    roleID: 0,
    userTypeId: 0,
    user_type_name: "",
  });

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  }>({
    key: "number",
    direction: "ascending",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const OptionData = ExportOptionData(selectedValue);

  useEffect(() => {
    const searchTime = setTimeout(() => {
      handleGetUserTableListData();
    }, 500);

    return () => {
      clearTimeout(searchTime);
    };
  }, [pageIndex, perPage, tableSearchData, sortConfig]);

  useEffect(() => {
    handleGetUserRoleListData();
    handleGetUserTypeListData();
  }, []);

  const handleGetUserTableListData = () => {
    setLoading(true);
    const tableOrdering =
      sortConfig.direction === "ascending"
        ? sortConfig.key
        : `-${sortConfig.key}`;
    const inputData = {
      pageIndex,
      perPage,
      tableSearchData,
      tableOrdering,
    };
    userInformationService
      .getUserTableListAPI(inputData)
      .then((response: { data: UserTableApiResponse }) => {
        const finaldata = response?.data?.data;
        if (finaldata && finaldata.results.length > 0) {
          setUserTableData(finaldata.results);
          setTotalTableRecords(finaldata.count);
        } else {
          setUserTableData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("User Information Error", error);
      });
  };

  const handleGetUserRoleListData = () => {
    userInformationService
      .getUserRoleListAPI()
      .then((response: { data: ApiResponse<UserRoleData[]> }) => {
        if (response?.data?.data?.length > 0) {
          setUserTypeDetails(response.data.data);
          const finalData: DropDown[] = response.data.data.map(
            (item: UserRoleData) => ({
              value: item.role_name,
              label: item.role_name,
            })
          );
          setUserRoleData(finalData);
        } else {
          setUserTypeDetails([]);
          setUserRoleData([]);
        }
      })
      .catch((error) => {
        console.log(error, "User Information Error");
      });
  };
  const handleGetUserTypeListData = () => {
    userInformationService
      .getUserTypeListAPI()
      .then((response: { data: ApiResponse<UserTypeData[]> }) => {
        if (response?.data.data?.length > 0) {
          setUserRoleDetails(response.data.data);
          const finalData: DropDown[] = response.data.data.map(
            (item: UserTypeData) => ({
              value: item.user_type_name,
              label: item.user_type_name,
            })
          );
          setUserTypeData(finalData);
        } else {
          setUserRoleDetails([]);
          setUserTypeData([]);
        }
      })
      .catch((error) => {
        console.log(error, "User Information Error");
      });
  };

  const handleGetEditModaldata = (row: TableSearchData) => {
    const role = userRoleData.find((x) => x.label === row.role_name);
    const roleDetails = UserTypeDetails.find(
      (x) => x.role_name === row.role_name
    )?.id;
    const typeDetails = userRoleDetails.find(
      (x) => x.user_type_name === row.user_type_name
    )?.id;
    const userType = userTypeData.find((x) => x.label === row.user_type_name);

    setTableEditData({
      id: row.id,
      first_name: row.first_name,
      last_name: row.last_name,
      email: row.email,
      message: row.message,
      role_name: role ? role.value : "",
      roleID: roleDetails,
      user_type_name: userType ? userType.value : "",
      userTypeId: typeDetails,
    });
    setEditModal(true);
    setErrors({});
  };
  const handleEditModalClose = () => setEditModal(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#404040",
      color: theme.palette.common.white,
      width: "12.5%",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
    border: "1px solid #DFDFDF",
  }));

  const columns: Column[] = [
    { id: "no", label: "#" },
    { id: "first_name", label: "First Name" },
    { id: "last_name", label: "Last Name" },
    { id: "email", label: "Email" },
    { id: "message", label: "Display Message" },
    { id: "role_name", label: "User Role" },
    { id: "user_type_name", label: "User Type" },
    { id: "action", label: "Action" },
  ];

  const handleSort = (columnId: keyof Data) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === columnId &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key: columnId, direction });
  };

  const sortedRows = React.useMemo(() => {
    // if (
    //   sortConfig !== null &&
    //   sortConfig.key !== null &&
    //   sortConfig.key !== undefined
    // ) {
    //   const key = sortConfig.key as keyof UserTableData;
    //   return [...userTableData].sort((a: UserTableData, b: UserTableData) => {
    //     const aValue = a[key];
    //     const bValue = b[key];

    //     if (aValue == null && bValue != null)
    //       return sortConfig.direction === "ascending" ? -1 : 1;
    //     if (aValue != null && bValue == null)
    //       return sortConfig.direction === "ascending" ? 1 : -1;
    //     if (aValue == null && bValue == null) return 0;

    //     if (aValue !== null && bValue !== null) {
    //       if (aValue < bValue) {
    //         return sortConfig.direction === "ascending" ? -1 : 1;
    //       }
    //       if (aValue > bValue) {
    //         return sortConfig.direction === "ascending" ? 1 : -1;
    //       }
    //     }

    //     return 0;
    //   });
    // }
    return userTableData;
  }, [userTableData, sortConfig]);

  const searchInputSelectOnchange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const { name, value } = e.target;
    setTableSearchData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPageIndex(1);
    setPerPage(10);
  };
  const handleInputSelectOnchange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const { name, value } = e.target;
    let formattedValue = value;
    if (name === "first_name" || name === "last_name") {
      formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }
    if (name === "role_name") {
      const role_id = UserTypeDetails.find((x) => x.role_name === value)?.id;
      setTableEditData((prev) => ({
        ...prev,
        [name]: value,
        ["roleID"]: role_id,
      }));
    } else {
      setTableEditData((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const handleRowsPerPageChange = (event: SelectChangeEvent<number>) => {
    setPerPage(Number(event.target.value));
    setPageIndex(1);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageIndex(value);
  };

  const totalPages = Math.ceil(totalTableRecords / perPage);
  const startIndex = Math.min((pageIndex - 1) * perPage + 1, totalTableRecords);
  const endIndex = Math.min(pageIndex * perPage, totalTableRecords);

  const formValidate = (): boolean => {
    const newErrors: Partial<TableSearchData> = {};
    const alphabetRegex = /^[A-Za-z\s]+$/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (!tableEditData.first_name.trim()) {
      newErrors.first_name = "First Name cannot be blank";
    } else if (specialCharRegex.test(tableEditData.first_name.trim())) {
      newErrors.first_name = "First Name cannot contain special characters";
    } else if (!alphabetRegex.test(tableEditData.first_name.trim())) {
      newErrors.first_name = "First Name can only contain alphabets";
    } else if (tableEditData.first_name.length > 50) {
      newErrors.first_name =
        "Ensure this field has not more than 50 characters";
    }
    if (!tableEditData.last_name.trim()) {
      newErrors.last_name = "Last Name cannot be blank";
    } else if (specialCharRegex.test(tableEditData.last_name.trim())) {
      newErrors.last_name = "First Name cannot contain special characters";
    } else if (!alphabetRegex.test(tableEditData.last_name.trim())) {
      newErrors.last_name = "First Name can only contain alphabets";
    } else if (tableEditData.last_name.length > 50) {
      newErrors.last_name = "Ensure this field has not more than 50 characters";
    }

    if (!tableEditData.role_name.trim()) {
      newErrors.role_name = "User Role cannot be blank";
    }
    // if (!tableEditData.user_type_name.trim()) {
    //   newErrors.user_type_name = "User Type cannot be blank";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveUserDetails = async () => {
    if (formValidate()) {
      const typeId: number | null = tableEditData.userTypeId
        ? tableEditData.userTypeId
        : null;
      const inputData = {
        first_name: tableEditData.first_name,
        last_name: tableEditData.last_name,
        message: tableEditData.message,
        role_id: Number(tableEditData.roleID),
        user_type_id: typeId,
      };
      await userInformationService
        .putUserDetailsUpdateAPI(inputData, tableEditData.id)
        .then((response) => {
          if (response && response.data) {
            showToast("User Details Updated successfully", "success");
            setEditModal(false);
            handleGetUserTableListData();
          } else {
            showToast("Failed to Updated Data", "error");
          }
        })
        .catch((error) => {
          console.log(error, "API Error");
        });
    }
  };

  const DropdownChange = (event: SelectChangeEvent) => {
    setSelectedValue(event.target.value);
    handleDownloadExportData(event.target.value);
  };

  const handleDownloadExportData = (key: string) => {
    const arrayIDs = userTableData.map((item) => item.id);
    userInformationService
      .getDownloadExportDataAPI(key, arrayIDs)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", getFileName(key, "userDetails"));
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("User Details Error", error);
      });
  };

  const handlePageResetFilter = () => {
    setTableSearchData({
      id: 0,
      first_name: "",
      last_name: "",
      email: "",
      message: "",
      role_name: "",
      user_type_name: "",
    });
    setSortConfig({
      key: "number",
      direction: "ascending",
    });
    setPageIndex(1);
    setPerPage(10);
    setSelectedValue("");
  };
  const handleHomeRedirection = () => {
    dispatch(headerActiveLink(""));
    navigate("/");
  };
  return (
    <>
      <Box
        className="contain__area"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 5,
        }}
      >
        <Box
          className="breadcrumbs"
          sx={{
            display: "flex",
            textAlign: "left",
            width: "90%",
            margin: {
              xs: "0px 20px 0px 1px",
              sm: "0px 56px 0px 1px",
              md: "30px 56px 0px 56px",
            },
          }}
        >
          <Box role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                to="/"
                className="LinkColorBlue"
                onClick={handleHomeRedirection}
              >
                Home
              </Link>
              <Link
                to="/system-administration/userInformation"
                className="LinkColorDark"
              >
                {" "}
                User Information
              </Link>
            </Breadcrumbs>
          </Box>
        </Box>
        <Paper
          sx={{
            width: {
              xs: "100%",
              sm: "90%",
            },
            borderRadius: "5px",
            border: "1px solid #DFDFDF",
            marginTop: "20px",
          }}
        >
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item xs={12} sm={6}>
                  <Typography
                    className="h4"
                    fontSize={22}
                    sx={{
                      color: "#0464AB",
                    }}
                  >
                    User Information
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box
            display="flex"
            justifyContent={{
              md: "space-between",
              sm: "space-between",
              xs: "center",
            }}
            flexWrap="wrap"
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={1}
              mx={2}
              marginLeft={{ xs: "30%", sm: "2%", md: "1.5%" }}
              flexWrap="wrap"
            >
              <Grid
                container
                alignItems={{ xs: "center" }}
                justifyContent={{ xs: "center" }}
                spacing={1}
              >
                <Grid item xs={12} sm={6} md={7}>
                  <Typography variant="body1">
                    Showing{" "}
                    <strong>
                      {startIndex}-{endIndex}
                    </strong>{" "}
                    of <strong>{totalTableRecords}</strong> items.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" mr={1}>
                      Show
                    </Typography>
                    <Select
                      value={perPage}
                      onChange={handleRowsPerPageChange}
                      variant="outlined"
                      size="small"
                      sx={{ borderRadius: "50px" }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                    <Typography variant="body1" ml={1}>
                      Entries
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box mt={1} mr={{ sm: 1, md: 3.5 }} ml={1}>
              <Grid
                container
                alignItems="center"
                justifyContent="end"
                spacing={2}
              >
                <Grid item xs={6} sm={6}>
                  <Button
                    variant="outlined"
                    className={styles1.resetButtoncss}
                    onClick={handlePageResetFilter}
                    sx={{
                      height: "35px",
                      width: {
                        xl: "max-content",
                        lg: "fit-content",
                        md: "135px",
                        sm: "135px",
                        xs: "fit-content",
                      },
                      fontSize: {
                        md: "12px",
                        sm: "12px",
                        xs: "9px",
                      },
                      textTransform: "none",
                    }}
                  >
                    <ReplayOutlinedIcon sx={{ marginRight: "2px" }} />
                    Reset Filter
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  paddingLeft={{ xs: "5px!important", md: "1px!important" }}
                >
                  <Box>
                    <ExportFileDropdown
                      name="dropdown"
                      label=""
                      value={selectedValue}
                      onChange={DropdownChange}
                      options={OptionData.slice(1)}
                      placeholder={
                        <div style={{ display: "flex", alignItems: "left" }}>
                          <FileExporticon
                            className={
                              selectedValue === "Export"
                                ? styles.activeSVG
                                : styles.inactiveSVG
                            }
                          />
                          <Typography
                            sx={{
                              marginLeft: { md: "6px", sm: "6px", xs: "6px" },
                            }}
                          >
                            {OptionData[0].label}
                          </Typography>
                        </div>
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ pt: 1 }}>
            {loading ? (
              <Box display="flex" justifyContent="center" padding={2}>
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer>
                <Table
                  className={styles1.tableListing}
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ minWidth: 900 }}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((data, index) => (
                        <StyledTableCell
                          key={data.id}
                          className={
                            index === columns.length - 1 ? styles1.sticky : ""
                          }
                        >
                          <Box display="flex" alignItems="center">
                            {data.label}
                            {index !== 0 &&
                              index !== 5 &&
                              index !== 6 &&
                              data.id !== "action" && (
                                <IconButton
                                  onClick={() => handleSort(data.id)}
                                  size="small"
                                  color="inherit"
                                >
                                  {sortConfig.key === data.id ? (
                                    sortConfig.direction === "ascending" ? (
                                      <ArrowUpwardIcon fontSize="inherit" />
                                    ) : (
                                      <ArrowDownwardIcon fontSize="inherit" />
                                    )
                                  ) : (
                                    <ArrowUpwardIcon fontSize="inherit" />
                                  )}
                                </IconButton>
                              )}
                          </Box>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {columns.map((item, index) => (
                        <TableCell
                          sx={{ border: "1px solid #DFDFDF" }}
                          key={item.id}
                          className={
                            index === columns.length - 1 ? styles1.sticky : ""
                          }
                          style={{
                            width: "12.5%",
                          }}
                        >
                          {index !== 0 &&
                          index !== columns.length - 1 &&
                          index !== columns.length - 2 &&
                          index !== columns.length - 3 ? (
                            <StyledTextField
                              variant="standard"
                              fullWidth
                              key={`search-${item.id}`}
                              name={item.id}
                              value={
                                tableSearchData[
                                  item.id as keyof TableSearchData
                                ] || ""
                              }
                              onChange={searchInputSelectOnchange}
                              placeholder="Search"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="start"
                                    sx={{ mr: 0, minHeight: 32 }}
                                  >
                                    <SearchOutlinedIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          ) : index === columns.length - 3 ? (
                            <CustomDropdown
                              label=""
                              name="role_name"
                              value={tableSearchData.role_name}
                              onChange={searchInputSelectOnchange}
                              options={userRoleData}
                              placeholder="Select"
                              mbHeading="0px"
                            />
                          ) : index === columns.length - 2 ? (
                            <CustomDropdown
                              label=""
                              name="user_type_name"
                              value={tableSearchData.user_type_name}
                              onChange={searchInputSelectOnchange}
                              options={userTypeData}
                              placeholder="Select"
                              mbHeading="0px"
                              disabled={true}
                            />
                          ) : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedRows.map((row, i) => (
                      <TableRow key={row.id}>
                        <TableCell>
                          {(pageIndex - 1) * perPage + i + 1}
                        </TableCell>
                        <TableCell sx={{ minWidth: "80px" }}>
                          {row.first_name}
                        </TableCell>
                        <TableCell sx={{ minWidth: "80px" }}>
                          {row.last_name}
                        </TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.message}</TableCell>
                        <TableCell>{row.role_name}</TableCell>
                        <TableCell>{row.user_type_name}</TableCell>
                        <TableCell
                          sx={{
                            position: "sticky",
                            zIndex: 1,
                            right: 0,
                            backgroundColor: "#fff",
                            border: "1px solid #DFDFDF",
                          }}
                        >
                          {
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                            >
                              <IconButton>
                                <EditIcon
                                  onClick={() => handleGetEditModaldata(row)}
                                  height={13}
                                />
                              </IconButton>
                            </Box>
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <CustomModal
              open={editModal}
              onClose={handleEditModalClose}
              actionButtonLabel="Save"
              title="Edit User Information"
              save={handleSaveUserDetails}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  User Id: <strong>{tableEditData.id}</strong>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label="First Name"
                    name="first_name"
                    value={tableEditData.first_name}
                    onChange={handleInputSelectOnchange}
                    required={true}
                    error={errors.first_name}
                    placeholder="Enter value"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label="Last Name"
                    name="last_name"
                    value={tableEditData.last_name}
                    onChange={handleInputSelectOnchange}
                    required={true}
                    error={errors.last_name}
                    placeholder="Enter value"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label="Email"
                    name="email"
                    value={tableEditData.email}
                    onChange={() => {}}
                    placeholder="Enter value"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomInput
                    label="Display Message"
                    name="message"
                    value={tableEditData.message}
                    onChange={handleInputSelectOnchange}
                    placeholder="Enter value"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomDropdown
                    label="User Role"
                    name="role_name"
                    value={tableEditData.role_name}
                    onChange={handleInputSelectOnchange}
                    options={userRoleData}
                    required={true}
                    error={errors.role_name}
                    placeholder="- Select -"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomDropdown
                    label="User Type"
                    name="user_type_name"
                    value={tableEditData.user_type_name}
                    onChange={() => {}}
                    options={userTypeData}
                    // required={true}
                    error={errors.user_type_name}
                    placeholder="- Select -"
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </CustomModal>
            <Box
              display="flex"
              justifyContent={{
                md: "space-between",
                sm: "space-between",
                xs: "center",
              }}
              flexWrap="wrap"
            >
              <Typography sx={{ p: 2 }}>
                Showing{" "}
                <strong>
                  {startIndex}-{endIndex}
                </strong>{" "}
                of <strong>{totalTableRecords}</strong> items.
              </Typography>
              <Box sx={{ py: 1, justifyContent: "flex-end", display: "flex" }}>
                <Pagination
                  count={totalPages}
                  color="primary"
                  page={pageIndex}
                  onChange={handlePageChange}
                />
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default UserInformation;
