import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "./layout.module.css";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MunuIcon from "../../assets/images/list (1).svg";
import Drawer from "@mui/material/Drawer";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import logo from "../../assets/images/jcaoc-logo.png";
import { IconButton } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useSelector, useDispatch } from "react-redux";
import {
  HeaderActiveState,
  RootState,
} from "../../utils/userDetailsCommonType";
import { setUserLogoutData } from "../../globalState/actions/authAction";
import {
  headerActiveLink,
  headerNullData,
} from "../../globalState/actions/headerAction";
import {
  createWorksheetDataEmpty,
  updateBasicInfo,
} from "../../globalState/actions/create-worksheet-action";
import CustomTooltip from "../../components/tooltip";

const Header: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetails = useSelector((state: RootState) => state.authReducer);
  const username = userDetails.loginDetials?.username;
  const activeUrl = useSelector(
    (state: HeaderActiveState) => state.headerURLData.headerActiveURL
  );
  const [selectedWorksheet, setSelectedWorksheet] = useState<string>("");
  const [selectedAdministration, setSelectedAdministration] =
    useState<string>("");
  const [selectedSystemAdministration, setSelectedSystemAdministration] =
    useState<string>("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openWorksheet, setOpenWorksheet] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openSysAdmin, setOpenSysAdmin] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const checkAdministrationUrl = administrationOptions.some(
      (item) => item.value === activeUrl
    );

    const checkSystemAdministrationUrl = systemAdministrationOptions.some(
      (item) => item.value === activeUrl
    );

    const checkWorksheetUrl = worksheetOptions.some(
      (item) => item.value === activeUrl
    );
    setSelectedAdministration(checkAdministrationUrl ? activeUrl : "");
    setSelectedSystemAdministration(
      checkSystemAdministrationUrl ? activeUrl : ""
    );
    setSelectedWorksheet(checkWorksheetUrl ? activeUrl : "");

    // if(activeUrl === "/WorkSheets/createWorksheet"){
    //   dispatch(createWorksheetDataEmpty());
    // }
  }, [activeUrl]);

  const toggleDrawer = (open: boolean, url: string) => () => {
    dispatch(headerActiveLink(url));
    dispatch(updateBasicInfo("activeTab", "1"));
    setDrawerOpen(open);
    if (url === "/WorkSheets/createWorksheet") {
      dispatch(createWorksheetDataEmpty());
    }
  };
  const handleWorksheetChange = (event: SelectChangeEvent<string>) => {
    dispatch(headerActiveLink(event.target.value));
    setSelectedWorksheet(event.target.value);
    navigate(event.target.value);
    dispatch(updateBasicInfo("activeTab", "1"));
    if (event.target.value === "/WorkSheets/createWorksheet") {
      dispatch(createWorksheetDataEmpty());
    }
  };

  const handleAdministrationChange = (event: SelectChangeEvent<string>) => {
    dispatch(headerActiveLink(event.target.value));
    setSelectedAdministration(event.target.value);
    navigate(event.target.value);
    dispatch(updateBasicInfo("activeTab", "1"));
  };

  const handleSystemAdministrationChange = (
    event: SelectChangeEvent<string>
  ) => {
    dispatch(headerActiveLink(event.target.value));
    setSelectedSystemAdministration(event.target.value);
    navigate(event.target.value);
    dispatch(updateBasicInfo("activeTab", "1"));
  };
  const isMenuOpen = Boolean(anchorEl);

  const worksheetOptions = [
    {
      value: "/WorkSheets/createWorksheet",
      label: "Create Folder-Worksheet",
    },
    { value: "/searchFolderWorksheet", label: "Search Folders-Worksheets" },
    {
      value: "/WorkSheets/searchArchivedWorksheet",
      label: "Search Archived Folders-Worksheets",
    },
  ];

  const administrationOptions = [
    {
      value: "/administration/updateUserInformation",
      label: "Update User Information",
    },
    {
      value: "/administration/ViewOrganizationName",
      label: `${
        userDetails.loginDetials?.organization
          ? "Update/View Organization Name"
          : "Create Organization"
      }`,
    },
    {
      value: userDetails.loginDetials?.invite_from_org
        ? "/administration/becomeOrganizationMember"
        : "/administration/viewOrganizationMember",
      label: `${
        userDetails.loginDetials?.invite_from_org
          ? "Become An Organization Member"
          : "Invite/Remove/View Organization Members"
      }`,
    },
  ];

  const systemAdministrationOptions = [
    { value: "/system-administration/worksheet", label: "Worksheets" },
    {
      value: "/system-administration/userInformation",
      label: "User Information",
    },
    { value: "/system-administration/updateFAQ", label: "Update FAQ" },
    {
      value: "/system-administration/systemMessage",
      label: "Update System Messages",
    },
    {
      value: "/system-administration/reportMonthlyNumber",
      label: "Report - Monthly Numbers",
    },
  ];

  const drawerContent = (
    <div style={{ width: "100vw", backgroundColor: "#022E4C" }}>
      <div
        style={{
          padding: "16px",
          display: "flex",
          alignItems: "center",
          boxShadow: "0px 8px 10px #48596633",
        }}
      >
        <IconButton
          onClick={toggleDrawer(false, "")}
          style={{ padding: "0px" }}
        >
          <CloseIcon style={{ color: "#fff", fontSize: "2.5rem" }} />
        </IconButton>
        <img src={logo} style={{ height: "35px", marginLeft: "15px" }} />
      </div>
      <div style={{ padding: "0px 16px", color: "#fff", margin: 10 }}>
        {userDetails.loginDetials?.isUserLoggedIn && (
          <div>
            <div
              className={styles.commanLink}
              onClick={() => setOpenWorksheet(!openWorksheet)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>Worksheet</div>
              <div>
                {openWorksheet ? (
                  <ExpandMoreIcon fontSize="large" />
                ) : (
                  <ArrowForwardIosIcon fontSize="small" />
                )}
              </div>
            </div>
            <Collapse in={openWorksheet} timeout="auto" unmountOnExit>
              <div style={{ paddingLeft: "20px" }}>
                {worksheetOptions.map((option) => (
                  <Link
                    key={option.value}
                    to={option.value}
                    className={styles.commanLink}
                    onClick={toggleDrawer(false, option.value)}
                  >
                    {option.label}
                  </Link>
                ))}
              </div>
            </Collapse>

            <div
              className={styles.commanLink}
              onClick={() => setOpenAdmin(!openAdmin)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>Administration</div>
              <div>
                {openAdmin ? (
                  <ExpandMoreIcon fontSize="large" />
                ) : (
                  <ArrowForwardIosIcon fontSize="small" />
                )}
              </div>
            </div>
            <Collapse in={openAdmin} timeout="auto" unmountOnExit>
              <div style={{ paddingLeft: "20px" }}>
                {administrationOptions.map((option) => (
                  <Link
                    key={option.value}
                    to={option.value}
                    className={styles.commanLink}
                    onClick={toggleDrawer(false, option.value)}
                  >
                    {option.label}
                  </Link>
                ))}
              </div>
            </Collapse>
            {userDetails.loginDetials?.is_superuser && (
              <div
                className={styles.commanLink}
                onClick={() => setOpenSysAdmin(!openSysAdmin)}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div> System Administration</div>
                <div>
                  {openSysAdmin ? (
                    <ExpandMoreIcon fontSize="large" />
                  ) : (
                    <ArrowForwardIosIcon fontSize="small" />
                  )}
                </div>
              </div>
            )}

            <Collapse in={openSysAdmin} timeout="auto" unmountOnExit>
              <div style={{ paddingLeft: "20px" }}>
                {systemAdministrationOptions.map((option) => (
                  <Link
                    key={option.value}
                    to={option.value}
                    className={styles.commanLink}
                    onClick={toggleDrawer(false, option.value)}
                  >
                    {option.label}
                  </Link>
                ))}
              </div>
            </Collapse>
          </div>
        )}

        <Link
          to="/about"
          className={styles.commanLink}
          onClick={toggleDrawer(false, "/about")}
        >
          About
        </Link>
        <a
          href="https://childsp-api-uat.brainvire.net/user_guide_api/child_support_statute/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.commanLink}
          onClick={toggleDrawer(false, "/10")}
        >
          Child Support Statute
        </a>
        <Link
          to="/basicChildSupport"
          className={styles.commanLink}
          onClick={toggleDrawer(false, "/basicChildSupport")}
        >
          Basic Child Support Obligation Table
        </Link>
        <Link
          to="/9"
          className={styles.commanLink}
          onClick={toggleDrawer(false, "/9")}
        >
          User Guide
        </Link>
        <Link
          to="/faq"
          className={styles.commanLink}
          onClick={toggleDrawer(false, "/faq")}
        >
          FAQ
        </Link>
        <Link
          to="/contact"
          className={styles.commanLink}
          onClick={toggleDrawer(false, "/contact")}
        >
          Contact
        </Link>
      </div>
    </div>
  );

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleUserLogOut = () => {
    dispatch(setUserLogoutData());
    dispatch(headerNullData());
    dispatch(createWorksheetDataEmpty());
    setAnchorEl(null);
    localStorage.clear();
    navigate("/");
  };
  const handleHomeRedirection = () => {
    dispatch(headerActiveLink(""));
    navigate("/");
  };

  const handlePageRedirection = (Url: string) => {
    dispatch(headerActiveLink(Url));
  };

  return (
    <>
      <Box className={styles.headerSection}>
        <AppBar
          position="fixed"
          sx={{
            background: "#022E4C",
            height: "50px!important",
            minHeight: "50px",
          }}
        >
          <div className={styles.max_width_container}>
            <Toolbar
              sx={{
                height: "100%",
                minHeight: "50px !important",
                marginX: { md: "9%", sm: "0%", sx: "0%" },
                paddingX: { md: "16px", xs: "10px" },
              }}
            >
              <Typography
                className={styles.headerLink}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "block",
                        lg: "none",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={MunuIcon}
                      alt="Menu"
                      onClick={toggleDrawer(true, "")}
                      sx={{
                        cursor: "pointer",
                        height: { md: "30px", sm: "30px", xs: "25px" },
                        marginRight: "10px",
                      }}
                    />
                    <Drawer
                      anchor="left"
                      open={drawerOpen}
                      onClose={toggleDrawer(false, "")}
                    >
                      {drawerContent}
                    </Drawer>
                  </Box>
                  <Box sx={{ marginRight: { md: 4, sm: 4, xs: 0 } }}>
                    <Box
                      component="img"
                      src={logo}
                      alt="Logo"
                      onClick={handleHomeRedirection}
                      sx={{
                        height: {
                          md: "30px",
                          sm: "30px",
                          xs: "22px",
                          cursor: "pointer",
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box>
                  <Typography
                    marginLeft={0}
                    marginRight={0}
                    fontSize={11}
                    className={styles.footerLinks}
                    sx={{
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "flex",
                      },
                    }}
                  >
                    <Box>
                      <Link
                        to="/about"
                        className={`${styles.headerLink} ${
                          location.pathname === "/about" ? styles.active : ""
                        }`}
                        onClick={() => handlePageRedirection("/about")}
                      >
                        About
                      </Link>

                      <Link
                        to="/9"
                        className={`${styles.headerLink} ${
                          location.pathname === "/9" ? styles.active : ""
                        }`}
                        onClick={() => handlePageRedirection("/9")}
                      >
                        User Guide
                      </Link>
                      <Link
                        to="/faq"
                        className={`${styles.headerLink} ${
                          location.pathname === "/faq" ? styles.active : ""
                        }`}
                        onClick={() => handlePageRedirection("/faq")}
                      >
                        FAQ
                      </Link>
                      <Link
                        to="/contact"
                        className={`${styles.headerLink} ${
                          location.pathname === "/contact" ? styles.active : ""
                        }`}
                        onClick={() => handlePageRedirection("/contact")}
                      >
                        Contact
                      </Link>
                      <a
                        href="https://childsp-api-uat.brainvire.net/user_guide_api/child_support_statute/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "white",
                          textDecoration: "none",
                          margin: "0 6px",
                        }}
                        className={"layout_headerLink__ZCnM2"}
                      >
                        Child Support Statute
                      </a>
                      <Link
                        to="/basicChildSupport"
                        className={`${styles.headerLink} ${
                          location.pathname === "/basicChildSupport"
                            ? styles.active
                            : ""
                        }`}
                        onClick={() =>
                          handlePageRedirection("/basicChildSupport")
                        }
                      >
                        Basic Child Support Obligation Table
                      </Link>
                    </Box>
                  </Typography>
                </Box>

                {userDetails.loginDetials?.isUserLoggedIn ? (
                  <Typography>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {username.length > 15 ? (
                        <CustomTooltip
                          placement="bottom-end"
                          fontSize="12px"
                          title={username}
                        >
                          <Typography
                            variant="body1"
                            color="inherit"
                            sx={{ cursor: "pointer", marginX: "5px" }}
                            onClick={handleMenu}
                            maxWidth="150px"
                            className={styles.truncatedText}
                          >
                            {`${username.substring(0, 15)}...`}
                          </Typography>
                        </CustomTooltip>
                      ) : (
                        <Typography
                          variant="body1"
                          color="inherit"
                          sx={{ cursor: "pointer", marginX: "5px" }}
                          onClick={handleMenu}
                          maxWidth="150px"
                          className={styles.truncatedText}
                        >
                          {username}
                        </Typography>
                      )}

                      <div onClick={handleMenu}>
                        {isMenuOpen ? (
                          <ExpandLessIcon
                            color="inherit"
                            sx={{ cursor: "pointer", verticalAlign: "middle" }}
                          />
                        ) : (
                          <ExpandMoreIcon
                            color="inherit"
                            sx={{ cursor: "pointer", verticalAlign: "middle" }}
                          />
                        )}
                      </div>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={isMenuOpen}
                        onClose={handleClose}
                        sx={{
                          mt: { md: "2.5%", sm: "2.5%", xs: "8%" },
                          borderRadius: "10px",
                        }}
                      >
                        <MenuItem
                          onClick={handleUserLogOut}
                          sx={{ paddingY: "2px", background: "#fff" }}
                        >
                          <LogoutIcon sx={{ mr: 1 }} />
                          Logout
                        </MenuItem>
                      </Menu>
                    </div>
                  </Typography>
                ) : (
                  <Box display={"flex"}>
                    <Link to="/register">
                      <Button
                        variant="contained"
                        disableElevation
                        sx={{
                          borderRadius: 5,
                          mx: 1,
                          fontSize: "12px",
                          background: "#0464AB",
                          border: "1px solid #0464AB",
                          textTransform: "none",
                          minWidth: { xs: "100px" },
                          padding: {
                            md: "5px 15px",
                            sm: "5px 15px",
                            xs: "5px 0px",
                          },
                        }}
                      >
                        Create Account
                      </Button>
                    </Link>
                    <Link to="/login">
                      <Button
                        variant="outlined"
                        disableElevation
                        sx={{
                          borderRadius: 5,
                          textTransform: "none",
                          fontSize: "12px",
                          border: "1px solid #FFFFFF",
                          color: "#fff",
                          padding: {
                            md: "5px 15px",
                            sm: "5px 15px",
                            xs: "5px 0px",
                          },
                        }}
                        color="inherit"
                      >
                        Login
                      </Button>
                    </Link>
                  </Box>
                )}
              </Typography>
            </Toolbar>
          </div>
        </AppBar>
      </Box>
      {userDetails.loginDetials?.isUserLoggedIn && (
        <Box
          className={styles.header2_Section}
          sx={{ display: { md: "block", sm: "none", xs: "none" } }}
        >
          <AppBar
            position="fixed"
            sx={{
              top: 50,
              background: "#0464AB",
              height: "28px",
              minHeight: "28px",
              color: "inherit",
            }}
          >
            <div
              className={styles.max_width_container}
              style={{ height: "28px" }}
            >
              <Toolbar
                sx={{ height: "100%", minHeight: "28px", color: "#fff" }}
                className={styles.customToolbar}
              >
                <div className="SelectSection">
                  <FormControl sx={{ minWidth: "fit-content" }}>
                    <Select
                      value={selectedWorksheet}
                      onChange={handleWorksheetChange}
                      displayEmpty
                      IconComponent={ExpandMoreIcon}
                      inputProps={{ "aria-label": "Worksheet" }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Worksheet</em>;
                        }
                        return worksheetOptions.find(
                          (option) => option.value === selected
                        )?.label;
                      }}
                      sx={{
                        padding: "0",
                        border: "none",
                        fontSize: "12px",
                        ".MuiSelect-icon": { color: "#fff" },
                        ".MuiOutlinedInput-notchedOutline": { border: "none" },
                        color: "#fff",
                        ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            paddingLeft: "8px",
                          },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            },
                            top: "78px!important",
                          },
                        },
                      }}
                    >
                      {worksheetOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          sx={{
                            color: "#000",
                            borderBlock: "1px solid #E2E0E0",
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ minWidth: "fit-content", border: "none" }}>
                    <Select
                      value={selectedAdministration}
                      onChange={handleAdministrationChange}
                      displayEmpty
                      IconComponent={ExpandMoreIcon}
                      inputProps={{ "aria-label": "Administration" }}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Administration</em>;
                        }
                        return administrationOptions.find(
                          (option) => option.value === selected
                        )?.label;
                      }}
                      sx={{
                        padding: "0",
                        border: "none",
                        fontSize: "12px",
                        ".MuiSelect-icon": { color: "#fff" },
                        ".MuiOutlinedInput-notchedOutline": { border: "none" },
                        color: "#fff",
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                              paddingTop: "0px",
                              paddingBottom: "0px",
                            },
                            top: "78px!important",
                          },
                        },
                      }}
                    >
                      {administrationOptions.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          sx={{
                            color: "#000",
                            borderBlock: "1px solid #E2E0E0",
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {userDetails.loginDetials?.is_superuser && (
                    <FormControl
                      sx={{ minWidth: "fit-content", border: "none" }}
                    >
                      <Select
                        placeholder="handleSystemAdministrationChange"
                        value={selectedSystemAdministration}
                        onChange={handleSystemAdministrationChange}
                        displayEmpty
                        IconComponent={ExpandMoreIcon}
                        inputProps={{ "aria-label": "System Administration" }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>System Administration</em>;
                          }
                          return systemAdministrationOptions.find(
                            (option) => option.value === selected
                          )?.label;
                        }}
                        sx={{
                          padding: "0",
                          border: "none",
                          fontSize: "12px",
                          ".MuiSelect-icon": { color: "#fff" },
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          color: "#fff",
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              },
                              top: "78px!important",
                            },
                          },
                        }}
                      >
                        {systemAdministrationOptions.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            sx={{
                              color: "#000",
                              borderBlock: "1px solid #E2E0E0",
                            }}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
              </Toolbar>
            </div>
          </AppBar>
        </Box>
      )}
    </>
  );
};

export default Header;
