import {
  AddPreexistingOrder,
  AddProposedAdjustmentIncomeOther,
} from "../../../types/create-worksheet/AdjustedIncomeTab";
import { FormErrors } from "../../../types/global";
import { isValidDate, isValidTime } from "../../../utils/constants";

export const AddPreexistingOrderValidation = (
  formData: AddPreexistingOrder
): { [key: string]: string } => {
  const formErrors: FormErrors = {};

  // if (!formData.court_name.trim()) {
  //   formErrors.court_name = "Please fill the data";
  // }

  // if (!formData.civil_action_case_number.trim()) {
  //   formErrors.civil_action_case_number = "Please fill the data";
  // }

  if (!formData.children_names_years_of_birth.trim()) {
    formErrors.children_names_years_of_birth = "Child Name cannot be blank";
  }

  // if (!formData.date_of_initial_order.trim()) {
  //   formErrors.date_of_initial_order = "Please fill the data";
  // } else if (!isValidDate(formData.date_of_initial_order)) {
  //   formErrors.date_of_initial_order = "Please Enter Valid Date";
  // }
  if (formData.date_of_initial_order) {
    if (!isValidDate(formData.date_of_initial_order)) {
      formErrors.date_of_initial_order = "Please Enter Valid Date";
    }
  }

  if (formData.time_of_initial_order) {
    if (!isValidTime(formData.time_of_initial_order)) {
      formErrors.time_of_initial_order = "Please Enter Valid Time";
    }
  }

  // if (!formData.time_of_initial_order.trim()) {
  //   formErrors.time_of_initial_order = "Please fill the data";
  // } else if (!isValidTime(formData.time_of_initial_order)) {
  //   formErrors.time_of_initial_order = "Please Enter Valid Time";
  // }

  const support1 = Number(formData.preexisting_child_support_paid_monthly1);
  const support2 = Number(formData.preexisting_child_support_paid_monthly2);

  if (support1 <= 0 && support2 <= 0) {
    formErrors.preexisting_child_support_paid_monthly1 =
      "Please Enter Current Child Support Amount Paid By Either Parent";
  }

  return formErrors;
};

export const ProposedAdjustmentIncomeValidation = (
  formData: AddProposedAdjustmentIncomeOther,
  openModalCallback: (message: string) => void
): { [key: string]: string } => {
  const formErrors: FormErrors = {};

  if (
    formData.parent_actually_supporting_child === "no" ||
    formData.legally_responsible_for_child === "no" ||
    formData.child_lives_in_parents_home === "no" ||
    formData.child_not_before_court === "no" ||
    formData.child_not_subject_to_support_order === "no"
  ) {
    formErrors.mainError = "Please fill the data";

    openModalCallback(
      "Responses to the requirements indicate the child does not meet the definition of a qualified child."
    );
  }

  if (!formData.child_name.trim()) {
    formErrors.child_name = "Child Name cannot be blank";
  }
  if (!formData.year_of_birth.trim()) {
    formErrors.year_of_birth = "Year of Birth cannot be blank";
  }
  if (!formData.adjustment_requested_by.trim()) {
    formErrors.adjustment_requested_by =
      "Adjustment Requested by cannot be blank";
  }

  if (!formData.parent_actually_supporting_child.trim()) {
    formErrors.parent_actually_supporting_child = "Please fill the data";
  }
  if (!formData.legally_responsible_for_child.trim()) {
    formErrors.legally_responsible_for_child = "Please fill the data";
  }
  if (!formData.child_lives_in_parents_home.trim()) {
    formErrors.child_lives_in_parents_home = "Please fill the data";
  }
  if (!formData.child_not_before_court.trim()) {
    formErrors.child_not_before_court = "Please fill the data";
  }
  if (!formData.child_not_subject_to_support_order.trim()) {
    formErrors.child_not_subject_to_support_order = "Please fill the data";
  }
  return formErrors;
};
