import {
  httpCommon,
  httpTokenCommon,
  httpTokenFileDownload
} from "./interceptor";

interface tableList {
  pageIndex: number;
  perPage: number;
  tableSearchData: {
    first_name: string;
    last_name: string;
    email: string;
    message: string;
    role_name: string;
    user_type_name: string;
  };
  tableOrdering: string;
}

class UserInformationService {
  getUserRoleListAPI() {
    return httpCommon.get("users/role-list/");
  }
  getUserTypeListAPI() {
    return httpCommon.get("users/user-type-list/");
  }
  getUserTableListAPI(inputdata: tableList) {
    const { pageIndex, perPage, tableSearchData, tableOrdering } = inputdata;
    return httpTokenCommon.get(
      `users/admin-user-list/?limit=${perPage}&page=${pageIndex}&email=${tableSearchData.email}&first_name=${tableSearchData.first_name}&last_name=${tableSearchData.last_name}&message=${tableSearchData.message}&role_id=${tableSearchData.role_name}&user_type_id=${tableSearchData.user_type_name}&ordering=${tableOrdering}`
    );
  }
  putUserDetailsUpdateAPI(
    inputdata: {
      first_name: string;
      last_name: string;
      message: string;
      role_id: number;
      user_type_id: number | null;
    },
    id: number
  ) {
    return httpTokenCommon.put(
      `users/admin-user-details-update/${id}/`,
      inputdata
    );
  }
  getDownloadExportDataAPI(key: string, download_ids: number[]) {
    return httpTokenFileDownload.get(
      `users/admin-usersdata-download/?download_format=${key}&download_ids=${download_ids}`
    );
  }
  getUserDetailsAPI() {
    return httpTokenCommon.get("users/user-details-update/");
  }
  getCourtListAPI() {
    return httpCommon.get("users/court-list");
  }
  getCountyListAPI() {
    return httpCommon.get("users/county-list");
  }
  patchUserDetailsUpdateAPI(inputdata: {
    first_name: string;
    last_name: string;
    bar_number: string;
    county_id: string;
    court_id: string;
    language: string;
  }) {
    return httpTokenCommon.patch("users/user-details-update/", inputdata);
  }
}

const userInformationService = new UserInformationService();
export default userInformationService;
