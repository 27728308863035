import {
  ActionPayload,
  CreateWorksheetInitialState
} from "../../../types/create-worksheet";
import {
  DELETE_ARRAY_ITEM,
  EMPTY_BASIC_INFO_FIELDS,
  UPDATE_ARRAY_ITEM,
  UPDATE_BASIC_INFO,
  CREATE_WORKSHEET_DATA_EMPTY,
  SET_WORKSHEET_API_DATA
} from "../../actions-Types/authActionTypes";

const initialState: CreateWorksheetInitialState = {
  // Basic Information Worksheet
  court_id: "",
  county_id: "",
  plaintiff_name: "",
  defendant_name: "",
  non_parent_custodian_name: "",
  civil_action_case_number: "",
  dhsc_no: "",
  type_of_action: "",
  initial_support_order_date: "",
  comments_for_the_court: "",
  parent_name_1: "",
  parent_name_2: "",
  parentsNameFields: false,
  non_custodial_parent: "",
  add_child_to_worksheet: [],
  nameOfPersonPreparingWorksheet: "",
  make_available_to_court_by: "",
  social_security_payment_1: "",
  social_security_payment_2: "",
  veterans_affairs_disability_payment_1: "",
  veterans_affairs_disability_payment_2: "",
  uninsured_health_expenses_1: "",
  uninsured_health_expenses_2: "",

  // Gross Income Schedule A
  tanf1: false,
  tanf2: false,
  gross_salary1: "",
  gross_salary2: "",
  commissions1: "",
  commissions2: "",
  self_employment_income1: "",
  self_employment_income2: "",
  bonuses1: "",
  bonuses2: "",
  overtime_payment1: "",
  overtime_payment2: "",
  severence_pay1: "",
  severence_pay2: "",
  recurring_income1: "",
  recurring_income2: "",
  interest_income1: "",
  interest_income2: "",
  income_from_dividends1: "",
  income_from_dividends2: "",
  trust_income1: "",
  trust_income2: "",
  income_from_annuities1: "",
  income_from_annuities2: "",
  capital_gains1: "",
  capital_gains2: "",
  social_security_disablity_benefits1: "",
  social_security_disablity_benefits2: "",
  federal_veterans_disability_benefits1: "",
  federal_veterans_disability_benefits2: "",
  workers_compensation_benefits1: "",
  workers_compensation_benefits2: "",
  unemployment_benefits1: "",
  unemployment_benefits2: "",
  judgements1: "",
  judgements2: "",
  gifts1: "",
  gifts2: "",
  prizes1: "",
  prizes2: "",
  alimony1: "",
  alimony2: "",
  assets1: "",
  assets2: "",
  fringe_benefits1: "",
  fringe_benefits2: "",
  other_income1: "",
  other_income2: "",
  total_gross_income1: "",
  total_gross_income2: "",
  comment1: "",
  comment2: "",

  // Adjusted Income Schedule B
  monthly_self_employment_income1: "",
  monthly_self_employment_income2: "",
  isAdjustmentAcknowledgeCheck:false,
  pre_existing_child_support: [],
  proposed_adjustment_for_qualified_child: [],
  reason_for_including_other_child1: "",
  reason_for_including_other_child2: "",

  // Health Insurance/Child Care Schedule D
  monthly_total1: "",
  monthly_total2: "",
  monthly_total3: "",
  work_related_child_care: [],
  health_insurance_premiums_parent1: "",
  health_insurance_premiums_parent2: "",
  health_insurance_premiums_parent3: "",

  // Deviations Schedule E
  requestLowIncomeDeviationP1: "",
  requestLowIncomeDeviationP2: "",
  proposedLowIncomeDeviationAmoun1: "",
  proposedLowIncomeDeviationAmoun2: "",
  judicialDiscretionAppliedAmount1: "",
  judicialDiscretionAppliedAmount2: "",
  proposed_low_income_deviation: [],
  judicial_discretion_for_low_income: [],
  specific_nonspecific_deviation: [],
  proposed_Deviation_Total1: "",
  proposed_Deviation_Total2: "",
  parenting_time_deviation1: "",
  parenting_time_deviation2: "",
  extra_ordinary_educational_expenses: [],
  extraordinary_educational_expenses_total1: "",
  extraordinary_educational_expenses_total2: "",
  extraordinary_educational_expenses_total3: "",
  extra_ordinary_medical_expenses: [],
  extraordinary_medical_expenses_total1: "",
  extraordinary_medical_expenses_total2: "",
  extraordinary_medical_expenses_total3: "",
  special_expenses_child_rearing: [],
  special_expenses_child_rearing_total1: "",
  special_expenses_child_rearing_total2: "",
  special_expenses_child_rearing_total3: "",
  unjust_or_inappropriate: "",
  best_interests_of_children: "",
  impair_ability_to_maintain: "",

  // Self-Employment Calculator
  business_description1: "",
  business_description2: "",
  business_name1: "",
  business_name2: "",
  business_type1: "",
  business_type2: "",
  gross_receipts1: "",
  gross_receipts2: "",
  cost_of_sales1: "",
  cost_of_sales2: "",
  equals_gross_profit1: "",
  equals_gross_profit2: "",
  a_compensation_to_owner1: "",
  a_compensation_to_owner2: "",
  other_salaries_wages1: "",
  other_salaries_wages2: "",
  advertising_promotion1: "",
  advertising_promotion2: "",
  car_truck_expenses1: "",
  car_truck_expenses2: "",
  depreciation1: "",
  depreciation2: "",
  employee_benefits1: "",
  employee_benefits2: "",
  insurance_business1: "",
  insurance_business2: "",
  interest1: "",
  interest2: "",
  office_supplies_expenses1: "",
  office_supplies_expenses2: "",
  rent_or_lease_building1: "",
  rent_or_lease_building2: "",
  rent_or_lease_equipment1: "",
  rent_or_lease_equipment2: "",
  taxes_licenses1: "",
  taxes_licenses2: "",
  travel_entertainment1: "",
  travel_entertainment2: "",
  utilities1: "",
  utilities2: "",
  other_expenses1: "",
  other_expenses2: "",
  explanation_other_expenses: "",
  total_business_expenses1: "",
  total_business_expenses2: "",
  net_income1: "",
  net_income2: "",
  compensation_to_owner1: "",
  compensation_to_owner2: "",
  excessive_expenses1: "",
  excessive_expenses2: "",
  home_office_expenses1: "",
  home_office_expenses2: "",
  equipment_depreciation1: "",
  equipment_depreciation2: "",
  other1: "",
  other2: "",
  explanation_non_deductible_expenses: "",
  total_non_deductible_expenses1: "",
  total_non_deductible_expenses2: "",
  total_self_employment_income1: "",
  total_self_employment_income2: "",
  notes_self_employment_income: "",

  // Footnotes
  foot_note: [],

  // Work sheet stepper
  activeTab: "1",

  description: "",
  worksheet_id: 0,
  version_count:"",

  number_of_included_children: 0,
  gross_income_schedule_a: undefined,
  adjusted_income_schedule_b: undefined,
  health_insurance_child_care_schedule_d: undefined,
  deviations_schedule_e: undefined,
  self_employment_calculator: undefined
};

export default (
  state = initialState,
  { type, payload }: { type: string; payload: ActionPayload }
) => {
  switch (type) {
    case UPDATE_BASIC_INFO:
      if ("field" in payload) {
        return {
          ...state,
          [payload.field]: payload.value
        };
      }
      return state;

    case UPDATE_ARRAY_ITEM:
      if ("arrayKey" in payload && "newItemValues" in payload) {
        const arrayKey = payload.arrayKey as keyof typeof state; // Assert the arrayKey is a valid key of state
        const items = state[arrayKey];
        if (Array.isArray(items)) {
          if (payload.itemId) {
            // Update existing item
            return {
              ...state,
              [arrayKey]: items.map((item) =>
                item.id === payload.itemId
                  ? { ...item, ...payload.newItemValues }
                  : item
              )
            };
          } else {
            // Add new item
            const newItem = { ...payload.newItemValues, id: Date.now() }; // Assigning a new ID using Date.now() for example
            return {
              ...state,
              [arrayKey]: [...items, newItem]
            };
          }
        }
      }
      return state;

    case DELETE_ARRAY_ITEM:
      if ("arrayKey" in payload && "itemId" in payload) {
        const arrayKey = payload.arrayKey as keyof typeof state; // Assert the arrayKey is a valid key of state
        const items = state[arrayKey];
        if (Array.isArray(items)) {
          return {
            ...state,
            [arrayKey]: items.filter((item) => item.id !== payload.itemId)
          };
        }
      }
      return state;

    case EMPTY_BASIC_INFO_FIELDS:
      if ("fields" in payload && typeof payload.fields === "object") {
        return {
          ...state,
          ...payload.fields
        };
      }
      return state;
    case CREATE_WORKSHEET_DATA_EMPTY:
      return initialState;

    case SET_WORKSHEET_API_DATA:
      return {
        ...state,
        ...payload
      };

    default:
      return state;
  }
};
