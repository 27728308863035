import { getTotalProposedDeviation } from "../pages/worksheet/DeviationsTab/DeviationsValidationTabs";
import {
  CreateWorksheetInitialState,
  JudicialDiscretionForLowIncome,
  ProposedLowIncomeDeviation,
} from "../types/create-worksheet";
import moment from "moment";

export function SetWorksheetDataToRedux(
  worksheetData: CreateWorksheetInitialState
) {
  console.log(worksheetData, "**********WORKSHEETDATA");
  //// Code for Deviation Tab calculation start
  const proposedFinalResultDeviation = getProposedLowIncomeDeviationAmount(
    worksheetData.deviations_schedule_e.proposed_low_income_deviation,
    worksheetData.parent_name_1,
    worksheetData.parent_name_2
  );
  const judicialFinalResultDeviation = getJudicialDiscretionAppliedAmount(
    worksheetData.deviations_schedule_e.judicial_discretion_for_low_income,
    worksheetData.parent_name_1,
    worksheetData.parent_name_2
  );

  const parentsTotal = getTotalProposedDeviation(
    worksheetData.deviations_schedule_e.specific_nonspecific_deviation || []
  );
  const ProposedParent1Total = parentsTotal[worksheetData.parent_name_1];
  const ProposedParent2Total = parentsTotal[worksheetData.parent_name_2];

  ///get Explanation data start
  //// code for Proposed explanation
  const ExPropoValue1 = worksheetData.deviations_schedule_e
    .proposed_low_income_deviation
    ? worksheetData.deviations_schedule_e.proposed_low_income_deviation[0]
        ?.low_income_deviation_explanation
    : "";
  const ExPropoValue2 =
    worksheetData.deviations_schedule_e.proposed_low_income_deviation.length ===
    2
      ? worksheetData.deviations_schedule_e.proposed_low_income_deviation[1]
          ?.low_income_deviation_explanation
      : "";

  //// code for judicial explanation
  const ExJudiValue1 = worksheetData.deviations_schedule_e
    .judicial_discretion_for_low_income
    ? worksheetData.deviations_schedule_e.judicial_discretion_for_low_income[0]
        ?.low_income_deviation_explanation
    : "";
  const ExJudiValue2 =
    worksheetData.deviations_schedule_e.judicial_discretion_for_low_income
      .length === 2
      ? worksheetData.deviations_schedule_e
          ?.judicial_discretion_for_low_income[1]
          ?.low_income_deviation_explanation
      : "";

  const line1AValueExplanation = ExPropoValue1 || ExJudiValue1;
  const lineABValueExplanation = ExPropoValue2 || ExJudiValue2;
  // get Explanation data end

  //// Code for Deviation Tab calculation end

  const worksheetResult = {
    worksheet_id: worksheetData.worksheet_id,
    /// Basic Information Worksheet
    court_id: worksheetData.court_id.toString(),
    county_id: worksheetData.county_id.toString(),
    plaintiff_name: worksheetData.plaintiff_name,
    defendant_name: worksheetData.defendant_name,
    non_parent_custodian_name: worksheetData.non_parent_custodian_name,
    civil_action_case_number: worksheetData.civil_action_case_number,
    dhsc_no: worksheetData.dhsc_no,
    type_of_action: worksheetData.type_of_action,
    initial_support_order_date: worksheetData.initial_support_order_date,
    comments_for_the_court: worksheetData.comments_for_the_court,
    parent_name_1: worksheetData.parent_name_1,
    parent_name_2: worksheetData.parent_name_2,
    parentsNameFields: false,
    non_custodial_parent: worksheetData.non_custodial_parent,
    add_child_to_worksheet: worksheetData.add_child_to_worksheet,
    number_of_included_children: worksheetData.number_of_included_children,
    nameOfPersonPreparingWorksheet:
      worksheetData.nameOfPersonPreparingWorksheet,
    make_available_to_court_by: worksheetData.make_available_to_court_by,
    social_security_payment_1: worksheetData.social_security_payment_1,
    social_security_payment_2: worksheetData.social_security_payment_2,
    veterans_affairs_disability_payment_1:
      worksheetData.veterans_affairs_disability_payment_1,
    veterans_affairs_disability_payment_2:
      worksheetData.veterans_affairs_disability_payment_2,
    uninsured_health_expenses_1: worksheetData.uninsured_health_expenses_1,
    uninsured_health_expenses_2: worksheetData.uninsured_health_expenses_2,

    /// Gross Income Schedule A
    tanf1: worksheetData.gross_income_schedule_a.tanf1,
    tanf2: worksheetData.gross_income_schedule_a.tanf2,
    gross_salary1: worksheetData.gross_income_schedule_a.gross_salary1,
    gross_salary2: worksheetData.gross_income_schedule_a.gross_salary2,
    commissions1: worksheetData.gross_income_schedule_a.commissions1,
    commissions2: worksheetData.gross_income_schedule_a.commissions2,
    self_employment_income1:
      worksheetData.gross_income_schedule_a.self_employment_income1,
    self_employment_income2:
      worksheetData.gross_income_schedule_a.self_employment_income2,
    bonuses1: worksheetData.gross_income_schedule_a.bonuses1,
    bonuses2: worksheetData.gross_income_schedule_a.bonuses2,
    overtime_payment1: worksheetData.gross_income_schedule_a.overtime_payment1,
    overtime_payment2: worksheetData.gross_income_schedule_a.overtime_payment2,
    severence_pay1: worksheetData.gross_income_schedule_a.severence_pay1,
    severence_pay2: worksheetData.gross_income_schedule_a.severence_pay2,
    recurring_income1: worksheetData.gross_income_schedule_a.recurring_income1,
    recurring_income2: worksheetData.gross_income_schedule_a.recurring_income2,
    interest_income1: worksheetData.gross_income_schedule_a.interest_income1,
    interest_income2: worksheetData.gross_income_schedule_a.interest_income2,
    income_from_dividends1:
      worksheetData.gross_income_schedule_a.income_from_dividends1,
    income_from_dividends2:
      worksheetData.gross_income_schedule_a.income_from_dividends2,
    trust_income1: worksheetData.gross_income_schedule_a.trust_income1,
    trust_income2: worksheetData.gross_income_schedule_a.trust_income2,
    income_from_annuities1:
      worksheetData.gross_income_schedule_a.income_from_annuities1,
    income_from_annuities2:
      worksheetData.gross_income_schedule_a.income_from_annuities2,
    capital_gains1: worksheetData.gross_income_schedule_a.capital_gains1,
    capital_gains2: worksheetData.gross_income_schedule_a.capital_gains2,
    social_security_disablity_benefits1:
      worksheetData.gross_income_schedule_a.social_security_disablity_benefits1,
    social_security_disablity_benefits2:
      worksheetData.gross_income_schedule_a.social_security_disablity_benefits2,
    federal_veterans_disability_benefits1:
      worksheetData.gross_income_schedule_a
        .federal_veterans_disability_benefits1,
    federal_veterans_disability_benefits2:
      worksheetData.gross_income_schedule_a
        .federal_veterans_disability_benefits2,
    workers_compensation_benefits1:
      worksheetData.gross_income_schedule_a.workers_compensation_benefits1,
    workers_compensation_benefits2:
      worksheetData.gross_income_schedule_a.workers_compensation_benefits2,
    unemployment_benefits1:
      worksheetData.gross_income_schedule_a.unemployment_benefits1,
    unemployment_benefits2:
      worksheetData.gross_income_schedule_a.unemployment_benefits2,
    judgements1: worksheetData.gross_income_schedule_a.judgements1,
    judgements2: worksheetData.gross_income_schedule_a.judgements2,
    gifts1: worksheetData.gross_income_schedule_a.gifts1,
    gifts2: worksheetData.gross_income_schedule_a.gifts2,
    prizes1: worksheetData.gross_income_schedule_a.prizes1,
    prizes2: worksheetData.gross_income_schedule_a.prizes2,
    alimony1: worksheetData.gross_income_schedule_a.alimony1,
    alimony2: worksheetData.gross_income_schedule_a.alimony2,
    assets1: worksheetData.gross_income_schedule_a.assets1,
    assets2: worksheetData.gross_income_schedule_a.assets2,
    fringe_benefits1: worksheetData.gross_income_schedule_a.fringe_benefits1,
    fringe_benefits2: worksheetData.gross_income_schedule_a.fringe_benefits2,
    other_income1: worksheetData.gross_income_schedule_a.other_income1,
    other_income2: worksheetData.gross_income_schedule_a.other_income2,
    total_gross_income1:
      worksheetData.gross_income_schedule_a.total_gross_income1,
    total_gross_income2:
      worksheetData.gross_income_schedule_a.total_gross_income2,
    comment1: worksheetData.gross_income_schedule_a.comment1,
    comment2: worksheetData.gross_income_schedule_a.comment2,

    /// Adjusted Income Schedule B
    monthly_self_employment_income1:
      worksheetData.adjusted_income_schedule_b.monthly_self_employment_income1,
    monthly_self_employment_income2:
      worksheetData.adjusted_income_schedule_b.monthly_self_employment_income2,
    isAdjustmentAcknowledgeCheck: worksheetData.adjusted_income_schedule_b
      ?.pre_existing_child_support
      ? true
      : false,
    pre_existing_child_support: worksheetData.adjusted_income_schedule_b
      ?.pre_existing_child_support
      ? worksheetData.adjusted_income_schedule_b?.pre_existing_child_support?.map(
          (item: {
            date_of_initial_order: string;
            time_of_initial_order: string;
          }) => {
            if (item.time_of_initial_order) {
              item.time_of_initial_order = moment(
                item.time_of_initial_order,
                "HH:mm:ss"
              ).format("hh:mma");
            }
            if (item.date_of_initial_order) {
              item.date_of_initial_order = moment(
                item.date_of_initial_order
              ).format("MM/DD/YYYY");
            }
            return item;
          }
        )
      : [],
    proposed_adjustment_for_qualified_child: worksheetData
      .adjusted_income_schedule_b.proposed_adjustment_for_qualified_child
      ? worksheetData.adjusted_income_schedule_b.proposed_adjustment_for_qualified_child.map(
          (item: { adjustment_requested_by: string }) => {
            return {
              ...item,
              parentName1:
                item.adjustment_requested_by === worksheetData.parent_name_1
                  ? "1"
                  : "",
              parentName2:
                item.adjustment_requested_by === worksheetData.parent_name_2
                  ? "1"
                  : "",
            };
          }
        )
      : [],
    reason_for_including_other_child1:
      worksheetData.adjusted_income_schedule_b
        .reason_for_including_other_child1,
    reason_for_including_other_child2:
      worksheetData.adjusted_income_schedule_b
        .reason_for_including_other_child2,

    /// Health Insurance/Child Care Schedule D
    monthly_total1:
      worksheetData.health_insurance_child_care_schedule_d.monthly_total1,
    monthly_total2:
      worksheetData.health_insurance_child_care_schedule_d.monthly_total2,
    monthly_total3:
      worksheetData.health_insurance_child_care_schedule_d.monthly_total3,
    work_related_child_care:
      worksheetData.health_insurance_child_care_schedule_d
        .work_related_child_care,
    health_insurance_premiums_parent1:
      worksheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent1,
    health_insurance_premiums_parent2:
      worksheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent2,
    health_insurance_premiums_parent3:
      worksheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent3,

    /// Deviations Schedule E
    requestLowIncomeDeviationP1: line1AValueExplanation,
    requestLowIncomeDeviationP2: lineABValueExplanation,
    proposedLowIncomeDeviationAmoun1:
      proposedFinalResultDeviation.parent1 || "",
    proposedLowIncomeDeviationAmoun2:
      proposedFinalResultDeviation.parent2 || "",
    judicialDiscretionAppliedAmount1:
      judicialFinalResultDeviation.parent1 || "",
    judicialDiscretionAppliedAmount2:
      judicialFinalResultDeviation.parent2 || "",
    proposed_low_income_deviation:
      worksheetData.deviations_schedule_e.proposed_low_income_deviation,
    judicial_discretion_for_low_income:
      worksheetData.deviations_schedule_e.judicial_discretion_for_low_income,
    specific_nonspecific_deviation:
      worksheetData.deviations_schedule_e.specific_nonspecific_deviation,
    proposed_Deviation_Total1: ProposedParent1Total || "",
    proposed_Deviation_Total2: ProposedParent2Total || "",
    parenting_time_deviation1:
      worksheetData.deviations_schedule_e.parenting_time_deviation1,
    parenting_time_deviation2:
      worksheetData.deviations_schedule_e.parenting_time_deviation2,
    extra_ordinary_educational_expenses:
      worksheetData.deviations_schedule_e.extra_ordinary_educational_expenses,
    extraordinary_educational_expenses_total1:
      worksheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total1,
    extraordinary_educational_expenses_total2:
      worksheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total2,
    extraordinary_educational_expenses_total3:
      worksheetData.deviations_schedule_e
        .extraordinary_educational_expenses_total3,
    extra_ordinary_medical_expenses:
      worksheetData.deviations_schedule_e.extra_ordinary_medical_expenses,
    extraordinary_medical_expenses_total1:
      worksheetData.deviations_schedule_e.extraordinary_medical_expenses_total1,
    extraordinary_medical_expenses_total2:
      worksheetData.deviations_schedule_e.extraordinary_medical_expenses_total2,
    extraordinary_medical_expenses_total3:
      worksheetData.deviations_schedule_e.extraordinary_medical_expenses_total3,
    special_expenses_child_rearing:
      worksheetData.deviations_schedule_e.special_expenses_child_rearing,
    special_expenses_child_rearing_total1:
      worksheetData.deviations_schedule_e.special_expenses_child_rearing_total1,
    special_expenses_child_rearing_total2:
      worksheetData.deviations_schedule_e.special_expenses_child_rearing_total2,
    special_expenses_child_rearing_total3:
      worksheetData.deviations_schedule_e.special_expenses_child_rearing_total3,
    unjust_or_inappropriate:
      worksheetData.deviations_schedule_e.unjust_or_inappropriate,
    best_interests_of_children:
      worksheetData.deviations_schedule_e.best_interests_of_children,
    impair_ability_to_maintain:
      worksheetData.deviations_schedule_e.impair_ability_to_maintain,

    /// Selft-Employment Calculator
    business_description1:
      worksheetData.self_employment_calculator.business_description1,
    business_description2:
      worksheetData.self_employment_calculator.business_description2,
    business_name1: worksheetData.self_employment_calculator.business_name1,
    business_name2: worksheetData.self_employment_calculator.business_name2,
    business_type1: worksheetData.self_employment_calculator.business_type1,
    business_type2: worksheetData.self_employment_calculator.business_type2,
    gross_receipts1: worksheetData.self_employment_calculator.gross_receipts1,
    gross_receipts2: worksheetData.self_employment_calculator.gross_receipts2,
    cost_of_sales1: worksheetData.self_employment_calculator.cost_of_sales1,
    cost_of_sales2: worksheetData.self_employment_calculator.cost_of_sales2,
    equals_gross_profit1:
      worksheetData.self_employment_calculator.equals_gross_profit1,
    equals_gross_profit2:
      worksheetData.self_employment_calculator.equals_gross_profit2,
    a_compensation_to_owner1:
      worksheetData.self_employment_calculator.a_compensation_to_owner1,
    a_compensation_to_owner2:
      worksheetData.self_employment_calculator.a_compensation_to_owner2,
    other_salaries_wages1:
      worksheetData.self_employment_calculator.other_salaries_wages1,
    other_salaries_wages2:
      worksheetData.self_employment_calculator.other_salaries_wages2,
    advertising_promotion1:
      worksheetData.self_employment_calculator.advertising_promotion1,
    advertising_promotion2:
      worksheetData.self_employment_calculator.advertising_promotion2,
    car_truck_expenses1:
      worksheetData.self_employment_calculator.car_truck_expenses1,
    car_truck_expenses2:
      worksheetData.self_employment_calculator.car_truck_expenses2,
    depreciation1: worksheetData.self_employment_calculator.depreciation1,
    depreciation2: worksheetData.self_employment_calculator.depreciation2,
    employee_benefits1:
      worksheetData.self_employment_calculator.employee_benefits1,
    employee_benefits2:
      worksheetData.self_employment_calculator.employee_benefits2,
    insurance_business1:
      worksheetData.self_employment_calculator.insurance_business1,
    insurance_business2:
      worksheetData.self_employment_calculator.insurance_business2,
    interest1: worksheetData.self_employment_calculator.interest1,
    interest2: worksheetData.self_employment_calculator.interest2,
    office_supplies_expenses1:
      worksheetData.self_employment_calculator.office_supplies_expenses1,
    office_supplies_expenses2:
      worksheetData.self_employment_calculator.office_supplies_expenses2,
    rent_or_lease_building1:
      worksheetData.self_employment_calculator.rent_or_lease_building1,
    rent_or_lease_building2:
      worksheetData.self_employment_calculator.rent_or_lease_building2,
    rent_or_lease_equipment1:
      worksheetData.self_employment_calculator.rent_or_lease_equipment1,
    rent_or_lease_equipment2:
      worksheetData.self_employment_calculator.rent_or_lease_equipment2,
    taxes_licenses1: worksheetData.self_employment_calculator.taxes_licenses1,
    taxes_licenses2: worksheetData.self_employment_calculator.taxes_licenses2,
    travel_entertainment1:
      worksheetData.self_employment_calculator.travel_entertainment1,
    travel_entertainment2:
      worksheetData.self_employment_calculator.travel_entertainment2,
    utilities1: worksheetData.self_employment_calculator.utilities1,
    utilities2: worksheetData.self_employment_calculator.utilities2,
    other_expenses1: worksheetData.self_employment_calculator.other_expenses1,
    other_expenses2: worksheetData.self_employment_calculator.other_expenses2,
    explanation_other_expenses:
      worksheetData.self_employment_calculator.explanation_other_expenses,
    total_business_expenses1:
      worksheetData.self_employment_calculator.total_business_expenses1,
    total_business_expenses2:
      worksheetData.self_employment_calculator.total_business_expenses2,
    net_income1: worksheetData.self_employment_calculator.net_income1,
    net_income2: worksheetData.self_employment_calculator.net_income2,
    compensation_to_owner1:
      worksheetData.self_employment_calculator.compensation_to_owner1,
    compensation_to_owner2:
      worksheetData.self_employment_calculator.compensation_to_owner2,
    excessive_expenses1:
      worksheetData.self_employment_calculator.excessive_expenses1,
    excessive_expenses2:
      worksheetData.self_employment_calculator.excessive_expenses2,
    home_office_expenses1:
      worksheetData.self_employment_calculator.home_office_expenses1,
    home_office_expenses2:
      worksheetData.self_employment_calculator.home_office_expenses2,
    equipment_depreciation1:
      worksheetData.self_employment_calculator.equipment_depreciation1,
    equipment_depreciation2:
      worksheetData.self_employment_calculator.equipment_depreciation2,
    other1: worksheetData.self_employment_calculator.other1,
    other2: worksheetData.self_employment_calculator.other2,
    explanation_non_deductible_expenses:
      worksheetData.self_employment_calculator
        .explanation_non_deductible_expenses,
    total_non_deductible_expenses1:
      worksheetData.self_employment_calculator.total_non_deductible_expenses1,
    total_non_deductible_expenses2:
      worksheetData.self_employment_calculator.total_non_deductible_expenses2,
    total_self_employment_income1:
      worksheetData.self_employment_calculator.total_self_employment_income1,
    total_self_employment_income2:
      worksheetData.self_employment_calculator.total_self_employment_income2,
    notes_self_employment_income:
      worksheetData.self_employment_calculator.notes_self_employment_income,

    /// Footnotes
    foot_note: worksheetData.foot_note,

    description: worksheetData.description,
    version_count: worksheetData.version_count,
  };

  return worksheetResult;
}

/// handle get Proposed Low Income Deviations Amount
function getProposedLowIncomeDeviationAmount(
  arrayList: ProposedLowIncomeDeviation[],
  parent1: string,
  parent2: string
) {
  const resultP1 = arrayList.find(
    (item) => item.deviation_is_for === parent1
  )?.low_income_deviation;
  const resultP2 = arrayList.find(
    (item) => item.deviation_is_for === parent2
  )?.low_income_deviation;

  return { parent1: resultP1, parent2: resultP2 };
}

/// handle get Judicial Discretion Applied Amount
function getJudicialDiscretionAppliedAmount(
  arrayList: JudicialDiscretionForLowIncome[],
  parent1: string,
  parent2: string
) {
  const resultP1 = arrayList.find(
    (item) => item.deviation_is_for === parent1
  )?.low_income_deviation;
  const resultP2 = arrayList.find(
    (item) => item.deviation_is_for === parent2
  )?.low_income_deviation;

  return { parent1: resultP1, parent2: resultP2 };
}
