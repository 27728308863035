import { httpTokenCommon } from "../interceptor";

interface TableSearchData {
  user_name: string;
  user_email: string;
}

class OrganizationService {
  createOrganizationAPI(inputdata: { description: string }) {
    return httpTokenCommon.post("organization/create_organization/", inputdata);
  }
  updateOrganizationAPI(inputdata: { description: string }) {
    return httpTokenCommon.post("organization/update_organization/", inputdata);
  }
  inviteOrganizationMemberAPI(inputdata: { member_email: string }) {
    return httpTokenCommon.post("organization/invite_member/", inputdata);
  }
  getOrganizationAPI() {
    return httpTokenCommon.get("organization/organization_name/");
  }
  getOrganizationMemberInvitedListAPI() {
    return httpTokenCommon.get(
      "organization/members_invited_to_join_organization/"
    );
  }
  changeOrganizationUserAPI(inputData: { email: string }) {
    return httpTokenCommon.post(
      "organization/change_administrartor/",
      inputData
    );
  }
  deleteOrganizationUserAPI(inputData: { email: string }) {
    return httpTokenCommon.post("organization/remove_member/", inputData);
  }
  deleteOrganizationAPI() {
    return httpTokenCommon.delete("organization/delete_organization/");
  }
  //Become An Organization Member
  getInviteFromOrganization() {
    return httpTokenCommon.get("organization/invite_from_organization/");
  }
  updateOrganizationInvitation = (data: { accepted: boolean }) => {
    return httpTokenCommon.post("organization/invitation_accepted/", data);
  };

  getOrganizationUserListAPI() {
    return httpTokenCommon.get("organization/fetch_org_mem/");
  }
  getOrganizationMemberListAPI(
    sortConfig: {
      key: string;
      direction: "asc" | "desc";
    },
    searchData: TableSearchData
  ) {
    return httpTokenCommon.get(
      `organization/orgnization_members/?sort_by=${sortConfig.key}&sort_order=${sortConfig.direction}&email=${searchData.user_email}&username=${searchData.user_name}`
    );
  }
}

const organizationService = new OrganizationService();
export default organizationService;
