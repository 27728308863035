// Action Types

import {
  CreateWorksheetInitialState,
  EmptyBasicInfoFieldsPayload,
  UpdateArrayItemPayload
} from "../../../types/create-worksheet";
import {
  DELETE_ARRAY_ITEM,
  EMPTY_BASIC_INFO_FIELDS,
  UPDATE_ARRAY_ITEM,
  UPDATE_BASIC_INFO,
  CREATE_WORKSHEET_DATA_EMPTY,
  SET_WORKSHEET_API_DATA
} from "../../actions-Types/authActionTypes";

/**
 * Creates an action to update basic information in the state.
 * @param {string} field The field in the state to update.
 * @param {string} value The new value for the field.
 * @returns {Object} The action object.
 */

export const updateBasicInfo = (field: string, value: string | boolean) => ({
  type: UPDATE_BASIC_INFO,
  payload: { field, value }
});

export const updateArrayInfo = (data: UpdateArrayItemPayload) => ({
  type: UPDATE_ARRAY_ITEM,
  payload: data
});
export const deleteFromArray = (data: {
  arrayKey: string;
  itemId: string;
}) => ({
  type: DELETE_ARRAY_ITEM,
  payload: data
});
export const emptyBasicInfoFields = (data: EmptyBasicInfoFieldsPayload) => ({
  type: EMPTY_BASIC_INFO_FIELDS,
  payload: data
});
export const createWorksheetDataEmpty = () => ({
  type: CREATE_WORKSHEET_DATA_EMPTY,
  payload: undefined
});
export const setWorksheetAPIData = (data: CreateWorksheetInitialState) => ({
  type: SET_WORKSHEET_API_DATA,
  payload: data
});
