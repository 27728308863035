// src/components/Loader.tsx
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const PageLoader: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        position: 'absolute',
        top: 0,
        left: 0,

      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default PageLoader;
