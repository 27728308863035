import React, { useState, useImperativeHandle, forwardRef } from "react";
import { Box, Grid, Typography } from "@mui/material";
import CustomInput from "../inputField";
import TextareaField from "../text-area";
import styles from "./reportTechnical.module.css";

export interface ReportTechnicalFormHandle {
  submitForm: () => void;
}

interface ReportInputValues {
  worksheet_id: string;
  email: string;
  browser: string;
  name: string;
  phone: string;
  technical_issue: string;
  captcha: string;
}

interface ReportTechnicalFormProps {
  inputCaptcha: string;
  handleSubmitTechnicalReport: (inputData: ReportInputValues) => void;
}

const ReportTechnicalForm = forwardRef<
  ReportTechnicalFormHandle,
  ReportTechnicalFormProps
>(({ inputCaptcha, handleSubmitTechnicalReport }, ref) => {
  const [errors, setErrors] = useState<Partial<ReportInputValues>>({});
  const [reportInputValues, setReportInputValues] = useState<ReportInputValues>(
    {
      worksheet_id: "",
      email: "",
      browser: "",
      name: "",
      phone: "",
      technical_issue: "",
      captcha: "",
    }
  );

  const handleReportInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setReportInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useImperativeHandle(ref, () => ({
    submitForm: onSubmitData,
  }));

  const formValidate = (): boolean => {
    const newErrors: Partial<ReportInputValues> = {};

    if (!reportInputValues.worksheet_id.trim()) {
      newErrors.worksheet_id = "Worksheet ID cannot be blank";
    }
    if (!reportInputValues.email.trim()) {
      newErrors.email = "Email Address cannot be blank";
    } else if (!/\S+@\S+\.\S+/.test(reportInputValues.email)) {
      newErrors.email = "Email Address is invalid";
    }
    if (!reportInputValues.technical_issue.trim()) {
      newErrors.technical_issue = "Technical issue cannot be blank";
    }

    if (reportInputValues.phone.length > 10) {
      newErrors.phone = "Phone Number must be 10 digits";
    }

    if (!reportInputValues.captcha.trim()) {
      newErrors.captcha = "Captcha cannot be blank";
    } else if (reportInputValues.captcha.trim() !== inputCaptcha) {
      newErrors.captcha = "Captcha is not correct.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmitData = async () => {
    if (formValidate()) {
      handleSubmitTechnicalReport(reportInputValues);
    }
  };

  const renderCaptcha = (captcha: string) => {
    return captcha.split("").map((char, index) => (
      <span
        key={index}
        style={
          {
            fontSize: 20,
          } as React.CSSProperties
        }
      >
        {char}
      </span>
    ));
  };

  return (
    <>
      <Typography
        gutterBottom
        id="modal-modal-description"
        sx={{

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          borderBottom: "1px solid #DFDFDF",
          pb: 1,
        }}
      >
        Enter the Technical Issue that you encountered in the &quot;Technical
        Issue&quot; field and then press the &quot;Send&quot; Button to send an
        email to Technical Support
      </Typography>
      <Box sx={{ pt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="worksheet_id"
              label="Worksheet ID"
              value={reportInputValues.worksheet_id}
              onChange={handleReportInputChange}
              placeholder="Enter Worksheet ID"
              error={errors.worksheet_id}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="email"
              label="Email"
              value={reportInputValues.email}
              onChange={handleReportInputChange}
              placeholder="example@gmail.com"
              error={errors.email}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CustomInput
              name="browser"
              label="Browser"
              value={reportInputValues.browser}
              onChange={handleReportInputChange}
              placeholder="Enter Browser Details"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="name"
              label="Name"
              value={reportInputValues.name}
              onChange={handleReportInputChange}
              placeholder="Enter Name"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="phone"
              label="Phone Number"
              type="number"
              value={reportInputValues.phone}
              onChange={handleReportInputChange}
              placeholder="Enter Phone Number"
              inputProps={{ maxLength: 10 }}
              error={errors.phone}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextareaField
              name="technical_issue"
              label="Technical Issue"
              value={reportInputValues.technical_issue}
              onChange={handleReportInputChange}
              placeholder="Write Issue"
              error={errors.technical_issue}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={12} display="flex" paddingTop={"0px!important"}>
            <CustomInput
              name="captcha"
              label="Captcha"
              value={reportInputValues.captcha}
              onChange={handleReportInputChange}
              placeholder="Enter Captcha"
              error={errors.captcha}
              required={true}
            />
            <Box
              pt={3.5}
              ml={{ xs: 2.5, sm: 3, md: 3 }}
              className={styles.captchaText}
            >
              {renderCaptcha(inputCaptcha)}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
});
ReportTechnicalForm.displayName = "ReportTechnicalForm";

export default ReportTechnicalForm;
