import { combineReducers } from "redux";
import authReducer from "./reducers/auth-reducer/index";
import createWorksheet from "./reducers/create-worksheet/index";
import headerURLData from "./reducers/header/index";

const rootReducer = combineReducers({
  authReducer,
  createWorksheet,
  headerURLData
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
