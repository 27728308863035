export function InputIncomecalculation(
  inputIncome: string | number,
  customIncome: number,
  selectTime: string
) {
  let finalResult = 0;

  switch (selectTime) {
    case "Hourly":
      finalResult = Number(inputIncome) * customIncome * 4.35;
      break;
    case "Weekly":
      finalResult = Number(inputIncome) * 4.35;
      break;
    case "Bi-Weekly":
      finalResult = (Number(inputIncome) * 26) / 12;
      break;
    case "Semi-Monthly":
      finalResult = Number(inputIncome) * 2;
      break;
    case "Yearly":
      finalResult = Number(inputIncome) / 12;
      break;
  }
  return finalResult.toFixed(2);
}
