import {
  httpCommon,
  httpCommonFileDownload,
  httpTokenCommon
} from "./interceptor";

interface tableList {
  pageIndex: number;
  perPage: number;
  tableSearchData: {
    term: string;
    explanation: string;
  };
  tableOrdering: string;
}
interface addUpdateAdminFAQ {
  term: string;
  explanation: string;
}

class FAQService {
  getFAQsDataAPI() {
    return httpCommon.get("faq_api/faqs/");
  }
  /// API called for Download FAQs data
  getDownloadFAQsDataAPI(key: string, download_ids: number[]) {
    return httpCommonFileDownload.get(
      `faq_api/faqs/download?download_format=${key}&download_ids=${download_ids}`
    );
  }

  ////Admin FAQ code
  getAdminFAQsDataAPI(inputdata: tableList) {
    const { pageIndex, perPage, tableSearchData, tableOrdering } = inputdata;
    return httpCommon.get(
      `faq_api/faqs/?limit=${perPage}&page=${pageIndex}&term=${tableSearchData.term}&explanation=${tableSearchData.explanation}&ordering=${tableOrdering}`
    );
  }
  addAdminFAQAPI(inputdata: addUpdateAdminFAQ) {
    return httpTokenCommon.post("faq_api/faqs/", inputdata);
  }
  updateAdminFAQAPI(inputdata: addUpdateAdminFAQ, id: number) {
    return httpTokenCommon.put(`faq_api/admin_update/faqs/${id}/`, inputdata);
  }
  deleteFAQDataAPI(id: number) {
    return httpTokenCommon.delete(`faq_api/admin_update/faqs/${id}/`);
  }
}

const fAQService = new FAQService();
export default fAQService;
