import { httpTokenFileDownload } from "../interceptor";

class certificateOfService {
  PDFDownload(id: number,) {
    return httpTokenFileDownload.post(`worksheet/certificate_of_service/${id}/`, {
      responseType: 'blob'
    });
  }
}

const CertificateOfService = new certificateOfService();
export default CertificateOfService;
