import {
  httpCommon,
  httpTokenCommon,
  httpTokenFileDownload,
} from "../interceptor";

interface tableList {
  pageIndex: number;
  perPage: number;
  tableSearchData: {
    plaintiff_name: string;
    defendant_name: string;
    county_name: string;
    court_name: string;
    civil_action_case_number: string;
    dhsc_no: string;
    prepared_by: string;
  };
  tableOrdering: string;
}

class SearchFolderWorksheetService {
  /// get Court list API
  getCourtListAPI() {
    return httpCommon.get("users/court-list");
  }

  /// get country list API
  getCountyListAPI() {
    return httpCommon.get("users/county-list");
  }

  //// get search Folder worksheet API endpoint
  getSearchFolderWorksheetTblListAPI(inputdata: tableList) {
    const { pageIndex, perPage, tableSearchData, tableOrdering } = inputdata;
    return httpTokenCommon.get(
      `worksheet/user_worksheet_list/?limit=${perPage}&page=${pageIndex}&plaintiff_name=${tableSearchData.plaintiff_name}&defendant_name=${tableSearchData.defendant_name}
      &county_name=${tableSearchData.county_name}&court_name=${tableSearchData.court_name}&civil_action_case_number=${tableSearchData.civil_action_case_number}
      &dhsc_no=${tableSearchData.dhsc_no}&prepared_by=${tableSearchData.prepared_by}&ordering=${tableOrdering}`
    );
  }

  //// get search Folder worksheet Sub table data by ID API endpoint
  getSearchFolderWorksheetSubTblListAPI(id: number | string) {
    return httpTokenCommon.get(
      `worksheet/worksheet_version_list/?worksheet_ids=${id}`
    );
  }

  //// get search Folder worksheet Sub table data by ID API endpoint
  getShareWorksheetAPI(
    inputData: { share_email: string; share_type: string; share_term: string },
    id: number
  ) {
    return httpTokenCommon.post(`worksheet/share_worksheet/${id}/`, inputData);
  }

  //// API called for Copy worksheet data
  postCopyWorksheetAPI(id: number) {
    return httpTokenCommon.post(`worksheet/copy_worksheet/${id}/`);
  }

  //// API called for recall from court data
  postRecallFromCourtAPI(id: number) {
    return httpTokenCommon.post(`worksheet/recall_from_court/${id}/`);
  }

  //// get search Folder worksheet Sub table data by ID API endpoint
  getDownloadWorkSheetAPI(key: string, download_ids: number[]) {
    return httpTokenFileDownload.get(
      `worksheet/download_worksheet/?download_format=${key}&download_ids=${download_ids}`
    );
  }

  //// API called for recall from court data
  worksheetArchiveAPI(id: number) {
    return httpTokenCommon.delete(`worksheet/archive/${id}/`);
  }

  /// fetch worksheet data by ID
  getWorksheetInfoDataAPI(id: number) {
    return httpTokenCommon.get(`worksheet/basic_info/${id}/`);
  }

  //// post make available to court by ID API endpoint
  postMakeAvailableToCourtAPI(
    inputData: { make_available_to_court_by: string },
    worksheetId: number
  ) {
    return httpTokenCommon.post(
      `worksheet/make_available_to_court/${worksheetId}/`,
      inputData
    );
  }
}

const searchFolderWorksheet = new SearchFolderWorksheetService();
export default searchFolderWorksheet;
