import { httpCommon } from "./interceptor";

interface ChildSupportObligationListInput {
  pageIndex: number;
  perPage: number;
}

class BasicChildSupportService {
  getChildSupportObligationListAPI(inputdata: ChildSupportObligationListInput) {
    const { pageIndex, perPage } = inputdata;
    return httpCommon.get(
      `child_obligation_api/child_support_obligation_list/?page=${pageIndex}&page_size=${perPage}`
    );
  }
}

const basicChildSupportService = new BasicChildSupportService();
export default basicChildSupportService;
