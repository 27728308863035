import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import style from "../searchWorksheet.module.css";
import SearchWorksheetButtons from "../../../components/searchWorksheetButtons";
import { useAppSelector } from "../../../globalState/hooks";
import { getBasicFinalValueFor2Line } from "../../../utils/getBasicInfoCalculate";
import FootnoteImage from "../../../assets/images/FootnoteImg.png";
import {
  formatNumber,
  formatNumberWithDigit,
} from "../../../utils/formatNumber";

const HealthInsuranceTab: React.FC = () => {
  const WorkSheetData = useAppSelector((state) => state.createWorksheet);
  const { FinalValue1, FinalValue2 } =
    getBasicFinalValueFor2Line(WorkSheetData);

  const summaryData = [
    {
      description: `Work Related Child Care Paid By ${WorkSheetData.parent_name_1}:`,
      amount: WorkSheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent1
        ? "$" +
          formatNumber(
            WorkSheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent1
          )
        : "",
      averageDescription: `Work Related Child Care Monthly Average For ${WorkSheetData.parent_name_1}:`,
      averageAmount: WorkSheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent1
        ? "$" +
          formatNumberWithDigit(
            WorkSheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent1 / 12
          )
        : "",
    },
    {
      description: `Work Related Child Care Paid By ${WorkSheetData.parent_name_2}:`,
      amount: WorkSheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent2
        ? "$" +
          formatNumber(
            WorkSheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent2
          )
        : "",
      averageDescription: `Work Related Child Care Monthly Average For ${WorkSheetData.parent_name_2}:`,
      averageAmount: WorkSheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent2
        ? "$" +
          formatNumberWithDigit(
            WorkSheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent2 / 12
          )
        : "",
    },
    {
      description: "Work Related Child Care Paid By Nonparent Custodian:",
      amount: WorkSheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent3
        ? "$" +
          formatNumber(
            WorkSheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent3
          )
        : "",
      averageDescription:
        "Work Related Child Care Monthly Average For Nonparent Custodian:",
      averageAmount: WorkSheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent3
        ? "$" +
          formatNumberWithDigit(
            WorkSheetData.health_insurance_child_care_schedule_d
              .health_insurance_premiums_parent3 / 12
          )
        : "",
    },
  ];

  const line1Total =
    (WorkSheetData.health_insurance_child_care_schedule_d
      .health_insurance_premiums_parent1 ||
      WorkSheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent2 ||
      WorkSheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent3) &&
    (
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d
          .health_insurance_premiums_parent1
      ) /
        12 +
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d
          .health_insurance_premiums_parent2
      ) /
        12 +
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d
          .health_insurance_premiums_parent3
      ) /
        12
    ).toFixed(2);

  const line2Total =
    (WorkSheetData?.health_insurance_child_care_schedule_d?.monthly_total1 ||
      WorkSheetData?.health_insurance_child_care_schedule_d?.monthly_total2 ||
      WorkSheetData?.health_insurance_child_care_schedule_d?.monthly_total3) &&
    Number(
      WorkSheetData.health_insurance_child_care_schedule_d.monthly_total1 || 0
    ) +
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d.monthly_total2 || 0
      ) +
      Number(
        WorkSheetData.health_insurance_child_care_schedule_d.monthly_total3 || 0
      );
  const fetchValueTotal = (Number(FinalValue1) + Number(FinalValue2)).toFixed(
    2
  );

  const line3TotalValue = Number(line1Total) + Number(line2Total);
  const line4Value1 =
    Number(FinalValue1) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue1) * 100) / Number(fetchValueTotal), 0),
          100
        ).toFixed(2);

  const line4Value2 =
    Number(FinalValue2) < 0
      ? "0.00"
      : Math.min(
          Math.max((Number(FinalValue2) * 100) / Number(fetchValueTotal), 0),
          100
        ).toFixed(2);

  const line5TotalValue =
    (Number(line4Value1) * line3TotalValue) / 100 +
    (Number(line4Value2) * line3TotalValue) / 100;
  return (
    <>
      {WorkSheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent1 ||
      WorkSheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent2 ||
      WorkSheetData.health_insurance_child_care_schedule_d
        .health_insurance_premiums_parent3 ||
      WorkSheetData.health_insurance_child_care_schedule_d.monthly_total1 !==
        null ||
      WorkSheetData.health_insurance_child_care_schedule_d.monthly_total2 !==
        null ||
      WorkSheetData.health_insurance_child_care_schedule_d.monthly_total3 !==
        null ? (
        <>
          <Box display="flex" justifyContent="center">
            <Typography
              className={style.contentHeading}
              marginTop={"15px !important"}
            >
              CHILD SUPPORT SCHEDULE D HEALTH INSURANCE & WORK RELATED CHILD
              CARE
            </Typography>
          </Box>
          <Box sx={{ pt: 3 }}>
            <TableContainer>
              <Table
                className="custom-table"
                aria-label="customized table"
                sx={{ minWidth: 900 }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={style.headerStyle}>
                      Schedule D - All amounts/data that display on Schedule D
                      were entered using the Online Child Support Calculator and
                      can only be changed by selecting the button “Open This
                      Worksheet.” Annual amounts entered convert to monthly sums
                      used in calculations. Totals from Line 3 will display on
                      Line 8 of the Worksheet. Totals from Line 5 will display
                      on Line 6 of the Worksheet.
                    </TableCell>
                    <TableCell className={style.headerStyle}>
                      {WorkSheetData.parent_name_1}
                    </TableCell>
                    <TableCell className={style.headerStyle}>
                      {WorkSheetData.parent_name_2}
                    </TableCell>
                    <TableCell className={style.headerStyle}>
                      Nonparent Custodian
                    </TableCell>
                    <TableCell className={style.headerStyle}>
                      Combined
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      1. Work Related Child Care expenses necessary for a
                      parent’s employment, education or vocational training.
                      Includes monthly average amounts paid by each parent (or
                      nonparent custodian) for children included in this action
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      {WorkSheetData.health_insurance_child_care_schedule_d
                        .health_insurance_premiums_parent1
                        ? "$" +
                          formatNumberWithDigit(
                            WorkSheetData.health_insurance_child_care_schedule_d
                              .health_insurance_premiums_parent1 / 12
                          )
                        : ""}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      {WorkSheetData.health_insurance_child_care_schedule_d
                        .health_insurance_premiums_parent2
                        ? "$" +
                          formatNumberWithDigit(
                            WorkSheetData.health_insurance_child_care_schedule_d
                              .health_insurance_premiums_parent2 / 12
                          )
                        : ""}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      {WorkSheetData.health_insurance_child_care_schedule_d
                        .health_insurance_premiums_parent3
                        ? "$" +
                          formatNumberWithDigit(
                            WorkSheetData.health_insurance_child_care_schedule_d
                              .health_insurance_premiums_parent3 / 12
                          )
                        : ""}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      {line1Total ? "$" + formatNumber(Number(line1Total)) : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      2. Health Insurance Premiums paid for the children.
                      Includes monthly amounts paid (or that will be paid) by
                      each Parent or Nonparent Custodian for health insurance
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.headerStyle2}
                    >
                      {WorkSheetData?.health_insurance_child_care_schedule_d
                        ?.monthly_total1
                        ? "$" +
                          formatNumber(
                            WorkSheetData
                              ?.health_insurance_child_care_schedule_d
                              ?.monthly_total1
                          )
                        : ""}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.headerStyle2}
                    >
                      {WorkSheetData?.health_insurance_child_care_schedule_d
                        ?.monthly_total2
                        ? "$" +
                          formatNumber(
                            WorkSheetData
                              ?.health_insurance_child_care_schedule_d
                              ?.monthly_total2
                          )
                        : ""}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.headerStyle2}
                    >
                      {WorkSheetData?.health_insurance_child_care_schedule_d
                        ?.monthly_total3
                        ? "$" +
                          formatNumber(
                            WorkSheetData
                              ?.health_insurance_child_care_schedule_d
                              ?.monthly_total3
                          )
                        : ""}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      {line2Total ? "$" + formatNumber(Number(line2Total)) : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      3. Total Monthly Additional Expenses. (Line 1 plus Line 2)
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      $
                      {formatNumberWithDigit(
                        Number(
                          WorkSheetData.health_insurance_child_care_schedule_d
                            .health_insurance_premiums_parent1
                        ) /
                          12 +
                          Number(
                            WorkSheetData
                              ?.health_insurance_child_care_schedule_d
                              ?.monthly_total1
                          )
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      $
                      {formatNumberWithDigit(
                        Number(
                          WorkSheetData.health_insurance_child_care_schedule_d
                            .health_insurance_premiums_parent2
                        ) /
                          12 +
                          Number(
                            WorkSheetData
                              ?.health_insurance_child_care_schedule_d
                              ?.monthly_total2
                          )
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      $
                      {formatNumberWithDigit(
                        Number(
                          WorkSheetData.health_insurance_child_care_schedule_d
                            .health_insurance_premiums_parent3
                        ) /
                          12 +
                          Number(
                            WorkSheetData
                              ?.health_insurance_child_care_schedule_d
                              ?.monthly_total3
                          )
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      ${formatNumber(Number(line3TotalValue))}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      4. Pro Rata Share of parent’s income. (From Child Support
                      Worksheet Line 3)
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      {line4Value1 ? line4Value1 + "%" : ""}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      {line4Value2 ? line4Value2 + "%" : ""}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ background: "#F8F8F8" }}
                      className={style.cellStyle}
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      100.00%
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      5. Pro Rata Share of Additional Expenses. (Amount in the
                      Combined column, Line 3, multiplied by the percentages on
                      Line 4. Results display on Line 6 of the Worksheet)
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      $
                      {formatNumberWithDigit(
                        (Number(line4Value1) * line3TotalValue) / 100
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      $
                      {formatNumberWithDigit(
                        (Number(line4Value2) * line3TotalValue) / 100
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ background: "#F8F8F8" }}
                      className={style.cellStyle}
                    ></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={style.cellStyle}
                    >
                      ${formatNumberWithDigit(line5TotalValue)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {WorkSheetData.health_insurance_child_care_schedule_d
            .work_related_child_care &&
          WorkSheetData.health_insurance_child_care_schedule_d
            .work_related_child_care.length > 0 ? (
            <Box mt={1}>
              <TableContainer>
                <Typography
                  className={style.contentHeading}
                  sx={{ bgcolor: "#DFDFDF", p: 1, textAlign: "center" }}
                >
                  Work Related Child Care
                </Typography>
                <Table
                  className="custom-table"
                  aria-label="customized table"
                  sx={{ minWidth: "900px" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={style.headerStyle}>
                        Child Name
                      </TableCell>
                      <TableCell align="center" className={style.headerStyle}>
                        Paid By
                      </TableCell>
                      <TableCell align="center" className={style.headerStyle}>
                        During School
                      </TableCell>
                      <TableCell
                        align="center"
                        className={style.headerStyle}
                        sx={{ mt: 1 }}
                      >
                        <Box> During Summer</Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={style.headerStyle}
                        sx={{ mt: 1 }}
                      >
                        <Box> During Breaks</Box>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={style.headerStyle}
                        sx={{ mt: 1 }}
                      >
                        <Box> Others</Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {WorkSheetData.health_insurance_child_care_schedule_d
                      .work_related_child_care &&
                      WorkSheetData.health_insurance_child_care_schedule_d.work_related_child_care.map(
                        (
                          row: {
                            child_name: string;
                            paid_by: string;
                            child_care_school_yearly: string;
                            child_care_summer_yearly: string;
                            child_care_other_breaks_yearly: string;
                            child_care_preschool_disability_yearly: string;
                          },
                          index: number
                        ) => (
                          <TableRow key={index}>
                            <TableCell
                              component="th"
                              scope="row"
                              className={style.cellStylehealth}
                              sx={{ bgcolor: "#cefbc9" }}
                            >
                              {`${index + 1}. ${row.child_name}`}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={style.cellStylehealth}
                              sx={{ bgcolor: "#cefbc9" }}
                            >
                              {row.paid_by}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={style.cellStylehealth}
                              sx={{ bgcolor: "#cefbc9" }}
                            >
                              {row.child_care_school_yearly
                                ? "$" + row.child_care_school_yearly
                                : "0"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={style.cellStylehealth}
                              sx={{ bgcolor: "#cefbc9" }}
                            >
                              {row.child_care_summer_yearly
                                ? "$" + row.child_care_summer_yearly
                                : "0"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={style.cellStylehealth}
                              sx={{ bgcolor: "#cefbc9" }}
                            >
                              {row.child_care_other_breaks_yearly
                                ? "$" + row.child_care_other_breaks_yearly
                                : "0"}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={style.cellStylehealth}
                              sx={{ bgcolor: "#cefbc9" }}
                            >
                              {row.child_care_preschool_disability_yearly
                                ? "$" +
                                  row.child_care_preschool_disability_yearly
                                : ""}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                  </TableBody>
                  <TableBody>
                    {summaryData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          className={style.cellStylehealth}
                          colSpan={2}
                        >
                          {row.description}
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "right!important" }}
                          className={style.cellStylehealth}
                        >
                          {row.amount}
                        </TableCell>
                        <TableCell
                          className={style.cellStylehealth}
                          colSpan={2}
                        >
                          {row.averageDescription}
                        </TableCell>
                        <TableCell
                          className={style.cellStylehealth}
                          sx={{ textAlign: "right!important" }}
                        >
                          {row.averageAmount}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : null}
        </>
      ) : (
        <Box textAlign={"center"} my={"4rem"}>
          <Box
            component="img"
            src={FootnoteImage}
            alt="Footnotes"
            sx={{
              width: { xs: "70%", md: "25%" },
              height: "auto",
            }}
          />
          <Typography className={style.contentHeading}>
            No Information To Display
          </Typography>
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <SearchWorksheetButtons />
      </Box>
    </>
  );
};

export default HealthInsuranceTab;
