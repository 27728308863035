import React from "react";
import CommonIcon from "./CommonIcon";

interface Option {
  value: string;
  label: string;
  icon: React.ReactElement;
}

const ExportOptionData = (selectedValue: string): Option[] => [
  {
    value: "Export",
    label: "Export Data",
    icon: <CommonIcon type="Export" selectedValue={selectedValue} />,
  },
  {
    value: "html",
    label: "HTML",
    icon: <CommonIcon type="HTML" selectedValue={selectedValue} />,
  },
  {
    value: "csv",
    label: "CSV",
    icon: <CommonIcon type="CSV" selectedValue={selectedValue} />,
  },
  {
    value: "text",
    label: "Text",
    icon: <CommonIcon type="Text" selectedValue={selectedValue} />,
  },
  {
    value: "excel",
    label: "Excel",
    icon: <CommonIcon type="Excel" selectedValue={selectedValue} />,
  },
  {
    value: "pdf",
    label: "PDF",
    icon: <CommonIcon type="PDF" selectedValue={selectedValue} />,
  },
  {
    value: "json",
    label: "JSON",
    icon: <CommonIcon type="JSON" selectedValue={selectedValue} />,
  },
];

export default ExportOptionData;
