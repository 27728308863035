import React, { ChangeEvent, useState } from "react";
import { Box, IconButton, CircularProgress } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import CustomInput from "../../components/inputField";
import { Card, CardContent, Typography, Button } from "@mui/material";
import loginService from "../../services/login-service";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { loginDetails } from "../../globalState/actions/authAction";

import { showToast } from "../../components/toastNotification/index";

interface LoginData {
  email: string;
  password: string;
}

const LoginForm: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState<LoginData>({
    email: "",
    password: ""
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailValidation, setEmailValidation] = useState<string>("");
  const [passwordValidation, setPasswordValidation] = useState<string>("");
  const handleTextData = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
    if (e.target.name === "email") {
      setEmailValidation("");
    }
    if (e.target.name === "password") {
      setPasswordValidation("");
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  const formValidation = () => {
    const emailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let isValidation;
    if (data.email.trim() === "") {
      setEmailValidation("Email Address cannot be blank");
      isValidation = true;
    } else {
      if (!emailReg.test(data.email.trim())) {
        setEmailValidation("Email Address is invalid");
      } else {
        setEmailValidation("");
      }
    }
    if (data.password === "") {
      setPasswordValidation("Password cannot be blank");
      isValidation = true;
    }

    return isValidation;
  };

  const handleSubmitForm = () => {
    if (!formValidation()) {
      setIsLoading(true);
      const inputData = {
        email: data.email.trim(),
        password: data.password
      };
      loginService
        .userLogin(inputData)
        .then((response) => {
          if (response && response?.data.message === "Login Successful") {
            let loginData = {};
            if (response?.data.user.invite_status === "Rejected") {
              loginData = {
                ...response?.data.user,
                invite_from_org: false,
                isUserLoggedIn: true
              };
            } else {
              loginData = {
                ...response?.data.user,
                isUserLoggedIn: true
              };
            }

            dispatch(loginDetails(loginData));
            localStorage.setItem("token", response?.data.access_token);
            navigate("/");
          } else {
            showToast(response?.data.message, "error");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error, "Login Error");
        });
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Card
          sx={{
            minWidth: 200,
            maxWidth: 370,
            my: 5,
            mx: 2,
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "40px",
            border: "1px solid #DFDFDF",
            opacity: "1px",
            boxShadow: "none"
          }}
        >
          <CardContent sx={{ textAlign: "center" }}>
            <Typography
              variant="h5"
              color="#0464AB;"
              gutterBottom
              sx={{
                fontSize: 24,
                letterSpacing: "1px",
                marginBottom: "0px"
              }}
              className="h5"
            >
              Login
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              fontSize={11}
              fontWeight={540}
              sx={{
                borderBottom: "1px solid #DFDFDF",
                p: 1,
                color: "#000000"
              }}
            >
              As a proactive security measure, passwords created before January
              5, 2021 must be reset using this{" "}
              <Link to="/resetPassword" className="linkLbl">
                Reset
              </Link>{" "}
              it link. Once you have reset your password, you may login as
              usual. If you need assistance, you may reach Child Support
              Commission Staff by clicking{" "}
              <Link to="/contact" className="linkLbl">
                Contact
              </Link>{" "}
              . Thank you.{" "}
            </Typography>
            <Box sx={{ pt: 1 }}>
              <form>
                <Box mb={2}>
                  <CustomInput
                    onChange={handleTextData}
                    value={data.email}
                    label="Email"
                    name="email"
                    type="textarea"
                    required={true}
                    placeholder="Email"
                    error={emailValidation !== "" ? emailValidation : ""}
                  />
                </Box>
                <Box>
                  <CustomInput
                    onChange={handleTextData}
                    value={data.password}
                    label="Password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required={true}
                    placeholder="Password"
                    error={passwordValidation !== "" ? passwordValidation : ""}
                    endAdornment={
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    }
                  />
                </Box>

                <Typography
                  variant="subtitle2"
                  gutterBottom
                  fontSize={11}
                  sx={{
                    pb: 2,
                    fontWeight: 600,
                    color: "#575757",
                    marginTop: "30px",
                    lineHeight: 2
                  }}
                >
                  If you forgot your password you can{" "}
                  <Link
                    to="/resetPassword"
                    style={{ color: "#1976d2", fontWeight: 700 }}
                  >
                    Reset
                  </Link>{" "}
                  it
                </Typography>
                <Button
                  variant="contained"
                  disabled={isLoading}
                  disableElevation
                  onClick={handleSubmitForm}
                  sx={{
                    borderRadius: "21px",
                    backgroundColor: "#0464AB",
                    width: 160,
                    height: 38,
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#FBB522",
                      color: "#022E4C",
                      fontWeight: "600",
                      borderColor: "#FBB522"
                    }
                  }}
                >
                  Login{" "}
                  {isLoading && (
                    <CircularProgress size={18} sx={{ padding: "0px 8px" }} />
                  )}
                </Button>
              </form>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default LoginForm;
