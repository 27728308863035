import { CreateWorksheetInitialState } from ".";

export const GrossIncomeScheduleInput1: Array<
  keyof CreateWorksheetInitialState
> = [
  "gross_salary1",
  "commissions1",
  "self_employment_income1",
  "bonuses1",
  "overtime_payment1",
  "severence_pay1",
  "recurring_income1",
  "interest_income1",
  "income_from_dividends1",
  "trust_income1",
  "income_from_annuities1",
  "capital_gains1",
  "social_security_disablity_benefits1",
  "federal_veterans_disability_benefits1",
  "workers_compensation_benefits1",
  "unemployment_benefits1",
  "judgements1",
  "gifts1",
  "prizes1",
  "alimony1",
  "assets1",
  "fringe_benefits1",
  "other_income1"
];

export const GrossIncomeScheduleInput2: Array<
  keyof CreateWorksheetInitialState
> = [
  "gross_salary2",
  "commissions2",
  "self_employment_income2",
  "bonuses2",
  "overtime_payment2",
  "severence_pay2",
  "recurring_income2",
  "interest_income2",
  "income_from_dividends2",
  "trust_income2",
  "income_from_annuities2",
  "capital_gains2",
  "social_security_disablity_benefits2",
  "federal_veterans_disability_benefits2",
  "workers_compensation_benefits2",
  "unemployment_benefits2",
  "judgements2",
  "gifts2",
  "prizes2",
  "alimony2",
  "assets2",
  "fringe_benefits2",
  "other_income2"
];
