import {
  httpCommon,
  httpTokenCommon,
  httpTokenFileDownload
} from "./interceptor";

interface tableList {
  pageIndex: number;
  perPage: number;
  tableSearchData: {
    description: string;
  };
  tableOrdering: string;
}
interface addUpdateAdminSystem {
  description: string;
}

class SystemMessageService {
  getAdminSystemMessageDataAPI(inputdata: tableList) {
    const { pageIndex, perPage, tableSearchData, tableOrdering } = inputdata;
    return httpCommon.get(
      `system_messages_api/system_messages/?limit=${perPage}&page=${pageIndex}&description=${tableSearchData.description}&ordering=${tableOrdering}`
    );
  }
  addAdminSystemMessageAPI(inputdata: addUpdateAdminSystem) {
    return httpTokenCommon.post(
      "system_messages_api/system_messages/",
      inputdata
    );
  }
  updateAdminSystemMessageAPI(inputdata: addUpdateAdminSystem, id: number) {
    return httpTokenCommon.put(
      `system_messages_api/system_messages/${id}/`,
      inputdata
    );
  }
  deleteSystemDataAPI(id: number) {
    return httpTokenCommon.delete(`system_messages_api/system_messages/${id}/`);
  }
  getDownloadSystemDataAPI(key: string, download_ids: number[]) {
    return httpTokenFileDownload.get(
      `system_messages_api/system_messages/download/?download_format=${key}&download_ids=${download_ids}`
    );
  }
}

const systemMessageService = new SystemMessageService();
export default systemMessageService;
