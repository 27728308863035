interface ProposedLowIncomeDeviation {
  deviation_is_for: string;
  number_of_children: number;
  max_amount_low_income_deviation: string;
  low_income_deviation: string;
  low_income_deviation_explanation: string;
  id?: string;
}
interface JudicialDiscretionForLowIncome {
  id: number;
  deviation_is_for: string;
  number_of_children: number;
  max_amount_low_income_deviation: string;
  proposed_low_income_deviation?: string;
  low_income_deviation: string;
  low_income_deviation_explanation: string;
}
export const GetLowIncomeDeviationAmt = (
  proposedDeviation: ProposedLowIncomeDeviation[],
  judicialIncome: JudicialDiscretionForLowIncome[],
  parent1: string,
  parent2: string
): { [key: string]: string } => {

  const results: { [key: string]: string } = {};

  const calculateForParent = (parent: string) => {
    const proposed = proposedDeviation?.find(
      (d) => d.deviation_is_for === parent
    );
    const judicial = judicialIncome?.find((j) => j.deviation_is_for === parent);

    // Initialize amountB with a default value
    let amountB: string = "";

    if (!proposed && !judicial) {
      results[parent] = ""; // No data available
      return;
    }

    const maxAmount = parseFloat(
      proposed?.max_amount_low_income_deviation ?? "0"
    );
    const proposedAmount = proposed
      ? parseFloat(proposed.low_income_deviation)
      : 0;
    const judicialAmount = judicial
      ? parseFloat(judicial.low_income_deviation)
      : 0;

    if (proposed && !judicial) {
      // Only proposedDeviation is filled
      if (proposedAmount <= maxAmount) {
        amountB = proposed.low_income_deviation; // Both 1(a) and 1(b) are the proposed amount
      } else {
        amountB = proposed.max_amount_low_income_deviation; // 1(b) is the max amount
      }
      results[parent] = amountB;
    } else if (proposed && judicial) {
      // Both proposedDeviation and judicialIncome are filled
      if (proposedAmount <= maxAmount && judicialAmount <= maxAmount) {
        amountB = judicial.low_income_deviation; // Both amounts are within limits
      } else {
        // At least one amount is greater than the max amount
        amountB =
          proposedAmount > maxAmount
            ? proposed.max_amount_low_income_deviation // 1(b) is max amount
            : judicialAmount.toString(); // 1(a) is judicial amount
      }
      results[parent] = amountB;
    } else if (!proposed && judicial) {
      // Only judicialIncome is filled
      if (judicialAmount <= maxAmount) {
        amountB = judicial.low_income_deviation; // 1(b) is judicial amount
      } else {
        amountB = ""; // If no proposed, just an empty string or default
      }
    }
    results[parent] = amountB;
  };

  // Calculate results for both parents
  calculateForParent(parent1);
  calculateForParent(parent2);

  return {
    result1: results[parent1] || "",
    result2: results[parent2] || "",
  };
};
