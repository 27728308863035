import { httpTokenCommon } from "./interceptor";

class TechnicalReportService {
  reportTechnicalIssueAPI(inputdata: {
    worksheet_id: number;
    email: string;
    browser: string | null;
    name: string | null;
    phone: number | null;
    technical_issue: string;
  }) {
    return httpTokenCommon.post(
      "report_technical_issue_api/report_technical_issue/",
      inputdata
    );
  }
}

const technicalReportService = new TechnicalReportService();
export default technicalReportService;
