import { httpCommon } from "./interceptor";

class LoginService {
  userLogin(inputdata: { email: string; password: string }) {
    return httpCommon.post("users/login/", inputdata);
  }
}

const loginService = new LoginService();
export default loginService;
