import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import rootReducer from "./allReducers";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "rootData",
  storage: storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistedStore = persistStore(store);
export type AppDispatch = typeof store.dispatch;

export { persistedStore, store };
